import React, { useEffect } from 'react';
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import BSWheelerBanner from "../Components/Buy_Sell/BSWheelerBanner";
import BSFourWheelerContent from "../Components/Buy_Sell/BSFourWheelerContent";
export default function BSFourWheeler() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="innerpages-nav">
      <div id="container">
      <Navigation />
      <BSWheelerBanner />
      <BSFourWheelerContent />
      <Footer />
      </div>
    </div>
  );
}
