import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import AdminNavbar from "../Navbar/AdminNavbar";
import WebsiteAllLeads from "./WebsiteAllLeads";
import WebsiteAllAuction from "./WebsiteAllAuction";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export default function WebsiteLeads() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="Website Leads Tabs" style={{marginLeft:"227px",marginTop:"80px"}}>
          <Tab label="All Service Views" />
          {/* <Tab label="All Auction Views" /> */}
        </Tabs>
        <div className="main" style={{marginTop:"50px"}}>
          {value === 0 && <WebsiteAllLeads />}
          {/* {value === 1 && <WebsiteAllAuction />} */}
        </div>
      </Box>
    </div>
  );
}
