import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { fetchContactsAPI } from '../../../../Api/v1/FreelancerPartner/leads'; // Removed unused import
import MUIDataTable from 'mui-datatables';
import 'react-toastify/dist/ReactToastify.css';
import styles from './css/ListLeadContact.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { deleteAPI, fetchWithQueryAPI } from '../../../../Api/v1/Util/Api';
import { contacts_delete, contacts_fetch_by_reference } from '../../../../Api/APIUrl';
import { showErrorMsg, showSuccessMsg } from '../../../../Store/util';
import Swal from 'sweetalert2';

export default function ListLeadContactComponent({showFullNav,routeProps,lableProps,userTypeProps,isClientProps}) {
    const [leads, setLeads] = useState([]);
    const state = useSelector((state) => state);
    const navigate = useNavigate();
    const { id } = useParams();

    const fetchContacts = async () => {
        try {
            const response = await fetchWithQueryAPI(state.auth.auth.token, contacts_fetch_by_reference+id);
            if (response && response.statusCode === 200) {
                setLeads(response.data);
            } else {
                toast.error("Failed to fetch contacts"); // Updated the error message
            }
        } catch (error) {
            toast.error("An error occurred while fetching contacts"); // Updated the error message
        }
    };

    const deleteContact = async (contactId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this contact!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await deleteAPI(state.auth.auth.token, contacts_delete, { _id: contactId });
                    if (response && response.statusCode === 200) {
                        showSuccessMsg(response.message);
                        fetchContacts();
                    } else {
                        showErrorMsg(response.message || response.error?.response?.data?.message || 'An error occurred');
                    }
                } catch (error) {
                    toast.error("An error occurred while deleting the contact"); // Updated the error message
                }
            }
        });
    };

    useEffect(() => {
        fetchContacts();
    }, [id]); // Added `id` to dependency array to refetch on `id` change

    const handleEdit = (contactId) => {
        navigate(`/${userTypeProps}/`+routeProps+`/edit-contact/${id}/${contactId}`);
    };
    

    const columns = [
        { name: 'first_name', label: 'First Name' },
        { name: 'last_name', label: 'Last Name' },
        { name: 'email_id', label: 'Email Id' },
        { name: 'phone_number', label: 'Phone Number' },
        { name: 'job_title', label: 'Job Title' },
        { name: 'gender', label: 'Gender' },
        {
            name: '_id',
            label: 'Actions',
            options: {
                customBodyRender: (value, tableMeta) => (
                    <>
                        <IconButton onClick={() => handleEdit(value)} color="secondary">
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => deleteContact(value)} aria-label="delete">
                            <DeleteIcon style={{ color: 'red' }} />
                        </IconButton>
                    </>
                ),
            },
        },
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
        customToolbarSelect: () => null, // Hide default selected rows toolbar
    };

    const customTheme = createTheme({
        components: {
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#dc3545', // Change this to your desired color
                        color: 'white',
                    },
                },
            },
            MuiTablePagination: {
                styleOverrides: {
                    toolbar: {
                        backgroundColor: '#dc3545', // Change the background color for the pagination toolbar
                        color: 'white',
                    },
                },
            },
        },
    });

    return (
        <>
            <ToastContainer />
            <div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary mb-2" style={{float:"right"}} onClick={() => navigate(`/${userTypeProps}/${routeProps}/create-contact/${id}`)}>
                            Create New Contact 
                        </button>
                    </div>
                </div>
                <ThemeProvider theme={customTheme}>
                    <MUIDataTable
                        title={`Contact List  (${leads.total_count ?? 0})`}
                        data={leads.contacts}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>
        </>
    );
}
