import React, { useState } from "react";
import AdminNavbar from "../../../../Admin/Component/Navbar/AdminNavbar";
import { Tabs, Tab } from 'react-bootstrap';
import EmployeeAttendance from "../../../../Admin/Component/v1/AttendanceReport/EmployeeAttendance";
import EmployeeAttendanceReport from "../../../../Admin/Component/v1/AttendanceReport/EmployeeAttendanceReport";

export default function AttendanceReport() {
  const [activeTab, setActiveTab] = useState('employeeReport');

  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
  };

  return (
    <>
      <AdminNavbar title={'Employee Attendance Report'} />
      <div className="main">
      <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="mb-3">
        <Tab eventKey="employeeReport" title="Employee Attendance">
          <EmployeeAttendance/>
        </Tab>
        <Tab eventKey="summaryReport" title="Summary Report">
          <EmployeeAttendanceReport/>
        </Tab>
      </Tabs>
      </div>
    </>
  );
}
