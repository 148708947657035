import React, { useEffect } from 'react';

import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import BSVillaBanner from "../Components/Buy_Sell/BSVillaBanner";
import BSVillaContent from "../Components/Buy_Sell/BSVillaContent";
export default function BSVilla() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="innerpages-nav">
       <div id="container" >
      <Navigation />
      <BSVillaBanner />
      <BSVillaContent />
      <Footer />
      </div>
    </div>
  );
}
