import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../Components/Footer/Footer";
import './css/PartnerWithUs.css';
import UserNavigation from '../Components/v1/NavBar/Navigaton';
import { showToastErrorMsg, showToastSuccessMsg } from "../Store/toastMsg";
import { partnerSubmit } from "../Api/v1/leads/Api";

const PartnerWithUs = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    email_id: "",
    occupation: "",
    state: "",
    district: ""
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.first_name) newErrors.first_name = "First Name is required";
    if (!formData.last_name) newErrors.last_name = "Last Name is required";
    if (!formData.mobile_number) newErrors.mobile_number = "Mobile number is required";
    if (!formData.email_id) newErrors.email_id = "Email is required";
    if (!formData.occupation) newErrors.occupation = "Occupation is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.district) newErrors.district = "District is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);

    const response = await partnerSubmit(JSON.stringify(formData));
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setFormData({
        first_name: "",
        last_name: "",
        mobile_number: "",
        email_id: "",
        occupation: "",
        state: "",
        district: ""
      });
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }

    setIsSubmitting(false);
  };

  const getIndianStates = () => [
    "Andhra Pradesh",
    "Telangana",
    "Keralam",
    "Tamil Nadu",
    "Karnataka"
  ];

  const getDistricts = (state) => {
    switch (state) {
      case "Andhra Pradesh":
        return [
          "Srikakulam",
          "Parvathipuram Manyam",
          "Vizianagaram",
          "Visakhapatnam",
          "Alluri Sitharama Raju",
          "Anakapalli",
          "Kakinada",
          "East Godavari",
          "Konaseema",
          "Eluru",
          "West Godavari",
          "NTR",
          "Krishna",
          "Palnadu",
          "Guntur",
          "Bapatla",
          "Sri Potti Sriramulu Nellore",
          "Prakasam",
          "Kurnool",
          "Nandyal",
          "Anantapuramu",
          "Sri Sathya Sai",
          "YSR",
          "Annamayya",
          "Tirupati",
          "Chittoor"
        ];
      case "Telangana":
        return [
          "Adilabad",
          "Kumuram Bheem Asifabad",
          "Mancherial",
          "Nirmal",
          "Nizamabad",
          "Jagtial",
          "Peddapalli",
          "Kamareddy",
          "Rajanna Sircilla",
          "Karimnagar",
          "Jayashankar Bhupalpally",
          "Sangareddy",
          "Medak",
          "Siddipet",
          "Jangaon",
          "Hanumakonda",
          "Warangal",
          "Mulugu",
          "Bhadradri Kothagudem",
          "Khammam",
          "Mahabubabad",
          "Suryapet",
          "Nalgonda",
          "Yadadri Bhuvanagiri",
          "Medchal–Malkajgiri",
          "Hyderabad",
          "Ranga Reddy",
          "Vikarabad",
          "Narayanpet",
          "Mahabubnagar",
          "Nagarkurnool",
          "Wanaparthy",
          "Jogulamba Gadwal"
        ];
      case "Keralam":
        return [
          "Alappuzha",
          "Ernakulam",
          "Idukki",
          "Kannur",
          "Kasaragod",
          "Kollam",
          "Kottayam",
          "Kozhikode",
          "Malappuram",
          "Palakkad",
          "Pathanamthitta",
          "Thiruvananthapuram",
          "Thrissur",
          "Wayanad"
        ];
      case "Tamil Nadu":
        return [
          "Ariyalur",
          "Chengalpattu",
          "Chennai",
          "Coimbatore",
          "Cuddalore",
          "Dharmapuri",
          "Dindigul",
          "Erode",
          "Kallakurichi",
          "Kancheepuram",
          "Kanyakumari",
          "Karur",
          "Krishnagiri",
          "Madurai",
          "Mayiladuthurai",
          "Nagapattinam",
          "Namakkal",
          "Nilgiris",
          "Perambalur",
          "Pudukkottai",
          "Ramanathapuram",
          "Ranipet",
          "Salem",
          "Sivaganga",
          "Tenkasi",
          "Thanjavur",
          "Theni",
          "Thoothukudi",
          "Tiruchirappalli",
          "Tirunelveli",
          "Tirupathur",
          "Tiruppur",
          "Tiruvallur",
          "Tiruvannamalai",
          "Tiruvarur",
          "Vellore",
          "Viluppuram",
          "Virudhunagar"
        ];
      case "Karnataka":
        return [
          "Bagalkote",
          "Bengaluru Urban",
          "Bengaluru Rural",
          "Belagavi",
          "Ballari",
          "Bidar",
          "Vijayapura",
          "Chamarajanagar",
          "Chikkaballapura",
          "Chikkamagaluru",
          "Chitradurga",
          "Dakshina Kannada",
          "Davanagere",
          "Dharwad",
          "Gadag",
          "Kalaburagi",
          "Hassan",
          "Haveri",
          "Kodagu",
          "Kolar",
          "Koppal",
          "Mandya",
          "Mysuru",
          "Raichur",
          "Ramanagara",
          "Shivamogga",
          "Tumakuru",
          "Udupi",
          "Uttara Kannada",
          "Vijayanagara",
          "Yadgiri"
        ];
      default:
        return [];
    }
  };
  

  return (
    <div className="home-nav">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Partner With Us | DALMaf Private Limited</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Join us at DALMaf Private Limited and explore the opportunities of partnering with us. We provide numerous benefits and growth opportunities for our partners." />
        <meta name="keywords" content="DALMaf, partnership, business growth, collaboration, partners" />
        <meta name="author" content="DALMaf" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <UserNavigation/>
      <div className="container mt-5">
        <div className="row row-equal-height d-flex">
          <div className="col-md-6 col-sm-12 mb-4">
            <div className="card form-container h-100">
              <h2 className="section-title">Why Partner with Dalmaf?</h2>
              <ul className="list-group list-group-flush h-100">
                <li className="list-group-item"><i className="fas fa-check-circle me-2"></i>We give 10-20 confirmed orders to each local partner on a daily basis.</li>
                <li className="list-group-item"><i className="fas fa-check-circle me-2"></i>You can increase your income by 50% every month and gain more loyal customers every day.</li>
                <li className="list-group-item"><i className="fas fa-check-circle me-2"></i>We have approximately 500 Partners in South India (CA, CS, CMA & Lawyers).</li>
                <li className="list-group-item"><i className="fas fa-check-circle me-2"></i>You can even outsource any work to us which you feel is beyond your practice area.</li>
                <li className="list-group-item"><i className="fas fa-check-circle me-2"></i>Gain a team of 100+ CA, CS, and Legal professionals.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="card form-container h-100">
              <div className="card-header">
                <h2 className="section-title text-center">Join Us</h2>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6 com-sm-12">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleChange}
                        />
                        {errors.first_name && <small className="text-danger">{errors.first_name}</small>}
                      </div>
                    </div>
                    <div className="col-md-6 com-sm-12">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          name="last_name"
                          value={formData.last_name}
                          onChange={handleChange}
                        />
                        {errors.last_name && <small className="text-danger">{errors.last_name}</small>}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mobile Number"
                          name="mobile_number"
                          value={formData.mobile_number}
                          onChange={handleChange}
                        />
                        {errors.mobile_number && <small className="text-danger">{errors.mobile_number}</small>}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email Id"
                          name="email_id"
                          value={formData.email_id}
                          onChange={handleChange}
                        />
                        {errors.email_id && <small className="text-danger">{errors.email_id}</small>}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <select
                          className="form-select"
                          name="occupation"
                          value={formData.occupation}
                          onChange={handleChange}
                        >
                          <option value="">Select Occupation</option>
                          <option value="Advocate">Advocate</option>
                          <option value="Angel Investor">Angel Investor</option>
                          <option value="Chartered Accountant">Chartered Accountant</option>
                          <option value="Company Secretary">Company Secretary</option>
                          <option value="Cost Accountant">Cost Accountant</option>
                          <option value="Ex-Tax Officer">Ex-Tax Officer</option>
                          <option value="Management Team (CXO)">Management Team (CXO)</option>
                          <option value="Retired Judge">Retired Judge</option>
                          <option value="Tax Expert">Tax Expert</option>
                        </select>
                        {errors.occupation && <small className="text-danger">{errors.occupation}</small>}
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <select
                          className="form-select"
                          name="state"
                          value={formData.state}
                          onChange={handleChange}
                        >
                          <option value="">Select State</option>
                          {getIndianStates().map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                        {errors.state && <small className="text-danger">{errors.state}</small>}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <select
                          className="form-select"
                          name="district"
                          value={formData.district}
                          onChange={handleChange}
                        >
                          <option value="">Select District</option>
                          {getDistricts(formData.state).map((district, index) => (
                            <option key={index} value={district}>
                              {district}
                            </option>
                          ))}
                        </select>
                        {errors.district && <small className="text-danger">{errors.district}</small>}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                          {isSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default PartnerWithUs;
