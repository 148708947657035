import React, { useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RevenueCountDashboard from "./RevenueCountDashboard";
import RevenueHistoryDashboard from "./RevenueHistoryDashboard";
import { Tab, Nav } from 'react-bootstrap';
import AttendanceDefaulterList from "./AttendanceDefaulterList";
import StatisticsAttendanceDashboard from "./StatisticsAttendanceDashboard";

const AttendanceDashboard = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('count');

  return (
    <>
      <AdminNavbar title={"Attendance Dashboard"} />
      <div className="main">
        {state?.auth?.auth?.role === "superadmin" ? (
          <Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="count">Attendance Statistics</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="history">Attendance Defaulter List</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="count">
                <StatisticsAttendanceDashboard />
              </Tab.Pane>
              <Tab.Pane eventKey="history">
                <AttendanceDefaulterList />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        ) : (
          <p>Unauthorized Access</p>
        )}
      </div>
    </>
  );
};

export default AttendanceDashboard;
