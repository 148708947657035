import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import Swal from "sweetalert2";

import {
  GetLead,
  GetUser,
  UpdateLead,
  DeleteLead,
  AdminLeadJob,
  GetAllSubAdminAndAdmin,
  GetLeadAll,
} from "../../../Api/Api";
import { useSelector } from "react-redux";
import moment from "moment";
// import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DateValidation,
  NonEmptyValidation,
  StringValidation,
  EmailValidation,
  PhoneNumberValidation,
  PincodeValidation,
  NonEmptyValidationDes,
  NonEmptyValidationForDate,
  DateValidationn,
} from "../../../Store/validate";

import Spinner from "../../../Spinner/Spinner";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { ContentState, EditorState, convertToRaw } from "draft-js";
// import draftToHtml from "draftjs-to-html";

export default function Lead() {
  const [selectedTab, setSelectedTab] = React.useState("All");
  const AllTabs = ["All", "Open", "In Progress", "Hold", "Converted", "Rejected"];

  const navigate = useNavigate();
  const [validation, setValidation] = useState({});

  const [newLeadTask, setLeadNewTask] = useState(true);

  const [loading, setLoading] = useState(true);

  const [getMyLead, setGetMyLead] = useState([]);
  // const [getEmployee, setGetEmployee] = useState([]);

  const state = useSelector((state) => state);

  //getlead
  const GetMyLead = async (status) => {
    setLoading(true);
    const token = state.auth.auth.token;
    const role = state.auth.auth.role;
    // const creator_id = state.auth.auth._id;
    let data;
    if (role === "subadmin") {
      data = await GetLead(token, status, "employee");
    } else {
      data = await GetLead(token, status);
    }

    if (data && data.statusCode === 200) {
      let temp = [];
      data.data.map((data, i) => {
        let assingedTo = getEmployee.filter((employeeData) => employeeData._id === data.name).map((e) => {
          return e.name;
        })

        let createdBy = getEmployee.filter((employeeData) => employeeData._id === data.createdBy).map((e) => {
          return e.name;
        })
        let dummy = {
          _id: data?._id,
          clientName: data?.clientName,
          serviceRequest: data?.serviceRequest,
          createdBy: createdBy,
          assingedTo: assingedTo,
          createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
          admin_read: data?.admin_read,
          subadmin_read: data?.subadmin_read,
          status: data?.status,
        };
        temp.push(dummy);
        return null;
      });
      setGetMyLead(temp);
      setLoading(false);
    } else {
      setGetMyLead([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };
  let getEmployee = [];
  const getAllEmployee = async () => {
    const token = state.auth.auth.token;
    let data = await GetAllSubAdminAndAdmin(token)
    // console.log("Employee data",data.data)
    getEmployee = data.data;
    // setGetEmployee(data.data);
    // console.log("setGetEmployee ",getEmployee)
    FetchLeadProfile();

  }

  //getlead
  const GetMyLeadAll = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    const role = state.auth.auth.role;
    // const creator_id = state.auth.auth._id;
    let data;
    if (role === "subadmin") {
      data = await GetLeadAll(token, "employee");
    } else {
      data = await GetLeadAll(token);
    }
    if (data && data.statusCode === 200) {
      let temp = [];
      data.data.map((data, i) => {
        let assingedTo = getEmployee.filter((employeeData) => employeeData._id === data.name).map((e) => {
          return e.name;
        })
        // console.log("assingedTo",assingedTo);
        let createdBy = getEmployee.filter((employeeData) => employeeData._id === data.createdBy).map((e) => {
          return e.name;
        })
        // console.log("createdBy",createdBy);
        let dummy = {
          _id: data?._id,
          clientName: data?.clientName,
          serviceRequest: data?.serviceRequest,
          createdBy: createdBy,
          assingedTo: assingedTo,

          createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
          admin_read: data?.admin_read,
          subadmin_read: data?.subadmin_read,

          status: data?.status,
        };
        temp.push(dummy);
        return null;
      });
      setGetMyLead(temp);
      setLoading(false);
    } else {
      setGetMyLead([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const [checked, setChecked] = useState(true);

  const [Leadprofile, setLeadProfileDetails] = useState([]);
  async function FetchLeadProfile() {
    setLoading(true);
    const id = state?.auth?.auth?._id;
    const token = state?.auth?.auth?.token;
    const getLeadBasicDetails = await GetUser(token, id);
    if (getLeadBasicDetails && getLeadBasicDetails?.statusCode === 200) {
      setLeadProfileDetails(getLeadBasicDetails.data);
      GetAllLeadSubadmins();
      GetMyLeadAll();
      setLoading(false);
    } else {
      setLeadProfileDetails([]);
      setLoading(false);
      //console.log("error while getting user profile info");
    }
  }

  useEffect(() => {
    window.scroll(0, 0);
    getAllEmployee();


    // GetMyLead("Inprogress");

  }, []);

  const [myLeadTask, setMyLeadTask] = useState({});
  const [myLeadTaskValidation, setMyLeadTaskValidation] = useState({});

  const [editorLeadState, setLeadEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const setMyLeadTaskValue = (key, value) => {
    setMyLeadTask({ ...myLeadTask, [key]: value });
    if (myLeadTaskValidation[key]) delete myLeadTaskValidation[key];
  };

  const setMyTaskLeadValidationValue = (key, value) => {
    setMyLeadTaskValidation({ ...myLeadTaskValidation, [key]: value });
  };

  const handleLeadEditorChange = (state) => {
    setLeadEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setMyLeadTaskValue("notes", html);
      setMyTaskLeadValidationValue("notes", NonEmptyValidationDes(html));
    }
  };

  // const [allLeadSubadmin, setAllLeadSubadmin] = useState([]);
  const GetAllLeadSubadmins = async () => {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    let data = await GetAllSubAdminAndAdmin(token);
    if (data && data.statusCode === 200) {
      setAllLeadSubadmin(data.data);
      setLoading(false);
    } else {
      setAllLeadSubadmin([]);
      setLoading(false);
      //console.log("Error while getting all subadmins");
    }
  };

  // let assignName = allLeadSubadmin?.filter((e) => {
  //   return e._id === myLeadTask?.name;
  // });
  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  const [allLeadSubadmin, setAllLeadSubadmin] = useState([]);

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.name = NonEmptyValidation(
      myLeadTask?.name
    );
    validate.date = NonEmptyValidationForDate(myLeadTask?.date);
    validate.clientName = StringValidation(myLeadTask?.clientName);
    validate.typeOfEntity = NonEmptyValidation(myLeadTask?.typeOfEntity);
    validate.mobileNumber = PhoneNumberValidation(myLeadTask?.mobileNumber);
    validate.email = EmailValidation(myLeadTask?.email?.toLowerCase());
    validate.professional = NonEmptyValidation(myLeadTask?.professional);
    validate.occupation = NonEmptyValidation(myLeadTask?.occupation);
    validate.address = NonEmptyValidation(myLeadTask?.address);
    validate.pinCode = PincodeValidation(myLeadTask?.pinCode);
    validate.city = StringValidation(myLeadTask?.city);
    validate.state = StringValidation(myLeadTask?.state);
    validate.notes = NonEmptyValidationDes(myLeadTask?.notes);
    validate.serviceRequest = NonEmptyValidation(myLeadTask?.serviceRequest);
    if (myLeadTask?.serviceRequest === "Others") {
      validate.serviceRequest = NonEmptyValidation(myLeadTask?.otherServiceRequest);
    }

    setMyLeadTaskValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let Payload = {
        name: myLeadTask?.name ? myLeadTask?.name : state?.auth?.auth?._id,
        date: myLeadTask.date,
        clientName: myLeadTask.clientName,
        typeOfEntity: myLeadTask.typeOfEntity,
        mobileNumber: myLeadTask.mobileNumber,
        email: myLeadTask.email?.toLowerCase(),
        pinCode: myLeadTask.pinCode,
        professional: myLeadTask.professional,
        occupation: myLeadTask.occupation,
        address: myLeadTask.address,
        state: myLeadTask.state,
        city: myLeadTask.city,
        serviceRequest: myLeadTask.serviceRequest=== "Others" ?  myLeadTask?.otherServiceRequest: myLeadTask.serviceRequest ,
        notes: myLeadTask.notes,
      };

      const token = state?.auth?.auth?.token;
      const response = await AdminLeadJob(Payload, token);
      if (response && response.statusCode === 200) {
        const editorStateReset = EditorState.push(
          editorLeadState,
          ContentState.createFromText("")
        );
        setLeadEditorState(editorStateReset);
        setLeadNewTask(true);
        setMyLeadTask({});
        GetMyLead();
        window.location.reload();
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };

  const handleLeadSubmitUpdate = async (e, value, id, value1) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
    };

    const token = state?.auth?.auth?.token;
    const response = await UpdateLead(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      window.location.reload();

      GetMyLead(value1);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };
  const [property, setProperty] = useState({});
  const setData = (e, key) => {
    setProperty({ ...property, [key]: e });
    if (validation[key]) delete validation[key];
  };

  const handleSubmitDelete = async (e, id, value) => {
    e.preventDefault();

    const token = state?.auth?.auth?.token;
    const response = await DeleteLead(id, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);

      GetMyLead("open");

      //  window.location.reload()
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };
  const handleRowClick = (rowData, rowMeta) => {
    const id = getMyLead[rowMeta?.dataIndex]?._id;
    navigate(`/LeadEdit/${id}`);
  };
  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "clientName",
      label: "Client Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer" }}
              // onClick={(val) => {
              //   navigate(`/LeadEdit/${tableMeta.rowData[0]}`);
              // }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "serviceRequest",
      label: "Service Request",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "assingedTo",
      label: "Assinged To",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "createdBy",
      label: "Created By",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "createdAt",
      label: "Created Date",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },

    {
      name: "status",
      label: " Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>{value}</div>
            // <select
            //   className="form-control form-control-lg"
            //   defaultValue={value}
            //   onChange={(e) => {
            //     handleLeadSubmitUpdate(
            //       e,
            //       e.target.value,
            //       tableMeta.rowData[0],
            //       value
            //     );
            //   }}
            // >
            //   <option value="inprogress">Inprogress</option>
            //   <option value="rejected">Rejected</option>
            //   <option value="hold">Hold</option>
            //   <option value="open">Open</option>
            //   <option value="converted">Converted</option>
            // </select>
          );
        },
      },
    },
    {
      name: "admin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
    {
      name: "subadmin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
    // {
    //   name: "Action",
    //   label: "Action",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <div className="text-center">
    //           <i
    //             onClick={(e) => {
    //               Swal.fire({
    //                 title: "Are you sure?",
    //                 text: "You want to delete !",
    //                 showCancelButton: true,
    //                 confirmButtonColor: "#3085d6",
    //                 cancelButtonColor: "#d33",
    //                 confirmButtonText: "Yes, delete",
    //               }).then((result) => {
    //                 if (result.isConfirmed) {
    //                   handleSubmitDelete(
    //                     e,
    //                     tableMeta.rowData[0],
    //                     tableMeta.rowData[5]
    //                   );
    //                 }
    //               });
    //             }}
    //             className="fa fa-trash-can"
    //             style={{
    //               color: "#E34242",
    //               fontSize: "16px",
    //               cursor: "pointer",
    //             }}
    //           ></i>
    //         </div>
    //       );
    //     },
    //   },
    // },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    onRowClick: handleRowClick,
    setRowProps: (row) => {
      if (row[5] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {newLeadTask ? (
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-0 fw-bold color-maroon">Lead</h5>
                  <div>
                    <button
                      type="button"
                      className="btn py-1 login-btn"
                      onClick={() => {
                        setLeadNewTask(false);
                      }}
                    >
                      Create lead
                    </button>
                  </div>
                </div>
                <div className="my-4">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7 px-0">
                        <div className="tab-section">
                          <ul className="ps-0">
                            {AllTabs.map((tab, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    selectedTab === tab
                                      ? "serviceTab active"
                                      : "serviceTab inactive"
                                  }
                                  onClick={() => {
                                    if (tab === "Rejected") {
                                      GetMyLead("rejected");
                                    } else if (tab === "Converted") {
                                      GetMyLead("converted");
                                    } else if (tab === "Open") {
                                      GetMyLead("open");
                                    } else if (tab === "Hold") {
                                      GetMyLead("hold");
                                    } else if (tab === "In Progress") {
                                      GetMyLead("inprogress");
                                    }
                                    else if (tab === "All") {
                                      GetMyLeadAll();
                                    }


                                    setSelectedTab(tab);
                                  }}
                                >
                                  <li>
                                    <span className="align-middle">{tab}</span>
                                  </li>
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <MUIDataTable
                    title={"Dalmaf - Lead"}
                    data={getMyLead}
                    columns={columns}
                    options={options}
                  />
                  {/* <table className="table">
                    <thead className="bg-historytablehead">
                      <tr>
                        <th scope="col" style={{ borderTopLeftRadius: "0" }}>
                          S. No
                        </th>
                        <th scope="col">client name</th>
                        <th scope="col">service request</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>

                    {getMyLead?.map((e, index) => (
                      <tbody className="historytablebody">
                        <tr
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(`/LeadEdit/${e._id}`);
                          }}
                        >
                         
                          <td>
                            <div className="">
                              <h6 className="fw-bold">{index + 1}</h6>
                            </div>
                          </td>
                          <td scope="row">
                            <div className="d-flex align-items-center ">
                              <div className=" ms-3">
                                <h6 className="fw-bold">{e?.clientName}</h6>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className=" ms-3">
                              <h6 className="fw-bold">{e?.serviceRequest}</h6>
                            </div>
                          </td>

                          <td>
                            <h6 className="fw-bold">
                              {moment(e.createdAt).format("DD/MM/YYYY")}
                            </h6>
                          </td>

                          <td>
                            <h6 className="fw-bold color-red">{e.status}</h6>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table> */}
                </div>
              </div>
            ) : (
              <div>
                <div className="">
                  <button
                    type="button"
                    className="btn login-btn ms-2 "
                    onClick={() => {
                      setLeadNewTask(true);
                      setMyLeadTaskValidation({});
                      setMyLeadTask({});
                    }}
                  >
                    Back
                  </button>
                </div>
                <div className=" my-4">
                  <div className="bg-historytablehead rounded-3 py-3 px-3">
                    <h6 className="mb-0">Create Lead</h6>
                  </div>
                  <div className="container">
                    <div className="row justify-content-center py-5">
                      <div className="col-lg-11">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">
                                Employee Name
                              </label>

                              <select
                                className="form-control form-control-lg"
                                // value={myLeadTask?.name}
                                // value={myLeadTask?.name}
                                value={myLeadTask?.name}
                                onChange={(e) => {
                                  setMyLeadTaskValue("name", e.target.value);
                                }}
                              // onBlur={(e) => {
                              //   setMyTaskLeadValidationValue(
                              //     "name",
                              //     NonEmptyValidation(e.target.value)
                              //   );
                              // }}
                              >
                                <option value="">
                                  ---Select Employee Name---
                                </option>
                                {allLeadSubadmin?.map((e, i) => (
                                  <option value={e?._id}>{e.name}</option>
                                ))}
                              </select>

                              {/* <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the Name"
                                aria-invalid={Boolean(
                                  myLeadTaskValidation?.name?.status === false
                                )}
                                value={myLeadTask?.name}
                                onChange={(e) => {
                                  setMyLeadTaskValue("name", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "name",
                                    StringValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              /> */}
                              <small className="text-danger">
                                {myLeadTaskValidation?.name?.message
                                  ? `Name ${myLeadTaskValidation?.name?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          {/*                         
                         <div className="col-lg-6">
                                        <div className="mb-4">
                                          <label className="form-label">
                                            Date 
                                          </label>
                                          <input
                                            type="type"
                                            className="form-control form-control-lg"
                                            placeholder="Enter date of hire"
                                            aria-invalid={Boolean(
                                              myLeadTaskValidation?.date
                                                ?.status === false
                                            )}
                                            
                                           
                                            onchange={(e) => {
                                              myLeadTaskValidation(
                                                "date",
                                                DateValidation(e.target.value)
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {myLeadTaskValidation?.date
                                              ?.message
                                              ? `Date${myLeadTaskValidation?.date?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                      </div> */}

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Client Name</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the Client Name"
                                aria-invalid={Boolean(
                                  myLeadTaskValidation?.clientName?.status ===
                                  false
                                )}
                                value={myLeadTask?.clientName}
                                onChange={(e) => {
                                  setMyLeadTaskValue(
                                    "clientName",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "clientName",
                                    StringValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myLeadTaskValidation?.clientName?.message
                                  ? `Client Name ${myLeadTaskValidation?.clientName?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          {/* <div className="col-lg-6">
                        
                            <div className="mb-4">
                              <label className="form-label"> Type Of Entity</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Type"
                                aria-invalid={Boolean(
                                  myLeadTaskValidation?.typeOfEntity?.status === false
                                )}
                                value={myLeadTask?.typeOfEntity}
                                onChange={(e) => {
                                  setMyLeadTaskValue("typeOfEntity", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "typeOfEntity",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myLeadTaskValidation?.typeOfEntity?.message
                                  ? `type Of Entity ${myLeadTaskValidation?.typeOfEntity?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>  */}

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">
                                {" "}
                                Mobile Number{" "}
                              </label>
                              <input
                                type="tel"
                                maxLength={10}
                                className="form-control form-control-lg"
                                placeholder="Enter Your Phone Number"
                                aria-invalid={Boolean(
                                  myLeadTaskValidation?.mobileNumber?.status ===
                                  false
                                )}
                                value={myLeadTask?.mobileNumber}
                                onChange={(e) => {
                                  setMyLeadTaskValue(
                                    "mobileNumber",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "mobileNumber",
                                    PhoneNumberValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myLeadTaskValidation?.mobileNumber?.message
                                  ? `Mobile Number ${myLeadTaskValidation?.mobileNumber?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Email</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the Email"
                                aria-invalid={Boolean(
                                  myLeadTaskValidation?.email?.status === false
                                )}
                                value={myLeadTask?.email?.toLowerCase()}
                                onChange={(e) => {
                                  setMyLeadTaskValue(
                                    "email",
                                    e.target.value?.toLowerCase()
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "email",
                                    EmailValidation(
                                      e.target.value?.toLowerCase()
                                    )
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myLeadTaskValidation?.email?.message
                                  ? `Email ${myLeadTaskValidation?.email?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">
                                {" "}
                                Professional
                              </label>
                              {/* <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the Professional"
                                aria-invalid={Boolean(
                                  myLeadTaskValidation?.professional?.status ===
                                    false
                                )}
                                value={myLeadTask?.professional}
                                onChange={(e) => {
                                  setMyLeadTaskValue(
                                    "professional",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "professional",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              /> */}
                              <select
                                className="form-control form-control-lg"
                                defaultValue={myLeadTask?.professional}
                                onChange={(e) => {
                                  setMyLeadTaskValue(
                                    "professional",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "professional",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="">---Select---</option>
                                <option value="Doctor">Doctor</option>
                                <option value="Lawyer">Lawyer</option>
                                <option value="CA">CA</option>
                                <option value="Engineer">Engineer</option>
                                <option value="Not Applicable">
                                  Not Applicable
                                </option>
                              </select>

                              <small className="text-danger">
                                {myLeadTaskValidation?.professional?.message
                                  ? `Professional ${myLeadTaskValidation?.professional?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label"> Occupation</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder=" Enter the Occupation "
                                aria-invalid={Boolean(
                                  myLeadTaskValidation?.occupation?.status ===
                                  false
                                )}
                                value={myLeadTask?.occupation}
                                onChange={(e) => {
                                  setMyLeadTaskValue(
                                    "occupation",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "occupation",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myLeadTaskValidation?.occupation?.message
                                  ? `Occupation ${myLeadTaskValidation?.occupation?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Address</label>
                              <textarea
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the Address"
                                aria-invalid={Boolean(
                                  myLeadTaskValidation?.address?.status ===
                                  false
                                )}
                                value={myLeadTask?.address}
                                onChange={(e) => {
                                  setMyLeadTaskValue("address", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "address",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              ></textarea>
                              <small className="text-danger">
                                {myLeadTaskValidation?.address?.message
                                  ? `Address ${myLeadTaskValidation?.address?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label"> Pincode</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the Pincode"
                                aria-invalid={Boolean(
                                  myLeadTaskValidation?.pinCode?.status ===
                                  false
                                )}
                                value={myLeadTask?.pinCode}
                                onChange={(e) => {
                                  setMyLeadTaskValue("pinCode", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "pinCode",
                                    PincodeValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myLeadTaskValidation?.pinCode?.message
                                  ? `Pincode ${myLeadTaskValidation?.pinCode?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">City</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter The City"
                                aria-invalid={Boolean(
                                  myLeadTaskValidation?.city?.status === false
                                )}
                                value={myLeadTask?.city}
                                onChange={(e) => {
                                  setMyLeadTaskValue("city", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "city",
                                    StringValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myLeadTaskValidation?.city?.message
                                  ? `City ${myLeadTaskValidation?.city?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">State</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the State"
                                aria-invalid={Boolean(
                                  myLeadTaskValidation?.state?.status === false
                                )}
                                value={myLeadTask?.state}
                                onChange={(e) => {
                                  setMyLeadTaskValue("state", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "state",
                                    StringValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myLeadTaskValidation?.state?.message
                                  ? `State ${myLeadTaskValidation?.state?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Date</label>
                              <input
                                type="date"
                                className="form-control form-control-lg"
                                placeholder="select The Date"
                                aria-invalid={Boolean(
                                  myLeadTaskValidation?.date?.status === false
                                )}
                                defaultValue={myLeadTask?.date}
                                onChange={(e) => {
                                  //console.log(e.target.value);
                                  setMyLeadTaskValue("date", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "date",
                                    DateValidationn(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myLeadTaskValidation?.date?.message
                                  ? `Date ${myLeadTaskValidation?.date?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">
                                Type of Entity
                              </label>
                              {/* <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the Type of Entity"
                                aria-invalid={Boolean(
                                  myLeadTaskValidation?.typeOfEntity?.status ===
                                    false
                                )}
                                value={myLeadTask?.typeOfEntity}
                                onChange={(e) => {
                                  setMyLeadTaskValue(
                                    "typeOfEntity",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "typeOfEntity",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              /> */}

                              <select
                                className="form-control"
                                value={myLeadTask?.typeOfEntity}
                                onChange={(e) => {
                                  setMyLeadTaskValue(
                                    "typeOfEntity",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "typeOfEntity",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="">---Select---</option>
                                <option value="Individual">Individual</option>
                                <option value="Solo">Solo</option>
                                <option value="Partnership">Partnership</option>
                                <option value="Company">Company</option>
                                <option value="Hosptial Institution">
                                  Hosptial Institution
                                </option>
                                <option value="Educational Institution">
                                  Educational Institution
                                </option>
                              </select>
                              <small className="text-danger">
                                {myLeadTaskValidation?.typeOfEntity?.message
                                  ? `TypeOfEntity ${myLeadTaskValidation?.typeOfEntity?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          {/* <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Subject</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the Name"
                                aria-invalid={Boolean(
                                  myLeadTaskValidation?.subject?.status === false
                                )}
                                value={myLeadTask?.subject}
                                onChange={(e) => {
                                  setMyLeadTaskValue("subject", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "subject",
                                    StringValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myLeadTaskValidation?.subject?.message
                                  ? `subject ${myLeadTaskValidation?.subject?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div> */}

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Note</label>
                              <div
                                className="form-control"
                                style={{ overflowY: "scroll", height: "400px" }}
                              >
                                <Editor
                                  wrapperClassName="wrapper-class"
                                  editorClassName="editor-class"
                                  toolbarClassName="toolbar-class"
                                  editorLeadState={editorLeadState}
                                  onEditorStateChange={(e) =>
                                    handleLeadEditorChange(e)
                                  }
                                />
                                {/* {myLeadTaskValidation?.question && (
                                <p className="text-danger">
                                    {myLeadTaskValidation?.question}
                                </p>
                              )} */}
                                {/* <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter the Name"
                                aria-invalid={Boolean(
                                  myLeadTaskValidation?.question?.status === false
                                )}
                                value={myLeadTask?.question}
                                onChange={(e) => {
                                  setMyLeadTaskValue("question", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "question",
                                    StringValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              /> */}

                              </div>
                              <small className="text-danger">
                                {myLeadTaskValidation?.notes?.message
                                  ? `Note ${myLeadTaskValidation?.notes?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">
                                Service Request
                              </label>
                              <select
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter A Service Request"
                                aria-invalid={Boolean(
                                  myLeadTaskValidation?.serviceRequest
                                    ?.status === false
                                )}
                                value={myLeadTask?.serviceRequest}
                                onChange={(e) => {
                                  setMyLeadTaskValue(
                                    "serviceRequest",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setMyTaskLeadValidationValue(
                                    "serviceRequest",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              >
                                <option value="">
                                  ---Select Service Request---
                                </option>
                                <option value="Legal">Legal</option>
                                <option value="Manpower">Manpower</option>
                                <option value="Buy/Sell">Buy/Sell</option>
                                <option value="Audit">Audit</option>
                                <option value="Finance">Finance</option>
                                <option value="Auction">Auction</option>
                                <option value="Others">Others</option>
                              </select>
                              {myLeadTask.serviceRequest === "Others" && (
                                <input
                                  type="text"
                                  className="form-control form-control-lg mt-2"
                                  placeholder="Enter Custom Service Request"
                                  value={myLeadTask.otherServiceRequest}
                                  onBlur={(e) =>
                                    setMyLeadTaskValue("otherServiceRequest", e.target.value)
                                  }
                                />
                              )}
                              <small className="text-danger">
                                {myLeadTaskValidation?.serviceRequest?.message
                                  ? `Service ${myLeadTaskValidation?.serviceRequest?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-4">
                            <button
                              type="button"
                              className="btn white-btn me-2 "
                              onClick={() => {
                                setMyLeadTaskValidation({});
                                setMyLeadTask({});
                                window.location.reload();
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn login-btn ms-2 "
                              onClick={(e) => {
                                handleSubmit(e);
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
