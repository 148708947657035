import React, { useEffect } from 'react';
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import BSVillaBanner from "../Components/Buy_Sell/BSVillaBanner";
import BSVillaSpecific from "../Components/Buy_Sell/BSVillaSpecific";
export default function BSVillaDetails() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="innerpages-nav">
    
       <div id="container" >
      <Navigation />
      <BSVillaBanner />
      <BSVillaSpecific />
      <Footer />
      </div>
    </div>
  );
}
