import React, { useState, useEffect } from 'react';
import { fetchAllService } from '../../../Api/v1/service/Api';
import { ScheduleMeet } from '../../../Api/v1/leads/Api';

export default function ScheduleMeetMain() {
    const [currentStage, setCurrentStage] = useState(1);
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        companyName: '',
        companyWebsite: '',
        employeeCount: '',
        turnover: '',
        selectedServices: [],
        selectedServiceTitles: []
    });
    const [errors, setErrors] = useState({});
    const [services, setServices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [formId,setFormId] = useState('');

    useEffect(() => {
        window.scroll(0, 0);
        if (currentStage === 3) {
            fetchServices();
        }
    }, [currentStage]);

    const fetchServices = async () => {
        setIsLoading(true);
        let data = await fetchAllService();
        if (data && data.statusCode === 200) {
            setServices(data.data);
            console.log("data", data)
        } else {
            setServices([]);
        }
        setIsLoading(false);
    };

    const validateStage = (stage) => {
        const newErrors = {};
        if (stage === 1) {
            if (!formData.fullName) newErrors.fullName = 'Full Name is required';
            if (!formData.email) newErrors.email = 'Email is required';
            if (!formData.phone) newErrors.phone = 'Phone No is required';
            if (!formData.companyName) newErrors.companyName = 'Company/Startup Name is required';
        } else if (stage === 2) {
            if (!formData.companyWebsite) newErrors.companyWebsite = 'Company Website/Reference Link is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const nextStage = async () => {
        if (validateStage(currentStage)) {
            if (currentStage === 1) {
                const temp = {
                    "step_1": {
                        "name": formData.fullName,
                        "email_id": formData.email,
                        "phone_number": formData.phone,
                        "company_name": formData.companyName
                    }
                }
                const response = await ScheduleMeet(temp);
                setFormId(response.data._id);
                if(response.statusCode===200){
                    setCurrentStage((prevStage) => prevStage + 1);
                }
            }else if (currentStage === 2) {
                const temp = {
                    "_id":formId,
                    "step_2": {
                        "company_website": formData.companyWebsite,
                        "employee_count": formData.employeeCount,
                        "turn_over": formData.turnover
                    }
                }
                const response = await ScheduleMeet(temp);
                if(response.statusCode===200){
                    setCurrentStage((prevStage) => prevStage + 1);
                }
            }else if (currentStage === 3) {
                const temp = {
                    "_id":formId,
                    "service_id":formData.selectedServices
                }
                const response = await ScheduleMeet(temp);
                if(response.statusCode===200){
                    setCurrentStage((prevStage) => prevStage + 1);
                }
            }else if(currentStage === 4) {
                const temp = {
                    "_id":formId,
                    "confirmation":true
                }
                const response = await ScheduleMeet(temp);
                if(response.statusCode===200){
                    setCurrentStage((prevStage) => prevStage + 1);
                }else{
                    setCurrentStage(6);
                }
            }
        }
    };

    // const nextStage = async () => {
    //     if (validateStage(currentStage)) {
    //         if (currentStage === 1) {
    //             const temp = {
    //                 "step_1": {
    //                     "name": "Nagaraj S 12",
    //                     "email_id": "nagarajsaravanavel@gmail.com",
    //                     "phone_number": 96090909090,
    //                     "company_name": "Test"
    //                 }
    //             }
    //             const response = await ScheduleMeetStage(JSON.stringify(formData));
    //         }
            
    //     }
    //     setCurrentStage((prevStage) => prevStage + 1);
    // }

    const prevStage = () => {
        setCurrentStage((prevStage) => prevStage - 1);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleServiceChange = (service) => {
        const selected = formData.selectedServices;
        const serviceIndex = selected.indexOf(service._id);
        const selectedTitles = formData.selectedServiceTitles;

        if (serviceIndex > -1) {
            selected.splice(serviceIndex, 1);
            selectedTitles.splice(selectedTitles.indexOf(service.title), 1);  // Remove title
        } else {
            selected.push(service._id);
            selectedTitles.push(service.title);  // Add title
        }

        setFormData({
            ...formData,
            selectedServices: selected,
            selectedServiceTitles: selectedTitles
        });
    };

    return (
        <div className="container mt-5">
            <div className="card p-4" style={{ minHeight: '400px', maxWidth: '800px', margin: '0 auto', overflowY: 'auto' }}>
                <div className="progress mb-4">
                    <div className="progress-bar" role="progressbar" style={{ width: `${(currentStage / 4) * 100}%`,backgroundColor: 'rgba(202, 105, 56, 1)',}} aria-valuenow={currentStage} aria-valuemin="0" aria-valuemax="4"></div>
                </div>
                {currentStage === 1 && (
                    <div>
                        <h3 className="mb-4">Personal Details</h3>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Full Name</label>
                                    <input type="text" name="fullName" className={`form-control ${errors.fullName ? 'is-invalid' : ''}`} placeholder="Enter your full name" value={formData.fullName} onChange={handleInputChange} />
                                    {errors.fullName && <div className="invalid-feedback">{errors.fullName}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Email</label>
                                    <input type="email" name="email" className={`form-control ${errors.email ? 'is-invalid' : ''}`} placeholder="Enter your email" value={formData.email} onChange={handleInputChange} />
                                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Phone No</label>
                                    <input type="text" name="phone" className={`form-control ${errors.phone ? 'is-invalid' : ''}`} placeholder="Enter your phone number" value={formData.phone} onChange={handleInputChange} />
                                    {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Company/Startup Name</label>
                                    <input type="text" name="companyName" className={`form-control ${errors.companyName ? 'is-invalid' : ''}`} placeholder="Enter your company/startup name" value={formData.companyName} onChange={handleInputChange} />
                                    {errors.companyName && <div className="invalid-feedback">{errors.companyName}</div>}
                                </div>
                            </div>
                        </div>
                        <button className="btn btn-primary mt-3" onClick={nextStage}>Next</button>
                    </div>
                )}
                {currentStage === 2 && (
                    <div>
                        <h3 className="mb-4">Business Details</h3>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Employee Count</label>
                                    <select
                                        name="employeeCount"
                                        className="form-control"
                                        value={formData.employeeCount}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select employee count</option>
                                        <option value="1-10">1-10</option>
                                        <option value="10-20">10-20</option>
                                        <option value="20-50">20-50</option>
                                        <option value="50-100">50-100</option>
                                        <option value="100-150">100-150</option>
                                        <option value="150-200">150-200</option>
                                        <option value="200-250">200-250</option>
                                        <option value="250-300">250-300+</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Turnover</label>
                                    <select
                                        name="turnover"
                                        className="form-control"
                                        value={formData.turnover}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select turnover</option>
                                        <option value="₹0-₹1m">₹0-₹1m</option>
                                        <option value="₹1-₹2m">₹1-₹2m</option>
                                        <option value="₹2-₹10m">₹2-₹10m</option>
                                        <option value="₹10-₹20m">₹10-₹20m</option>
                                        <option value="₹20-₹30m">₹20-₹30m</option>
                                        <option value="₹30-₹50m">₹30-₹50m</option>
                                        <option value="₹50-₹100m">₹50-₹100m</option>
                                        <option value="₹100-₹150m">₹100-₹150m+</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Company Website/Reference Link</label>
                            <input
                                type="url"
                                name="companyWebsite"
                                className={`form-control ${errors.companyWebsite ? 'is-invalid' : ''}`}
                                placeholder="Enter your company website or reference link"
                                value={formData.companyWebsite}
                                onChange={handleInputChange}
                            />
                            {errors.companyWebsite && <div className="invalid-feedback">{errors.companyWebsite}</div>}
                        </div>
                        <button className="btn btn-secondary me-2" onClick={prevStage}>Previous</button>
                        <button className="btn btn-primary" onClick={nextStage}>Next</button>
                    </div>
                )}
                {currentStage === 3 && (
                    <div>
                        <h3 className="mb-4">Select Services</h3>
                        {isLoading ? (
                            <div className="text-center my-4">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading services...</span>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <input
                                    type="text"
                                    className="form-control mb-3"
                                    placeholder="Search services..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
                                />
                                <div className="row overflow-auto" style={{ maxHeight: "300px" }}>
                                    {services?.total_count ? (
                                        services.service_details
                                            .filter(service =>
                                                service.title.toLowerCase().includes(searchTerm) ||
                                                service.mainServiceId?.title.toLowerCase().includes(searchTerm) ||
                                                service.subServiceL1Id?.title.toLowerCase().includes(searchTerm)
                                            )
                                            .map((service, index) => (
                                                <div key={index} className="col-md-6 mb-3">
                                                    <div className="card p-3 h-100">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value={service._id}
                                                                id={`service${index}`}
                                                                checked={formData.selectedServices.includes(service._id)}
                                                                onChange={() => handleServiceChange(service)}
                                                            />
                                                            <label className="form-check-label" htmlFor={`service${index}`}>
                                                                <strong>{service?.title}</strong><br />
                                                                <span className="text-muted">{service?.mainServiceId?.title}</span>,
                                                                <span className="text-muted">{service?.subServiceL1Id?.title}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                    ) : (
                                        <div className="col-12">
                                            <div className="alert alert-warning" role="alert">
                                                No services available.
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <button className="btn btn-secondary me-2 mt-3" onClick={prevStage}>Previous</button>
                        <button className="btn btn-primary mt-3" onClick={nextStage}>Next</button>
                    </div>
                )}
                {currentStage === 4 && (
                    <div>
                        <h3 className="mb-4">Confirmation</h3>
                        <p>Please review your details and submit the form.</p>
                        <div className="row">
                            <div className="col-md-6">
                                <ul className="list-group">
                                    <li className="list-group-item">Full Name: {formData.fullName}</li>
                                    <li className="list-group-item">Email: {formData.email}</li>
                                    <li className="list-group-item">Phone No: {formData.phone}</li>
                                    <li className="list-group-item">Company/Startup Name: {formData.companyName}</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul className="list-group">
                                    <li className="list-group-item">Company Website: {formData.companyWebsite}</li>
                                    <li className="list-group-item">Employee Count: {formData.employeeCount}</li>
                                    <li className="list-group-item">Turnover: {formData.turnover}</li>
                                    <li className="list-group-item">Selected Services: {formData.selectedServiceTitles.join(', ')}</li>
                                </ul>
                            </div>
                        </div>
                        <button className="btn btn-secondary me-2" onClick={prevStage}>Previous</button>
                        <button className="btn btn-success" onClick={nextStage}>Submit</button>
                    </div>
                )}
                {currentStage === 5 && (
                    <div className='text-center'>
                        <h3 className="mb-4">Your Meeting Request Sent Successfully.<br></br> Team will reach you soon</h3>
                    </div>
                )}
                {currentStage === 6 && (
                    <div className='text-center'>
                        <h3 className="mb-4">Sorry, There was some issue with system. Try again later</h3>
                    </div>
                )}
            </div>
        </div>
    );
}
