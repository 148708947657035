import React, { useEffect } from 'react';
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import HouseBanner from "../Components/Property/HouseBanner";
import HouseContent from "../Components/Property/HouseContent";
export default function House() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="innerpages-nav">
        <div id="container" >
      <Navigation />
      <HouseBanner />
      <HouseContent />
      <Footer />
      </div>
    </div>
  );
}
