import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import moment from "moment";

import { useNavigate } from "react-router-dom";
import {
  ListingBasedOnRole,
  ListingBasedOnRoleWithStatus,
  UserProfileUpdate,
} from "../../../Api/Api";
import { useSelector } from "react-redux";

import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ToastContainer, toast } from "react-toastify";
import MUIDataTable from "mui-datatables";

export default function UserManagementComponent() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = React.useState("Active Members");
  const AllTabs = ["Active Members", "Inactive Members"];

  const [UserManagement, setUserManagement] = useState([]);

  useEffect(() => {
    getUserManagement("active");
  }, []);

  const state = useSelector((state) => state);
  const getUserManagement = async (status, value) => {
    const token = state.auth.auth.token;
    const data = await ListingBasedOnRoleWithStatus(token, "user", status);
    if (data && data.statusCode === 200) {
      let data1 = data?.data.filter((e) => e.customPackage === value);
      let temp = [];
      data1?.map((data, i) => {
        let dummy = {
          _id: data?._id,
          name: data?.name,
          phoneNumber: data?.phoneNumber,
          email: data?.email,
          lastLogin: moment(data?.lastLogin).format("DD/MM/YYYY , h:mm:ss a"),

          createdAt: moment(data?.createdAt).format("DD/MM/YYYY  , h:mm:ss a"),
          status: data?.status,
        };
        temp.push(dummy);
        return null;
      });
      setUserManagement(temp);
    } else {
      setUserManagement([]);
      //console.log("Error while getting my task");
    }
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const handleSubmitUpdate = async (e, value, id) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
    };
    const token = state?.auth?.auth?.token;
    const response = await UserProfileUpdate(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);

      if (value !== "active") {
        setSelectedTab("Active Members");
        getUserManagement("active");
      } else {
        setSelectedTab("Inactive Members");
        getUserManagement("inactive");
      }

      //  window.location.reload()
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: pink[600],
      "&:hover": {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: pink[600],
    },
  }));

  const label = { inputProps: { "aria-label": "Color switch demo" } };
  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer" }}
                // onClick={(val) => {
                //   navigate(`/UserProfileDetails/${tableMeta.rowData[0]}`);
                // }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "phoneNumber",
      label: "Phone No",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "email",
      label: "E- Mail ID",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "createdAt",
      label: "Created On",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "lastLogin",
      label: "Last Log-In",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              <div>{value}</div>
              {/* <p className="mb-0">At {moment(value).format("h:mm:ss a")}</p>   */}
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {value === "inactive" ? (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <PinkSwitch
                        {...label}
                        onChange={(e) => {
                          handleSubmitUpdate(e, "active", tableMeta.rowData[0]);
                        }}
                      />
                    }
                  />
                </FormGroup>
              ) : (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <PinkSwitch
                        {...label}
                        defaultChecked
                        onChange={(e) => {
                          handleSubmitUpdate(
                            e,
                            "inactive",
                            tableMeta.rowData[0]
                          );
                        }}
                      />
                    }
                  />
                </FormGroup>
              )}
            </>
          );
        },
      },
    },
  ];

  function handleRowClick(rowData, rowMeta) {
    const id = UserManagement[rowMeta?.dataIndex]?._id;
    navigate(`/UserProfileDetails/${id}`);
  }

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    onRowClick: handleRowClick,
    elevation:0, 
    setRowProps: (row) => {
      if (row[5] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  return (
    <div>
      <ToastContainer />
        <div>
          <div className="">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 px-0">
                  <div className="tab-section">
                    <ul className="ps-0">
                      {AllTabs.map((tab, index) => {
                        return (
                          <div
                            key={index}
                            className={
                              selectedTab === tab
                                ? "serviceTab active"
                                : "serviceTab inactive"
                            }
                            onClick={() => {
                              if (tab === "Inactive Members") {
                                getUserManagement("inactive");
                              } else if (tab === "Active Members") {
                                getUserManagement("active");
                              }

                              setSelectedTab(tab);
                            }}
                          >
                            <li>
                              <span className="align-middle">{tab}</span>
                            </li>
                          </div>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <MUIDataTable
                title={"Dalmaf - User Management"}
                className="text-center  "
                data={UserManagement}
                columns={columns}
                options={options}
              />
            </div>
           
          </div>
        </div>
    </div>
  );
}
