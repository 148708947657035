import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import MUIDataTable from 'mui-datatables';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fetchWithQueryAPI } from '../../../../Api/v1/Util/Api';
import { format } from 'date-fns';
import { generic_is_client, generic_pagination_limit, generic_pagination_start, lead_fetch, lead_fetch_with_status } from '../../../../Api/APIUrl';

export default function ListLeadComponent({ userTypeProps, lableProps, isClientProps, routeProps, statusFilterProps }) {
    const [leads, setLeads] = useState([]);
    const state = useSelector((state) => state);
    const navigate = useNavigate();

    const [page, setPage] = useState(0); // Current page
    const [loading, setLoading] = useState(false); // Loading state
    const [hasMore, setHasMore] = useState(true); // Whether more data is available
    const rowsPerPage = 10; // Rows per page

    const fetchLeads = async (page, rowsPerPage) => {
        setLoading(true);
        try {
            let url = "";
            if (isClientProps) {
                url = lead_fetch+'?' + generic_is_client + isClientProps;
            } else {
                url = `${lead_fetch_with_status}${statusFilterProps}&${generic_is_client}${isClientProps}${generic_pagination_start}${page * rowsPerPage}${generic_pagination_limit}${rowsPerPage}`;
            }
            const response = await fetchWithQueryAPI(state.auth.auth.token, url);
            if (response && response.statusCode === 200) {
                setLeads((prevLeads) => {
                    const newLeads = response.data.leads.filter((lead) => !prevLeads.some((prevLead) => prevLead._id === lead._id));
                    return [...prevLeads, ...newLeads];
                });
                setHasMore(response.data.leads.length > 0);
            } else {
                toast.error("Failed to fetch " + lableProps);
            }
        } catch (error) {
            toast.error("An error occurred while fetching " + lableProps);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLeads(page, rowsPerPage);
    }, [page]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight && !loading && hasMore) {
                setPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, hasMore]);

    const handleView = (leadId) => {
        navigate(`/${userTypeProps}/${routeProps}/view-${routeProps}/${leadId}`);
    };

    const columns = [
        {
            name: 'company_name',
            label: 'Company Name',
            options: {
                customBodyRender: (value, tableMeta) => (
                    <span onClick={() => handleView(tableMeta.rowData[5])} style={{ cursor: 'pointer' }}>
                        {value}
                    </span>
                ),
            },
        },
        // { name: 'email_id', label: 'Email' },
        { name: 'phone_number', label: 'Phone' },
        { name: 'city', label: 'City' },
        { name: 'lead_generated_by', label: 'Lead By' },
        {
            name: 'createdAt',
            label: 'Created At',
            options: {
                customBodyRender: (value) => format(new Date(value), 'dd/MM/yyyy HH:mm:ss'),
            },
        },
        // { name: 'status', label: 'Status' },
        {
            name: '_id',
            label: 'Actions',
            options: {
                customBodyRender: (value, tableMeta) => (
                    <>
                        <IconButton onClick={() => handleView(tableMeta.rowData[5])} color="primary">
                            <VisibilityIcon />
                        </IconButton>
                    </>
                ),
            },
        },
    ];

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
        fixedHeader: true, // Fixes the header
        tableBodyHeight: '400px', // Sets the height of the table body
        onTableChange: (action, tableState) => {
            if (action === 'changePage') {
                setPage(tableState.page);
            }
        }
    };

    const customTheme = createTheme({
        components: {
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#dc3545', // Change this to your desired color
                        color: 'white'
                    },
                },
            },
        },
    });

    return (
        <>
            <ToastContainer />
            <div>
                <ThemeProvider theme={customTheme}>
                    <MUIDataTable
                        title={`${statusFilterProps ? statusFilterProps + ' - ' : ''}${lableProps} List (${leads.length})`}
                        data={leads}
                        columns={columns}
                        options={options}
                    />

                </ThemeProvider>
                {loading && <p>Loading...</p>}
            </div>
        </>
    );
}
