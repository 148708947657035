import axios from "axios";
import { url } from "../../BaseUrl";

const APIBaseUrl = url;

export const fetchBestServices = async () => {
    const response = await axios
        .get(`${APIBaseUrl}/v1/services/best_offering`, {
            headers: {
                Accept: "application/json",
                "content-type": "application/json",
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

export const fetchServicesCount = async () => {
    const response = await axios
        .get(`${APIBaseUrl}/v1/services/count`, {
            headers: {
                Accept: "application/json",
                "content-type": "application/json",
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

export const fetchAllService = async () => {
    const response = await axios
        .get(`${APIBaseUrl}/v1/services/find_service_detail`, {
            headers: {
                Accept: "application/json",
                "content-type": "application/json",
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

