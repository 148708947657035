import React, { useEffect, useRef, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import Norecord from "../../../Assets/Images/MicrosoftTeams-image.webp";

import Si3 from "../../../Assets/Images/as3.webp";
import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AdminspecificlistSR,
  GetAllSubAdminAndAdmin,
  GetParticularServiceRequest,
  GetParticularServiceRequestUser,
  GetServiceRequestByRole,
  GetServiceRequestSubadmin,
  UpdateServiceRequest,
  UploadDocument,
} from "../../../Api/Api";
import moment from "moment";
import {
  EmailValidation,
  NonEmptyValidation,
  PhoneNumberValidation,
  StringValidation,
} from "../../../Store/validate";

import MUIDataTable from "mui-datatables";
import { useNavigate, useParams } from "react-router-dom";

export default function UserServiceDetails() {
  const [ShowTable, setShowTable] = useState(true);
  // const [loading, setLoading] = useState(true);

  const [viewDetails, setViewDetails] = useState(true);

  const [selectedTab2, setSelectedTab2] = React.useState("New Request");
  
  const AllTabs2 = [
    
    "New Request",
    "Assigned",
    "To Do",
    "In Progress",
    "Completed",
  ];


  const state = useSelector((state) => state);
  const [reassign, setReassign] = useState(true);
  const [serviceReq, setServiceReq] = useState([]);
  const [serviceReqParticular, setServiceReqParticular] = useState({});
  const [serviceReqParticularValidation, setServiceReqParticularValidation] =
    useState({});

  const setServiceReqParticularValue = (parentKey, childKey, value, type) => {
    if (type === "object") {
      setServiceReqParticular({
        ...serviceReqParticular,
        ...{
          [parentKey]: {
            ...serviceReqParticular[parentKey],
            [childKey]: value,
          },
        },
      });
      if (serviceReqParticularValidation[parentKey])
        delete serviceReqParticularValidation[parentKey];
      if (serviceReqParticularValidation[childKey])
        delete serviceReqParticularValidation[childKey];
    } else {
      setServiceReqParticular({ ...serviceReqParticular, [childKey]: value });
      if (serviceReqParticularValidation[parentKey])
        delete serviceReqParticularValidation[parentKey];
      if (serviceReqParticularValidation[childKey])
        delete serviceReqParticularValidation[childKey];
    }
  };

  const setServiceReqParticularValidationValue = (
    parentKey,
    childKey,
    value,
    type
  ) => {
    if (type === "object") {
      setServiceReqParticularValidation({
        ...serviceReqParticularValidation,
        ...{
          [parentKey]: {
            ...serviceReqParticularValidation[parentKey],
            [childKey]: value,
          },
        },
      });
    } else {
      setServiceReqParticularValidation({
        ...serviceReqParticularValidation,
        [childKey]: value,
      });
    }
  };

  useEffect(() => {
    
      getServiceReq("unassigned");

    GetAllSubadmins();
  }, []);

  const {id} = useParams()

  const getServiceReq = async (status) => {
    const token = state.auth.auth.token;
    let response;
   
      response = await GetParticularServiceRequestUser(token,id,status);
    if (response.statusCode === 200) {
      setServiceReq(response.data);
      console.log(response.data)
      // setLoading(false);
    } else {
      setServiceReq([]);

      //console.log("error while getting user profile info");
    }
  };

  const getServiceReqParticular = async (id) => {
    // setLoading(true);
    const token = state.auth.auth.token;
    const response = await GetParticularServiceRequest(token, id);
    if (response.statusCode === 200) {
      setServiceReqParticular(response.data);
      // setLoading(false);
    } else {
      setServiceReqParticular([]);

      //console.log("error while getting user profile info");
    }
  };

  const [allSubadmin, setAllSubadmin] = useState([]);
  const GetAllSubadmins = async () => {
    // setLoading(true);
    const token = state?.auth?.auth?.token;
    let data = await GetAllSubAdminAndAdmin(token);
    if (data && data.statusCode === 200) {
      setAllSubadmin(data.data);
      // setLoading(false);
    } else {
      setAllSubadmin([]);

      //console.log("Error while getting all subadmins");
    }
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const CeoRef = useRef();
  const uploadCeoPic = async (e) => {
    const file = e.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("fileName", e.target.files[0].name);
      formData.append("path", "Image/");

      let response = await UploadDocument(formData);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);

        const data = response.data;

        if (serviceReqParticular?.fileUpload?.length) {
          let scheduleData = serviceReqParticular?.fileUpload;
          scheduleData.push(data);
          setServiceReqParticularValue("", "fileuploadTo", scheduleData, "");
        } else {
          setServiceReqParticularValue("", "fileuploadTo", [data], "");
        }
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }
  };

  //console.log(serviceReqParticular?.fileUpload);
  const [assign, setAssign] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.name = StringValidation(serviceReqParticular?.name);
    // validate.mobileNumber = NonEmptyValidation(
    //   serviceReqParticular?.mobileNumber
    // );
    validate.email = NonEmptyValidation(
      serviceReqParticular?.email?.toLowerCase()
    );

    validate.servicesCategory = NonEmptyValidation(
      serviceReqParticular?.servicesCategory
    );
    validate.servicesSubcategory = NonEmptyValidation(
      serviceReqParticular?.servicesSubcategory
    );
    validate.servicesTitle = NonEmptyValidation(
      serviceReqParticular?.servicesTitle
    );

    validate.assigned_to = NonEmptyValidation(
      serviceReqParticular?.assigned_to?.name
    );

    setServiceReqParticularValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let Payload;
      if (serviceReqParticular.status === "unassigned") {
        Payload = {
          name: serviceReqParticular.name,
          type: serviceReqParticular.type,
          email: serviceReqParticular.email?.toLowerCase(),
          question: serviceReqParticular.question,
          mobileNumber: serviceReqParticular.mobileNumber,
          servicesCategory: serviceReqParticular.servicesCategory,
          servicesSubcategory: serviceReqParticular.servicesSubcategory,
          servicesTitle: serviceReqParticular.servicesTitle,
          assigned_to: serviceReqParticular.assigned_to?.name,
          subject: serviceReqParticular.subject,
          // userId: serviceReqParticular.userId._id,
          fileuploadTo: serviceReqParticular.fileuploadTo,
          _id: serviceReqParticular._id,
          status: "assigned",
        };
      } else {
        Payload = {
          name: serviceReqParticular.name,
          type: serviceReqParticular.type,
          email: serviceReqParticular.email?.toLowerCase(),
          question: serviceReqParticular.question,
          mobileNumber: serviceReqParticular.mobileNumber,
          servicesCategory: serviceReqParticular.servicesCategory,
          servicesSubcategory: serviceReqParticular.servicesSubcategory,
          servicesTitle: serviceReqParticular.servicesTitle,
          assigned_to: assign
            ? serviceReqParticular.assigned_to?.name
            : serviceReqParticular.assigned_to?._id,
          subject: serviceReqParticular.subject,
          fileuploadTo: serviceReqParticular.fileuploadTo,
          // userId: serviceReqParticular.userId._id,
          _id: serviceReqParticular._id,
          status: serviceReqParticular?.status,
        };
      }

      const token = state.auth.auth.token;

      const response = await UpdateServiceRequest(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        getServiceReq("assigned");
        // setViewDetails(true);
        // window.location.reload();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while assign service");
    }
  };
  // const [comment, setComment] = useState("");
   const [leadComment, setLeadComment] = useState("");
   const [commentsValidation, setCommentsValidation] = useState({});

   const setCommentsValue = (key, value) => {
     setLeadComment({ ...leadComment, [key]: value });

     if (commentsValidation[key]) delete commentsValidation[key];
   };

   const setCommentsLeadValidationValue = (key, value) => {
     setCommentsValidation({
       ...commentsValidation,

       [key]: value,
     });
   };
  const handleSubmitComment = async (e) => {
    e.preventDefault();
     let validate = {};
    validate.comments = NonEmptyValidation(leadComment?.comments);
    setCommentsValidation(validate)
    if (Object.values(validate).every((v) => v.status === true)) {
      let Payload = {
        _id: serviceReqParticular._id,
        comments: leadComment?.comments,
      };
      // 6441de0da3fe015898ab275f
      const token = state.auth.auth.token;

      const response = await UpdateServiceRequest(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        getServiceReqParticular();
        // window.location.reload();
        setLeadComment("");
      
        e.target.reset();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while update user details");
    }
  };
  // const handleSubmitDelete = async (e, id, value) => {
  //   e.preventDefault();

  //   const token = state?.auth?.auth?.token;
  //   const response = await DeleteServicereq(id, token);
  //   if (response && response.statusCode === 200) {
  //     showToastSuccessMsg(response.message);

  //     //  window.location.reload()
  //   } else {
  //     showToastErrorMsg(response.message || response.error.response.data.message);
  //     //setfailureModal(true);
  //   }
  // };
  const handleSubmitPayment = async (e) => {
    e.preventDefault();
    let Payload = {
      _id: serviceReqParticular._id,
      payment: true,
    };

    const token = state.auth.auth.token;

    const response = await UpdateServiceRequest(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      getServiceReqParticular();
      // window.location.reload();
        window.location.reload();

        setLeadComment("");
        
      e.target.reset();
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };
  const handleSubmitUpdate = async (e, userId, value) => {
    e.preventDefault();
    let Payload;
    if (value === "subadmin") {
      Payload = {
        _id: userId,
        subadmin_read: true,
        // admin_read:"",
      };
    } else {
      Payload = {
        _id: userId,
        // subadmin_read:"",
        admin_read: true,
      };
    }
    const token = state.auth.auth.token;

    const response = await UpdateServiceRequest(Payload, token);
    if (response && response.statusCode === 200) {
      getServiceReq("assigned");
    } else {
      //console.log("Error");
    }
  };
  const navigate = useNavigate();

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "name",
      label: "User Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              style={{ textTransform: "capitalize", cursor: "pointer" }}
              // onClick={(val) => {
              //   getServiceReqParticular(tableMeta.rowData[0]);
              //   if (tableMeta.rowData[7] === false) {
              //     handleSubmitUpdate(val, tableMeta.rowData[0], "admin");
              //   } else if (tableMeta.rowData[8] === false) {
              //     handleSubmitUpdate(val, tableMeta.rowData[0], "subadmin");
              //   }
              //   setViewDetails(false);
              //   //  navigate(`/UpdateServiceReq/${tableMeta.rowData[0]}`)
              // }}
            >
              {value}
            </div>
          );
        },
      },
    },
    {
      name: "servicesCategory",
      label: "Srvice Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer" }}
                // onClick={(val) => {
                //   getServiceReqParticular(tableMeta.rowData[0]);
                //   if (tableMeta.rowData[7] === false) {
                //     handleSubmitUpdate(val, tableMeta.rowData[0], "admin");
                //   } else if (tableMeta.rowData[8] === false) {
                //     handleSubmitUpdate(val, tableMeta.rowData[0], "subadmin");
                //   }
                //   setViewDetails(false);
                //   // navigate(`/UpdateServiceReq/${tableMeta.rowData[0]}`)
                // }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    // {
    //   name: "subject",
    //   label: "Description",
    //   options: {
    //     customBodyRender: (value) => {
    //       return <div>{value}</div>;
    //     },
    //   },
    // },
    {
      name: "assigned_by",
      label: "Assigned By",
      options: {
        customBodyRender: (value) => {
          return <div>{value?.name}</div>;
        },
      },
    },
    {
      name: "assigned_to",
      label: "Assigned To",
      options: {
        customBodyRender: (value) => {
          return <div>{value?.name}</div>;
        },
      },
    },
    {
      name: "createdAt",
      label: "Created On",
      options: {
        customBodyRender: (value) => {
          return <div>{moment(value).format("DD-MM-YYYY")}</div>;
        },
      },
    },
    // {
    //   name: "status",
    //   label: "Status",
    // },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>{value}</div>
            // <select
            //   className="form-control form-control-lg"
            //   defaultValue={value}
            //   onChange={(e) => {
            //     handleSubmitUpdateStatus(
            //       e,
            //       e.target.value,
            //       tableMeta.rowData[0],
            //       value
            //     );
            //   }}
            // >
            //   <option value="assigned">Assigned</option>
            //   <option value="unassigned">unassigned</option>
            // </select>
          );
        },
      },
    },
    {
      name: "serviceRequestId",
      label: "View",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              style={{ textTransform: "capitalize", cursor: "pointer" }}
              onClick={(val) => {
                getServiceReqParticular(value);
                if (tableMeta.rowData[7] === false) {
                  handleSubmitUpdate(val, value, "admin");
                } else if (tableMeta.rowData[8] === false) {
                  handleSubmitUpdate(val, value, "subadmin");
                }
                setViewDetails(false);
                //  navigate(`/UpdateServiceReq/${tableMeta.rowData[0]}`)
              }}
            >
              <button className='btn btn-primary'>View</button>
            </div>
          );
        },
      },
    },
    {
      name: "admin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
    {
      name: "subadmin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
    // {
    //   name: "Action",
    //   label: "Action",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <div className="text-center">
    //           <i
    //             onClick={(e) => {
    //               Swal.fire({
    //                 title: "Are you sure?",
    //                 text: "You want to delete !",
    //                 // icon: "warning",
    //                 showCancelButton: true,
    //                 confirmButtonColor: "#3085d6",
    //                 cancelButtonColor: "#d33",
    //                 confirmButtonText: "Yes, delete",
    //               }).then((result) => {
    //                 if (result.isConfirmed) {
    //                   handleSubmitDelete(
    //                     e,
    //                     tableMeta.rowData[0],
    //                     tableMeta.rowData[5]
    //                   );
    //                 }
    //               });
    //             }}
    //             // onClick={(e) => {
    //             //   handleSubmitDelete(e,tableMeta.rowData[0],value)
    //             // }}
    //             className="fa fa-trash-can"
    //             style={{
    //               color: "#E34242",
    //               fontSize: "16px",
    //               cursor: "pointer",
    //             }}
    //           ></i>
    //         </div>
    //       );
    //     },
    //   },
    // },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (
        (state?.auth?.auth?.role === "superadmin" && row[7] === false) ||
        (state?.auth?.auth?.role === "subadmin" && row[8] === false)
      ) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main srpage">
        {
          // loading ? (
          //   <Spinner />
          // ) :

          <>
            {viewDetails ? (
              <>
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="">
                      <h5 className="mb-0 fw-bold color-maroon">
                        Service Request Management
                      </h5>
                    </div>
                    <div>
                      <div className="col-6"></div>
                      <div className="col-6 text-end">
                        <button
                          type="button"
                          className="btn py-1 login-btn"
                          onClick={() => {
                            navigate(`/UserProfileDetails/${id}`);
                          }}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="my-4">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-7 px-0">
                          <div className="tab-section">
                            <ul className="ps-0">
                              {AllTabs2.map((tab, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={
                                      selectedTab2 === tab
                                        ? "serviceTab active"
                                        : "serviceTab inactive"
                                    }
                                    onClick={() => {
                                      if (tab === "Assigned") {
                                        getServiceReq("assigned");
                                      } else if (
                                        tab === "New Request"
                                      ) {
                                        getServiceReq("unassigned");
                                      } else if (tab === "To Do") {
                                        getServiceReq("ToDo");
                                      } else if (tab === "In Progress") {
                                        getServiceReq("inprogress");
                                      } else if (tab === "Completed") {
                                        getServiceReq("completed");
                                      }
                                      setSelectedTab2(tab);
                                    }}
                                  >
                                    <li>
                                      <span className="align-middle">
                                        {tab}
                                      </span>
                                    </li>
                                  </div>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <MUIDataTable
                      data={serviceReq}
                      columns={columns}
                      options={options}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div>
                <div className="row">
                  <div className="col-6">
                    {" "}
                    <p className="mb-0">
                      {" "}
                      <span
                        style={{ cursor: "pointer" }}
                        className="mb-0 fs-5 fw-bold color-maroon mr-2"
                        onClick={() => {
                          setViewDetails(true);
                          if (state?.auth?.auth?.role === "superadmin") {
                            getServiceReq("assigned");
                          } else {
                            getServiceReq("assigned");
                          }
                        }}
                      >
                        Services Request{" "}
                      </span>{" "}
                      <span className="mb-0 fs-6 color-maroon">
                        {" "}
                        \Service Details
                      </span>{" "}
                    </p>
                  </div>
                  <div className="col-6 text-end">
                    <button
                      className="btn login-btn"
                      onClick={() => {
                        setViewDetails(true);
                        getServiceReq("unassigned");
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div>

                <div>
                  <div className=" my-4">
                    <div className="bg-historytablehead rounded-3 py-3 px-3">
                      <h6 className="mb-0">Update Service</h6>
                    </div>
                    <div className="container">
                      <div className="row justify-content-center py-5">
                        <div className="col-lg-11">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Name</label>
                                <input
                                  type="text"
                                  className="form-control form-control-lg"
                                  placeholder="Enter the Name"
                                  aria-invalid={Boolean(
                                    serviceReqParticularValidation?.name
                                      ?.status === false
                                  )}
                                  value={serviceReqParticular?.name}
                                  onChange={(e) => {
                                    setServiceReqParticularValue(
                                      "",
                                      "name",
                                      e.target.value,
                                      ""
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setServiceReqParticularValidationValue(
                                      "",
                                      "name",
                                      StringValidation(e.target.value),
                                      ""
                                    );
                                  }}
                                  disabled
                                  autoComplete="off"
                                />
                                <small className="text-danger">
                                  {serviceReqParticularValidation?.name?.message
                                    ? `Name ${serviceReqParticularValidation?.name?.message}`
                                    : ""}
                                </small>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Mobile No</label>
                                <input
                                  type="tel"
                                  className="form-control form-control-lg"
                                  maxLength={10}
                                  placeholder="Enter the Mobile number"
                                  aria-invalid={Boolean(
                                    serviceReqParticularValidation?.mobileNumber
                                      ?.status === false
                                  )}
                                  defaultValue={
                                    serviceReqParticular?.mobileNumber
                                  }
                                  onChange={(e) => {
                                    setServiceReqParticularValue(
                                      "",
                                      "mobileNumber",
                                      e.target.value,
                                      ""
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setServiceReqParticularValidationValue(
                                      "",
                                      "mobileNumber",
                                      PhoneNumberValidation(e.target.value),
                                      ""
                                    );
                                  }}
                                  disabled
                                  autoComplete="off"
                                />
                                <small className="text-danger">
                                  {serviceReqParticularValidation?.mobileNumber
                                    ?.message
                                    ? `Mobile Number ${serviceReqParticularValidation?.mobileNumber?.message}`
                                    : ""}
                                </small>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Email Id</label>
                                <input
                                  type="email"
                                  className="form-control form-control-lg"
                                  placeholder="Enter Email-Id"
                                  aria-invalid={Boolean(
                                    serviceReqParticularValidation?.email
                                      ?.status === false
                                  )}
                                  value={serviceReqParticular?.email?.toLowerCase()}
                                  onChange={(e) => {
                                    setServiceReqParticularValue(
                                      "",
                                      "email",
                                      e.target.value,
                                      ""
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setServiceReqParticularValidationValue(
                                      "",
                                      "email",
                                      EmailValidation(
                                        e.target.value?.toLowerCase()
                                      ),
                                      ""
                                    );
                                  }}
                                  disabled
                                  autoComplete="off"
                                />
                                <small className="text-danger">
                                  {serviceReqParticularValidation?.email
                                    ?.message
                                    ? `Email Id ${serviceReqParticularValidation?.email?.message}`
                                    : ""}
                                </small>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Question</label>
                                <input
                                  type="text"
                                  className="form-control form-control-lg"
                                  placeholder="Enter Your Question"
                                  aria-invalid={Boolean(
                                    serviceReqParticularValidation?.question
                                      ?.status === false
                                  )}
                                  value={serviceReqParticular?.question}
                                  onChange={(e) => {
                                    setServiceReqParticularValue(
                                      "",
                                      "question",
                                      e.target.value,
                                      ""
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setServiceReqParticularValidationValue(
                                      "",
                                      "question",
                                      NonEmptyValidation(e.target.value),
                                      ""
                                    );
                                  }}
                                  disabled
                                  autoComplete="off"
                                />
                                <small className="text-danger">
                                  {serviceReqParticularValidation?.question
                                    ?.message
                                    ? `Question ${serviceReqParticularValidation?.question?.message}`
                                    : ""}
                                </small>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">
                                  Service Category
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-lg"
                                  placeholder="Enter the Service Category"
                                  aria-invalid={Boolean(
                                    serviceReqParticularValidation
                                      ?.servicesCategory?.status === false
                                  )}
                                  value={serviceReqParticular?.servicesCategory}
                                  onChange={(e) => {
                                    setServiceReqParticularValue(
                                      "",
                                      "servicesCategory",
                                      e.target.value,
                                      ""
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setServiceReqParticularValidationValue(
                                      "",
                                      "servicesCategory",
                                      NonEmptyValidation(e.target.value),
                                      ""
                                    );
                                  }}
                                  disabled
                                  autoComplete="off"
                                />
                                <small className="text-danger">
                                  {serviceReqParticularValidation
                                    ?.servicesCategory?.message
                                    ? `Category ${serviceReqParticularValidation?.servicesCategory?.message}`
                                    : ""}
                                </small>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">
                                  Service Subcategory
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-lg"
                                  placeholder="enter the Service Subcategory"
                                  aria-invalid={Boolean(
                                    serviceReqParticularValidation
                                      ?.servicesSubcategory?.status === false
                                  )}
                                  value={
                                    serviceReqParticular?.servicesSubcategory
                                  }
                                  onChange={(e) => {
                                    setServiceReqParticularValue(
                                      "",
                                      "servicesSubcategory",
                                      e.target.value,
                                      ""
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setServiceReqParticularValidationValue(
                                      "",
                                      "servicesSubcategory",
                                      NonEmptyValidation(e.target.value),
                                      ""
                                    );
                                  }}
                                  disabled
                                  autoComplete="off"
                                />
                                <small className="text-danger">
                                  {serviceReqParticularValidation
                                    ?.servicesSubcategory?.message
                                    ? `Subcategory ${serviceReqParticularValidation?.servicesSubcategory?.message}`
                                    : ""}
                                </small>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">
                                  Service Title
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-lg"
                                  placeholder="Enter the Service Title"
                                  aria-invalid={Boolean(
                                    serviceReqParticularValidation
                                      ?.servicesTitle?.status === false
                                  )}
                                  value={serviceReqParticular?.servicesTitle}
                                  onChange={(e) => {
                                    setServiceReqParticularValue(
                                      "",
                                      "servicesTitle",
                                      e.target.value,
                                      ""
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setServiceReqParticularValidationValue(
                                      "",
                                      "servicesTitle",
                                      NonEmptyValidation(e.target.value),
                                      ""
                                    );
                                  }}
                                  disabled
                                  autoComplete="off"
                                />
                                <small className="text-danger">
                                  {serviceReqParticularValidation?.servicesTitle
                                    ?.message
                                    ? `Title ${serviceReqParticularValidation?.servicesTitle?.message}`
                                    : ""}
                                </small>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label ">
                                  Assigned To
                                </label>
                                {serviceReqParticular?.status ===
                                "unassigned" ? (
                                  <select
                                    className="form-control form-control-lg"
                                    defaultValue={
                                      serviceReqParticular?.assigned_to?.name
                                    }
                                    onChange={(e) => {
                                      setAssign(true);
                                      let name = allSubadmin?.filter(
                                        (s) => s.name === e.target.value
                                      );
                                      let nameId = name?.map((n) => {
                                        return n._id;
                                      });

                                      setServiceReqParticularValue(
                                        "assigned_to",
                                        "name",
                                        nameId.toString(),
                                        "object"
                                      );
                                    }}
                                    onBlur={(e) => {
                                      let name = allSubadmin?.filter(
                                        (s) => s.name === e.target.value
                                      );
                                      let nameId = name?.map((n) => {
                                        return n._id;
                                      });
                                      setServiceReqParticularValidationValue(
                                        "assigned_to",
                                        "name",
                                        NonEmptyValidation(nameId.toString()),
                                        "object"
                                      );
                                    }}
                                    disabled
                                  >
                                    {!serviceReqParticular?.assigned_to
                                      ?.name ? (
                                      <option value="">
                                        ---Select Member---
                                      </option>
                                    ) : (
                                      ""
                                    )}
                                    {allSubadmin?.map((e) => (
                                      // <option value={e._id}>{e.name}</option>
                                      <option value={e.name}>{e.name}</option>
                                    ))}
                                  </select>
                                ) : (
                                  <select
                                    className="form-control form-control-lg"
                                    value={
                                      serviceReqParticular?.assigned_to?.name
                                    }
                                    onChange={(e) => {
                                      setAssign(true);
                                      let name = allSubadmin?.filter(
                                        (s) => s.name === e.target.value
                                      );
                                      let nameId = name?.map((n) => {
                                        return n._id;
                                      });

                                      setServiceReqParticularValue(
                                        "assigned_to",
                                        "name",
                                        nameId.toString(),
                                        "object"
                                      );
                                    }}
                                    onBlur={(e) => {
                                      let name = allSubadmin?.filter(
                                        (s) => s.name === e.target.value
                                      );
                                      let nameId = name?.map((n) => {
                                        return n._id;
                                      });
                                      setServiceReqParticularValidationValue(
                                        "assigned_to",
                                        "name",
                                        NonEmptyValidation(nameId.toString()),
                                        "object"
                                      );
                                    }}
                                    disabled={reassign === true}
                                  >
                                    {!serviceReqParticular?.assigned_to
                                      ?.name ? (
                                      <option value="">
                                        ---Select Member---
                                      </option>
                                    ) : (
                                      ""
                                    )}
                                    {allSubadmin?.map((e) => (
                                      // <option value={e._id}>{e.name}</option>
                                      <option value={e.name}>{e.name}</option>
                                    ))}
                                  </select>
                                )}

                                <small className="text-danger">
                                  {serviceReqParticularValidation?.assigned_to
                                    ?.name?.message
                                    ? `Assigned to ${serviceReqParticularValidation?.assigned_to?.name?.message}`
                                    : ""}
                                </small>
                                {/* {serviceReqParticular?.assigned_to?.name ? (
                                  <button
                                    className="btn btn-sm btn-warning mt-2"
                                    onClick={() => {
                                      setReassign(false);
                                    }}
                                  >
                                    Reassign
                                  </button>
                                ) : (
                                  ""
                                )} */}
                              </div>
                            </div>
                            <div className="col-lg-6 my">
                              {state?.auth?.auth?.role === "user" ? (
                                <div className="mb-3">
                                  <label
                                    htmlFor="fileUpload"
                                    className="form-label"
                                  >
                                    Select File
                                  </label>

                                  <input
                                    type="file"
                                    className="form-control"
                                    id="file"
                                    ref={CeoRef}
                                    onChange={(e) => {
                                      uploadCeoPic(e);
                                    }}
                                    disabled
                                  />
                                  {serviceReqParticular?.fileUploadTo?.map(
                                    (img, index) => (
                                      <>
                                        <a
                                          style={{ color: "black" }}
                                          href={img}
                                          target="_blank"
                                        >
                                          {index + 1} {img}
                                        </a>
                                        <br />
                                      </>
                                    )
                                  )}
                                </div>
                              ) : (
                                <div className="mb-3">
                                  <label
                                    htmlFor="fileUpload"
                                    className="form-label"
                                  >
                                    Select File
                                  </label>

                                  <input
                                    type="file"
                                    className="form-control"
                                    id="file"
                                    ref={CeoRef}
                                    onChange={(e) => {
                                      uploadCeoPic(e);
                                    }}
                                  />
                                  {serviceReqParticular?.fileUploadTo?.map(
                                    (img, index) => (
                                      <>
                                        <a
                                          style={{ color: "black" }}
                                          href={img}
                                          target="_blank"
                                        >
                                          {index + 1} {img}
                                        </a>
                                        <br />
                                      </>
                                    )
                                  )}
                                </div>
                              )}
                              {/* {console.log(serviceReqParticular?.fileUploadTo)} */}

                              {/* {serviceReqParticular?.fileUploadTo?.map(
                                (img, index) => (
                                  <>
                                    {index + 1}.{" "}
                                    <a href={img} target="_blank">
                                      {img}
                                    </a>
                                  </>
                                )
                              )} */}
                            </div>

                            <div className="col-lg-6">
                              {state?.auth?.auth?.role === "user" ? (
                                <div className="mb-3">
                                  <label for="services" className="form-label">
                                    Status
                                  </label>
                                  <select
                                    className="form-control"
                                    value={serviceReqParticular?.status}
                                    onChange={(e) => {
                                      setServiceReqParticularValue(
                                        "",
                                        "status",
                                        e.target.value,
                                        ""
                                      );
                                    }}
                                    disabled
                                  >
                                    <option value="ToDo">To Do</option>
                                    <option value="assigned">Assigned</option>
                                    <option value="unassigned">
                                      Unassigned
                                    </option>
                                    <option value="inprogress">
                                      In Progress
                                    </option>
                                    <option value="completed">Completed</option>
                                  </select>
                                  <small className="text-danger">
                                    {serviceReqParticularValidation?.status
                                      ?.message
                                      ? `Status ${serviceReqParticularValidation?.status?.message}`
                                      : ""}
                                  </small>
                                </div>
                              ) : (
                                <div className="mb-3">
                                  <label for="services" className="form-label">
                                    Status
                                  </label>
                                  <select
                                    className="form-control"
                                    value={serviceReqParticular?.status}
                                    onChange={(e) => {
                                      setServiceReqParticularValue(
                                        "",
                                        "status",
                                        e.target.value,
                                        ""
                                      );
                                    }}
                                  >
                                    <option value="ToDo">To Do</option>
                                    <option value="assigned">Assigned</option>
                                    <option value="unassigned">
                                      Unassigned
                                    </option>
                                    <option value="inprogress">
                                      In Progress
                                    </option>
                                    <option value="completed">Completed</option>
                                  </select>
                                  <small className="text-danger">
                                    {serviceReqParticularValidation?.status
                                      ?.message
                                      ? `Status ${serviceReqParticularValidation?.status?.message}`
                                      : ""}
                                  </small>
                                </div>
                              )}
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Subject</label>
                                <textarea
                                  rows={6}
                                  className="form-control form-control-lg"
                                  aria-invalid={Boolean(
                                    serviceReqParticularValidation?.subject
                                      ?.status === false
                                  )}
                                  value={serviceReqParticular?.subject}
                                  onChange={(e) => {
                                    setServiceReqParticularValue(
                                      "",
                                      "subject",
                                      e.target.value,
                                      ""
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setServiceReqParticularValidationValue(
                                      "",
                                      "subject",
                                      NonEmptyValidation(e.target.value),
                                      ""
                                    );
                                  }}
                                  disabled
                                  autoComplete="off"
                                ></textarea>
                                <small className="text-danger">
                                  {serviceReqParticularValidation?.subject
                                    ?.message
                                    ? `Subject ${serviceReqParticularValidation?.subject?.message}`
                                    : ""}
                                </small>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                {state?.auth?.auth?.role === "user" &&
                                serviceReqParticular?.payment === true ? (
                                  <button className="login-btn">
                                    Make Payment
                                  </button>
                                ) : (
                                  ""
                                )}
                                {state?.auth?.auth?.role === "subadmin" ? (
                                  <button
                                    className="login-btn"
                                    onClick={(e) => handleSubmitPayment(e)}
                                  >
                                    Request Payment
                                  </button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <button
                                type="button"
                                className="btn login-btn ms-2 "
                                onClick={(e) => {
                                  handleSubmit(e);
                                }}
                              >
                                Save
                              </button>
                            </div>
                            {1 ? (
                              <>
                                {/* {serviceReqParticular?.length && state?.auth?.auth?.role==="user" ? (<> */}
                                {serviceReqParticular?.commentsTo?.map(
                                  (e, i) => (
                                    <>
                                      <div
                                        key={i}
                                        className="send-pro-bg mt-3"
                                        style={{
                                          border: "1px solid black",
                                          borderRadius: "8px",
                                          marginBottom: "8px",
                                        }}
                                      >
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div className="d-flex   align-items-center">
                                            <div className="flex-shrink-0 ">
                                              {e.profileImage ? (
                                                <img
                                                  className="admin-pro-img "
                                                  style={{
                                                    width: "50px",
                                                    height: "50px",
                                                  }}
                                                  src={e.profileImage}
                                                  alt="..."
                                                />
                                              ) : (
                                                <span className="material-symbols-rounded filled-icon dash-icon">
                                                  person
                                                </span>
                                              )}
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                              <h6 className="fw-bold">
                                                {e.name}
                                              </h6>
                                              <p className="mb-0">{e.role}</p>
                                            </div>
                                          </div>
                                          <div>
                                            <p className="mb-0">
                                              Asked{" "}
                                              {moment(e.createdAt).fromNow()}
                                            </p>
                                          </div>
                                        </div>
                                        <p className="mb-0 mt-3 pl-send-pro-bg">
                                          {e.comments}
                                        </p>
                                      </div>
                                    </>
                                  )
                                )}
                                {state?.auth?.auth?.role === "superadmin" ||
                                (serviceReqParticular?.commentsTo?.length &&
                                  state?.auth?.auth?.role === "user") ||
                                state?.auth?.auth?.role === "subadmin" ||
                                state?.auth?.auth?.role === "admin" ? (
                                  <>
                                    <div className="input-group flex-nowrap  my-3">
                                      <input
                                        style={{
                                          border: "1px solid #c9c8c8",
                                          backgroundColor: "transparent",
                                          borderRight: "0px",
                                        }}
                                        type="text"
                                        className="form-control"
                                        placeholder="Your Message"
                                        aria-invalid={Boolean(
                                          commentsValidation?.comments
                                            ?.status === false
                                        )}
                                        defaultValue={leadComment?.comments}
                                        onChange={(e) => {
                                          setCommentsValue(
                                            "comments",
                                            e.target.value
                                          );
                                        }}
                                        aria-describedby="addon-wrapping"
                                      />
                                      <span
                                        className="input-group-text"
                                        id="addon-wrapping"
                                        style={{
                                          border: "1px solid #c9c8c8",
                                          backgroundColor: "transparent",
                                          borderLeft: "0px",
                                        }}
                                      >
                                        <button
                                          className="btn py-1  login-btn"
                                          type="button"
                                          id="button-addon2"
                                          onClick={(e) => {
                                            handleSubmitComment(e);
                                          }}
                                        >
                                          Send
                                          <i className="fa-solid fa-paper-plane ms-2"></i>
                                        </button>
                                      </span>
                                    </div>
                                    <small className="text-danger">
                                      {commentsValidation?.comments?.message
                                        ? `comments ${commentsValidation?.comments?.message}`
                                        : ""}
                                    </small>
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        }
      </div>
    </div>
  );
}
