import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchWithQueryAPI } from "../../../../Api/v1/Util/Api";
import { attendance_all_employee_listing, generic_specific_date } from "../../../../Api/APIUrl";
import { Table, Form, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from "react-router-dom";

export default function EmployeeAttendance() {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const token = state.auth.auth.token;
  const [employeeData, setEmployeeData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedStatus, setSelectedStatus] = useState('');

  useEffect(() => {
    fetchEmployeeAttendance(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    filterEmployeeData(selectedStatus);
  }, [selectedStatus, employeeData]);

  const fetchEmployeeAttendance = async (dateValue) => {
    try {
      let response = await fetchWithQueryAPI(token, `${attendance_all_employee_listing}?${generic_specific_date}${dateValue}`);
      if (response.statusCode === 200) {
        setEmployeeData(response.data);
        setFilteredData(response.data); // Set filtered data initially to all fetched data
      } else {
        console.error('Failed to fetch employee attendance:', response.status);
      }
    } catch (error) {
      console.error('Error fetching employee attendance:', error);
    }
  };

  const handleDateChange = (event) => {
    const selectedValue = event.target.value;
    if (new Date(selectedValue) <= new Date()) {
      setSelectedDate(selectedValue);
    } else {
      alert("Future dates are not allowed.");
    }
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const filterEmployeeData = (status) => {
    if (status === '') {
      setFilteredData(employeeData); // If no status is selected, show all data
    } else {
      const filtered = employeeData.filter((employee) => employee.clock_in_status === status);
      setFilteredData(filtered);
    }
  };

  // Function to format the time part from the timestamp
  const formatTime = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = new Date(timestamp);
    const formattedDate = date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    const time = timestamp.split('T')[1].slice(0, 5); // Extract the time part (HH:MM)
    return `${formattedDate} ${time}`;
  };

  return (
    <div className="container mt-3">
      <h2>Employee Attendance Report</h2>
      <Row>
        <Col md={8}></Col>
        <Col md={2}>
          <Form.Group controlId="dateFilter">
            <Form.Label>Date Filter</Form.Label>
            <Form.Control
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              max={new Date().toISOString().split('T')[0]} // Restrict to today's date or earlier
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group controlId="statusFilter">
            <Form.Label>Clock In Status</Form.Label>
            <Form.Control as="select" value={selectedStatus} onChange={handleStatusChange}>
              <option value="">All</option>
              <option value="clock_in">Clock In</option>
              <option value="clock_out">Clock Out</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Name</th>
            <th>Clock In Status</th>
            <th>Last Clock In Time</th>
            <th>First Clock In Time</th>
            <th>Last Clock Out Time</th>
            <th>Total Hours</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((employee) => (
            <tr key={employee.employee_id}>
              <td><span style={{cursor:'pointer',textDecoration:'underline'}}onClick={() => navigate(`/admin/employee/clock-in-out/${employee.employee_id}`)}>{employee.name}</span></td>
              <td>{employee.clock_in_status === 'clock_out' ? 'Clock Out' : 'Clock In'}</td>
              <td>{formatTime(employee.clock_in_time)}</td>
              <td>{formatTime(employee.first_clock_in_time)}</td>
              <td>{formatTime(employee.last_clock_out_time)}</td>
              <td>{employee.total_hours}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
