import React, { useEffect, useState } from "react";
import { GetPaymentPackage, GetSpecificPaymentPackage,GetUser, getAllPackagesAdmin } from "../../../Api/Api";
import AdminNavbar from "../Navbar/AdminNavbar";

import Si3 from "../../../Assets/Images/as3.webp";
import moment from "moment";
import { useSelector } from "react-redux";
import Spinner from "../../../Spinner/Spinner";
import MUIDataTable from "mui-datatables";
import CustomModal from "../../../Components/CustomModal";
import { getSpecificPackagee } from "../../../Api/Api";

export default function PaymentPackages() {
  useEffect(() => {
    window.scroll(0, 0);

    getListPayment();
    fetchPackage()
   
  }, []);
  const [paymodal, setPaymentModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [listpayment, setListPayment] = useState([]);
  const state = useSelector((state) => state);
  const getListPayment = async () => {
    setLoading(true);
    const token = state?.auth?.auth?.token;

    const data = await GetPaymentPackage(token);
    if (data && data.statusCode === 200) {
      let temp = [];
      data.data.map((data, i) => {
        let dummy = {
          _id: data?._id,
          packageId: data?.packageId,
          paymentStatus: data?.paymentStatus,

          createdAt: moment(data?.createdAt).format("DD/MM/YYYY , h:mm:ss a"),
          updatedAt: moment(data?.updatedAt).format("DD/MM/YYYY , h:mm:ss a"),
          profileId: data?.profileId,

          paymentType: data?.paymentType,
          paymentStatus: data?.paymentStatus,
          price: data?.price,
          paymentId: data?.paymentId,
        };
        temp.push(dummy);
        return null;
      });
      setListPayment(temp);
      setLoading(false);
    } else {
      setListPayment([]);
      setLoading(false);
     
    }
  };

  const [paymentpackages,setPaymentPackages] = useState([])

  async function fetchPackage() {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    const getBasicDetails = await getAllPackagesAdmin(token);
    if (getBasicDetails && getBasicDetails?.statusCode === 200) {
      setPaymentPackages(getBasicDetails.data);
      setLoading(false);
    } else {
      setPaymentPackages([]);
      setLoading(false);
      //console.log("error while getting package");
    }
  }

  const [allPackage, setAllPackage] = useState([]);
  async function fetchDataPackage(id) {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    const getBasicDetails = await getSpecificPackagee(token,id);
    if (getBasicDetails && getBasicDetails?.statusCode === 200) {
      setAllPackage(getBasicDetails.data);
      setLoading(false);
    } else {
       setAllPackage([]);
       setLoading(false);
      //console.log("error while getting package");
    }
  }

  const [getPayment1, setGetPayment1] = useState({});

  const GetPymPackagesTsks1 = async (id) => {
  setLoading(true);
  const token = state.auth.auth.token;
  let data = await GetSpecificPaymentPackage(token,id);
  if (data && data.statusCode === 200) {
    fetchData(data.data.profileId)
    fetchDataPackage(data.data.packageId)
    setGetPayment1(data.data);
    setLoading(false);
  } else {
    setGetPayment1([]);
    setLoading(false);
    //console.log("Error while getting my task");
  }
};

const [profileDetail, setProfileDetails] = useState({});
async function fetchData(id) {
  const token = state?.auth?.auth?.token;
  const getBasicDetails = await GetUser(token, id);
  if (getBasicDetails && getBasicDetails?.statusCode === 200) {
    setProfileDetails(getBasicDetails.data);
  } else {
     setProfileDetails([]);
    //console.log("error while getting user profile info")
  }
}


  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "packageId",
      label: "Package Id",
      options: {
        customBodyRender: (value, tableMeta) => {
         return (
           <div>
             {paymentpackages
               ?.filter((e) => e._id === value)
               .map((i) => (
                 <div
                   key={i}
                   onClick={(val) => {
                     GetPymPackagesTsks1(tableMeta.rowData[0]);

                     setPaymentModal(true);
                   }}
                 >
                   {i.packageType?.packageName}
                 </div>
               ))}
           </div>
         );
       
        },
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        customBodyRender: (value) => {
          return <div>₹{value}</div>;
        },
      },
    },
    {
      name: "paymentStatus",
      label: "Payment Status",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },

    {
      name: "profileId",
      label: "profile Id",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "createdAt",
      label: "Start Date",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              <div>{value}</div>
              
            </div>
          );
        },
      },
    },
    {
      name: "updatedAt",
      label: "End  Date",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              <div>{value}</div>
            
            </div>
          );
        },
      },
    },
   
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[5] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };


  return (
    <div>
      <AdminNavbar />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div>
              <div className="">
                <h5 className="mb-0 fw-bold color-maroon">Payment Package</h5>
              </div>
              <div className="my-4">
                <MUIDataTable
                  title={"Dalmaf - Payment Package "}
                  data={listpayment}
                  columns={columns}
                  options={options}
                />
              </div>
            </div>
          </>
        )}
        <CustomModal
          open={paymodal}
          onClickOutside={() => {
            setPaymentModal(false);
          }}
        >
          <div className="paymodal-content w-50 m-auto">
            <div className="paymodal-body p-0 ">
              <div className="container ">
                <span
                  className="material-symbols-rounded filled-icon close-icon"
                  style={{
                    marginRight: "30px",
                    marginTop: "50px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  onClick={() => {
                    setPaymentModal(false);
                  }}
                >
                  close
                </span>
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className=" my-5 px-3">
                          <div className="row justify-content-center mt-2">
                            <div className="col-lg-10">
                              <div className="mb-5">
                                <h4 className="fw-bold text-center  mt-2">
                                  Payment Details
                                </h4>
                              </div>
                              <div>
                                <div className="mb-3">
                                  <div className="row">
                                    <div className="col-6">
                                      <img
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                        }}
                                        src={profileDetail?.profileImage}
                                        alt=""
                                      />
                                      <h6 for="" className="form-label mb-3">
                                        Name : {profileDetail?.name}
                                      </h6>
                                      <h6 for="" className="form-label mb-3">
                                        Email ID : {profileDetail?.email}
                                      </h6>
                                      <h6 for="" className="form-label mb-3">
                                        Mobile Number :{" "}
                                        {profileDetail?.phoneNumber}
                                      </h6>
                                    </div>
                                    <div className="col-6">
                                      <div className="col-lg-12 mb-3">
                                        <div className="admin-service2">
                                          <div className="small-banner-img-head">
                                            <img
                                              style={{
                                                width: "60px",
                                                height: "60px",
                                              }}
                                              src={Si3}
                                              alt="..."
                                            />

                                            <p className="mb-0 cost-pack">
                                              ₹{allPackage?.price}
                                            </p>
                                          </div>
                                          <div className="mt-3">
                                            <h6
                                              className="color-maroon fw-bold"
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {
                                                allPackage?.packageType
                                                  ?.packageName
                                              }
                                            </h6>
                                            <div className="d-flex mb-2 align-items-center">
                                              <b>Serive Name:</b>
                                              {allPackage?.services?.map(
                                                (e, i) => (
                                                  <h6
                                                    className="banner-heading mb-0"
                                                    key={i}
                                                  >
                                                    {e?.servicesName}
                                                    <li
                                                      style={{
                                                        padding: "3px",
                                                        listStyleType: "none",
                                                      }}
                                                    >
                                                      {e?.servicesName},
                                                    </li>
                                                  </h6>
                                                )
                                              )}
                                            </div>
                                            <p className="mb-0 ">
                                              <b>
                                                To Whome:{" "}
                                                <span className="banner-heading">
                                                  {allPackage?.toWhom}
                                                </span>
                                              </b>
                                            </p>
                                            <p className="mb-0 ">
                                              <b>
                                                Package Type:{" "}
                                                <span className="banner-heading">
                                                  {
                                                    allPackage?.packageType
                                                      ?.packageName
                                                  }
                                                </span>
                                              </b>
                                            </p>
                                            <p className="mb-0 ">
                                              <b>
                                                Price:{" "}
                                                <span className="banner-heading">
                                                  {allPackage?.price}
                                                </span>
                                              </b>
                                            </p>
                                            <p className="mb-0 ">
                                              <b>
                                                GST:{" "}
                                                <span className="banner-heading">
                                                  {allPackage?.gstPercentage}
                                                </span>
                                              </b>
                                            </p>
                                            <p className="mb-0 ">
                                              <b>
                                                Created At:{" "}
                                                <span className="banner-heading">
                                                  {moment(
                                                    allPackage?.createdAt
                                                  ).format("DD/MM-YYYY")}
                                                </span>
                                              </b>
                                            </p>
                                            <p className="mb-0 ">
                                              <b>
                                                Status:{" "}
                                                <span className="banner-heading">
                                                  {allPackage?.status}
                                                </span>
                                              </b>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <h6>
                                      {" "}
                                      Payment Status :{" "}
                                      <span style={{ color: "green" }}>
                                        {getPayment1?.paymentStatus?.toUpperCase()}
                                      </span>
                                    </h6>
                                    <h6>
                                      {" "}
                                      Payment Date :{" "}
                                      {moment(getPayment1?.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
}
