import React, { useEffect } from 'react';
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import BSHouseBanner from "../Components/Buy_Sell/BSHouseBanner";
import BSHouseContent from "../Components/Buy_Sell/BSHouseContent";
export default function BSHouse() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="innerpages-nav">
      <div id="container" >
      <Navigation />
      <BSHouseBanner />
      <BSHouseContent />
      <Footer />
      </div>
    </div>
  );
}
