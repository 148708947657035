import React, { useEffect } from "react";
import Footer from "../Components/Footer/Footer";

import Navigation from "../Components/Navbar/Navigation";
import UserNavigation from '../Components/v1/NavBar/Navigaton';
import {Helmet} from "react-helmet";
export default function TermsandCondition() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="home-nav">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms and Conditions | DALMaf Private Limited</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Since we will not be meeting face to face, it is important to
                  set out the terms of the agreement clearly in advance. This
                  website is owned and operated by DALMAF Private Limited,
                  56/44, Block-A2, Thirumalai Pillai Road, T.Nagar, Chennai 600
                  017 INDIA. By visiting our website and accessing the
                  information, resources, services, products, and tools we
                  provide, you understand and agree to accept and adhere to the
                  following terms and conditions as stated in this policy
                  (hereafter referred to as 'User Agreement'), along with the
                  terms and conditions as stated in our Privacy Policy (please
                  refer to the Privacy Policy section below for more
                  information)." />
        <meta name="keywords" content="DALMaf" />
        <meta name="author" content="DALMaf" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
      <UserNavigation />
      <div>
        <div className="term-cond ">
          <div className="container">
            <div className="row justify-content-center pt-3">
              <div className="col-lg-12 py-5">
                <h4 className="fw-bold color-maroon mb-4">
                  DALMAF.com – Terms and Conditions
                </h4>
                <h6 className="fw-bold ">
                  IF YOU DO NOT AGREE TO OUR TERMS AND CONDITIONS OR OUR PRIVACY
                  POLICY, WE KINDLY REQUEST YOU TO REFRAIN FROM USING THIS
                  WEBSITE
                </h6>
                <h5 className="fw-bold color-maroon mt-3 mb-4">
                  Welcome to DALMAF.COM
                </h5>
                <p>
                  Since we will not be meeting face to face, it is important to
                  set out the terms of the agreement clearly in advance. This
                  website is owned and operated by DALMAF Private Limited,
                  56/44, Block-A2, Thirumalai Pillai Road, T.Nagar, Chennai 600
                  017 INDIA. By visiting our website and accessing the
                  information, resources, services, products, and tools we
                  provide, you understand and agree to accept and adhere to the
                  following terms and conditions as stated in this policy
                  (hereafter referred to as 'User Agreement'), along with the
                  terms and conditions as stated in our Privacy Policy (please
                  refer to the Privacy Policy section below for more
                  information).
                </p>

                <p>
                  We reserve the right to change this User Agreement from time
                  to time without notice. You acknowledge and agree that it is
                  your responsibility to review this User Agreement periodically
                  to familiarize yourself with any modifications. Your continued
                  use of this site after such modifications will constitute
                  acknowledgment and agreement of the modified terms and
                  conditions.
                </p>
                <p>
                  The decision to engage any of these professionals is an
                  important one, and one that you must make carefully based
                  solely on your own judgment. If you agree to avail of the
                  services on our website, you are giving us permission to make
                  this selection on your behalf. If you disagree with these
                  terms and conditions, or do not want us to choose a suitable
                  professional to fulfill your request, please do not use our
                  services.
                </p>
                <p>
                  We constantly strive to keep our content and documents
                  accurate, current and up-to date. However, because of changes
                  in the law and regulations, we cannot and do not guarantee
                  that any or all of the information on the site and other
                  communication is completely current.
                </p>
                <p>
                  Please be advised that sometimes, the law, legal requirements,
                  rules and regulations are location specific and may differ
                  from location to location. The general information or other
                  material we provide cannot fit every situation or
                  circumstance.
                </p>
                <p>
                  Our sites and services are not intended to create any
                  advocate-client relationship, and your use of our sites and
                  services does not and will not in any circumstance create any
                  such relationship between you and us.
                </p>
                <p>
                  If you have any queries about{" "}
                  <span className="fw-bold">DALMAF</span>, please do not
                  hesitate to contact us.
                </p>
                <p>
                  If you wish to use our “Common Needs” feature, you affirm that
                  you are more than 18 years of age and are fully able and
                  competent to enter into the terms, conditions, obligations,
                  affirmations, representations, and warranties consequent to
                  the creation of the documents, and are aware of the same.
                  Kindly call us for further assistance.
                </p>
                <div>
                  <h5 className="fw-bold color-maroon">1. USE AND CONDUCT</h5>
                  <ul>
                    <li>
                      In order to access our Resources, you may be required to
                      provide certain information about yourself (such as
                      identification, email, phone number, contact details,
                      etc.) as part of the registration process, or as part of
                      your ability to use the Resources. You agree that any
                      information you provide will always be accurate, correct,
                      and up to date.
                    </li>
                    <li>
                      You are responsible for maintaining the confidentiality of
                      any login information associated with any account you use
                      to access our Resources. Accordingly, you are responsible
                      for all activities that occur under your accounts.
                    </li>
                    <li>
                      Engaging in any activity that disrupts or interferes with
                      our Resources, including the servers and/or networks to
                      which our Resources are located or connected, is strictly
                      prohibited.
                    </li>
                    <li>
                      You are solely responsible any consequences, losses, or
                      damages that we may directly or indirectly incur or suffer
                      due to any unauthorized activities conducted by you, as
                      explained above, and may incur criminal or civil
                      liability. You are solely responsible any consequences,
                      losses, or damages that we may directly or indirectly
                      incur or suffer due to any unauthorized activities
                      conducted by you, as explained above, and may incur
                      criminal or civil liability.
                    </li>
                    <li>
                      The decision to engage any of these professionals is an
                      important one, and one that you must make carefully based
                      solely on your own judgment. If you agree to avail of the
                      services on our website, you are giving us permission to
                      make this selection on your behalf. If you disagree with
                      these terms and conditions, or do not want us to choose a
                      suitable professional to fulfill your request, please do
                      not use our services.
                    </li>
                    <li>
                      We constantly strive to keep our content and documents
                      accurate, current and up-to date. However, because of
                      changes in the law and regulations, we cannot and do not
                      guarantee that any or all of the information on the site
                      and other communication is completely current.
                    </li>
                    <li>
                      Please be advised that sometimes, the law, legal
                      requirements, rules and regulations are location specific
                      and may differ from location to location. The general
                      information or other material we provide cannot fit every
                      situation or circumstance.
                    </li>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon">
                    2. RIGHT TO REMOVAL OF CONTENT
                  </h5>
                  <ul>
                    <li>
                      We have the right at our sole discretion to remove any
                      content that, we feel in our judgment does not comply with
                      this User Agreement, along with any content that we feel
                      is otherwise offensive, harmful, objectionable,
                      inaccurate, or violates any 3rd party copyrights or
                      trademarks. We are not responsible for any delay or
                      failure in removing such content. If you post content that
                      we choose to remove, you hereby consent to such removal,
                      and consent to waive any claim against us.
                    </li>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon">3. INDEMNITY CLAUSE</h5>
                  <ul>
                    <li>
                      You agree to indemnify and hold harmless DALMAF.COM a
                      digital property of DALMAF Private Limited and its parent
                      company and affiliates, and their directors, officers,
                      managers, employees, donors, agents, and licensors, from
                      and against all losses, expenses, damages and costs,
                      including reasonable attorneys' fees, resulting from any
                      violation of this User Agreement or the failure to fulfill
                      any obligations relating to your account incurred by you
                      or any other person using your account. We reserve the
                      right to take over the exclusive defence of any claim for
                      which we are entitled to indemnification under this User
                      Agreement. In such event, you shall provide us with such
                      cooperation as is reasonably requested by us.
                    </li>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon">4. LIMITATION ON USE</h5>
                  <ul>
                    <li>
                      The contents of <span className="fw-bold">DALMAF</span>
                      .com are for personal use only and not for commercial
                      exploitation. You may not decompile, reverse engineer,
                      disassemble, rent, lease, loan, sell, sublicense, or
                      create derivative works from{" "}
                      <span className="fw-bold">DALMAF</span>.com. Nor may you
                      use any network monitoring or discovery software to
                      determine the site architecture, or extract information
                      about usage or users. You may not use any robot, spider,
                      other automatic device, or manual process to monitor or
                      copy the contents without taking prior written permission
                      from <span className="fw-bold">DALMAF</span>.com. You may
                      not copy, modify, reproduce, republish, distribute,
                      display, or transmit for commercial, non-profit or public
                      purposes all or any portion of{" "}
                      <span className="fw-bold">DALMAF</span>.com, except to the
                      extent permitted above. You may not use or otherwise
                      export or re-export{" "}
                      <span className="fw-bold">DALMAF</span>.com or any portion
                      available on or through{" "}
                      <span className="fw-bold">DALMAF</span>.com in violation
                      of the export control laws and regulations of India. Any
                      unauthorized use of
                      <span className="fw-bold">DALMAF</span>.com or its content
                      is prohibited.
                    </li>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon">
                    5. Termination of Usage
                  </h5>
                  <ul>
                    <li>
                      {" "}
                      You agree that we may, at our sole discretion, suspend or
                      terminate your access to all or part of our website and
                      Resources with or without notice and for any reason,
                      including, without limitation, breach of this User
                      Agreement. Any suspected illegal, fraudulent or abusive
                      activity may be grounds for terminating your relationship
                      and may be referred to appropriate law enforcement
                      authorities. Upon suspension or termination, your right to
                      use the Resources we provide will immediately cease, and
                      we reserve the right to remove or delete any information
                      that you may have on file with us, including any account
                      or login information.
                    </li>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon"></h5>
                  <ul>
                    <li>
                      The information provided in the ‘FAQs’ section also does
                      not amount to legal advice, such merely being commonly
                      asked queries about Will making, Lease Agreement drafting,
                      Cheque Dishonour notices, Money recovery notices, Power of
                      Attorney to collect rent and other documents which may be
                      added from time to time. Users are advised to consult
                      lawyers if they need specialized guidance on any of these
                      documents.
                    </li>
                    <li>
                      Please note that neither DALMAF.com, nor any of its
                      subsidies or employees are advocates. We are not a law
                      firm and we do not provide legal advice. Nothing on our
                      website or material sent to you in our communication is to
                      be construed as legal advice.
                    </li>
                    <li>
                      Our website, blog and other material is only for the
                      purpose of spreading information and awareness and are not
                      substitutes for the advices or services of an advocate or
                      legal professional.
                    </li>
                    <li>
                      Wherever required in order to fulfill your needs, we will
                      facilitate a connection with a suitable professional such
                      as lawyers, chartered accountants or company secretaries.
                      Please bear in mind that such professionals are not our
                      representatives, agents or employees. Our site and
                      services are only one source of information among the many
                      sources that are available to you. You may wish to
                      consider multiple sources in order to make an informed
                      decision.
                    </li>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon">
                    6. DALMAF.com is not responsible for any DATA LOSS
                  </h5>
                  <ul>
                    <p>
                      DALMAF.com is not responsible for any loss of data
                      resulting from accidental or deliberate deletion, network
                      or system outages, backup failure, file corruption, or any
                      other reasons
                    </p>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon">7. ADVERTISERS</h5>
                  <ul>
                    <p>
                      DALMAF.com may contain advertising and sponsorship.
                      Advertisers and sponsors are responsible for ensuring that
                      material submitted for inclusion on DALMAF.com is accurate
                      and complies with applicable laws. DALMAF.com will not be
                      responsible for the illegality of or any error or
                      inaccuracy in advertisers' or sponsors' materials.
                    </p>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon">8. REGISTRATION</h5>
                  <ul>
                    <p>
                      Certain sections of DALMAF.com may require you to
                      register. If registration is requested, you agree to
                      provide DALMAF.com with accurate and complete registration
                      information. It is your responsibility to inform
                      DALMAF.com any changes to that information. Each
                      registration is for a single person only, unless
                      specifically designated otherwise on the registration
                      page. DALMAF.com does not permit a) any other person using
                      the registered sections under your name; or b) access
                      through a single name being made available to multiple
                      users on a network. You are responsible for preventing
                      such unauthorized use. If you believe there has been
                      unauthorized use, please notify DALMAF.com immediately by
                      contacting us. If we find that unauthorized use is being
                      made of DALMAF.com and the services we provide, the right
                      of any or many users may be terminated.
                    </p>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon">
                    9. ERRORS AND CORRECTIONS
                  </h5>
                  <ul>
                    <p>
                      DALMAF.com does not represent or warrant that the service
                      will be error-free, free of viruses or other harmful
                      components, or that defects will be corrected. DALMAF.com
                      may make improvements and/or changes to its features,
                      functionality or service at any time.
                    </p>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon">
                    10. MODIFICATIONS TO TERMS OF USE
                  </h5>
                  <ul>
                    <p>
                      DALMAF.com may change the agreement at any time. The user
                      will be responsible for checking the Terms and Conditions
                      before use. Use of the service after the change will
                      indicate acceptance of the new terms and conditions.
                      DALMAF.com has the right to discontinue service, or deny
                      access to anyone who violates our policies or the terms
                      and conditions stated herein, without prior notice or
                      warning. DALMAF.com may freely transfer, assign or
                      delegate all or any part of this Terms & Conditions, and
                      any rights and duties hereunder. This Terms & Conditions
                      will be binding upon and inure to the benefit of the
                      heirs, successors and permitted assignees of the parties
                    </p>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon">
                    11. MODIFICATIONS TO SERVICE
                  </h5>
                  <ul>
                    <p>
                      DALMAF.com reserves the right to modify or discontinue,
                      temporarily or permanently, the service with or without
                      notice to the user. The user agrees that DALMAF.com shall
                      not be liable to the user or any third party for any
                      modification or discontinuance of the service. The user
                      acknowledges and agrees that any termination of service
                      under any provision of this agreement may be affected
                      without prior notice, and acknowledges and agrees that
                      DALMAF.com may immediately delete data and files in the
                      user's account and bar any further access to such files or
                      the Service.
                    </p>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon">12. ENTIRE AGREEMENT</h5>
                  <ul>
                    <p>
                      This agreement constitutes the entire and whole agreement
                      between user and DALMAF.com, and is intended as a complete
                      and exclusive statement of the terms of the agreement.
                      This agreement shall supersede all other communications
                      between DALMAF.com and its users with respect to the
                      subject matter hereof and supersedes and replaces all
                      prior or contemporaneous understandings or agreements,
                      written or oral, regarding such subject matter. If at any
                      time you find these Terms and Conditions unacceptable or
                      if you do not agree to these Terms and Conditions, please
                      do not use this Site. We may revise these Terms and
                      Conditions at any time without notice to you. It is your
                      responsibility to review these Terms and Conditions
                      periodically.
                    </p>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon">
                    13. UNLAWFUL ACTIVITY
                  </h5>
                  <ul>
                    <p>
                      DALMAF.com reserves the right to investigate complaints or
                      reported violations of the Agreement and to take any
                      action DALMAF.com deems appropriate including but not
                      limited to reporting any suspected unlawful activity to
                      law enforcement officials, regulators, or other third
                      parties and disclosing any information necessary or
                      appropriate to such persons or entities relating to user
                      profiles, e-mail addresses, usage history, posted
                      materials, IP addresses and traffic information.
                    </p>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon">
                    14. REMEDIES FOR VIOLATIONS
                  </h5>
                  <ul>
                    <p>
                      DALMAF.com reserves the right to seek all remedies
                      available at law and in equity for violations of the
                      Agreement, including but not limited to the right to block
                      access from a particular Internet address to DALMAF.com
                      and its features.
                    </p>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon">
                    15. SEVERABILITY OF PROVISIONS
                  </h5>
                  <ul>
                    <p>
                      The Agreement and the Privacy Policy constitute the entire
                      agreement with respect to the use of the service provided
                      by DALMAF.com. If any provision of these terms and
                      conditions is unlawful, void or unenforceable then that
                      provision shall be deemed severable from the remaining
                      provisions and shall not affect their validity and
                      enforceability.
                    </p>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon">16. MEMBERSHIP TERMS</h5>
                  <ul>
                    <li>
                      You represent and warrant that you are at least eighteen
                      years of age and that you possess the legal right and the
                      ability to enter into agreements pertaining to Terms &
                      Conditions, to register on the DALMAF.com Services under
                      your own name and to use the DALMAF.com Services in
                      accordance with the Terms & Conditions and abide by the
                      obligations hereunder.
                    </li>
                    <li>
                      Opting any Membership Services at DALMAF.com would be
                      subject to terms and conditions.
                    </li>
                    <ol style={{ listStyleType: "upper-roman" }}>
                      <li>
                        The membership term shall commence on the starting date
                        of membership as specified in the Membership Application
                        and shall continue until the ending date of membership
                        also indicated therein. The Member acknowledges that the
                        Services can only be enjoyed during the term period of
                        the membership
                      </li>
                      <li>
                        The Provider shall offer legal services to the Member
                        during the term of the membership as agreed upon by the
                        parties. These services may include, but are not limited
                        to, legal advice, consultation, document preparation,
                        Drafting, and any other legal assistance as deemed
                        necessary by the Provider. Any queries in the services
                        Kindly send an Email to 
                        <a className="ms-2" href="serviceattention@dalmaf.com" target="_blank">
                          serviceattention@dalmaf.com
                        </a>
                      </li>
                      <li>
                        The Member understands that the Services offered by the
                        Provider are subject to the applicable laws,
                        regulations, and ethical rules governing the provision
                        of legal services
                      </li>
                      <li>
                        The Member agrees to provide accurate and complete
                        information to the Provider, including personal details,
                        contact information, and any relevant facts related to
                        the legal matter for which services are sought.
                      </li>
                      <li>
                        The Member shall cooperate with the Provider and
                        promptly respond to any requests for information or
                        documentation necessary to provide the Services.
                      </li>
                      <li>
                        The Provider does not guarantee any specific outcome or
                        result regarding the Services. The Provider shall
                        exercise reasonable professional skill and care in
                        providing the Services but makes no warranties, express
                        or implied, regarding the Services' effectiveness,
                        accuracy, or suitability for any particular purpose.
                      </li>
                      <li>
                        The Provider shall not be liable for any damages,
                        losses, or expenses arising out of or in connection with
                        the Member's use of the Services, except to the extent
                        caused by the Provider's gross negligence or willful
                        misconduct.
                      </li>
                      <li>
                        The Provider reserves the right to terminate the
                        membership in the event of non-payment of fees,
                        unethical or illegal conduct by the Member, or if the
                        Provider determines that it is unable to provide the
                        Services effectively or within the bounds of ethical and
                        legal obligations.
                      </li>
                      <li>
                        This Agreement shall be governed by and construed in
                        accordance with the laws of Courts of Chennai District
                        shall have Jurisdiction in all such cases, without
                        regard to its conflict of laws principles
                      </li>
                      <li>
                        Any dispute arising out of or in connection with this
                        Agreement shall be submitted to binding arbitration in
                        Courts of Chennai District shall have Jurisdiction in
                        all such cases in accordance with laws of India
                      </li>
                    </ol>
                    <li>
                      You are solely responsible for maintaining the
                      confidentiality of your password and for all activities
                      carried out under your DALMAF.com account. You agree to
                      prohibit anyone else from using your password and
                      immediately notify LegalKart.com of any unauthorized use
                      of your password or other security concerns.
                    </li>
                    <li>
                      International members acknowledge and agree that
                      LegalKart.com may collect and use your Information and
                      disclose it to other entities outside your resident
                      jurisdiction. In addition, such Information may be stored
                      on servers located outside your resident jurisdiction. By
                      providing us with your Information, you acknowledge that
                      you consent to the transfer of such Information outside
                      your resident jurisdiction as detailed in our Privacy
                      Policy. If you do not consent to such transfer, you may
                      not use our Content or Services.
                    </li>
                    <li>
                      Your affirmative act of using this Website and/or
                      registering for the Website or the Services constitutes
                      your electronic signature to these Terms & Conditions and
                      your consent to enter into agreements with us
                      electronically.
                    </li>
                    <li>
                      You are prohibited from using any type of computer 'worm,'
                      'virus' or any other device that is intended or is likely
                      to disrupt, overload, or otherwise impair the workings of
                      any part of the Website. If you do engage in such conduct,
                      the resulting damage will be hard to quantify as a fixed
                      amount and thus you hereby agree to pay us liquidated
                      damages in the amount of INR 10,00,000/- for each day that
                      the Website is damaged until the Website is completely
                      repaired and further unqualified damages which will occur
                      due to your act will be accessed and you shall be liable
                      to pay the same. This amount is derived by estimating the
                      value of (1) the loss of good will caused by an inoperable
                      website, (2) the time and money it will take to repair the
                      Website and to address the concerns of visitors. We are
                      required to use reasonable efforts to repair the Website
                      as quickly as possible. This clause will not prohibit us
                      from seeking additional compensation if your conduct
                      injures us in a way not expressly contemplated herein.
                    </li>
                    <li>
                      <span className="fw-bold">DALMAF</span>.com reserves the
                      right to terminate any account which doesn’t follow our
                      Terms and Conditions.
                    </li>
                  </ul>
                </div>
              
                {/* <div>
                  <h5 className="fw-bold color-maroon">
                  17. Electronic Payment
                  </h5>
                </div> */}
                <div>
                  <h5 className="fw-bold color-maroon">
                  17. ASSIGNMENT
                  </h5>
                  <ul>
                    <p>
                    We may assign this contract at any time to any parent, subsidiary, or any affiliated company, or as part of the sale to, merger with, or other transfer of our company to another entity. We will post a notice on the Website regarding any change of ownership so that you have the opportunity to discontinue your use of the Website or cancel your registration if you do not wish to continue to use the Website and the Services under the new ownership. You may not assign, transfer or sublicense these Terms & Conditions to anyone else and any attempt to do so in violation of this section shall be null and void.
                    </p>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon">
                  18. CANCELLATION AND REFUND POLICY
                  </h5>
                  <ul>
                    <a href="cancellation-and-refund-policy">To Know more click here</a><br></br>
                    <b>All the services listed in the DALMAF.com under a particular category may not have a return policy since it’s a service oriented not physical product to be returned.</b>

                  </ul>
                </div>
                {/* <div>
                  <h5 className="fw-bold color-maroon">
                  30 Day Refund Policy
                  </h5>
                  <ul>
                   <p>If a service opted by you is not progressing as per your expectations, you can request for a refund within 30 days of payment. After 30 days, you can request for the refund to be processed as a service credit - which can be used for any other future service.</p>
                   <p className="fw-bold">Cancellation Fee Deduction</p>
                   <p>Before processing any refund, we reserve the right to make a best effort to complete the service. In case you are not satisfied with the service, a cancellation fee of 20% + fee paid to the government would be applicable. In case of change of service, the cancellation fee would not be applicable.</p>
                   <p>Hence, all refunds will be subject to a minimum of 20% cancellation fee.</p>
                   <p className="fw-bold">Change of Service</p>
                   <p>If you want to change the service you ordered for a different one, you can request this change of service within 20 days of purchase. The purchase price of the original service, less any and money paid to government entities, such as filing fees or taxes, or to other third parties with deducted and the next service will be launched.</p>
                   <p className="fw-bold">Penalty or Tax Payment or charges levied by Government</p>
                   <p>DALMAF.com and its employees play the role of a corporate service provider. While, it is our responsibility to prepare the necessary filings, it is the clients responsibility to ensure accuracy and correctness. Hence, DALMAF.com will not pay any penalties or taxes on behalf of its clients. Further, DALMAF.com is not responsible or liable for any other cost incurred by the client related to the completion of the service that is out of scope.</p>
                   <p className="fw-bold">Factors Outside our Control</p>
                   <p>We cannot guarantee the results or outcome of your particular procedure. For instance, the government may reject a trademark application for legal reasons beyond the scope of DALMAF.com services. In some cases, a government backlog or problems with the government platforms (e.g. MCA website, Income Tax website, FSSAI website, Ecourts & other government portals) can lead to long delays before your process is complete. Problems like these are beyond our control and are not covered by this guarantee or eligible for refund. Hence, delay in processing of your file by the Government cannot be a reason for refund.</p>
                   <p className="fw-bold">Force Majeure</p>
                   <p>DALMAF.com shall not be considered in breach of its Satisfaction Guarantee policy or default under any terms of service, and shall not be liable to the Client for any cessation, interruption, or delay in the performance of its obligations by reason of earthquake, flood, fire, storm, lightning, drought, landslide, hurricane, cyclone, typhoon, tornado, natural disaster, act of God or the public enemy, epidemic, famine or plague, action of a court or public authority, change in law, explosion, war, terrorism, armed conflict, labour strike, lockout, boycott or similar event beyond our reasonable control, whether foreseen or unforeseen (each a "Force Majeure Event").</p>
                   
                  </ul>
                </div> */}
                <div>
                  <h5 className="fw-bold color-maroon">
                  19. Guarantee
                  </h5>
                  <ul>
                    <p>
                    UNLESS OTHERWISE EXPRESSED, <a href="www.dalmaf.com" className="text-danger" target="_blank">www.dalmaf.com</a>  EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                    </p>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold color-maroon">
                  20. ARBITRATION
                  </h5>
                  <ul>
                    <p>
                    Any controversy or claim arising out of or relating to this Agreement or DALMAF.com services shall be settled by binding Arbitration in accordance with laws of India. Any such controversy or claim shall be arbitrated on an individual basis, and shall not be consolidated in any arbitration with any claim or controversy of any other party. Any other dispute or disagreement of a legal nature will also be decided in accordance with the laws of India, and the Courts of Chennai District shall have Jurisdiction in all such cases.
                    </p>
                  </ul>
                </div>
                <div>
                  <h5 className="fw-bold mt-5 ">
                  IF YOU DO NOT AGREE TO OUR TERMS AND CONDITIONS OR OUR PRIVACY POLICY, WE KINDLY REQUEST YOU TO REFRAIN FROM USING THIS WEBSITE
                  </h5>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
