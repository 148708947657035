import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import AdminNavbar from "../Navbar/AdminNavbar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AuctionLeadsDetails from "./AuctionLeadsDetails";

export default function AuctionLeads() {
  const [value, setValue] = React.useState('property');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="Auction Leads" style={{ marginLeft: "275px", marginTop: "-40px" }}>
          <Tab label="Property Auction Views" value="property" />
          <Tab label="Gold Auction Views" value="gold" />
          <Tab label="Vehicle Auction Views" value="vehicle" />
        </Tabs>
        <div className="main" style={{ marginTop: "50px" }}>
          <AuctionLeadsDetails auctionType={value} />
        </div>
      </Box>
    </div>
  );
}
