import React, { useEffect, useState } from "react";
import { sections } from "../../Datas";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Ser1 from "../../Assets/Images/ser1.webp";
import Ser2 from "../../Assets/Images/ser2.webp";
import Ser3 from "../../Assets/Images/ser3.webp";
import Si1 from "../../Assets/Images/si-1.webp";
import Si2 from "../../Assets/Images/si-2.webp";
import Si3 from "../../Assets/Images/si-3.webp";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  EmailValidation,
  NonEmptyValidation,
  PhoneNumberValidation,
  StringValidation,
} from "../../Store/validate";
import { GetContent, UserDmContent, UseraboutContent } from "../../Api/Api";
import { useSelector } from "react-redux";
import { GetUser } from "../../Api/Api";
import { UserServiceRequest } from "../../Api/Api";
import CustomModal from "../CustomModal";
import ServicesAllpages from "../ServicesAllpages";
export default function DMContent() {
  const locationSearch = useLocation();
  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    AOS.init();
    window.scroll(0, 0);
    const search = locationSearch.search;
    const tab = new URLSearchParams(search).get("tab");

    if (tab) {
      setSelectedTab(tab);
    }
    return () => {};
  }, [locationSearch]);

  const [loginModal, setloginModal] = useState(false);

   const [dmContentDetail, setDmContentDetails] = useState({});

   const [contactValidation, setContactValidation] = useState({});

   const setContactDetailsValue = (key, value) => {
     setDmContentDetails({ ...dmContentDetail, [key]: value });
     if (contactValidation[key]) delete contactValidation[key];
   };

   const setContactValidationValue = (key, value) => {
     setContactValidation({ ...contactValidation, [key]: value });
   };

   //error
   const showToastErrorMsg = (errMsg) => {
     toast.error(errMsg, {
       position: toast.POSITION.TOP_RIGHT,
       className: "toast-message",
       autoClose: false,
     });
   };

   //success
   const showToastSuccessMsg = (successMsg) => {
     toast.success(successMsg, {
       position: toast.POSITION.TOP_RIGHT,
       className: "toast-message",
       autoClose: 5000,
     });
   };

   const state = useSelector((state)=>state)

   const [userProfile, setUserProfile] = useState([]);
useEffect(() => {
  if(state?.auth?.isLoggedIn===true){
    getUserProfile();
  }
  GetMyDmContent()
}, []);
const getUserProfile = async () => {
  const id = state?.auth?.auth?._id 
  const token = state.auth.auth.token
  const response = await GetUser(token,id);
  if (response.statusCode === 200) {
    setUserProfile(response.data);
  } else {
    //console.log("error while getting user profile info")
  }
};


   const handleSubmit = async (e) => {
     e.preventDefault();
    
      let validate = {};
     validate.name = StringValidation(dmContentDetail?.name);
     validate.emailId = EmailValidation(dmContentDetail?.emailId);
     validate.subject = NonEmptyValidation(dmContentDetail?.subject);
     validate.question = NonEmptyValidation(dmContentDetail?.question);
     validate.mobileNumber = PhoneNumberValidation(dmContentDetail?.mobileNumber);
  validate.servicesType = NonEmptyValidation(dmContentDetail?.servicesType);

     setContactValidation(validate);
     if (Object.values(validate).every((v) => v.status === true)) {
       const Payload = {
         emailId: dmContentDetail.emailId,
         name: dmContentDetail.name,
         subject: dmContentDetail.subject,
         question: dmContentDetail.question,
         mobileNumber: dmContentDetail.mobileNumber,
         userId: state?.auth?.auth?._id,
          servicesType: dmContentDetail.servicesType,

       };

       
       const response = await UseraboutContent(Payload);
       if (response && response.statusCode === 200) {
         
         showToastSuccessMsg(response.message);
         window.location.reload();
         setDmContentDetails({});
         dmContentDetail({});

       } else {
         showToastErrorMsg(
           response.message || response.error.response.data.message
         );
       }
     } else {
       //console.log("Error while update user details");
     }
     }
     
  //  };

  let cat = localStorage?.getItem("category")?.split("?");

   const handleSubmitService = async (e) => {
     const Payload = {
         name: userProfile.name,
         email: userProfile.email,
         subject: " ",
         question: " ",
         mobileNumber: userProfile.phoneNumber,
         type:"service",
         userId: state?.auth?.auth?._id,
         servicesCategory: cat[0].substring(cat[0].lastIndexOf("/") + 1),
         servicesSubcategory: localStorage?.getItem("subcategory"),
         servicesTitle: localStorage?.getItem("title"),
       };
       // const token = state?.auth?.auth?.token
       const response = await UserServiceRequest(Payload);
       if (response && response.statusCode === 200) {
         showToastSuccessMsg(response.message);
       } else {
         showToastErrorMsg(
           response.message || response.error.response.data.message
         );
        
       }
    
   };

   const [dmContent1, setDmContent1] = useState({});
  const [form, setForm] = useState(false);

const [dmContentValidation1, setDmContentValidation1] = useState({});

const setDmContentValue1 = (key, value) => {
  setDmContent1({ ...dmContent1, [key]: value });
  if (dmContentValidation1[key]) delete dmContentValidation1[key];
};

const setDmContentValidationValue1 = (key, value) => {
  setDmContentValidation1({ ...dmContentValidation1, [key]: value });
};


const handleSubmitDmContent = async (e) => {
  e.preventDefault()
    const Payload = {
      name: dmContent1.name,
      email: dmContent1.email,
      subject: dmContent1.subject,
      question: dmContent1.question,
      mobileNumber: dmContent1.mobileNumber,
      type: "service",
      servicesCategory: cat[0].substring(cat[0].lastIndexOf("/") + 1),
        servicesSubcategory: localStorage?.getItem("subcategory"),
        servicesTitle: localStorage?.getItem("title"),
     
    };
    // const token = state?.auth?.auth?.token
    //console.log(Payload);
    const response = await UserServiceRequest(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setDmContent1({})
      dmContent1({})
      setForm(false)
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
};

const [dmContentContent,setDmContentContent] = useState([])
  const GetMyDmContent = async () => {
    let id = localStorage.getItem("level2Id")
    const token = state.auth.auth.token;
    let data = await GetContent("active",id);
    if (data && data.statusCode === 200) {
      setDmContentContent(data.data);
    } else {
      setDmContentContent([]);
      //console.log("Error while getting my task");
    }
  };

  return (
    <div className="overflow-pages">
      <div className="req-call-back-fixed">
        <button
          type="button"
          className="btn login-btn w-100"
          onClick={(e) => {
            if (state?.auth?.isLoggedIn) {
              handleSubmitService(e);
            } else {
              setForm(true);
            }
          }}
        >
          Request Call Back
        </button>
      </div>

      <div>
        <ToastContainer />
        <div className="about-banner2 pt-5">
          <div className="container">
            {/* <div className="row">
            <div className="col-8"></div>
            <div className="col-4 text-end">
              <button
                type="button"
                className="btn login-btn w-100"
                onClick={(e) => {
                  if (state?.auth?.isLoggedIn) {
                    handleSubmitService(e);
                  } else {
                    setForm(true);
                  }
                }}
              >
                Request Call Back
              </button>
            </div>
          </div> */}
            {/* <div className="row">
              <div className="col-lg-4 col-md-6  m-auto" data-aos="fade-left">
                <div className="about-img">
                  <img className="w-100 h-auto" src={RFA} alt="" />
                </div>
              </div>
              <div className="col-lg-5 col-md-6  m-auto" data-aos="fade-left">
                <div className="auditcontent-box">
                  {sections[selectedTab]?.map((item, index) => {
                    return (
                      <h4 className="banner-heading mb-2 mt-3">
                        {item.pagename}
                      </h4>
                    );
                  })}
                  <div className="bg-grey">
                    <div className="d-flex ">
                      <div className="form-check mx-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          value="solo"
                          onChange={(e) => {
                            setSolo(e.target.value);
                          }}
                          defaultChecked={solo === "solo"}
                        />
                        <label
                          className="form-check-label fw-bold"
                          for="flexRadioDefault1"
                        >
                          Solo Startup
                        </label>
                      </div>
                      <div className="form-check mx-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          value="company"
                          onChange={(e) => {
                            setSolo(e.target.value);
                          }}
                        />
                        <label
                          className="form-check-label fw-bold"
                          for="flexRadioDefault1"
                        >
                          Company or LLP
                        </label>
                      </div>
                    </div>
                    <div className="m-2">
                      <p className="small mb-0 inline-audit">
                        <span className="material-symbols-rounded color-maroon align-middle">
                          double_arrow
                        </span>
                        <span className="align-middle color-maroon fw-bold">
                          GST Registration
                        </span>
                      </p>
                      <p className="small mb-0 inline-audit">
                        <span className="material-symbols-rounded color-maroon align-middle">
                          double_arrow
                        </span>
                        <span className="align-middle color-maroon fw-bold">
                          Electricity Bill
                        </span>
                      </p>
                      <p className="small mb-0 inline-audit">
                        <span className="material-symbols-rounded color-maroon align-middle">
                          double_arrow
                        </span>
                        <span className="align-middle color-maroon fw-bold">
                          Passport
                        </span>
                      </p>
                      <p className="small mb-0 inline-audit">
                        <span className="material-symbols-rounded color-maroon align-middle">
                          double_arrow
                        </span>
                        <span className="align-middle color-maroon fw-bold">
                          Aadhaar Copy
                        </span>
                      </p>
                      <p className="small mb-0 inline-audit">
                        <span className="material-symbols-rounded color-maroon align-middle">
                          double_arrow
                        </span>
                        <span className="align-middle color-maroon fw-bold">
                          Gas Bill
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="audit-content-horiz mt-3">
                    <h6 className="fw-bold">Required Document</h6>
                    <ul>
                      <li>Pan card Copy</li>
                      <li>Electricity Bill</li>
                      <li>Passport</li>
                      <li>Aadhaar Copy</li>
                      <li>Gas Bill</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-8  m-auto" data-aos="fade-left">
                {price?.map((e) =>
                  e.price.map((p) => (
                    <div className="price-details">
                      <h6 className="fw-bold py-3 px-2 border-bottom">
                        Price Details
                      </h6>
                      <div className=" d-flex justify-content-between">
                        <p className="price-details-content">Market Price</p>
                        {solo === "solo" ? (
                          <p className="price-details-content">
                            ₹{p.solo.marketPrice}
                          </p>
                        ) : (
                          <p className="price-details-content">
                            ₹{p.company.marketPrice}
                          </p>
                        )}
                      </div>
                      <div className=" d-flex justify-content-between">
                        <p className="price-details-content">Dalmaf Price</p>
                        {solo === "solo" ? (
                          <p className="price-details-content text-secondary">
                            ₹{p.solo.dalmafPrice}
                          </p>
                        ) : (
                          <p className="price-details-content text-secondary">
                            ₹{p.company.dalmafPrice}
                          </p>
                        )}
                      </div>
                      <div className=" d-flex justify-content-between border-bottom">
                        <p className="price-details-content">GST </p>
                        {solo === "solo" ? (
                          <p className="price-details-content">₹{p.solo.GST}</p>
                        ) : (
                          <p className="price-details-content">
                            ₹{p.company.GST}
                          </p>
                        )}
                      </div>
                      <div className=" d-flex justify-content-between border-bottom">
                        <p className="price-details-content color-maroon">
                          Total Amount
                        </p>
                        {solo === "solo" ? (
                          <p className="price-details-content color-maroon">
                            ₹{p.solo.dalmafPrice + p.solo.GST}
                          </p>
                        ) : (
                          <p className="price-details-content color-maroon">
                            ₹{p.company.dalmafPrice + p.company.GST}
                          </p>
                        )}
                      </div>
                      <p className="py-3 px-2 small mb-0">
                        You will save{" "}
                        <span className="text-primary">$3000</span> on this
                        order
                      </p>
                      <div>
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                          style={{borderTopLeftRadius:"6px",
                            borderTopRightRadius: "6px"}}
                            type="button"
                            className="btn login-btn w-auto mb-2 
                            "
                            onClick={() => {
                              setBookNow(true);
                            }}
                          >
                            Buy Now
                          </button>
                          <button
                          style={{borderTopLeftRadius:"6px",
                          borderTopRightRadius: "6px"}}
                          type="button"
                          className="btn login-btn w-auto mb-2"
                          onClick={() => {
                            setBookNow(true);
                          }}
                        >
                          Buy Now
                        </button>
                        </div>

                        <button
                          type="button"
                          className="btn login-btn w-100"
                          onClick={(e) => {
                            if (state?.auth?.isLoggedIn) {
                              handleSubmitService(e, "", "after");
                            } else {
                              setloginModal(true);
                            }
                          }}
                        >
                          Request Service
                        </button>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div> */}
        </div>
      </div>
      <div className=" py-5">
        <div className="container">
          {dmContentContent?.map((item, index) => {
            return (
              <div className="row">
                <div
                  key={index}
                  className="col-lg-12 m-auto"
                  data-aos="fade-left"
                >
                  <div className="row">
                    <div className="col-lg-8 my-auto">
                      <div className="about-content my-5">
                        {/* {item.mainServiceId.title ? (
                          <h3 className="banner-heading after-line mb-3">
                            {item.mainServiceId.title}
                          </h3>
                        ) : null} */}
                        {item.subServiceL2Id.title && (
                          <p className="fw-bold">{item.subServiceL2Id.title}</p>
                        )}
                        {item?.content ? (
                        <p dangerouslySetInnerHTML={{
                          __html: item?.content,
                        }}></p>
                ) : null}
                        {item?.points?.map((point, i ) => {
                          return <p >{point}</p>;
                        })}
                        {/* {item?.footer && <p>{item.footer}</p>}
                        {item?.table && item.table} */}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        {/* {item?.marketingimg && item.marketingimg} */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="client-left-section py-5">
        <div className="container my-4">
          <h2 className="banner-heading after-line mb-5">FEEL FREE TO ASK</h2>
          <div className="row">
            <div className="col-lg-6 " data-aos="fade-left">
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.823571029211!2d80.23700327559305!3d13.046899587275508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267c25ec3b519%3A0xb10841584ccc73c4!2sDALMAF%20Private%20Limited!5e0!3m2!1sen!2sin!4v1688118977225!5m2!1sen!2sin"
                  width="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-left">
              {/* {state?.auth?.isLoggedIn ? (<button type="button" className="btn login-btn w-100"
                  onClick={(e)=>{
                    handleSubmit(e)
                  }}
                  >
                  Request Callback
                </button>) : ( */}
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Enter Your Name"
                      aria-invalid={Boolean(
                        contactValidation?.name?.status === false
                      )}
                      value={dmContentDetail?.name || ""}
                      onChange={(e) => {
                        setContactDetailsValue("name", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "name",
                          StringValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {contactValidation?.name?.message
                        ? `Name ${contactValidation?.name?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Mobile No <span className="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      maxLength={10}
                      className="form-control form-control-lg"
                      placeholder="Enter Your Mobile No"
                      aria-invalid={Boolean(
                        contactValidation?.mobileNumber?.status === false
                      )}
                      value={dmContentDetail?.mobileNumber || ""}
                      onChange={(e) => {
                        setContactDetailsValue("mobileNumber", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "mobileNumber",
                          PhoneNumberValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {contactValidation?.mobileNumber?.message
                        ? `Mobile Number ${contactValidation?.mobileNumber?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      E- Mail ID <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      placeholder="Enter Your E- Mail ID"
                      aria-invalid={Boolean(
                        contactValidation?.emailId?.status === false
                      )}
                      value={dmContentDetail?.emailId || ""}
                      onChange={(e) => {
                        setContactDetailsValue("emailId", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "emailId",
                          EmailValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {contactValidation?.emailId?.message
                        ? `Email id ${contactValidation?.emailId?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Subject <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Subject "
                      aria-invalid={Boolean(
                        contactValidation?.subject?.status === false
                      )}
                      value={dmContentDetail?.subject || ""}
                      onChange={(e) => {
                        setContactDetailsValue("subject", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "subject",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {contactValidation?.subject?.message
                        ? `Subject ${contactValidation?.subject?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                {/* <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label ">
                      Subject <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Enter Your  Subject "
                    />
                  </div>
                </div> */}

                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Service Type
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <select
                      className="form-control form-control-lg"
                      value={dmContentDetail?.servicesType || ""}
                      onChange={(e) => {
                        setContactDetailsValue("servicesType", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "servicesType",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    >
                      <option value="">---Select Service Type---</option>
                      <option value="Legal">Legal</option>
                      <option value="Audit">Audit</option>
                      <option value="Manpower">Manpower</option>
                      <option value="Auction">Auction</option>
                      <option value="Buy/Sell">Buy/Sell</option>
                    </select>
                    {/* <small className="text-danger">
                      {contactValidation?.servicesType?.message
                        ? `Service Type ${contactValidation?.servicesType?.message}`
                        : ""}
                    </small> */}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Questions <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows="6"
                      className="form-control"
                      placeholder="Enter Questions"
                      aria-invalid={Boolean(
                        contactValidation?.question?.status === false
                      )}
                      value={dmContentDetail?.question || ""}
                      onChange={(e) => {
                        setContactDetailsValue("question", e.target.value);
                      }}
                      onBlur={(e) => {
                        setContactValidationValue(
                          "question",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                      autoComplete="off"
                    />
                    <small className="text-danger">
                      {contactValidation?.question?.message
                        ? `Question ${contactValidation?.question?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  

                  <button
                    type="button"
                    className="btn login-btn w-100"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      <div className="number-counter py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <span className="material-symbols-rounded align-middle about-call filled-icon">
                        call
                      </span>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">Toll Free</h5>
                    <h5 className="mb-0 fs-4">1800-309-8445</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <span className="material-symbols-rounded align-middle about-call filled-icon">
                        mail
                      </span>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">Email ID</h5>
                    <h5 className="mb-0 fs-4">info@dalmaf.com</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <i className="fa-brands fa-whatsapp about-whatsapp-icon"></i>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">WhatsApp Us</h5>
                    <h5 className="mb-0 fs-4">+91 96000 38059</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ServicesAllpages />
      </div>
      <CustomModal
        open={form}
        onClickOutside={() => {
          setForm(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container ">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setForm(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container px-4 ">
                  <div className="row justify-content-center reg-page-scroll">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="mb-2">
                            <b>
                              Please enter your information below, <br />
                              Our staff will contact you as soon as possible.
                            </b>
                          </div>
                          <hr />
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">
                                Name <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Name"
                                aria-invalid={Boolean(
                                  dmContentValidation1?.name?.status === false
                                )}
                                value={dmContent1?.name || ""}
                                onChange={(e) => {
                                  setDmContentValue1("name", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setDmContentValidationValue1(
                                    "name",
                                    StringValidation(e.target.value)
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {dmContentValidation1?.name?.message
                                  ? `Name ${dmContentValidation1?.name?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">
                                Mobile No <span className="text-danger">*</span>
                              </label>
                              <input
                                type="tel"
                                maxLength={10}
                                className="form-control"
                                value={dmContent1?.mobileNumber || ""}
                                placeholder="Enter Your Mobile No"
                                aria-invalid={Boolean(
                                  dmContentValidation1?.mobileNumber
                                    ?.status === false
                                )}
                                onChange={(e) => {
                                  setDmContentValue1(
                                    "mobileNumber",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setDmContentValidationValue1(
                                    "mobileNumber",
                                    PhoneNumberValidation(e.target.value)
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {dmContentValidation1?.mobileNumber?.message
                                  ? `Mobile no ${dmContentValidation1?.mobileNumber?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">
                                E- Mail ID{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Enter Your E- Mail ID"
                                aria-invalid={Boolean(
                                  dmContentValidation1?.email?.status === false
                                )}
                                value={dmContent1?.email?.toLowerCase() || ""}
                                onChange={(e) => {
                                  setDmContentValue1(
                                    "email",
                                    e.target.value?.toLowerCase()
                                  );
                                }}
                                onBlur={(e) => {
                                  setDmContentValidationValue1(
                                    "emailId",
                                    EmailValidation(
                                      e.target.value
                                    )?.toLowerCase()
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {dmContentValidation1?.email?.message
                                  ? `Email ${dmContentValidation1?.email?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">Questions</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Questions "
                                aria-invalid={Boolean(
                                  dmContentValidation1?.question?.status ===
                                    false
                                )}
                                value={dmContent1?.question || ""}
                                onChange={(e) => {
                                  setDmContentValue1(
                                    "question",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">
                                Service Type{" "}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <select
                                className="form-control form-control-lg"
                                value={dmContent1?.servicesType || ""}
                                onChange={(e) => {
                                  setDmContentValue1(
                                    "servicesType",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setDmContentValidationValue1(
                                    "servicesType",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="">
                                  ---Select Service Type---
                                </option>
                                <option value="Legal">Legal</option>
                                <option value="Audit">Audit</option>
                                <option value="Manpower">Manpower</option>
                                <option value="Auction">Auction</option>
                                <option value="Buy/Sell">Buy/Sell</option>
                              </select>
                              {/* <small className="text-danger">
                                {dmContentValidation1?.servicesType?.message
                                  ? `Service Type ${dmContentValidation1?.servicesType?.message}`
                                  : ""}
                              </small> */}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Subject</label>
                              <textarea
                                rows="6"
                                className="form-control"
                                placeholder="Enter Subject"
                                aria-invalid={Boolean(
                                  dmContentValidation1?.subject?.status ===
                                    false
                                )}
                                value={dmContent1?.subject || ""}
                                onChange={(e) => {
                                  setDmContentValue1(
                                    "subject",
                                    e.target.value
                                  );
                                }}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <button
                              type="button"
                              className="btn login-btn w-100"
                              onClick={(e) => {
                                handleSubmitDmContent(e);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
