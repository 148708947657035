import React from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

export default function ServiceRequestManagement() {
  const [selectedTab, setSelectedTab] = React.useState("Assigned ");
  const AllTabs = ["Assigned ", "Un Assigned "];


  
  return (
    <div>
      <AdminNavbar />
      <div className="main">
        <div>
          <div className="d-flex justify-content-between">
            <h5 className="mb-0 fw-bold color-maroon">Service Request Management</h5>
          </div>
          <div className="my-4">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 px-0">
                  <div className="tab-section">
                    <ul className="ps-0">
                      {AllTabs.map((tab, index) => {
                        return (
                          <div
                            key={index}
                            className={
                              selectedTab === tab
                                ? "serviceTab active"
                                : "serviceTab inactive"
                            }
                            onClick={() => {
                              setSelectedTab(tab);
                            }}
                          >
                            <li>
                              <span className="align-middle">{tab}</span>
                            </li>
                          </div>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <table className="table">
              <thead className="bg-historytablehead">
                <tr>
                  <th scope="col" style={{ borderTopLeftRadius: "0" }}>
                    S. No
                  </th>
                  <th scope="col">Task Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Status</th>
                  <th scope="col">Created On </th>
                </tr>
              </thead>
              <tbody className="historytablebody">
                <tr>
                  <td>
                    <div className="">
                      <h6 className="fw-bold mb-0">01</h6>
                    </div>
                  </td>
                  <td scope="row">
                    <h6 className="mb-0 fw-bold">legal Case</h6>
                  </td>
                  <td>
                    <p className="mb-0">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                  </td>
                  <td>
                    <h6 className="fw-bold mb-0 color-red">New Request</h6>
                  </td>
                  <td>
                    <h6 className="fw-bold">21 March 2023</h6>
                    <p className="mb-0 small">At 8.00Pm</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
