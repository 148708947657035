import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import AdminNavbar from "../Navbar/AdminNavbar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PartnerDetails from "./PartnerDetails";

export default function Partner() {
  const [value, setValue] = React.useState('New');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="Auction Leads" style={{ marginLeft: "227px", marginTop: "-25px" }}>
          <Tab label="New" value="New" />
          <Tab label="Reivew" value="Review" />
          <Tab label="Pending" value="Pending" />
          <Tab label="Rejected" value="Rejected" />
          <Tab label="Approved" value="Converted" />
        </Tabs>
        <div className="main" style={{ marginTop: "50px" }}>
          <PartnerDetails status={value} />
        </div>
      </Box>
    </div>
  );
}
