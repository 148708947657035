import { ContentState, EditorState } from "draft-js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import { EditorState } from "draft-js";
// import draftToHtml from "draftjs-to-html";
// import { Editor } from "react-draft-wysiwyg";

import {
    DeleteProperty,
    UpdateWheeler,
    UploadDocument,
    WheelerSpecific
} from "../../../Api/Api";
import AdminNavbar from "../Navbar/AdminNavbar";
let htmlToDraft = null;
if (typeof window === "object") {
    htmlToDraft = require("html-to-draftjs").default;
}

const VehicleSpecificView = () => {
    const state = useSelector((state) => state);
    const navigate = useNavigate();
    const { action, id } = useParams();
    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        const month = (`0${d.getMonth() + 1}`).slice(-2);
        const day = (`0${d.getDate()}`).slice(-2);
        const year = d.getFullYear();
        return `${year}-${month}-${day}`;
      };
    let isDisabled = true;
    if(action == "Edit"){
        isDisabled = false;
    }

    useEffect(() => {
        window.scroll(0, 0);

        getAdminList();
        // getAmenityList();
        // getCommunityList();
    }, []);

    const [property, setProperty] = useState({
        floor_information: [{ floor_no: "", floor_image: "", floor_desc: "" }],
    });
    // const [amenities, setAmenities] = useState([]);
    // const [community, setCommunities] = useState([]);

    const getAdminList = async () => {
        const token = state?.auth?.auth?.token;
        const response = await WheelerSpecific( state?.auth?.auth?.token,id);
        if (response && response.statusCode === 200) {
            let dummy = response.data;
            let cmsDescription = EditorState.createEmpty();
            if (dummy.property_desc) {
                let blocksFromHtml = htmlToDraft(dummy.description);
                let { contentBlocks, entityMap } = blocksFromHtml;
                let contentState = ContentState.createFromBlockArray(
                    contentBlocks,
                    entityMap
                );
                cmsDescription = EditorState.createWithContent(contentState);
            }
            dummy.description = cmsDescription;
            // dummy.amenities = dummy.amenities.map((label) => ({
            //   label: label.Name,
            //   value: label._id,
            // }));
            // dummy.community = dummy.community.map((label) => ({
            //   label: label.name,
            //   value: label._id,
            // }));
            setProperty(dummy);
        } else {
            setProperty({});
            errorMsg(response.message);
        }
    };
    const [validation, setValidation] = useState({});
    const setData = (e, key) => {
        setProperty({ ...property, [key]: e });
        if (validation[key]) delete validation[key];
    };

    const setFloorData = (e, key, subKey, index) => {
        let data = property[key] ?? [];
        data[index] = { ...data[index], [subKey]: e };
        setProperty({
            ...property,
            [key]: data,
        });
    };

    const setAddArrayData = (key) => {
        let data = property[key];

        data.push("");
        setProperty({
            ...property,
            [key]: data,
        });
        if (validation[key]) delete validation[key];
    };

    const setRemoveArrayData = (key, index) => {
        let data = property[key];
        data.splice(index, 1);
        setProperty({
            ...property,
            [key]: data,
        });
        if (validation[key]) delete validation[key];
    };

    const setPropertyData = (e, key) => {
        setProperty({
            ...property,
            property_information: {
                ...property.property_information,
                [key]: e,
            },
        });
        if (validation[key]) delete validation[key];
    };
    const setArrayData = (e, key, index) => {
        let arr = [];
        arr = property[key] ?? [];

        if (index !== null) {
            arr.splice(index, 1);
        } else {
            arr.push(e);
        }

        setProperty({
            ...property,
            [key]: arr,
        });
    };
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );


    const number = /^[0-9]*$/;
    const CheckValidation = () => {
        let Status = true,
          validdateData = {};
        //console.log(validdateData);
    
        if (!property.reference_id) {
          validdateData.reference_id = "Reference Id is Required";
          Status = false;
        }
        if (!property.asset_type) {
          validdateData.asset_type = "Asset Type is Required";
          Status = false;
        }
    
        if (!property.brand) {
          validdateData.brand = "Brand is Required";
          Status = false;
        }
        if (!property.vehicle_number) {
          validdateData.vehicle_number = "Vehicle Number is Required";
          Status = false;
        }
        if (!property.city) {
          validdateData.city = "City is Required";
          Status = false;
        }
        if (!property.reserve_price) {
          validdateData.reserve_price = "Reserve  Price is Required";
          Status = false;
        }
    
    
        if (!property.emd) {
          validdateData.emd = "EMD price is Required";
          Status = false;
        }
        if (!property.auction_date) {
          validdateData.auction_date = "Auction Date is Required";
          Status = false;
        }
        if (!property.document) {
            property.document = [];
        }
        if (!property.institution) {
          validdateData.institution = "Institution is Required";
          Status = false;
        }
        if (!property.contact_details) {
          validdateData.contact_details = "Contact_details is Required";
          Status = false;
        }
        if (!property.source_id) {
            validdateData.source_id = "Source is Required";
            Status = false;
          }
        if (!property.sale_notice_url) {
            property.sale_notice_url = [];
        }
        if (!property?.description) {
          validdateData.description = "Description is Required";
          Status = false;
        }
    
        setValidation({ ...validation, ...validdateData });
        return Status;
      };
    // update property

    const updateVehicleAuction = async (e) => {
        console.log(e)
        e.preventDefault();
    
        if (CheckValidation()) {
    
          const payload = {
            _id:property._id,
            status:property.status,
            asset_type:property.asset_type,
            brand:property.brand,
            vehicle_number:property.vehicle_number,
            city:property.city,
            auction_date:property.auction_date,
            reserve_price:property.reserve_price,
            emd:property.emd,
            institution:property.institution,
            contact_details:property.contact_details,
            sale_notice_url:property.sale_notice_url,
            document:property.document,
            source_id:property.source_id
          };
          delete payload.id
          const token = state?.auth?.auth?.token;
    
          const response = await UpdateWheeler(payload,token);
          if (response && response.statusCode === 200) {
    
            successMsg(response.message);
            getTimeout();
          } else {
            errorMsg(response.message);
          }
        }
      };

    const UploadDocuments = async (e, key, subKey, ind) => {
        if (e.target.files.length <= 5) {
            for (let index = 0; index < e.target.files.length; index++) {
                const element = e.target.files[index];

                let formData = new FormData();
                let str = element.name;
                if (element.size > 1024 * 4096) {
                    errorMsg("File Should Be Lesser than 5 MB");
                    return false;
                }
                formData.append("file", element);
                formData.append(
                    "fileName",
                    moment().format("YYYYMMDDHHmmss") + "_" + str
                );
                formData.append("path", "Image/");
                const response = await UploadDocument(formData);
                if (response && response.statusCode === 200) {
                    successMsg(response.message);
                    if (subKey) {
                        setFloorData(response.data, key, subKey, ind);
                    } else {
                        setArrayData(response.data, key, null);
                    }
                } else {
                    errorMsg(response.message);
                }
            }
        } else {
            errorMsg("You chosen more than 5 files");
        }
    };

    // const getAmenityList = async () => {
    //   const response = await AmenityList();
    //   if (response && response.statusCode === 200) {
    //     let amenty = response.data.map((label) => ({
    //       label: label.Name,
    //       value: label._id,
    //     }));
    //     setAmenities(amenty);
    //   } else {
    //     errorMsg(response.message);
    //   }
    // };
    // const handleCreateLocation = async (key, string) => {
    //   const Payload = {
    //     Name: string,
    //   };
    //   const response = await CreateAmenities(Payload);
    //   if (response && response.statusCode === 200) {
    //     getAmenityList();
    //     setArrayData(
    //       { label: response.data.Name, value: response.data._id },
    //       key,
    //       null
    //     );

    //     successMsg(response.message);
    //   } else {
    //     errorMsg(response.message);
    //   }
    // };
    // const getCommunityList = async () => {
    //   const response = await CommunitiesList();
    //   if (response && response.statusCode === 200) {
    //     let community = response.data.map((label) => ({
    //       label: label.name,
    //       value: label._id,
    //     }));
    //     setCommunities(community);
    //   } else {
    //     errorMsg(response.message);
    //   }
    // };
    // const handleCreatecommunity = async (key, string) => {
    //   const Payload = {
    //     name: string,
    //   };
    //   const response = await CreateCommunities(Payload);
    //   if (response && response.statusCode === 200) {
    //     getCommunityList();
    //     setArrayData(
    //       { label: response.data.name, value: response.data._id },
    //       key,
    //       null
    //     );
    //     successMsg(response.message);
    //   } else {
    //     errorMsg(response.message);
    //   }
    // };
    const deleteProperty = async (e) => {
        e.preventDefault();
        const token = state?.auth?.auth?.token;
        const payload = {
            _id: id,
        };
        const response = await DeleteProperty(payload, token);
        if (response && response.statusCode === 200) {
            successMsg(response.message);

            getTimeout();
        } else {
            errorMsg(response.message);
        }
    };
    const getTimeout = () => {
        setTimeout(() => {
            setTimeout(navigate("/Vehicle"));
        }, 1000);
    };
    const successMsg = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };
    const errorMsg = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    return (
        <>
            <AdminNavbar />

            <div className="main">
                <div className="content">
                    <div className="">
                        <div className="">
                            <button
                                type="button"
                                className="btn login-btn ms-2 "
                                onClick={(e) => {
                                    navigate("/Vehicle");
                                }}
                            >
                                Back
                            </button>
                        </div>
                        <div className=" my-4">
                            <div className="bg-historytablehead rounded-3 py-3 px-3">
                                <h6 className="mb-0">Vehicle Details</h6>
                            </div>

                            <div className="container">
                                <div className="row justify-content-center py-5">
                                    <div className="col-lg-11">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="container">
                                                    <div className="row justify-content-center py-5">
                                                        <div className="col-lg-11">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <form>
                                                                        <div className="admincard">
                                                                            <div className="row">
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-4">
                                                                                        <label className="form-label">Reference Id</label>
                                                                                        <input 
                                                                                            disabled
                                                                                            type="text"
                                                                                            name="reference_id"
                                                                                            className="form-control"
                                                                                            style={{ border: validation.reference_id ? "1px solid red" : "" }}
                                                                                            placeholder="Enter Reference Id"
                                                                                            autoComplete="off"
                                                                                            defaultValue={property.reference_id}
                                                                                            onChange={(e) =>
                                                                                                setData(e.target.value, "reference_id")
                                                                                            }
                                                                                        />
                                                                                        {validation.reference_id && (
                                                                                            <p className="text-danger">
                                                                                                {validation.reference_id}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-4">
                                                                                        <label className="form-label">Asset Type</label>
                                                                                        <select
                                                                                            className="form-control"
                                                                                            style={{ border: validation.asset_type ? "1px solid red" : "" }}
                                                                                            value={property?.asset_type}
                                                                                            onChange={(e) =>
                                                                                                setData(e.target.value, "asset_type")
                                                                                            }
                                                                                            disabled={isDisabled}                                                                                        >
                                                                                            <option value="">Select Asset Type</option>
                                                                                            <option value="Car">Car</option>
                                                                                            <option value="Two Wheeler">Two Wheeler</option>
                                                                                            <option value="Others">
                                                                                                Others
                                                                                            </option>
                                                                                        </select>
                                                                                        {validation.asset_type && (
                                                                                            <p className="text-danger">
                                                                                                {validation.asset_type}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-4">
                                                                                        <label className="form-label">Brand</label>
                                                                                        <input 
                                                                                            disabled={isDisabled}
                                                                                            type="text"
                                                                                            name="Brand"
                                                                                            className="form-control"
                                                                                            style={{ border: validation.brand ? "1px solid red" : "" }}
                                                                                            placeholder="Enter Brand"
                                                                                            autoComplete="off"
                                                                                            defaultValue={property.brand}
                                                                                            onChange={(e) =>
                                                                                                setData(e.target.value, "brand")
                                                                                            }
                                                                                        />
                                                                                        {validation.brand && (
                                                                                            <p className="text-danger">
                                                                                                {validation.brand}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-4">
                                                                                        <label className="form-label">Vehicle Number</label>
                                                                                        <input
                                                                                            disabled={isDisabled}
                                                                                            type="text"
                                                                                            name="vehicle_number"
                                                                                            className="form-control"
                                                                                            style={{ border: validation.vehicle_number ? "1px solid red" : "" }}
                                                                                            placeholder="Enter Vehicle Number"
                                                                                            autoComplete="off"
                                                                                            defaultValue={property.vehicle_number}
                                                                                            onChange={(e) =>
                                                                                                setData(e.target.value, "vehicle_number")
                                                                                            }
                                                                                        />
                                                                                        {validation.vehicle_number && (
                                                                                            <p className="text-danger">
                                                                                                {validation.vehicle_number}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="row">

                                                                                <div className="col-lg-6">

                                                                                    <div className="mb-4">
                                                                                        <label className="form-label">City</label>
                                                                                        <input 
                                                                                            disabled={isDisabled}
                                                                                            type="text"
                                                                                            name="city"
                                                                                            className="form-control"
                                                                                            style={{ border: validation.city ? "1px solid red" : "" }}
                                                                                            placeholder="Enter City Name"
                                                                                            autoComplete="off"
                                                                                            defaultValue={property.city}
                                                                                            onChange={(e) =>
                                                                                                setData(e.target.value, "city")
                                                                                            }
                                                                                        />
                                                                                        {validation.city && (
                                                                                            <p className="text-danger">
                                                                                                {validation.city}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-4">
                                                                                        <label className="form-label">Auction Date</label>
                                                                                        <input 
                                                                                            disabled={isDisabled}
                                                                                            type="date"
                                                                                            name="auction_date"
                                                                                            className="form-control"
                                                                                            style={{ border: validation.auction_date ? "1px solid red" : "" }}
                                                                                            placeholder="Enter Auction Date"
                                                                                            autoComplete="off"
                                                                                            defaultValue={formatDate(property.auction_date)}
                                                                                            onChange={(e) =>
                                                                                                setData(e.target.value, "auction_date")
                                                                                            }
                                                                                        />
                                                                                        {validation.auction_date && (
                                                                                            <p className="text-danger">
                                                                                                {validation.auction_date}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </div>




                                                                            </div>
                                                                            <div className="row">

                                                                                <div className="col-lg-6">

                                                                                    <div className="mb-4">
                                                                                        <label className="form-label">Reserve Price</label>
                                                                                        <input
                                                                                            disabled={isDisabled}
                                                                                            type="number"
                                                                                            name="reserve_price"
                                                                                            className="form-control"
                                                                                            style={{ border: validation.reserve_price ? "1px solid red" : "" }}
                                                                                            placeholder="Enter Reserve Price"
                                                                                            autoComplete="off"
                                                                                            defaultValue={property.reserve_price}
                                                                                            onChange={(e) =>
                                                                                                setData(e.target.value, "reserve_price")
                                                                                            }
                                                                                        />
                                                                                        {validation.reserve_price && (
                                                                                            <p className="text-danger">
                                                                                                {validation.reserve_price}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">

                                                                                    <div className="mb-4">
                                                                                        <label className="form-label">EMD Amount</label>
                                                                                        <input 
                                                                                            disabled={isDisabled}
                                                                                            type="number"
                                                                                            name="emd"
                                                                                            className="form-control"
                                                                                            style={{ border: validation.emd ? "1px solid red" : "" }}
                                                                                            placeholder="Enter EMD Amount"
                                                                                            autoComplete="off"
                                                                                            defaultValue={property.emd}
                                                                                            onChange={(e) =>
                                                                                                setData(e.target.value, "emd")
                                                                                            }
                                                                                        />
                                                                                        {validation.emd && (
                                                                                            <p className="text-danger">
                                                                                                {validation.emd}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            <div className="row">

                                                                                <div className="col-lg-6">

                                                                                    <div className="mb-4">
                                                                                        <label className="form-label">Institution</label>
                                                                                        <input                                                                                             
                                                                                            disabled={isDisabled}
                                                                                            type="text"
                                                                                            name="institution"
                                                                                            className="form-control"
                                                                                            style={{ border: validation.institution ? "1px solid red" : "" }}
                                                                                            placeholder="Enter Institution Name"
                                                                                            autoComplete="off"
                                                                                            defaultValue={property.institution}
                                                                                            onChange={(e) =>
                                                                                                setData(e.target.value, "institution")
                                                                                            }
                                                                                        />
                                                                                        {validation.institution && (
                                                                                            <p className="text-danger">
                                                                                                {validation.institution}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="mb-4">
                                                                                        <label className="form-label">Contact Details</label>
                                                                                        <input                                                                                             
                                                                                            disabled={isDisabled}
                                                                                            type="text"
                                                                                            name="contact_details"
                                                                                            className="form-control"
                                                                                            style={{ border: validation.contact_details ? "1px solid red" : "" }}
                                                                                            placeholder="Enter Contact Details"
                                                                                            autoComplete="off"
                                                                                            defaultValue={property.contact_details}
                                                                                            onChange={(e) =>
                                                                                                setData(e.target.value, "contact_details")
                                                                                            }
                                                                                        />
                                                                                        {validation.contact_details && (
                                                                                            <p className="text-danger">
                                                                                                {validation.contact_details}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </div>




                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                    <div className="mb-4">
                                                                                        <label className="form-label">Source</label>
                                                                                        <input                                                                                             
                                                                                            disabled={isDisabled}
                                                                                            type="text"
                                                                                            name="source_id"
                                                                                            className="form-control"
                                                                                            style={{ border: validation.source_id ? "1px solid red" : "" }}
                                                                                            placeholder="Enter Source"
                                                                                            autoComplete="off"
                                                                                            defaultValue={property.source_id}
                                                                                            onChange={(e) =>
                                                                                                setData(e.target.value, "source_id")
                                                                                            }
                                                                                        />
                                                                                        {validation.source_id && (
                                                                                            <p className="text-danger">
                                                                                                {validation.source_id}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            <div className="row my-4">
                                                                                <div className="col-lg-12">
                                                                                    <label className="form-label">
                                                                                        Description
                                                                                    </label>
                                                                                    <div className="form-control">
                                                                                        <Editor
                                                                                            wrapperClassName="wrapper-class"
                                                                                            editorClassName="editor-class"
                                                                                            toolbarClassName="toolbar-class"
                                                                                            editorState={editorState}
                                                                                            // onEditorStateChange={(e) =>
                                                                                            //     handleEditorChange(e)
                                                                                            // }
                                                                                        />
                                                                                        {validation.description && (
                                                                                            <p className="text-danger">
                                                                                                {validation.description}
                                                                                            </p>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row my-4">
                              <div className="col-lg-6">
                                <label className="form-label">
                                  Sale Notice URL
                                </label>
                                {
                                  property?.sale_notice_url?.map((data) => {
                                    return (
                                      <img
                                        src={data}
                                        alt=""
                                        style={{ width: "200px", height: "200px" }}
                                        className="form-control"
                                      />
                                    )
                                  })
                                }

                              </div>
                              <div className="col-lg-6">
                                <label className="form-label">
                                  Vehicle Documents
                                </label>
                                {
                                  property?.document?.map((data) => {
                                    return (
                                      <img
                                        src={data}
                                        alt=""
                                        style={{ width: "200px", height: "200px" }}
                                        className="form-control"
                                      />
                                    )
                                  })
                                }

                              </div>
                            </div>
                            <div style={{ visibility: isDisabled ? 'hidden' : 'visible'}}>

                            <div className="textcenter mt-4">
                            <button
                              className="btn login-btn ms-2 "
                              onClick={(e) => updateVehicleAuction(e)}
                            >
                              Update Vehicle Details
                            </button>
                          </div>

                                                                        </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    );
};

export default VehicleSpecificView;
