import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../../Footer/Footer';
import UserNavigation from '../NavBar/Navigaton';
import ScheduleMeetMain from './ScheduleMeetMain';

export default function ScheduleMeet() {


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Schedule Meet | DALMaf Private Limited</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="description" content="At Dalmaf, We understand the challenges and complexities that businesses experience in today's dynamic and highly competitive marketplace. As a result, we are here to deliver a wide range of Expert Assistance Tailored to your Success. We Provide Comprehensive Services in the areas of Legal, Audit, Human Resources, Bank Auctions, Buying/Selling, and Marketing Assistance resulting from our extensive experience and dedication to excellence." />
                <meta name="keywords" content="At Dalmaf, We understand the challenges and complexities that businesses experience in today's dynamic and highly competitive marketplace. As a result, we are here to deliver a wide range of Expert Assistance Tailored to your Success. We Provide Comprehensive Services in the areas of Legal, Audit, Human Resources, Bank Auctions, Buying/Selling, and Marketing Assistance resulting from our extensive experience and dedication to excellence." />
                <meta name="author" content="DALMaf" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <UserNavigation />
            <ScheduleMeetMain/>
            <Footer />

        </>
    );
}
