import React, { useEffect, useState } from 'react'
import { GetAllEmployeeRole, GetAllSubAdminAndAdmin, leaveSpecific } from '../../../Api/Api';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Profile from "../../../Assets/Images/navbar-img/f1.webp";
import moment from 'moment';
import AdminNavbar from '../Navbar/AdminNavbar';
import Spinner from '../../../Spinner/Spinner';

const LeaveEdit = () => {
    const {id} = useParams()
    const [specificleaveList, setSpecificleaveList] = useState({});
useEffect(() => {

    GetAllSubadmins();
    getListEmployeeRole()
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    fetchDataProfile()
},  [id])
  
  const [loading, setLoading] = useState(true);
  const [roleList, setRoleList] = useState([]);

  const state = useSelector((state) => state);
  const [allSubadmin, setAllSubadmin] = useState([]);
  const GetAllSubadmins = async () => {
    setLoading(true);
    const role = "subadmin";
    const token = state?.auth?.auth?.token;
    let data = await GetAllSubAdminAndAdmin(token, role, "active");
    if (data && data.statusCode === 200) {
      setAllSubadmin(data.data);
      setLoading(false);
    } else {
      setAllSubadmin([]);
      setLoading(false);
      console.error("Error while getting all subadmins");
    }
  };  
const fetchDataProfile = async () => {
    const token = state?.auth?.auth?.token;
    const response = await leaveSpecific(token, id);
    if (response && response?.statusCode === 200) {
      setSpecificleaveList(response?.data);
    } else {
      setSpecificleaveList({});
      //console.log("error while getting user profile info");
    }
  };
  const getListEmployeeRole = async () => {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    const response = await GetAllEmployeeRole(token, "active");
    if (response.statusCode === 200) {
      setRoleList(response.data);
      setLoading(false);
    } else {
      setRoleList([]);
      setLoading(false);
    }
  };
  return (
<div>


<AdminNavbar />

<div className="main">
  {loading ? (
    <Spinner />
  ) : (
<>
<div className="row d-flex align-items-center justify-content-center  mt-0">
    <div className="container ">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className=" mt-4 mb-3 px-3">
            <div className="row justify-content-center mt-2">
              <div className="col-lg-11">
                <div className="mb-4">
                  <h4 className="fw-bold mt-2">
                    Application Details
                  </h4>
                </div>
                <div className="mb-3">
                  <div className="d-flex align-item-center ">
                    <img
                      className=""
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50px",
                      }}
                      src={
                        specificleaveList?.userimage
                          ? specificleaveList?.userimage
                          : Profile
                      }
                      alt=""
                    />
                    <div className="ms-3">
                      <h6>{specificleaveList?.username}</h6>
                      <h6 className="fw-bold color-maroon">
                        {roleList
                          ?.filter(
                            (e) =>
                              e._id ===
                              specificleaveList?.userDesignation
                          )
                          .map((i) => (
                            <div>{i?.title}</div>
                          ))}
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="">
                  <table className="table table-hover">
                    <tbody>
                      <tr>
                        <th scope="row">Leave type</th>
                        <td>
                          <p className=" greenbox-bg mb-0">
                            {specificleaveList?.type}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Date</th>
                        <td>
                          {moment(
                            specificleaveList?.startDate
                          ).format("DD/MM/YYYY")}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Duration</th>
                        <td>
                          {moment(
                            specificleaveList?.endDate
                          ).format("DD/MM/YYYY")}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Reason</th>
                        <td> {specificleaveList?.content}</td>
                      </tr>
                      <tr>
                        <th scope="row">Status</th>
                        <td>
                          <th
                            scope="row"
                            style={{ textTransform: "capitalize" }}
                          >
                            {specificleaveList?.status}
                          </th>
                          {/* <select
                            className="form-control form-control-lg"
                            value={specificleaveList.status}
                            //  onChange={(e)=>{
                            //   setSpecificValue("status",e.target.value)
                            //  }}
                          >
                            <option value="approved">
                              Approved
                            </option>
                            <option value="pending">Pending</option>
                            <option value="rejected">
                              Rejected
                            </option>
                          </select> */}
                        </td>
                      </tr>
                      {specificleaveList?.status === "approved" ? (
                        <tr>
                          <th scope="row">Approved by</th>
                          <td>
                            <select
                              className="form-control form-control-lg"
                              value={specificleaveList?.approvedBy}
                              disabled
                            >
                              {allSubadmin?.map((e) => (
                                <option value={e?._id}>
                                  {e?.name}
                                </option>
                              ))}
                            </select>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                </div>
                {/* <div className="mb-3 mt-3">
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={(e) => {
                        handleSubmitUpdate(
                          e,
                          specificleaveList?.status,
                          specificleaveList._id
                        );
                        setLeavedetailsModal(false);
                      }}
                      className="btn py-1 me-3 px-2 btn-light fw-bold  d-flex justify-content-end align-items-center"
                    >
                      <span>Update</span>{" "}
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>
    )}
    </div>
</div>
    
    
   
  )
}

export default LeaveEdit