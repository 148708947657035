import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styles from './css/CreateLeadComponent.module.css';  // Adjust the path as necessary
import { useSelector } from 'react-redux';
import { createLabelAPI, fetchLabelAPI } from '../../../../Api/v1/Util/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { showErrorMsg, showSuccessMsg } from '../../../../Store/util';
import { createNewLeadAPI } from '../../../../Api/v1/FreelancerPartner/leads';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactQuill from 'react-quill';

export default function CreateLeadComponent({ userTypeProps, lableProps, isClientProps, routeProps }) {
    const navigate = useNavigate();
    const state = useSelector((state) => state);
    const initialLeadState = {
        type: 'Organisation',
        company_name: '',
        status: 'New',
        email_id: '',
        source: '',
        custom_source: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        lead_type:'',
        phone_number: '',
        vat_number: '',
        gst_number: '',
        currency: 'INR',
        currency_symbol: '₹',
        // labels: 'Test',
        notes:''
    };

    const [lead, setLead] = useState(initialLeadState);

    const [errors, setErrors] = useState({});
    const [types] = useState([
        { value: 'Organisation', label: 'Organisation' },
        { value: 'Person', label: 'Person' }
    ]);

    const [lead_type] = useState([
        { value: 'Cold', label: 'Cold' },
        { value: 'Hot', label: 'Hot' }
    ]);
    const [currencies] = useState([
        { value: 'INR', label: 'INR' },
        { value: 'USD', label: 'USD' },
        { value: 'EUR', label: 'EUR' }
    ]);
    const [symbols] = useState([
        { value: '₹', label: '₹' },
        { value: '$', label: '$' },
        { value: '€', label: '€' }
    ]);
    const [allLabels, setAllLabels] = useState([]);
    const [sourceOptions] = useState([
        { value: 'Walk In', label: 'Walk In' },
        { value: 'Facebook Ads', label: 'Facebook Ads' },
        { value: 'Google Ads', label: 'Google Ads' },
        { value: 'others', label: 'Others' }
    ]);

    useEffect(() => {
        // Fetch labels on component mount
        handleFetchLabels();
    }, []);

    const handleInputChange = (e, field) => {
        setLead({ ...lead, [field]: e.target.value });
    };

    const handleSelectChange = (selectedOption, field) => {
        if (field === 'source' && selectedOption.value !== 'others') {
            setLead({ ...lead, [field]: selectedOption.value, custom_source: '' });
        } else {
            setLead({ ...lead, [field]: selectedOption.value });
        }
    };

    const handleCreateOption = async (inputValue) => {
        try {
            const response = await createLabelAPI(state.auth.auth.token, { title: inputValue, label_type: 'leads' });
            // Add the newly created label to the list of labels
            // setAllLabels([...allLabels, { value: response.data.label._id, label: inputValue }]);
            // setLead({
            //     ...lead,
            //     labels: [...lead.labels, { value: response.data.label._id, label: inputValue }]
            // });
        } catch (error) {
            console.error('Error creating label:', error);
        }
    };

    const handleNotesChange = (value) => {
        setLead({ ...lead, notes: value });
    };

    const handleFetchLabels = async () => {
        try {
            const response = await fetchLabelAPI(state.auth.auth.token, 'leads');
            setAllLabels(response.data.label.map(label => ({ value: label._id, label: label.title })));
        } catch (error) {
            console.error('Error fetching labels:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validate fields
        const newErrors = {};
        if (!lead.company_name) newErrors.company_name = 'Company Name is required';
        if (!lead.phone_number) newErrors.phone_number = 'Phone Number is required';
        if (!lead.email_id) newErrors.email_id = 'Email ID is required';
        if (!lead.email_id.includes('@')) newErrors.email_id = 'Email ID is invalid';
        if (!lead.source) newErrors.source = 'Source is required';
        if (!lead.lead_type) newErrors.lead_type = 'Lead type is required';

        
        // lead.labels = lead.labels.value;
        setErrors(newErrors);
        console.log("newErrors",newErrors)
        if (Object.keys(newErrors).length > 0) return;
        

        // Submit form data
        const response = await createNewLeadAPI(state.auth.auth.token, lead);
        if (response && response.statusCode === 200) {
            showSuccessMsg(response.message);
            setLead(initialLeadState);
            navigate(`/${userTypeProps}/lead/my-lead-list`);
        } else {
            showErrorMsg(
                response.message || response.error.response.data.message
            );
        }
    };

    return (
        <>
            <ToastContainer />
            <div className={styles.leadForm}>
                <div className='row'>
                    <div className='col-md-6'>
                        <h2>Create New Lead Form</h2>
                    </div>
                    <div className='col-md-6'>
                        <button className='btn btn-primary' style={{ float: 'right' }} onClick={() => navigate(`/${userTypeProps}/lead/my-lead-list`)}>Go Back</button>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="row">

                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <label htmlFor="type" className={`${styles.placeholder} ${lead.type ? styles.filled : ''}`}>
                                    Type
                                </label>
                                <Select
                                    id="type"
                                    value={types.find(type => type.value === lead.type)}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, 'type')}
                                    options={types}
                                    placeholder="Select Type"
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <label htmlFor="type" className={`${styles.placeholder} ${lead_type.type ? styles.filled : ''}`}>
                                    Lead Type
                                </label>
                                <Select
                                    id="lead_type"
                                    value={lead_type.find(type => type.value === lead.lead_type)}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, 'lead_type')}
                                    options={lead_type}
                                    placeholder="Select Lead Type"
                                />
                            </div>
                            {errors.lead_type && <span className={styles.error}>{errors.lead_type}</span>}
                        </div>
                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <div className={styles.inputContainer}>
                                    <input
                                        type="text"
                                        id="company_name"
                                        value={lead.company_name}
                                        onChange={(e) => handleInputChange(e, 'company_name')}
                                        className={`${styles.input} ${errors.company_name ? styles.errorInput : ''}`}
                                        placeholder=" "
                                    />
                                    <label htmlFor="company_name" className={`${styles.placeholder} ${lead.company_name ? styles.filled : ''}`}>
                                        Name / Company Name
                                    </label>
                                </div>
                                {errors.company_name && <span className={styles.error}>{errors.company_name}</span>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <div className={styles.inputContainer}>
                                    <input
                                        type="text"
                                        id="email_id"
                                        value={lead.email_id}
                                        onChange={(e) => handleInputChange(e, 'email_id')}
                                        className={`${styles.input} ${errors.email_id ? styles.errorInput : ''}`}
                                        placeholder=" "
                                    />
                                    <label htmlFor="email_id" className={`${styles.placeholder} ${lead.email_id ? styles.filled : ''}`}>
                                        Email ID
                                    </label>
                                </div>
                                {errors.email_id && <span className={styles.error}>{errors.email_id}</span>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <div className={styles.inputContainer}>
                                    <input
                                        type="text"
                                        id="vat_number"
                                        value={lead.vat_number}
                                        onChange={(e) => handleInputChange(e, 'vat_number')}
                                        className={`${styles.input} ${errors.vat_number ? styles.errorInput : ''}`}
                                        placeholder=" "
                                    />
                                    <label htmlFor="vat_number" className={`${styles.placeholder} ${lead.vat_number ? styles.filled : ''}`}>
                                        Vat Number
                                    </label>
                                </div>
                                {errors.vat_number && <span className={styles.error}>{errors.vat_number}</span>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <div className={styles.inputContainer}>
                                    <input
                                        type="text"
                                        id="gst_number"
                                        value={lead.gst_number}
                                        onChange={(e) => handleInputChange(e, 'gst_number')}
                                        className={`${styles.input} ${errors.gst_number ? styles.errorInput : ''}`}
                                        placeholder=" "
                                    />
                                    <label htmlFor="gst_number" className={`${styles.placeholder} ${lead.gst_number ? styles.filled : ''}`}>
                                        GST Number
                                    </label>
                                </div>
                                {errors.gst_number && <span className={styles.error}>{errors.gst_number}</span>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <label htmlFor="currency" className={`${styles.placeholder} ${lead.currency ? styles.filled : ''}`}>
                                    Currency
                                </label>
                                <Select
                                    id="currency"
                                    value={currencies.find(currency => currency.value === lead.currency)}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, 'currency')}
                                    options={currencies}
                                    placeholder="Select Currency"
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <label htmlFor="currency_symbol" className={`${styles.placeholder} ${lead.currency_symbol ? styles.filled : ''}`}>
                                    Currency Symbol
                                </label>
                                <Select
                                    id="currency_symbol"
                                    value={symbols.find(symbol => symbol.value === lead.currency_symbol)}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, 'currency_symbol')}
                                    options={symbols}
                                    placeholder="Select Currency Symbol"
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <label htmlFor="source" className={`${styles.placeholder} ${lead.source ? styles.filled : ''}`}>
                                    Source
                                </label>
                                <Select
                                    id="source"
                                    value={sourceOptions.find(source => source.value === lead.source)}
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, 'source')}
                                    options={sourceOptions}
                                    placeholder="Select Source"
                                />
                            </div>
                            {errors.source && <span className={styles.error}>{errors.source}</span>}
                        </div>
                        {lead.source === 'others' && (
                            <div className="col-md-4">
                                <div className={styles.formGroup}>
                                    <div className={styles.inputContainer}>
                                        <input
                                            type="text"
                                            id="custom_source"
                                            value={lead.custom_source}
                                            onChange={(e) => handleInputChange(e, 'custom_source')}
                                            className={`${styles.input} ${errors.custom_source ? styles.errorInput : ''}`}
                                            placeholder=" "
                                        />
                                        <label htmlFor="custom_source" className={`${styles.placeholder} ${lead.custom_source ? styles.filled : ''}`}>
                                            Custom Source
                                        </label>
                                    </div>
                                    {errors.custom_source && <span className={styles.error}>{errors.custom_source}</span>}
                                </div>
                            </div>
                        )}
                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <div className={styles.inputContainer}>
                                    <input
                                        type="text"
                                        id="address"
                                        value={lead.address}
                                        onChange={(e) => handleInputChange(e, 'address')}
                                        className={`${styles.input} ${errors.address ? styles.errorInput : ''}`}
                                        placeholder=" "
                                    />
                                    <label htmlFor="address" className={`${styles.placeholder} ${lead.address ? styles.filled : ''}`}>
                                        Address
                                    </label>
                                </div>
                                {errors.address && <span className={styles.error}>{errors.address}</span>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <div className={styles.inputContainer}>
                                    <input
                                        type="text"
                                        id="city"
                                        value={lead.city}
                                        onChange={(e) => handleInputChange(e, 'city')}
                                        className={`${styles.input} ${errors.city ? styles.errorInput : ''}`}
                                        placeholder=" "
                                    />
                                    <label htmlFor="city" className={`${styles.placeholder} ${lead.city ? styles.filled : ''}`}>
                                        City
                                    </label>
                                </div>
                                {errors.city && <span className={styles.error}>{errors.city}</span>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <div className={styles.inputContainer}>
                                    <input
                                        type="text"
                                        id="state"
                                        value={lead.state}
                                        onChange={(e) => handleInputChange(e, 'state')}
                                        className={`${styles.input} ${errors.state ? styles.errorInput : ''}`}
                                        placeholder=" "
                                    />
                                    <label htmlFor="state" className={`${styles.placeholder} ${lead.state ? styles.filled : ''}`}>
                                        State
                                    </label>
                                </div>
                                {errors.state && <span className={styles.error}>{errors.state}</span>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <div className={styles.inputContainer}>
                                    <input
                                        type="text"
                                        id="zip"
                                        value={lead.zip}
                                        onChange={(e) => handleInputChange(e, 'zip')}
                                        className={`${styles.input} ${errors.zip ? styles.errorInput : ''}`}
                                        placeholder=" "
                                    />
                                    <label htmlFor="zip" className={`${styles.placeholder} ${lead.zip ? styles.filled : ''}`}>
                                        Zip
                                    </label>
                                </div>
                                {errors.zip && <span className={styles.error}>{errors.zip}</span>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <div className={styles.inputContainer}>
                                    <input
                                        type="text"
                                        id="country"
                                        value={lead.country}
                                        onChange={(e) => handleInputChange(e, 'country')}
                                        className={`${styles.input} ${errors.country ? styles.errorInput : ''}`}
                                        placeholder=" "
                                    />
                                    <label htmlFor="country" className={`${styles.placeholder} ${lead.country ? styles.filled : ''}`}>
                                        Country
                                    </label>
                                </div>
                                {errors.country && <span className={styles.error}>{errors.country}</span>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className={styles.formGroup}>
                                <div className={styles.inputContainer}>
                                    <input
                                        type="text"
                                        id="phone_number"
                                        value={lead.phone_number}
                                        onChange={(e) => handleInputChange(e, 'phone_number')}
                                        className={`${styles.input} ${errors.phone_number ? styles.errorInput : ''}`}
                                        placeholder=" "
                                    />
                                    <label htmlFor="phone_number" className={`${styles.placeholder} ${lead.phone_number ? styles.filled : ''}`}>
                                        Phone Number
                                    </label>
                                </div>
                                {errors.phone_number && <span className={styles.error}>{errors.phone_number}</span>}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className={styles.formGroup}>
                                <label htmlFor="notes" className={styles.placeholder}>Notes</label>
                                <ReactQuill
                                    style={{height:'100px'}}
                                    theme="snow"
                                    value={lead.notes}
                                    onChange={handleNotesChange}
                                    className={styles.editor}
                                    placeholder="Enter notes here..."
                                />
                            </div>
                        </div>
                        {/* <div className="col-md-12">
                            <div className={styles.formGroup}>
                                <label htmlFor="labels" className={`${styles.placeholder} ${lead.labels ? styles.filled : ''}`}>
                                    Labels
                                </label>
                                <CreatableSelect
                                    id="labels"
                                    isMulti
                                    value={lead.labels}
                                    onChange={(selectedOptions) => setLead({ ...lead, labels: selectedOptions })}
                                    options={allLabels}
                                    placeholder="Select or Create Labels"
                                    onCreateOption={handleCreateOption}
                                />
                            </div>
                        </div> */}
                        <div className="col-md-12" style={{marginTop:'50px'}}>
                            <button type="submit" className='btn btn-primary'>Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
