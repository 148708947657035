import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWithQueryAPI } from '../../../../Api/v1/Util/Api';
import { lead_overall_dashboard } from '../../../../Api/APIUrl';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';

export default function LeadClientDashboard({ typeProps, lableProps, isClientProps, routeProps, statusFilterProps }) { 
    const state = useSelector((state) => state);

    const [dashboardData, setDashboardData] = useState(null);

    const fetchLeadDashboard = async () => {
        try {
            const response = await fetchWithQueryAPI(state.auth.auth.token, lead_overall_dashboard);
            if (response && response.statusCode === 200) {
                setDashboardData(response.data);
            } else {
                toast.error("Failed to fetch " + lableProps);
            }
        } catch (error) {
            toast.error("An error occurred while fetching " + lableProps);
        } 
    };

    const generateChartData = (data) => {
        return {
            labels: ['Total Leads', 'New Leads', 'Qualified Leads', 'Discussion Leads', 'Negotiation Leads', 'Converted Leads', 'Not Converted Leads'],
            datasets: [
                {
                    label: 'Cold',
                    data: [
                        data.total_leads.cold,
                        data.total_new_leads.cold,
                        data.total_qualified_leads.cold,
                        data.total_discussion_leads.cold,
                        data.total_negotiation_leads.cold,
                        data.total_converted_leads.cold,
                        data.total_not_converted_leads.cold
                    ],
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Hot',
                    data: [
                        data.total_leads.hot,
                        data.total_new_leads.hot,
                        data.total_qualified_leads.hot,
                        data.total_discussion_leads.hot,
                        data.total_negotiation_leads.hot,
                        data.total_converted_leads.hot,
                        data.total_not_converted_leads.hot
                    ],
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                }
            ],
        };
    };

    useEffect(() => {
        fetchLeadDashboard();
    },);

    return (
        <>
            <ToastContainer />
            <div className='row'>
                <div className='col-md-10 col-lg-10 col-sm-10'>
                    {dashboardData ? (
                        <Bar
                            data={generateChartData(dashboardData)}
                            options={{
                                responsive: true,
                                plugins: {
                                    legend: {
                                        position: 'top',
                                    },
                                    title: {
                                        display: true,
                                        text: 'Lead Dashboard',
                                    },
                                },
                            }}
                        />
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
                
            </div>
        </>
    );
}
