import React from "react";
import { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

import CustomModal from "../../../Components/CustomModal";
import { NonEmptyValidation, StringValidation } from "../../../Store/validate";
import {
  CreateEmployeeRole,
  GetAllEmployeeRoleSpecific,
  UpdateRole,
} from "../../../Api/Api";
import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";

import { GetAllAdminRole } from "../../../Api/Api";
import MUIDataTable from "mui-datatables";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";

let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

export default function AdminRoleManagement() {
  const [createAdminRole, setCreateAdminRole] = useState(false);

  const [updateAdminRole, setUpdateAdminRole] = useState(false);
  const [loading, setLoading] = useState(true);
  const [listAdmin, setListAdmin] = useState([]);

  const [selectedTab, setSelectedTab] = React.useState("Active Role");
  const AllTabs = ["Active Role", "Inactive Role"];
  const MAX_LENGTH = 30;
  useEffect(() => {
    window.scroll(0, 0);

    getListAdmin("active");
  }, []);

  //  const handleSubmitDelete = async (e, id, value) => {
  //    e.preventDefault();

  //    const token = state?.auth?.auth?.token;
  //    const response = await Deleterole(id, token);
  //    if (response && response.statusCode === 200) {
  //      showToastSuccessMsg(response.message);

  //      //  window.location.reload()
  //    } else {
  //      showToastErrorMsg(
  //        response.message || response.error.response.data.message
  //      );
  //      //setfailureModal(true);
  //    }
  //  };
  const getListAdmin = async (status) => {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    const response = await GetAllAdminRole(token, status);
    if (response.statusCode === 200) {
      let temp = [];
      response.data.map((data, i) => {
        let dummy = {
          _id: data?._id,
          title: data?.title,
          description: data?.description,
          status: data?.status,
          admin_read: data?.admin_read,
          subadmin_read: data?.subadmin_read,
        };
        temp.push(dummy);
        return null;
      });
      setListAdmin(temp);
      // setListAdmin(response.data);
      setLoading(false);
    } else {
      setListAdmin([]);
      setLoading(false);
    }
  };

  const [adminRole, setAdminRole] = useState({});
  const [adminRole1, setAdminRole1] = useState({});

  const state = useSelector((state) => state);

  const getListAdminSpecific = async (id) => {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    const response = await GetAllEmployeeRoleSpecific(token, id);
    if (response.statusCode === 200) {
      let dummy = response.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.description) {
        let blocksFromHtml = htmlToDraft(dummy.description);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.description = cmsDescription;
      setAdminRole1(dummy);
      setLoading(false);
    } else {
      setAdminRole1({});
      setLoading(false);
    }
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const [AdminRoleValidate, setAdminRoleValidate] = useState({});
  const setAdminRoleValue = (key, value) => {
    setAdminRole({ ...adminRole, [key]: value });
    if (AdminRoleValidate[key]) delete AdminRoleValidate[key];
  };

  const setAdminRoleValidateValue = (key, value) => {
    setAdminRoleValidate({ ...AdminRoleValidate, [key]: value });
  };

  const [adminRoleValidate1, setAdminRoleValidate1] = useState({});
  const setAdminRoleValue1 = (parentKey, childKey, value, type) => {
    if (type === "object") {
      setAdminRole1({
        ...adminRole1,
        ...{ [parentKey]: { ...adminRole1[parentKey], [childKey]: value } },
      });
      if (adminRoleValidate1[parentKey])
        delete adminRoleValidate1[parentKey];
      if (adminRoleValidate1[childKey])
        delete adminRoleValidate1[childKey];
    } else {
      setAdminRole1({
        ...adminRole1,
        [childKey]: value,
      });
      if (adminRoleValidate1[parentKey])
        delete adminRoleValidate1[parentKey];
      if (adminRoleValidate1[childKey])
        delete adminRoleValidate1[childKey];
    }
  };

  const setAdminRoleValidateValue1 = (parentKey, childKey, value, type) => {
    if (type === "object") {
      setAdminRoleValidate1({
        ...adminRoleValidate1,
        ...{
          [parentKey]: {
            ...adminRoleValidate1[parentKey],
            [childKey]: value,
          },
        },
      });
    } else {
      setAdminRoleValidate1({
        ...adminRoleValidate1,
        [childKey]: value,
      });
    }
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setAdminRoleValue("description", html);
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.description = NonEmptyValidation(adminRole?.description);
    validate.title = NonEmptyValidation(adminRole?.title);
    validate.pageAccess = NonEmptyValidation(adminRole?.pageAccess);
    if(adminRole?.Dashboard ||adminRole?.PurchasedServices||adminRole?.MyTask||adminRole?.ServiceRequest||adminRole?.ServicePackage||adminRole?.UserManagement||adminRole?.AdminManagement||adminRole?.AdminRole||adminRole?.EmployeeRole||
      adminRole?.Property||adminRole?.History||adminRole?.Package||adminRole?.Lead
      ||adminRole?.EmployeeManagement||adminRole?.TaskList||adminRole?.LogManagement||
      adminRole?.ProfileManagement||adminRole?.JobManagement||adminRole?.cms||
      adminRole?.leaveapprove||adminRole?.NewsLetter||adminRole?.ContactUsers||adminRole?.GoldAuction||
      adminRole?.TowWheeler||adminRole?.FourWheeler||adminRole?.PropertyRequest||
      adminRole?.PaymentService||adminRole?.PaymentPackage){
        validate.pageAccess.status=true
      }
      //console.log(validate)
    setAdminRoleValidate(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        description: adminRole.description,
        status: "active",
        role: "admin",
        title: adminRole?.title,
        pageAccess: {
          Dashboard: adminRole?.Dashboard ===false? false: true,
          PurchasedServices: adminRole?.PurchasedServices
            ? adminRole?.PurchasedServices
            : false,
          MyTask: adminRole?.MyTask ===false? false: true,
          ServiceRequest: adminRole?.ServiceRequest
            ? adminRole?.ServiceRequest
            : false,
          ServicePackage: adminRole?.ServicePackage
            ? adminRole?.ServicePackage
            : false,
          UserManagement: adminRole?.UserManagement
            ? adminRole?.UserManagement
            : false,
          AdminManagement: adminRole?.AdminManagement
            ? adminRole?.AdminManagement
            : false,
          AdminRole: adminRole?.AdminRole ? adminRole?.AdminRole : false,
          EmployeeRole: adminRole?.EmployeeRole
            ? adminRole?.EmployeeRole
            : false,
          // Property: adminRole?.Property ? adminRole?.Property : false,
          History: adminRole?.History ? adminRole?.History : false,
          Package: adminRole?.Package ? adminRole?.Package : false,
          Lead: adminRole?.Lead ? adminRole?.Lead : false,
          EmployeeManagement: adminRole?.EmployeeManagement
            ? adminRole?.EmployeeManagement
            : false,
          TaskList: adminRole?.TaskList ? adminRole?.TaskList : false,
          LogManagement: adminRole?.LogManagement
            ? adminRole?.LogManagement
            : false,
          ProfileManagement: adminRole?.ProfileManagement
            ? adminRole?.ProfileManagement
            : false,
          JobManagement: adminRole?.JobManagement
            ? adminRole?.JobManagement
            : false,
          cms: adminRole?.cms ? adminRole?.cms : false,
          // JobApplications:adminRole?.Job ? adminRole?.Job : false,
          LeaveApprove: adminRole?.LeaveApprove
          ===false? false: true,
          NewsLetter: adminRole?.NewsLetter
            ? adminRole?.NewsLetter
            : false,
          ContactUsers: adminRole?.ContactUsers
            ? adminRole?.ContactUsers
            : false,
          GoldAuction: adminRole?.GoldAuction
            ? adminRole?.GoldAuction
            : false,
            TowWheeler: adminRole?.TowWheeler ? adminRole?.TowWheeler : false,
            FourWheeler: adminRole?.FourWheeler ? adminRole?.FourWheeler : false,
          PropertyRequest: adminRole?.PropertyRequest
            ? adminRole?.PropertyRequest
            : false,
          PaymentService: adminRole?.PaymentService
            ? adminRole?.PaymentService
            : false,
          PaymentPackage: adminRole?.PaymentPackage
            ? adminRole?.PaymentPackage
            : false,
        },
      };

      const token = state?.auth?.auth?.token;
      const response = await CreateEmployeeRole(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setCreateAdminRole(false);
        setAdminRole({});
        getListAdmin("active");
        window.location.reload()
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while create role");
    }
  };

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    let validate = {};
    // validate.description = NonEmptyValidation(adminRole1?.description);
    // validate.role = NonEmptyValidation(adminRole1?.role);
    // validate.title = NonEmptyValidation(adminRole1?.title);
    setAdminRoleValidate1(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let html;
      let Payload;
      let isNotEmpty =
        adminRole1?.description &&
        adminRole1?.description.getCurrentContent().hasText();
      if (isNotEmpty) {
        html = draftToHtml(
          convertToRaw(adminRole1?.description.getCurrentContent())
        );
        Payload = {
          _id: adminRole1._id,
          description: html,
          status: "active",
          role: "admin",
          title: adminRole1?.title,
          pageAccess: {
            Dashboard: adminRole1?.pageAccess?.Dashboard
              ? adminRole1?.pageAccess.Dashboard
              : false,
            PurchasedServices: adminRole1?.pageAccess.PurchasedServices
              ? adminRole1?.pageAccess.PurchasedServices
              : false,
            MyTask: adminRole1?.pageAccess.MyTask ? adminRole1?.pageAccess.MyTask : false,
            ServiceRequest: adminRole1?.pageAccess.ServiceRequest
              ? adminRole1?.pageAccess.ServiceRequest
              : false,
            ServicePackage: adminRole1?.pageAccess.ServicePackage
              ? adminRole1?.pageAccess.ServicePackage
              : false,
            UserManagement: adminRole1?.pageAccess.UserManagement
              ? adminRole1?.pageAccess.UserManagement
              : false,
            AdminManagement: adminRole1?.pageAccess.AdminManagement
              ? adminRole1?.pageAccess.AdminManagement
              : false,
            AdminRole: adminRole1?.pageAccess.AdminRole
              ? adminRole1?.pageAccess.AdminRole
              : false,
              EmployeeRole: adminRole1?.pageAccess.EmployeeRole
              ? adminRole1?.pageAccess.EmployeeRole
              : false,
            PropertyAuction: adminRole1?.pageAccess.PropertyAuction ? adminRole1?.pageAccess.PropertyAuction : false,
            History: adminRole1?.pageAccess.History ? adminRole1?.pageAccess.History : false,
            Package: adminRole1?.pageAccess.Package ? adminRole1?.pageAccess.Package : false,
            Lead: adminRole1?.pageAccess.Lead ? adminRole1?.pageAccess.Lead : false,
            EmployeeManagement: adminRole1?.pageAccess.EmployeeManagement
              ? adminRole1?.pageAccess.EmployeeManagement
              : false,
            TaskList: adminRole1?.pageAccess.TaskList ? adminRole1?.pageAccess.TaskList : false,
            LogManagement: adminRole1?.pageAccess.LogManagement
              ? adminRole1?.pageAccess.LogManagement
              : false,
            ProfileManagement: adminRole1?.pageAccess.ProfileManagement
              ? adminRole1?.pageAccess.ProfileManagement
              : false,
            JobManagement: adminRole1?.pageAccess.JobManagement
              ? adminRole1?.pageAccess.JobManagement
              : false,
            cms: adminRole1?.pageAccess.cms ? adminRole1?.pageAccess.cms : false,
            // JobApplications:adminRole1?.Job ? adminRole1?.Job : false,
            LeaveApprove: adminRole1?.pageAccess.LeaveApprove
              ? adminRole1?.pageAccess.LeaveApprove
              : false,
            NewsLetter: adminRole1?.pageAccess.NewsLetter
              ? adminRole1?.pageAccess.NewsLetter
              : false,
            ContactUsers: adminRole1?.pageAccess.ContactUsers
              ? adminRole1?.pageAccess.ContactUsers
              : false,
            GoldAuction: adminRole1?.pageAccess.GoldAuction
              ? adminRole1?.pageAccess.GoldAuction
              : false,
            VehicleAuction: adminRole1?.pageAccess.VehicleAuction
              ? adminRole1?.pageAccess.VehicleAuction
              : false,
            // TowWheeler: adminRole1?.pageAccess.TowWheeler ? adminRole1?.pageAccess.TowWheeler : false,
            // FourWheeler: adminRole1?.pageAccess.FourWheeler ? adminRole1?.pageAccess.FourWheeler : false,
            PropertyRequest: adminRole1?.pageAccess.PropertyRequest
              ? adminRole1?.pageAccess.PropertyRequest
              : false,
            PaymentService: adminRole1?.pageAccess.PaymentService
              ? adminRole1?.pageAccess.PaymentService
              : false,
            PaymentPackage: adminRole1?.pageAccess.PaymentPackage
              ? adminRole1?.pageAccess.PaymentPackage
              : false,
          },
        };
      }

      const token = state?.auth?.auth?.token;
      const response = await UpdateRole(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setUpdateAdminRole(false);
        setAdminRole1({});
        getListAdmin("active");
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while update role");
    }
  };

  const handleSubmitStatusUpdate = async (e, value, id) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
    };
    const token = state?.auth?.auth?.token;
    const response = await UpdateRole(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      if (value !== "active") {
        setSelectedTab("Active Members");
        getListAdmin("active");
      } else {
        setSelectedTab("Inactive Members");
        getListAdmin("inactive");
      }
      // getListAdmin("active");
      //  window.location.reload()
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: pink[600],
      "&:hover": {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: pink[600],
    },
  }));

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              onClick={() => {
                getListAdminSpecific(tableMeta.rowData[0]);
                setUpdateAdminRole(true);
              }}
            >
              {value}
            </div>
          );
        },
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        customBodyRender: (value) => {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: value.substring(0, 100),
              }}
            >
              {/* {`${value.substring(
                0,
                MAX_LENGTH
              )}...`} */}
            </div>
          );
        },
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {value === "inactive" ? (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <PinkSwitch
                        {...label}
                        onChange={(e) => {
                          handleSubmitStatusUpdate(
                            e,
                            "active",
                            tableMeta.rowData[0]
                          );
                        }}
                      />
                    }
                  />
                </FormGroup>
              ) : (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <PinkSwitch
                        {...label}
                        defaultChecked
                        onChange={(e) => {
                          handleSubmitStatusUpdate(
                            e,
                            "inactive",
                            tableMeta.rowData[0]
                          );
                        }}
                      />
                    }
                  />
                </FormGroup>
              )}
            </>
          );
        },
      },
    },

    // {
    //   name: "Action",
    //   label: "Action",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <div className="text-center">
    //           <i
    //             onClick={(e) => {
    //               Swal.fire({
    //                 title: "Are you sure?",
    //                 text: "You want to delete !",
    //                 // icon: "warning",
    //                 showCancelButton: true,
    //                 confirmButtonColor: "#3085d6",
    //                 cancelButtonColor: "#d33",
    //                 confirmButtonText: "Yes, delete",
    //               }).then((result) => {
    //                 if (result.isConfirmed) {
    //                   handleSubmitDelete(
    //                     e,
    //                     tableMeta.rowData[0],
    //                     tableMeta.rowData[5]
    //                   );
    //                 }
    //               });
    //             }}
    //             // onClick={(e) => {
    //             //   handleSubmitDelete(e,tableMeta.rowData[0],value)
    //             // }}
    //             className="fa fa-trash-can"
    //             style={{
    //               color: "#E34242",
    //               fontSize: "16px",
    //               cursor: "pointer",
    //             }}
    //           ></i>
    //         </div>
    //       );
    //     },
    //   },
    // },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[5] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  //console.log(adminRole1)

  return (
    <div>
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0 fw-bold color-maroon">Admin Role Management</h5>
            <div>
              <button
                type="button"
                className="btn py-1 login-btn me-1"
                onClick={() => {
                  setCreateAdminRole(true);
                }}
              >
                Create Role
              </button>
            </div>
          </div>
          <div className="my-4">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 px-0">
                  <div className="tab-section">
                    <ul className="ps-0">
                      {AllTabs.map((tab, index) => {
                        return (
                          <div
                            key={index}
                            className={
                              selectedTab === tab
                                ? "serviceTab active"
                                : "serviceTab inactive"
                            }
                            onClick={() => {
                              getListAdmin(
                                tab === "Inactive Role" ? "inactive" : "active"
                              );
                              setSelectedTab(tab);
                            }}
                          >
                            <li>
                              <span className="align-middle">{tab}</span>
                            </li>
                          </div>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <MUIDataTable
              title={"Dalmaf - Admin Role Management "}
              data={listAdmin}
              columns={columns}
              options={options}
            />
            {/* <table className="table">
              <thead className="bg-historytablehead">
                <tr>
                  <th scope="col" style={{ borderTopLeftRadius: "0" }}>
                    S. No
                  </th>
                  <th scope="col">Role</th>
                  <th scope="col">Description </th>
                  <th scope="col">Status </th>
                </tr>
              </thead>
              <tbody className="historytablebody">
                {listAdmin &&
                  listAdmin.length > 0 &&
                  listAdmin.map((data, index) => {
                    return (
                      <tr>
                        <td>
                          <div className="">
                            <h6 className="fw-bold">{index + 1}</h6>
                          </div>
                        </td>
                        <td scope="row">
                          <div className="d-flex align-items-center ">
                            <div className="flex-shrink-0">
                              <h6 className="fw-bold">{data.role}</h6>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className=" ">
                            <h6 className="fw-bold">{`${data.description.substring(
                              0,
                              MAX_LENGTH
                            )}...`}</h6>
                          </div>
                        </td>
                        <td>
                          <h6 className="fw-bold">
                            <h6 className="fw-bold">{data.status}</h6>
                          </h6>
                        </td>
                        
                      </tr>
                    );
                  })}
               
              </tbody>
            </table> */}
            {/* <CustomModal
                  open={createAdminRole}
                  onClickOutside={() => {
                    setCreateAdminRole(false);
                  }}
                >
                  <div className="modal-content w-50 m-auto">
                    <div className="modal-body p-0 ">
                      <div className="container">
                        <div className="row d-flex align-items-center justify-content-center  mt-0">
                          <div className="container ">
                            <div className="row justify-content-center">
                              <div className="col-lg-12">
                                <div className=" my-5 px-3">
                                  <div className="row justify-content-center mt-2">
                                    <div className="col-lg-12">
                                      <div className="admin-service">
                                        <div
                                          className="small-banner-img-head"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <h5 className="w-100 h-auto text-center">
                                            Create Role
                                          </h5>
                                        </div>
                                        <div className="mb-3">
                                          <label for="" className="form-label">
                                            Role
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="ex:subadmin"
                                            required
                                            aria-invalid={Boolean(
                                              AdminRoleValidate?.role
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              adminRole?.role || ""
                                            }
                                            onChange={(e) => {
                                              setAdminRoleValue(
                                                "role",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setAdminRoleValidateValue(
                                                "role",
                                                NonEmptyValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                            autoComplete="off"
                                          />
                                          <small className="text-danger">
                                            {AdminRoleValidate?.role?.message
                                              ? `Role ${AdminRoleValidate?.role?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                        <div className="mb-3">
                                          <label for="" className="form-label">
                                            Description
                                          </label>
                                          <textarea
                                            type="text"
                                            className="form-control"
                                            required
                                            aria-invalid={Boolean(
                                              AdminRoleValidate?.description
                                                ?.status === false
                                            )}
                                            defaultValue={
                                              adminRole?.description || ""
                                            }
                                            onChange={(e) => {
                                              setAdminRoleValue(
                                                "description",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={(e) => {
                                              setAdminRoleValidateValue(
                                                "description",
                                                NonEmptyValidation(
                                                  e.target.value
                                                )
                                              );
                                            }}
                                            autoComplete="off"
                                          ></textarea>
                                          <small className="text-danger">
                                            {AdminRoleValidate?.description
                                              ?.message
                                              ? `Description ${AdminRoleValidate?.description?.message}`
                                              : ""}
                                          </small>
                                        </div>
                                        <div className="mb-3 text-center">
                                          <button
                                            type="button"
                                            className="btn mx-2 white-btn"
                                            onClick={() => {
                                             setCreateAdminRole(false);
                                            }}
                                          >
                                            {" "}
                                            Cancel
                                          </button>

                                          <button
                                            type="button"
                                            className="btn  my-2  login-btn"
                                            onClick={(e) => handleSubmit(e)}
                                          >
                                            Create
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CustomModal> */}
            <CustomModal
              open={createAdminRole}
              onClickOutside={() => {
                setCreateAdminRole(false);
              }}
            >
              <div className="modal-content w-100 m-auto">
                <div className="modal-body p-0 ">
                  <div className="container">
                    <span
                      className="material-symbols-rounded filled-icon close-icon"
                      style={{
                        marginRight: "30px",
                        marginTop: "50px",
                        textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                      }}
                      onClick={() => {
                        setCreateAdminRole(false);
                        setAdminRole({});
                        window.location.reload()
                      }}
                    >
                      close
                    </span>
                    <div className="row d-flex align-items-center justify-content-center  mt-0">
                      <div className="container ">
                        <div className="row justify-content-center">
                          <div className="col-lg-12">
                            <div className=" my-5 px-3">
                              <div className="row justify-content-center mt-2">
                                <div className="col-lg-12">
                                  <div className="admin-service">
                                    <div
                                      className="small-banner-img-head"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <h5 className="w-100 h-auto text-center">
                                        Create Role
                                      </h5>
                                    </div>
                                    <div className="mb-3">
                                      <label for="" className="form-label">
                                        Title
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        required
                                        aria-invalid={Boolean(
                                          AdminRoleValidate?.title
                                            ?.status === false
                                        )}
                                        defaultValue={adminRole?.title || ""}
                                        onChange={(e) => {
                                          setAdminRoleValue(
                                            "title",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={(e) => {
                                          setAdminRoleValidateValue(
                                            "title",
                                            StringValidation(e.target.value)
                                          );
                                        }}
                                        autoComplete="off"
                                      />
                                      <small className="text-danger">
                                        {AdminRoleValidate?.title?.message
                                          ? `Title ${AdminRoleValidate?.title?.message}`
                                          : ""}
                                      </small>
                                    </div>

                                    <div className="mb-3">
                                      <label for="" className="form-label">
                                        Description
                                      </label>
                                      <div
                                        className="form-control form-control-lg"
                                        style={{ height: "auto" }}
                                      >
                                        <Editor
                                          wrapperClassName="wrapper-class"
                                          editorClassName="editor-class"
                                          toolbarClassName="toolbar-class"
                                          editorState={editorState}
                                          onEditorStateChange={(e) =>
                                            handleEditorChange(e)
                                          }
                                        />
                                      </div>
                                      {/* <textarea
                                                type="text"
                                                className="form-control"
                                                required
                                                aria-invalid={Boolean(
                                                  AdminRoleValidate
                                                    ?.description?.status ===
                                                    false
                                                )}
                                                defaultValue={
                                                  adminRole?.description ||
                                                  ""
                                                }
                                                onChange={(e) => {
                                                  setAdminRoleValue(
                                                    "description",
                                                    e.target.value
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setAdminRoleValidateValue(
                                                    "description",
                                                    NonEmptyValidation(
                                                      e.target.value
                                                    )
                                                  );
                                                }}
                                                autoComplete="off"
                                              ></textarea> */}
                                      <small className="text-danger">
                                        {AdminRoleValidate?.description
                                          ?.message
                                          ? `Description ${AdminRoleValidate?.description?.message}`
                                          : ""}
                                      </small>
                                    </div>
                                    <div className="mb-3">
                                      <label for="" className="form-label">
                                        Page Access
                                      </label>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.Dashboard
                                          }
                                          defaultChecked
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "Dashboard",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Dashboard
                                      </div>

                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.PurchasedServices ||
                                            ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "PurchasedServices",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Purchased Services
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.MyTask || ""
                                          }
                                          defaultChecked
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "MyTask",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        My Task
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.ServiceRequest || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "ServiceRequest",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Service Request
                                      </div>

                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.ServicePackage || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "ServicePackage",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Service Package
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.UserManagement || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "UserManagement",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        User Management
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.AdminManagement || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "AdminManagement",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Admin Management
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.AdminRole || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "AdminRole",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Admin Role
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.EmployeeRole || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "EmployeeRole",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Employee Role
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.Property || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "Property",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Property
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.History || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "History",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        History
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.Package || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "Package",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Package
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.Lead || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "Lead",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Lead
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.JobManagement || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "JobManagement",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Job Management
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.EmployeeManagement ||
                                            ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "EmployeeManagement",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Employee Management
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.TaskList || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "TaskList",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Global Task List
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.LogManagement || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "LogManagement",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Log Management
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.ProfileManagement ||
                                            ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "ProfileManagement",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Profile Management
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={adminRole?.cms || ""}
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "cms",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        CMS
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.ContactUsers || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "ContactUsers",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Contact Users
                                      </div>
                                      {/* <div className="col-4">
                                        <input
                                          type="checkbox"
                                          defaultValue={
                                            adminRole?.PropertyRequest
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "PropertyRequest",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Property Request
                                      </div> */}
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          defaultValue={
                                            adminRole?.LeaveApprove || ""
                                          }
                                          defaultChecked
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "LeaveApprove",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Leave Approve
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.NewsLetter || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "NewsLetter",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        News Letter
                                      </div>

                                      
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.GoldAuction || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "GoldAuction",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Gold Auction
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.TwoWheeler || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              " TwoWheeler",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        TwoWheeler
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      

                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.FourWheeler || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              " FourWheeler",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        FourWheeler
                                      </div>
                                      {/* <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.PaymentService || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "PaymentService",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Payment Service
                                      </div> */}
                                    </div>
                                    <div className="row mb-2">
                                      {/* <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole?.PaymentPackage || ""
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue(
                                              "PaymentPackage",
                                              e.target.checked
                                            );
                                            if (
                                              AdminRoleValidate["pageAccess"]
                                            )
                                              delete AdminRoleValidate[
                                                "pageAccess"
                                              ];
                                          }}
                                        />{" "}
                                        Payment Package
                                      </div> */}
                                    </div>

                                    <small className="text-danger">
                                      {AdminRoleValidate?.pageAccess?.message
                                        ? `Page Access ${AdminRoleValidate?.pageAccess?.message}`
                                        : ""}
                                    </small>
                                  </div>
                                </div>

                                <div className="mb-3 text-center mt-5">
                                  <button
                                    type="button"
                                    className="btn mx-2 white-btn"
                                    onClick={() => {
                                      setCreateAdminRole(false);
                                      setAdminRole({});
                                      window.location.reload()
                                    }}
                                  >
                                    {" "}
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="btn  my-2  login-btn"
                                    onClick={(e) => handleSubmit(e)}
                                  >
                                    Create
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CustomModal>

            <CustomModal
              open={updateAdminRole}
              onClickOutside={() => {
                setUpdateAdminRole(false);
              }}
            >
              <div className="modal-content w-75 m-auto">
                <div className="modal-body p-0 ">
                  <div className="container">
                    <div className="row d-flex align-items-center justify-content-center  mt-0">
                      <div className="container ">
                        <div className="row justify-content-center">
                          <div className="col-lg-12">
                            <div className=" my-5 px-3">
                              <div className="row justify-content-center mt-2">
                                <div className="col-lg-12">
                                  <div className="admin-service">
                                    <div
                                      className="small-banner-img-head"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <h5 className="w-100 h-auto text-center">
                                        Update Role
                                      </h5>
                                    </div>
                                    <div className="mb-3">
                                      <label for="" className="form-label">
                                        Title
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        required
                                        aria-invalid={Boolean(
                                          adminRoleValidate1?.title
                                            ?.status === false
                                        )}
                                        defaultValue={
                                          adminRole1?.title || ""
                                        }
                                        onChange={(e) => {
                                          setAdminRoleValue1(
                                            "",
                                            "title",
                                            e.target.value,
                                            ""
                                          );
                                        }}
                                        onBlur={(e) => {
                                          setAdminRoleValidateValue1(
                                            "",
                                            "title",
                                            StringValidation(e.target.value),
                                            ""
                                          );
                                        }}
                                        autoComplete="off"
                                      />
                                      <small className="text-danger">
                                        {adminRoleValidate1?.title?.message
                                          ? `Title ${adminRoleValidate1?.title?.message}`
                                          : ""}
                                      </small>
                                    </div>

                                    <div className="mb-3">
                                      <label for="" className="form-label">
                                        Description
                                      </label>
                                      <div
                                        className="form-control form-control-lg"
                                        style={{ height: "300px" }}
                                      >
                                        <Editor
                                          wrapperClassName="wrapper-class"
                                          editorClassName="editor-class"
                                          toolbarClassName="toolbar-class"
                                          editorState={
                                            adminRole1?.description
                                          }
                                          onEditorStateChange={(e) =>
                                            setAdminRoleValue1(
                                              "",
                                              "description",
                                              e,
                                              ""
                                            )
                                          }
                                        />
                                      </div>
                                      {/* <textarea
                                                type="text"
                                                className="form-control"
                                                required
                                                aria-invalid={Boolean(
                                                  adminRoleValidate1
                                                    ?.description?.status ===
                                                    false
                                                )}
                                                defaultValue={
                                                  adminRole1?.description ||
                                                  ""
                                                }
                                                onChange={(e) => {
                                                  setAdminRoleValue1(
                                                    "",
                                                    "description",
                                                    e.target.value,
                                                    ""
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setAdminRoleValidateValue1(
                                                    "",
                                                    "description",
                                                    NonEmptyValidation(
                                                      e.target.value,
                                                    ),
                                                    ""

                                                  );
                                                }}
                                                autoComplete="off"
                                              ></textarea> */}
                                      <small className="text-danger">
                                        {adminRoleValidate1?.description
                                          ?.message
                                          ? `Description ${adminRoleValidate1?.description?.message}`
                                          : ""}
                                      </small>
                                    </div>

                                    <div className="mb-3">
                                      <label for="" className="form-label">
                                        Page Access
                                      </label>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess?.Dashboard
                                          }
                                          // defaultChecked={
                                          //   adminRole1?.pageAccess?.Dashboard
                                          // }
                                          checked
                                          onChange={(e) => {
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "Dashboard",
                                              true,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Dashboard
                                      </div>

                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.PurchasedServices
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.PurchasedServices
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "PurchasedServices",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Purchased Services
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess?.MyTask
                                          }
                                          // defaultChecked={
                                          //   adminRole1?.pageAccess?.MyTask
                                          // }
                                          checked
                                          onChange={(e) => {
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "MyTask",
                                              true,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        My Task
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.ServiceRequest
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.ServiceRequest
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "ServiceRequest",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Service Request
                                      </div>

                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.ServicePackage
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.ServicePackage
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "ServicePackage",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Service Package
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.UserManagement
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.UserManagement
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "UserManagement",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        User Management
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.AdminManagement
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.AdminManagement
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "AdminManagement",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Admin Management
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess?.AdminRole
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess?.AdminRole
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "AdminRole",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Admin Role
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.EmployeeRole
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.EmployeeRole
                                          }
                                          onChange={(e) => {
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "EmployeeRole",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Employee Role
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                    <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.NewsLetter
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.NewsLetter
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "NewsLetter",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        News Letter
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess?.History
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess?.History
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "History",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        History
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          defaultValue={
                                            adminRole1?.pageAccess?.Package
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess?.Package
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "Package",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Package
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess?.Lead
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess?.Lead
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "Lead",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Lead
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.JobManagement
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.JobManagement
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "JobManagement",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Job Management
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.EmployeeManagement
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.EmployeeManagement
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "EmployeeManagement",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Employee Management
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess?.TaskList
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess?.TaskList
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "TaskList",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Global Task List
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.LogManagement
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.LogManagement
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "LogManagement",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Log Management
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.ProfileManagement
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.ProfileManagement
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "ProfileManagement",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Profile Management
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess?.cms
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess?.cms
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "cms",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        CMS
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.ContactUsers
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.ContactUsers
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "ContactUsers",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Contact Users
                                      </div>

                                      {/* <div className="col-4">
                                        <input
                                          type="checkbox"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.PropertyRequest
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.PropertyRequest
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "PropertyRequest",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Property Request
                                      </div> */}
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.LeaveApprove
                                          }
                                          checked
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "LeaveApprove",
                                              true,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Leave Approve
                                      </div>
                                    </div>
                                    <div className="row mb-2">
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess?.PropertyAuction
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess?.PropertyAuction
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "PropertyAuction",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Property Auction
                                      </div>

                                     
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.GoldAuction
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.GoldAuction
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "GoldAuction",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Gold Auction
                                      </div>
                                      <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.VehicleAuction
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.VehicleAuction
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "VehicleAuction",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Vehicle Auction
                                      </div>
                                    </div>

                                    <div className="row mb-2">
                                      
                                      {/* <div className="col-4">
                                        <input
                                          type="checkbox"
                                          className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.FourWheeler
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.FourWheeler
                                          }
                                          onChange={(e) => {
                                            console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "FourWheeler",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Four Wheeler
                                      </div> */}

                                      {/* <div className="col-4">
                                        <input
                                          type="checkbox"
                                          // className="form-control"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.PaymentService
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.PaymentService
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "PaymentService",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                        />{" "}
                                        Payment Service
                                      </div> */}
                                    </div>
                                    <div className="row mb-2">
                                      {/* <div className="col-4">
                                        <input
                                          type="checkbox"
                                          defaultValue={
                                            adminRole1?.pageAccess
                                              ?.PaymentPackage
                                          }
                                          defaultChecked={
                                            adminRole1?.pageAccess
                                              ?.PaymentPackage
                                          }
                                          onChange={(e) => {
                                            //console.log(e.target.checked);
                                            setAdminRoleValue1(
                                              "pageAccess",
                                              "PaymentPackage",
                                              e.target.checked,
                                              "object"
                                            );
                                          }}
                                          // className="form-control"
                                        />{" "}
                                        Payment Package
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="mb-3 text-center mt-5">
                                  <button
                                    type="button"
                                    className="btn mx-2 white-btn"
                                    onClick={() => {
                                      setUpdateAdminRole(false);
                                      setAdminRole1({});
                                      getListAdmin("active");
                                    }}
                                  >
                                    {" "}
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="btn  my-2  login-btn"
                                    onClick={(e) => handleSubmitUpdate(e)}
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CustomModal>
          </div>
        </div>
      </div>
    </div>
  );
}
