import React, { useEffect, useState } from 'react';
import Typewriter from '../../Home/Typewriter';
import './BestServices.css'; // Import CSS file for styling
import { useNavigate } from 'react-router-dom';
import { fetchBestServices } from '../../../Api/v1/service/Api';

export default function BestServices() {
    useEffect(() => {
        window.scrollTo(0, 0);
        getBestServices();
    }, []);

    const navigate = useNavigate();
    const [bestServices, setBestServices] = useState([]);

    const getBestServices = async () => {
        let data = await fetchBestServices();
        if (data && data.statusCode === 200) {
            setBestServices(data?.data);
        } else {
            setBestServices([]);
        }
    };

    return (
        <div className="py-5 th-hero-wrapper hero-3">
            {/* <h2 className="mb-4 text-center position-relative">
                Our Best Services
                <span style={{float:"right",fontSize:"16px",cursor:"pointer"}} onClick={() => {navigate("/service-listing");}}><u>View All</u></span>
                <span className="underline"></span>
            </h2> */}
            <div className='container'>
            <div className="row justify-content-lg-between justify-content-center align-items-center">
                <div className="col-lg-6 col-sm-9 pe-xl-5">
                    <div className="title-area text-center text-lg-start">
                        <span className="sub-title">
                            <div className="icon-masking me-2">
                                <span className="mask-icon" data-mask-src="img/theme-img/title_shape_2.svg"></span> 
                                <img src="img/theme-img/title_shape_2.svg" alt="shape"/>
                            </div>
                            Top Services
                        </span>
                        <h2 className="sec-title">
                            We Provide Exclusive Service For 
                            <span className="text-theme fw-normal"> Your Business</span>
                        </h2>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="sec-btn">
                        <a href="" onClick={(e) => {e.preventDefault();navigate("/service-listing");}} className="th-btn">
                            VIEW ALL SERVICES
                            <i className="fa-regular fa-arrow-right ms-2"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div className="row row-cols-1 row-cols-md-3 g-4">
                {bestServices?.map((data, index) => (
                    <div className="col" key={index} style={{cursor:"pointer"}}  onClick={() => {
                        navigate({
                            pathname: "/Service",
                            search: `tab=${data.title}&subtab=${data.subServiceL1Name} | ${data.mainServiceName}&id=${data._id}`,
                        });
                    }}>
                        <div className="card h-100 shadow service-card">
                            <div className="card-body">
                                <h5 className="card-title">{data.title}</h5>
                                <h6 className="card-subtitle mb-2 text-muted">
                                    {data.mainServiceName} - {data.subServiceL1Name}
                                </h6>
                                {/* <p className="card-text">Description of the service goes here...</p> */}
                                <button
                                    onClick={() => {
                                        navigate({
                                            pathname: "/Service",
                                            search: `tab=${data.title}&subtab=${data.subServiceL1Name} | ${data.mainServiceName}&id=${data._id}`,
                                        });
                                    }}
                                    className="btn btn-primary">Learn more</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            </div>
            <div className="hero-shape1"><img src="img/hero/hero_shape_3_1.png" alt="shape"/></div>
        </div>
    );
}
