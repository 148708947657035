import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchWithQueryAPI } from "../../../Api/v1/Util/Api";
import { attendance_dashboard_statistics_list_admin_url } from "../../../Api/APIUrl";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line
} from 'recharts';
import { format, startOfToday, endOfToday, startOfMonth, eachDayOfInterval } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const StatisticsAttendanceDashboard = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fromDate, setFromDate] = useState(startOfMonth(new Date()));
  const [toDate, setToDate] = useState(endOfToday());
  const [selectedEmployee, setSelectedEmployee] = useState(''); // All employees by default

  const colors = ["#8884d8", "#82ca9d", "#ffc658", "#ff6b6b", "#8dd1e1", "#a4de6c", "#d0ed57"];

  const handleFetchCountDashboard = async () => {
    if (!fromDate || !toDate) return;

    const formattedFromDate = format(fromDate, 'yyyy-MM-dd');
    const formattedToDate = format(toDate, 'yyyy-MM-dd');

    try {
      const response = await fetchWithQueryAPI(
        state.auth.auth.token,
        `${attendance_dashboard_statistics_list_admin_url}?from_date=${formattedFromDate}&to_date=${formattedToDate}`
      );
      if (response.statusCode === 200) {
        setAttendanceData(response.data);
      } else {
        setError(response.message || "Failed to fetch data");
      }
    } catch (error) {
      setError("Error fetching attendance data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchCountDashboard();
  }, [fromDate, toDate, selectedEmployee]);

  const timeToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const formatSecondsToHours = (totalSeconds) => {
    if (totalSeconds === undefined || totalSeconds === null) return "N/A";
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  const formatTime = (timeString) => {
    if (!timeString || !timeString.includes(":")) return "N/A";
    const [hour, minute, second] = timeString.split(":");
    return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:${second.padStart(2, '0')}`;
  };

  const createDateRange = (startDate, endDate) => {
    return eachDayOfInterval({ start: startDate, end: endDate });
  };

  const chartData = createDateRange(fromDate, toDate).map((date) => {
    const dateString = format(date, 'yyyy-MM-dd');
    return {
      date: dateString,
      ...attendanceData.reduce((acc, employee) => {
        if (selectedEmployee === '' || employee.name === selectedEmployee) {
          const stat = employee.statistics.find((s) => s.date === dateString) || {};
          acc[`${employee.name}_total_hours`] = timeToSeconds(stat.total_hours || "00:00:00");
          acc[`${employee.name}_first_clock_in_time`] = formatTime(stat.first_clock_in_time || "00:00:00");
          acc[`${employee.name}_last_clock_out_time`] = formatTime(stat.last_clock_out_time || "00:00:00");
          acc[`${employee.name}_auto_clockout`] = stat.auto_clockout || false;
        }
        return acc;
      }, {}),
    };
  });

  chartData.sort((a, b) => new Date(a.date) - new Date(b.date));

  const formatTimeNew = (isoString) => {
    if (!isoString) return "N/A";
    return new Date(isoString).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { date, employeeName, first_clock_in_time, last_clock_out_time, auto_clockout, total_hours } = payload[0].payload;

      return (
        <div className="custom-tooltip" style={tooltipStyles}>
          <div style={tooltipRowStyle}>
            <span style={labelStyle}>Date:</span>
            <span style={valueStyle}>{format(new Date(date), 'dd-MM-yyyy')}</span>
          </div>
          <div style={tooltipRowStyle}>
            <span style={labelStyle}>Employee:</span>
            <span style={valueStyle}>{employeeName}</span>
          </div>
          <div style={tooltipRowStyle}>
            <span style={labelStyle}>Total Hours:</span>
            <span style={valueStyle}>{formatSecondsToHours(total_hours)}</span>
          </div>
          <div style={tooltipRowStyle}>
            <span style={labelStyle}>First Clock In:</span>
            <span style={valueStyle}>{formatTimeNew(first_clock_in_time)}</span>
          </div>
          <div style={tooltipRowStyle}>
            <span style={labelStyle}>Last Clock Out:</span>
            <span style={valueStyle}>{formatTimeNew(last_clock_out_time)}</span>
          </div>
          <div style={tooltipRowStyle}>
            <span style={labelStyle}>Auto Clockout:</span>
            <span style={valueStyle}>{auto_clockout ? "Yes" : "No"}</span>
          </div>
        </div>
      );
    }
    return null;
  };

  const tooltipStyles = {
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '10px',
    fontSize: '14px',
    lineHeight: '1.5',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    color: '#333',
  };

  const tooltipRowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
  };

  const labelStyle = {
    fontWeight: 'bold',
    marginRight: '10px',
  };

  const valueStyle = {
    textAlign: 'right',
  };

  return (
    <div className="container mt-4">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-danger">{error}</p>
      ) : (
        <div className="row">
          <div className="col-md-10">
            <div style={{ width: '100%', marginTop: '20px', overflowX: 'auto' }}>
              <ResponsiveContainer width="100%" height={500}>
                <LineChart
                  data={chartData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 80 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="date" 
                    tickFormatter={(tick) => format(new Date(tick), 'dd-MM-yyyy')} 
                    interval="preserveStartEnd"
                  />
                  <YAxis 
                    tickFormatter={formatSecondsToHours} 
                    domain={['auto', 'auto']} 
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />

                  {attendanceData.map((employee, index) => (
                    <Line
                      key={employee.employee_id}
                      type="monotone"
                      dataKey={`${employee.name}_total_hours`}
                      stroke={colors[index % colors.length]}
                      strokeWidth={3} // Increase line width
                      name={employee.name}
                      dot={true} // Optionally use custom dot
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="from-date">From Date:</label>
              <DatePicker
                id="from-date"
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="to-date">To Date:</label>
              <DatePicker
                id="to-date"
                selected={toDate}
                onChange={(date) => setToDate(date)}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="employee">Select Employee:</label>
              <select
                id="employee"
                value={selectedEmployee}
                onChange={(e) => setSelectedEmployee(e.target.value)}
                className="form-control"
              >
                <option value="">All Employees</option>
                {attendanceData.map((employee) => (
                  <option key={employee.employee_id} value={employee.name}>
                    {employee.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatisticsAttendanceDashboard;
