import React, { useEffect } from 'react';
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import ApartmentContent from "../Components/Property/ApartmentContent";
import ApartmentBanner from "../Components/Property/ApartmentBanner";
export default function Apartment() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);


  return (
    <div className="innerpages-nav">
      <div id="container">
      <Navigation />
      <ApartmentBanner />
      <ApartmentContent />
      <Footer />
      </div>
      
    </div>
  );
}
