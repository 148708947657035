import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './css/TaskCommentComponent.module.css';
import { createAPI, fetchWithQueryAPI } from '../../../../Api/v1/Util/Api';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { task_comment_find, task_comment_add } from '../../../../Api/APIUrl';

const TaskCommentComponent = ({ showFullNav }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const state = useSelector((state) => state);
  const { id, taskId } = useParams();

  useEffect(() => {
    fetchComments();
  }, [taskId]);

  const fetchComments = async () => {
    try {
      const response = await fetchWithQueryAPI(state.auth.auth.token, task_comment_find + taskId);
      if (response.statusCode === 200) {
        setComments(response.data.task_comment);
        setLoading(false);
      } else {
        setError('Error fetching comments');
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
      setError('Error fetching comments');
    }
  };

  const handleAddComment = async () => {
    if (newComment.trim() !== '') {
      setLoading(true);
      setError(null);
      try {
        const response = await createAPI(state.auth.auth.token, task_comment_add , { message: newComment, task_id: taskId });
        if(response.statusCode===200){
            fetchComments();
            setNewComment('');
        }
        
      } catch (error) {
        console.error('Error adding comment:', error);
        setError('Error adding comment');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeleteComment = async (id) => {
    setLoading(true);
    setError(null);
    try {
      await axios.delete(`/api/comments/${id}`, {
        headers: {
          Authorization: `Bearer ${state.auth.auth.token}`
        }
      });
      setComments(comments.filter(comment => comment.id !== id));
    } catch (error) {
      console.error('Error deleting comment:', error);
      setError('Error deleting comment');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={showFullNav ? styles.taskCommentComponent : styles.taskCommentComponentSmall}>
      <h3>Comments</h3>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      <div className={styles.commentForm}>
        <ReactQuill 
          value={newComment} 
          onChange={setNewComment} 
          placeholder="Add a comment" 
          className={styles.commentFormInput} 
        />
        <button onClick={handleAddComment} className={styles.commentFormButton}>Add Comment</button>
      </div>
      <div className={styles.commentList}>
        {comments?.map((comment) => (
          <div key={comment.id} className={styles.commentItem}>
            <div className={styles.commentHeader}>
              <img src={comment?.user?.image} alt={comment?.user?.name} className={styles.commentUserImage} />
              <div>
                <p className={styles.commentUserName}>{comment.commented_by_name}</p>
                <p className={styles.commentTimestamp}>{new Date(comment?.createdAt).toLocaleString()}</p>
              </div>
            </div>
            <div className={styles.commentText} dangerouslySetInnerHTML={{ __html: comment.message }}></div>
            {/* <button onClick={() => handleDeleteComment(comment.id)}>Delete</button> */}
          </div>
        ))}
        {comments.length === 0 && "No Comment's yet"}
      </div>
    </div>
  );
};

export default TaskCommentComponent;
