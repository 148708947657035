import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import MUIDataTable from 'mui-datatables';
import 'react-toastify/dist/ReactToastify.css';
import styles from './css/ListLeadComponent.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fetchWithQueryAPI } from '../../../../Api/v1/Util/Api';
import { generic_status, task_fetch, task_fetch_by_reference } from '../../../../Api/APIUrl';
import moment from 'moment';

export default function ViewTaskComponent({ filterProps,showFullNav,routeProps,lableProps,userTypeProps,isClientProps }) {
    const [tasks, setTasks] = useState([]);
    const state = useSelector((state) => state);
    const navigate = useNavigate();
    const { id } = useParams();
    // console.log({ filterProps,showFullNav,routeProps,lableProps,userTypeProps,isClientProps })
    const fetchTasks = async () => {
        try {
            let url = task_fetch_by_reference+id+'&'+generic_status+filterProps
            if(lableProps!='task'){
                url = task_fetch+'?'+generic_status+filterProps
            }
            const response = await fetchWithQueryAPI(state.auth.auth.token,url );
            if (response && response.statusCode === 200) {
                setTasks(response.data);
            } else {
                toast.error('Failed to fetch tasks');
            }
        } catch (error) {
            toast.error('An error occurred while fetching tasks');
        }
    };

    useEffect(() => {
        fetchTasks();
    }, []);

    const handleView = (taskId) => {
        if(routeProps==="task")
            navigate(`/${userTypeProps}/${routeProps}/view-specific-task/${taskId}`);
        else
            navigate(`/${userTypeProps}/${routeProps}/view-specific-task/${id}/${taskId}`);
    };

    let columns;
    if(routeProps==="task"){
        columns = [
            {
                name: 'title',
                label: 'Title',
                options: {
                    customBodyRender: (value, tableMeta) => (
                        <span onClick={() => handleView(tableMeta.rowData[6])} style={{ cursor: 'pointer' }}>
                            {value}
                        </span>
                    ),
                },
            },
            { name: 'belongs_to', label: 'Belongs To' },
            {
                name: 'start_date',
                label: 'Start Date',
                options: {
                    customBodyRender: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                },
            },
            {
                name: 'end_date',
                label: 'Due Date',
                options: {
                    customBodyRender: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                },
            },
            { name: 'created_by', label: 'Created By' },
            { name: 'priority', label: 'Priority' },
            {
                name: '_id',
                label: 'Actions',
                options: {
                    customBodyRender: (value, tableMeta) => (
                        <>
                            <IconButton onClick={() => handleView(tableMeta.rowData[6])} color="primary">
                                <VisibilityIcon />
                            </IconButton>
                        </>
                    ),
                },
            },
        ];
    }else{
        columns = [
            {
                name: 'title',
                label: 'Title',
                options: {
                    customBodyRender: (value, tableMeta) => (
                        <span onClick={() => handleView(tableMeta.rowData[6])} style={{ cursor: 'pointer' }}>
                            {value}
                        </span>
                    ),
                },
            },
            {
                name: 'start_date',
                label: 'Start Date',
                options: {
                    customBodyRender: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                },
            },
            {
                name: 'end_date',
                label: 'Due Date',
                options: {
                    customBodyRender: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
                },
            },
            { name: 'created_by', label: 'Created By' },
            { name: 'updated_by', label: 'Updated By' },
            { name: 'priority', label: 'Priority' },
            {
                name: '_id',
                label: 'Actions',
                options: {
                    customBodyRender: (value, tableMeta) => (
                        <>
                            <IconButton onClick={() => handleView(tableMeta.rowData[6])} color="primary">
                                <VisibilityIcon />
                            </IconButton>
                        </>
                    ),
                },
            },
        ];
    }
    

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
    };

    const customTheme = createTheme({
        components: {
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#dc3545',
                        color: 'white',
                    },
                },
            },
        },
    });

    return (
        <>
            <ToastContainer />
            <div>
                <ThemeProvider theme={customTheme}>
                    <MUIDataTable
                        title={`${filterProps} - Task List (${tasks?.total_count ?? 0})`}
                        data={tasks.task}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>
        </>
    );
}
