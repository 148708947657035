import React, { useState } from 'react';
import styles from './UpdatePasswordComponent.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { updatePassword } from '../../../../Api/v1/FreelancerPartner/Profile';
import { showErrorMsg, showSuccessMsg} from '../../../../Store/util';
import { ToastContainer } from 'react-toastify';

export default function UpdatePasswordComponent() {

    const state = useSelector((state) => state);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const validateForm = () => {
        let formErrors = {};
        if (!currentPassword) {
            formErrors.currentPassword = 'Current password is required';
        }
        if (!newPassword) {
            formErrors.newPassword = 'New password is required';
        } else if (newPassword.length < 8) {
            formErrors.newPassword = 'Password must be at least 8 characters long';
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleInputChange = (e, setter, errorKey) => {
        setter(e.target.value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [errorKey]: '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            let Payload ={
                email:state.auth.auth.email,
                password:currentPassword,
                updatePassword:newPassword
            }
            const response = await updatePassword(Payload,state.auth.auth.token);
            if (response && response.statusCode === 200) {
                showSuccessMsg(response.message);
                setCurrentPassword('');
                setNewPassword('');
            } else {
                showErrorMsg(
                  response.message || response.error.response.data.message
                );
            }
        }
    };

    return (
        <>
            <ToastContainer />
            <div className={styles.container}>
                <h2>Change Password</h2>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <input
                                type={showCurrentPassword ? 'text' : 'password'}
                                id="currentPassword"
                                value={currentPassword}
                                onChange={(e) => handleInputChange(e, setCurrentPassword, 'currentPassword')}
                                className={`${styles.input} ${errors.currentPassword ? styles.errorInput : ''}`}
                                placeholder=" "
                            />
                            <label htmlFor="currentPassword" className={`${styles.placeholder} ${currentPassword ? styles.filled : ''}`}>
                                Current Password
                            </label>
                            <FontAwesomeIcon
                                icon={showCurrentPassword ? faEyeSlash : faEye}
                                className={styles.eyeIcon}
                                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                            />
                        </div>
                        {errors.currentPassword && <span className={styles.error}>{errors.currentPassword}</span>}
                    </div>
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <input
                                type={showNewPassword ? 'text' : 'password'}
                                id="newPassword"
                                value={newPassword}
                                onChange={(e) => handleInputChange(e, setNewPassword, 'newPassword')}
                                className={`${styles.input} ${errors.newPassword ? styles.errorInput : ''}`}
                                placeholder=" "
                            />
                            <label htmlFor="newPassword" className={`${styles.placeholder} ${newPassword ? styles.filled : ''}`}>
                                New Password
                            </label>
                            <FontAwesomeIcon
                                icon={showNewPassword ? faEyeSlash : faEye}
                                className={styles.eyeIcon}
                                onClick={() => setShowNewPassword(!showNewPassword)}
                            />
                        </div>
                        {errors.newPassword && <span className={styles.error}>{errors.newPassword}</span>}
                    </div>
                    <button type="submit" className={styles.updateButton}>Update Password</button>
                </form>
            </div>
        </>
    );
}
