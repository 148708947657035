import React, { useEffect } from 'react';
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import BSWheelerBanner from "../Components/Buy_Sell/BSWheelerBanner";
import BSTwoWheelerContent from "../Components/Buy_Sell/BSTwoWheelerContent";
export default function BSTwoWheeler() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="innerpages-nav">
        <div id="container" >
      <Navigation />
      <BSWheelerBanner />
      <BSTwoWheelerContent />
      <Footer />
      </div>
    </div>
  );
}
