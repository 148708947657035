import axios from "axios";
import { url } from "../../BaseUrl";

const APIBaseUrl = url;

export const fetchScheduleMeeting = async (status,token) => {
    const response = await axios
        .get(`${APIBaseUrl}/v1/schedule_meeting/find_all?status=${status}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

export const fetchSpecificScheduleMeeting = async (_id,token) => {
    const response = await axios
        .get(`${APIBaseUrl}/v1/schedule_meeting/find_particular?_id=${_id}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

export const updateScheduleMeetingStatus = async (payload,token) => {
    const response = await axios
        .put(`${APIBaseUrl}/v1/schedule_meeting/update`,payload, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

