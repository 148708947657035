import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AdminNavbar from "../Navbar/AdminNavbar";

import moment from "moment";
import {
  GetProperty,
  GetPropertySubadmin,
  UpdateProperty,
} from "../../../Api/Api";
import CustomModal from "../../../Components/CustomModal";
import Spinner from "../../../Spinner/Spinner";

const Adminproperty = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);

    getPropertyList("Active");
  }, []);
  const [selectedTab, setSelectedTab] = React.useState("Active");
  // const AllTabs = ["Active", "Inactive", "Sold", "Pending"];
  const AllTabs = ["Active", "Inactive"];

  const [loading, setLoading] = useState(true);

  const [propertyList, setPropertyList] = useState([]);
  const [propertyModal, setPropertyModal] = useState(false);
  const getPropertyList = async (status, value) => {
    setLoading(true);
    if (state?.auth?.auth?.role === "superadmin") {
      const data = await GetProperty(status);
      if (data && data.statusCode === 200) {
        let temp = [];
        data.data.map((data, i) => {
          let dummy = {
            _id: data?._id,
            property_id: data?.property_id,
            bank:data?.bank,
            source_id:data?.source_id,
            property_type: data?.property_type,
            possession_status: data?.possession_status,
            emd_submission_last_date:data?.emd_submission_last_date,
            reserve_price: data?.reserve_price,
            emd_amount: data?.emd_amount,
            auction_date: moment(data?.auction_date).format("YYYY/MM/DD"),
            userId: data?.userId,
            status: data?.status,
          };
          temp.push(dummy);
          return null;
        });

        setPropertyList(temp);
        setLoading(false);
      } else {
        setPropertyList([]);
        setLoading(false);
        errorMsg(data?.message);
      }
    } else {
      const response = await GetProperty(
        // state?.auth?.auth?._id,
        status
      );
      if (response && response.statusCode === 200) {
        setPropertyList(response.data);
        setLoading(false);
      } else {
        setPropertyList([]);
        setLoading(false);
        errorMsg(response.message);
      }
    }
  };

  const [file, setFile] = useState([]);

  const onChangeImage = (e) => {
    setFile(e.target.files[0]);
  };


  const successMsg = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const errorMsg = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleSubmitUpdate = async (e, value, id, value1) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
    };

    const token = state?.auth?.auth?.token;
    const response = await UpdateProperty(Payload, token);
    if (response && response.statusCode === 200) {
      successMsg(response.message);
      getPropertyList(value1);
      //  window.location.reload()
    } else {
      errorMsg(response.message || response.error.response.data.message);
      //setfailureModal(true);
    }
  };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "property_id",
      label: "Property Id",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(`/Property/View/${tableMeta.rowData[0]}`)
                }
              >
                <u>{value}</u>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "bank",
      label: "Bank",
    },
    {
      name: "property_type",
      label: "Property Type",
    },
    {
      name: "possession_status",
      label: "Possession Status",
    },
    {
      name: "reserve_price",
      label: "Reserve Price",
    },
    {
      name: "emd_amount",
      label: "EMD Amount",
    },
    {
      name: "auction_date",
      label: "Auction Date",
    },
    {
      name: "source_id",
      label: "Source",
    },
    {
      name: "userId",
      label: "Created By",
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <select
              className="form-control form-control-lg"
              defaultValue={value}
              onChange={(e) => {
                //console.log(e.target.value);
                handleSubmitUpdate(
                  e,
                  e.target.value,
                  tableMeta.rowData[0],
                  value
                );
              }}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
              {/* <option value="Sold">Sold</option>
              <option value="Pending">Pending</option> */}
            </select>
          );
        },
      },
    },
    {
      name: "_id",
      label: "Action",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="text-center">
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(`/Property/Edit/${tableMeta.rowData[0]}`)
                }
              >
                  <i
                className="fa fa-edit"
                style={{
                  color: "#E3943A",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              ></i>
              </div>
            
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
  };

  return (
    <>
      <AdminNavbar />

      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div>
              <div className="d-flex align-items-center mb-4 justify-content-between">
                <h5 className="mb-0 fw-bold color-maroon">Properties</h5>
                <div>
                  {/* <Link
                  onClick={() => {
                    setPropertyModal(true);
                  }}
                >
                  <button className="loginbtn">Property Bulk Upload</button>
                </Link> */}

                  <Link to="/Createpropertyadmin">
                    <button type="button" className="btn py-1 login-btn">
                      Create Property
                    </button>
                  </Link>
                </div>
              </div>
              <div className="">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7 px-0">
                      <div className="tab-section">
                        <ul className="ps-0">
                          {AllTabs.map((tab, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  selectedTab === tab
                                    ? "serviceTab active"
                                    : "serviceTab inactive"
                                }
                                onClick={() => {
                                  if (tab === "Inactive") {
                                    getPropertyList("Inactive");
                                  } else if (tab === "Pending") {
                                    getPropertyList("Pending");
                                  } else if (tab === "Sold") {
                                    getPropertyList("Sold");
                                  } else if (tab === "Active") {
                                    getPropertyList("Active");
                                  }

                                  setSelectedTab(tab);
                                }}
                              >
                                <li>
                                  <span className="align-middle">{tab}</span>
                                </li>
                              </div>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <MUIDataTable
                  title={"Dalmaf - Properties for Auction"}
                  data={propertyList}
                  columns={columns}
                  options={options}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <CustomModal
        open={propertyModal}
        onClickOutside={() => {
          setPropertyModal(false);
        }}
      >
        <div className="modal-dialog interestmodel">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalToggleLabel2">
                Property Upload
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setPropertyModal(false);
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <input
                  type="file"
                  id="myfile"
                  className="contactforminput"
                  accept=".xls,.xlsx"
                  name="file"
                  onChange={onChangeImage}
                />
              </div>
              {/* <div className="btntextcenter">
                <button className="loginbtn" onClick={createProperty}>
                  Upload
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </CustomModal>
      <ToastContainer />
    </>
  );
};

export default Adminproperty;
