import React, { useEffect, useState } from "react";
import AI1 from "../../../Assets/Images/ai-1.webp";
import AIhover1 from "../../../Assets/Images/ai-hover1.webp";
import Logo from "../../../Assets/dalmaf-vertical-logo.png";

import Swal from "sweetalert2";
import AI3 from "../../../Assets/Images/ai-3.webp";
import AI4 from "../../../Assets/Images/ai-4.webp";
import AIhover3 from "../../../Assets/Images/ai-hover3.webp";
import AIhover4 from "../../../Assets/Images/ai-hover4.webp";
import AddI2 from "../../../Assets/Images/icon/Frame20948.webp";
import Addhover2 from "../../../Assets/Images/icon/Frame209482.webp";
import AddI5 from "../../../Assets/Images/icon/Vecto.webp";
import Addhover5 from "../../../Assets/Images/icon/Vecto2.webp";
import AddI3 from "../../../Assets/Images/icon/Vector.webp";
import Addhover3 from "../../../Assets/Images/icon/Vector2.webp";
import AddI4 from "../../../Assets/Images/icon/eos-icons_role-binding.webp";
import Addhover4 from "../../../Assets/Images/icon/eos-icons_role-binding2.webp";
import AddI1 from "../../../Assets/Images/icon/mdi_account-service.webp";
import Addhover1 from "../../../Assets/Images/icon/mdi_account-service2.webp";
import SIhover1 from "../../../Assets/Images/ser-icon-hover.webp";
import SI1 from "../../../Assets/Images/ser-icon.webp";

import NotificationBadge, { Effect } from "react-notification-badge";
import AddI7 from "../../../Assets/Images/icon/icon-park-solid_log.webp";
import Addhover7 from "../../../Assets/Images/icon/icon-park-solid_log2.webp";
// import AddI8 from "../../../Assets/Images/icon/Vecto.webp";
// import Addhover8 from "../../../Assets/Images/icon/Vecto2.webp";
import { Link, NavLink, useNavigate } from "react-router-dom";


import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  GetNotification,
  GetUser,
  Markread,
  UpdateNotification,
} from "../../../Api/Api";
import { GetInitials } from "../../../Store/Constants";
import { logOutSuccess } from "../../../Store/auth/action";
import { createAPI, fetchWithQueryAPI } from "../../../Api/v1/Util/Api";
import { attendance_current_status, attendance_current_submit } from "../../../Api/APIUrl";
import { showErrorMsg } from "../../../Store/util";
import { ToastContainer } from "react-toastify";

export default function AdminNavbar({ title }) {
  //nav toggle

  const [Show, setShow] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [clockStatus, setClockStatus] = useState({})

  useEffect(() => {
    window.scroll(0, 0);
    getNotifications();
    getSpecificUser();
    handleClockStatus();
  }, []);

  const handleClockStatus = async () => {
    let response = await fetchWithQueryAPI(state?.auth?.auth?.token, attendance_current_status);
    if (response.statusCode === 200)
      setClockStatus(response.data)
  }

  const reverseGeocode = async (lat, lng) => {
    // Use a reverse geocoding API to get the address
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
    );
    const data = await response.json();
    return data.display_name;
  };

  const handleClockUpdate = async (type) => {
    let payload;
  
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const address = await reverseGeocode(lat, lng);
          payload = {
            type: type,
            lat: lat,
            lng: lng,
            address: address,
            isMobileDevice:isMobileDevice
          };

          // Proceed with the API call after location is retrieved
          let response = await createAPI(state?.auth?.auth?.token, attendance_current_submit, payload);
          if (response.statusCode === 406) handleClockStatus();
        },
        (error) => {
          // Handle the case when the user denies location access
          if (error.code === error.PERMISSION_DENIED) {
            showErrorMsg("Refresh again allow permission to mark "+type+" or close and reopen the browser");
          }
        }
      );
    } else {
      showErrorMsg("Geolocation is not supported by your browser.");
    }
  };
  
  // Function to detect if the device is mobile
  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };


  function Logout(e) {
    e.preventDefault();
    dispatch(logOutSuccess(state?.auth?.isLoggedIn === false));
    localStorage.clear();
    navigate("/");

    //console.log("Logout function called");
  }
  const removeHTMLTags = (input) => input?.replace(/<\/?[^>]+(>|$)/g, "");
  const [specificUser, setSpecificUser] = useState([]);

  const getSpecificUser = async () => {
    const token = state?.auth?.auth?.token;
    const id = state?.auth?.auth?._id;
    const data = await GetUser(token, id);
    if (data && data.statusCode === 200) {
      setSpecificUser(data?.data);
    } else {
      //console.log("Error while getting user details");
    }
  };

  const [notification, setNotification] = useState([]);
  const getNotifications = async () => {
    const token = state?.auth?.auth?.token;
    const role = state?.auth?.auth?.role;
    const data = await GetNotification(
      token,
      role === "superadmin" ? "admin" : role
      // role === "superadmin" ? "superadmin" : role
    );
    // console.log(data, "80");
    if (data && data.statusCode === 200) {
      // console.log(data,"test")
      setNotification(data.data);
    } else {
      //console.log("Error while getting user details");
    }
  };

  const handleSubmitUpdate = async (e, userId) => {
    e.preventDefault();
    let Payload;
    if (state?.auth?.auth?.role === "subadmin") {
      Payload = {
        _id: userId,
        subadmin_read: true,
      };
    } else if (state?.auth?.auth?.role === "user") {
      Payload = {
        _id: userId,
        user_read: true,
      };
    } else {
      Payload = {
        _id: userId,
        admin_read: true,
      };
    }
    const token = state.auth.auth.token;

    const response = await UpdateNotification(Payload, token);
    // console.log(response ,"response")
    if (response && response.statusCode === 200) {
      getNotifications();
    } else {
      //console.log("error");
    }
  };

  let lenAdmin = notification?.filter((e) => {
    return e.admin_read === false;
  });
  let lenSubadmin = notification?.filter((e) => {
    return e.subadmin_read === false;
  });
  let lenUser = notification?.filter((e) => {
    return e.user_read === false;
  });

  const remove = () => {
    localStorage?.removeItem("general");
    localStorage?.removeItem("job");
    localStorage?.removeItem("account");
  };

  const submitMark = async (e) => {
    e.preventDefault();
    let Payload;
    if (state?.auth?.auth?.role === "superadmin") {
      Payload = {
        markAdmin: true,
      };
    } else if (state?.auth?.auth?.role === "admin") {
      Payload = {
        markSubadmin: true,
      };
    } else if (state?.auth?.auth?.role === "subadmin") {
      Payload = {
        markSubadmin: true,
      };
    } else {
      Payload = {
        markUser: true,
      };
    }

    const token = state.auth.auth.token;

    const response = await Markread(Payload, token);

    if (response && response.statusCode === 200) {
      getNotifications();
    } else {
      //console.log("error");
    }
  };

  return (
    <div className="admin">
      <ToastContainer />
      <div className="topbar">
        <div className="conainer">
          <div className="topbar-content-bg d-flex justify-content-between align-items-center">
            {/* <div>{title ? <h5 className="fw-bold">{title}</h5> : null}</div> */}
            <div>
              {
                state?.auth?.auth?.role === "subadmin" && (
                  <>
                    {
                      clockStatus.clock_in_time && (
                        <>
                          Today Clock In at
                          <span>
                            {' ' + clockStatus.clock_in_time.slice(8, 10) + '/' + // Day
                              clockStatus.clock_in_time.slice(5, 7) + '/' + // Month
                              clockStatus.clock_in_time.slice(2, 4) + ' ' + // Year
                              clockStatus.clock_in_time.slice(11, 16)+'. '}
                          </span>
                          Your are <u>{clockStatus.current_status==='clock_out'?'Clocked Out':'Clocked In'}</u>
                        </>
                      )
                    }
                  </>
                )
              }

            </div>
            <div className="d-flex justify-content-between  align-items-center">
              <div>
                {state?.auth?.auth?.role === "user" ? (
                  <NotificationBadge
                    count={lenUser?.length}
                    effect={Effect.SCALE}
                  />
                ) : (
                  ""
                )}
                {state?.auth?.auth?.role === "superadmin" ||
                  state?.auth?.auth?.role === "admin" ? (
                  <NotificationBadge
                    count={lenAdmin?.length}
                    effect={Effect.SCALE}
                  />
                ) : (
                  ""
                )}

                {state?.auth?.auth?.role === "subadmin" ? (
                  <NotificationBadge
                    count={lenSubadmin?.length}
                    effect={Effect.SCALE}
                  />
                ) : (
                  ""
                )}
                {
                  state?.auth?.auth?.role === "subadmin" && (
                    <>
                      {clockStatus.current_status !== 'clock_in' ?
                        <button className="btn" onClick={() => handleClockUpdate('clock_in')}>Clock In</button> :
                        <button className="btn" onClick={() => handleClockUpdate('clock_out')}>Clock Out</button>}
                    </>
                  )
                }
                

                <span
                  className="material-symbols-rounded cursor  align-middle "
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  notifications
                </span>
                <ul
                  style={{
                    width: "fit-content",
                    inset: "0px auto auto -13px !important",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                  }}
                  className="dropdown-menu top-drop-menu "
                  aria-labelledby="dropdownMenuButton1"
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <h5 className="m-2 fw-bold">Notifications</h5>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="markalign" onClick={(e) => submitMark(e)}>
                        Mark all as read
                      </h6>
                    </div>
                  </div>

                  <hr className="mb-0" />
                  <div style={{ height: "50vh", overflowY: "auto" }}>
                    {
                      // console.log(notification)
                    }
                    {notification?.map((e, index) => {
                      // console.log(notification,"notification")

                      let path;
                      switch (e?.path) {
                        case "/MyTask":
                          if (state?.auth?.auth?.role === "superadmin") {
                            path = "/TaskEdit/" + e?.redirect_id;
                            break;
                          } else {
                            path = "/MyTaskEdit/" + e?.redirect_id;
                            break;
                          }

                        case "/serviceRequest":
                          path = "/ServiceRequestEdit/" + e?.redirect_id;

                          break;

                        case "/JobApplicationEdit":
                          path = "/JobApplicationEdit/" + e?.redirect_id;
                          // console.log("/JobApplicationEdit" + e?.redirect_id,"test")
                          break;
                        case "/JobEdit":
                          path = "/JobEdit/" + e?.redirect_id;
                          // console.log("/JobEdit" + e?.redirect_id)
                          break;
                        case "/LeaveEdit":
                          path = "/LeaveEdit/" + e?.redirect_id;
                          // console.log("/LeaveEdit" + e?.redirect_id)
                          break;

                        case "/Lead":
                          path = "/LeadEdit/" + e?.redirect_id;
                          break;
                        default:
                          path = e?.path;
                          break;
                      }
                      return (
                        <li
                          key={index}
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              e.admin_read === true ||
                                e.subadmin_read === true ||
                                e.user_read === true
                                ? ""
                                : "#e4e1e1",
                          }}
                          onClick={(val) => {
                            if (
                              !e.admin_read ||
                              !e.subadmin_read ||
                              !e.user_read
                            ) {
                              handleSubmitUpdate(val, e._id);
                            }
                          }}
                        >
                          <Link className="dropdown-item" to={path}>
                            <div className="row">
                              <p className="col-6 color-maroon fw-bold mb-0">
                                <div className="d-flex align-items-center">
                                  {e?.path !== "/JobEdit" &&
                                    e?.path !== "/JobApplicationEdit" &&
                                    e?.path !== "/LeaveEdit" ? (
                                    <span className="material-symbols-rounded filled-icon mr-2">
                                      person
                                    </span>
                                  ) : (
                                    ""
                                  )}

                                  <span style={{ fontSize: "13px" }}>
                                    {e?.path === "/JobEdit" ? (
                                      <>
                                        {" "}
                                        <p className="mb-0">
                                          {" "}
                                          New Job Created&#128188;
                                        </p>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {e?.path === "/JobApplicationEdit" ? (
                                      <p className="mb-0">
                                        Job Application&#128233;
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    {e?.path === "/LeaveEdit" ? (
                                      <p className="mb-0">
                                        Leave Request&#128204;
                                      </p>
                                    ) : (
                                      ""
                                    )}

                                    <p className="mb-0">
                                      <span>
                                        {/* {removeHTMLTags(e.name)} */}
                                        {e.name}{" "}
                                      </span>
                                    </p>
                                  </span>
                                </div>
                              </p>
                              <p className="col-6 mb-0 small text-end">
                                {moment(e.createdAt).format("DD-MM-YYYY")}{" "}
                                {moment(e.createdAt).format("h:mm:s a")}
                              </p>
                            </div>
                            <h6
                              className="col-6 small"
                              style={{
                                marginLeft: "20px",
                                textTransform: "capitalize",
                              }}
                            >
                              Department : {e.title}
                            </h6>
                            <h6
                              className="small"
                              style={{ marginLeft: "20px" }}
                            >
                              <span>Type : </span>
                              <span
                                style={{
                                  textWrap: "wrap",
                                  textTransform: "capitalize",
                                }}
                              >
                                {/* {e.type} */}
                                {e.type?.length >= 55
                                  ? removeHTMLTags(
                                    e.type?.substring(0, 50) + "...."
                                  )
                                  : removeHTMLTags(e.type)}
                              </span>
                            </h6>
                            <h6
                              className="small"
                              style={{ marginLeft: "20px" }}
                            >
                              <span>Message : </span>
                              <span
                                style={{
                                  textWrap: "wrap",
                                  textTransform: "capitalize",
                                }}
                              >
                                {e.message?.length >= 55
                                  ? removeHTMLTags(
                                    e.message?.substring(0, 50) + "...."
                                  )
                                  : removeHTMLTags(e.message)}
                              </span>
                            </h6>
                          </Link>
                          <hr className="my-0" />
                        </li>
                      );
                    })}
                  </div>
                </ul>
              </div>
              <div>
                <span
                  className="material-symbols-rounded cursor  mx-4 align-middle "
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  language
                </span>
              </div>
              <div>
                {!specificUser?.profileImage ? (
                  <>
                    <h4
                      id="dropdownMenuButton2"
                      data-bs-toggle="dropdown"
                      style={{
                        width: "50px",
                        height: "50px",
                        textAlign: "center",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      className="admin-pro-img"
                    >
                      {GetInitials(specificUser?.name)}
                    </h4>
                    <ul
                      style={{ width: "100px", cursor: "pointer" }}
                      className="dropdown-menu top-drop-menu m-2 "
                      aria-labelledby="dropdownMenuButton2"
                    >
                      <h5
                        className="text-center"
                        onClick={(e) => {
                          Swal.fire({
                            title: "Are you sure?",
                            text: "You want to logout !",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#540326",
                            cancelButtonColor: "#e08638",
                            confirmButtonText: "Yes, logout",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              Logout(e);
                            }
                          });
                        }}
                      >
                        logout
                      </h5>
                    </ul>
                  </>
                ) : (
                  <img
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    className="admin-pro-img"
                    style={{ width: "50px", height: "50px", cursor: "pointer" }}
                    // src={
                    //   specificUser?.profileImage
                    //     ? specificUser?.profileImage
                    //     : localStorage?.getItem("image")
                    // }
                    src={
                      localStorage?.getItem("image")
                        ? localStorage?.getItem("image")
                        : specificUser?.profileImage
                    }
                    alt=""
                  />
                )}
                <ul
                  style={{ width: "100px", cursor: "pointer" }}
                  className="dropdown-menu top-drop-menu m-2 "
                  aria-labelledby="dropdownMenuButton2"
                >
                  <h5
                    className="text-center"
                    onClick={(e) => {
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You want to logout !",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#540326",
                        cancelButtonColor: "#e08638",
                        confirmButtonText: "Yes, logout",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          Logout(e);
                        }
                      });
                    }}
                  >
                    logout
                  </h5>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="responsive-topbar">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container d-flex justify-content-between">
            <div className="dashboard-logo d-flex align-items-center justify-content-center">
              <NavLink className="res-logo-img" to={"/"}>
                {" "}
                <img className="logo-img" src={Logo} alt="" />
              </NavLink>
            </div>
            <div className="d-flex">
              {/* <div className="btn btn-default top-btn-desk">
                <span className="material-symbols-rounded menu-icon">
                  search
                </span>
              </div> */}
              <button
                onClick={() => {
                  setShow(true);
                }}
                className="btn btn-default top-btn-desk"
                id="menu-toggle"
              >
                <span className="material-symbols-rounded menu-icon">menu</span>
              </button>
            </div>
          </div>
        </nav>
      </div>
      <div className={Show ? "sidenav active" : "sidenav"}>
        <div className="container">
          <div className="sidenav-logo">
            <NavLink className="res-logo-img" to={"/"}>
              {" "}
              <img className="logo-img" src={Logo} alt="" />
            </NavLink>
            <button
              onClick={() => {
                setShow(false);
              }}
              className="btn btn-default closemenu-toggle"
              id="menu-toggle"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
        </div>
        <div className="side-content-bg">
          <ul>
            {state?.auth?.auth?.role === "user" ||
              state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.Dashboard === true ||
              state?.auth?.auth?.role === "subadmin" ? (
              <NavLink to="/Dashboard">
                <span className="material-symbols-rounded filled-icon">
                  dashboard
                </span>
                <li onClick={() => remove()}>Dashboard</li>
              </NavLink>
            ) : (
              ""
            )}
            {state?.auth?.auth?.role === "user" ||
              state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.RevenueDashboard === true ||
              state?.auth?.auth?.role === "subadmin" ? (
              <NavLink to="/revenue-dashboard">
                <span className="material-symbols-rounded filled-icon">
                  dashboard
                </span>
                <li onClick={() => remove()}>Revenue Dashboard</li>
              </NavLink>
            ) : (
              ""
            )}
            {state?.auth?.auth?.role === "user" ||
              state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.RevenueDashboard === true ||
              state?.auth?.auth?.role === "subadmin" ? (
              <NavLink to="/attendance-dashboard">
                <span className="material-symbols-rounded filled-icon">
                  dashboard
                </span>
                <li onClick={() => remove()}>Attendance Dashboard</li>
              </NavLink>
            ) : (
              ""
            )}
            {
              // state?.auth?.auth?.role === "user" ||
              state?.auth?.auth?.role === "superadmin" //||
                // specificUser?.pageAccess?.pageAccess?.Dashboard === true ||
                // state?.auth?.auth?.role === "subadmin" 
                ? (
                  <NavLink to="/admin/employee-clock-in-out">
                    <span className="material-symbols-rounded filled-icon">
                      dashboard
                    </span>
                    <li onClick={() => remove()}>Employee Clock In/Out</li>
                  </NavLink>
                ) : (
                  ""
                )}
            {state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.TaskList === true ? (
              <NavLink to="/TaskList">
                <span className="material-symbols-rounded filled-icon">
                  task
                </span>
                <li onClick={() => remove()}>Global Task List</li>
              </NavLink>
            ) : (
              ""
            )}
            {specificUser?.pageAccess?.pageAccess?.MyTask === true ||
              state?.auth?.auth?.role === "subadmin" ||
              state?.auth?.auth?.role === "superadmin" ||
              state?.auth?.auth?.role === "admin" ? (
              <NavLink to="/MyTask">
                <span className="material-symbols-rounded filled-icon">
                  task
                </span>
                <li onClick={() => remove()}>My Task</li>
              </NavLink>
            ) : (
              ""
            )}

            {state?.auth?.auth?.role === "superadmin" ||
              state?.auth?.auth?.role === "subadmin" ||
              specificUser?.pageAccess?.pageAccess?.LeaveApprove === true ? (
              <NavLink to="/LeaveModule">
                <div className="figure">
                  <img className="pi-icon" src={SI1} alt="" />
                  <img className="pi-icon image-hover" src={SIhover1} alt="" />
                </div>
                <li onClick={() => remove()}>Leave Management</li>
              </NavLink>
            ) : (
              ""
            )}

            {state?.auth?.auth?.role === "user" ||
              specificUser?.pageAccess?.pageAccess?.ServiceRequest === true ||
              state?.auth?.auth?.role === "superadmin" ? (
              <NavLink className="dropdown-item" to="/ServiceRequest">
                <div className="figure">
                  <img className="pi-icon" src={AddI1} alt="" />
                  <img className="pi-icon image-hover" src={Addhover1} alt="" />
                </div>
                <li onClick={() => remove()}>Service Request</li>
              </NavLink>
            ) : (
              ""
            )}

            {state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.cms === true ? (
              // ||
              // specificUser?.pageAccess?.pageAccess?.AdminManagement === true  ||
              <div className="dropdown drop-admin-nav">
                <a
                  className=" dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink6"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="figure">
                    <img className="pi-icon" src={AddI4} alt="" />
                    <img
                      className="pi-icon image-hover"
                      src={Addhover4}
                      alt=""
                    />
                  </div>
                  <li>CMS</li>
                </a>

                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink6"
                >
                  <li>
                    {state?.auth?.auth?.role === "superadmin" ||
                      specificUser?.pageAccess?.pageAccess?.cms === true ? (
                      <NavLink className="dropdown-item" to="/Main">
                        <div className="figure">
                          <img className="pi-icon" src={AddI4} alt="" />
                          <img
                            className="pi-icon image-hover"
                            src={Addhover4}
                            alt=""
                          />
                        </div>
                        <li onClick={() => remove()}>Main</li>
                      </NavLink>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    {state?.auth?.auth?.role === "superadmin" ||
                      specificUser?.pageAccess?.pageAccess?.cms === true ? (
                      <NavLink className="dropdown-item" to="/Level1">
                        <div className="figure">
                          <img className="pi-icon" src={AddI5} alt="" />
                          <img
                            className="pi-icon image-hover"
                            src={Addhover5}
                            alt=""
                          />
                        </div>
                        <li onClick={() => remove()}>Level 1</li>
                      </NavLink>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    {state?.auth?.auth?.role === "superadmin" ||
                      specificUser?.pageAccess?.pageAccess?.cms === true ? (
                      <NavLink className="dropdown-item" to="/Level2">
                        <div className="figure">
                          <img className="pi-icon" src={AddI5} alt="" />
                          <img
                            className="pi-icon image-hover"
                            src={Addhover5}
                            alt=""
                          />
                        </div>
                        <li onClick={() => remove()}>Level 2</li>
                      </NavLink>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    {state?.auth?.auth?.role === "superadmin" ||
                      specificUser?.pageAccess?.pageAccess?.cms === true ? (
                      <NavLink className="dropdown-item" to="/Content">
                        <div className="figure">
                          <img className="pi-icon" src={AddI5} alt="" />
                          <img
                            className="pi-icon image-hover"
                            src={Addhover5}
                            alt=""
                          />
                        </div>
                        <li onClick={() => remove()}>Content</li>
                      </NavLink>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}

            {state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.AdminRole === true ||
              specificUser?.pageAccess?.pageAccess?.AdminManagement === true ? (
              <div className="dropdown drop-admin-nav">
                <a
                  className=" dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="figure">
                    <img className="pi-icon" src={AddI4} alt="" />
                    <img
                      className="pi-icon image-hover"
                      src={Addhover4}
                      alt=""
                    />
                  </div>
                  <li>Admin Management</li>
                </a>

                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    {state?.auth?.auth?.role === "superadmin" ||
                      specificUser?.pageAccess?.pageAccess?.AdminRole === true ? (
                      <NavLink
                        className="dropdown-item"
                        to="/AdminRoleManagement"
                      >
                        <div className="figure">
                          <img className="pi-icon" src={AddI4} alt="" />
                          <img
                            className="pi-icon image-hover"
                            src={Addhover4}
                            alt=""
                          />
                        </div>
                        <li onClick={() => remove()}>Admin Role</li>
                      </NavLink>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    {state?.auth?.auth?.role === "superadmin" ||
                      specificUser?.pageAccess?.pageAccess?.AdminManagement ===
                      true ? (
                      <NavLink className="dropdown-item" to="/AdminManagement">
                        <div className="figure">
                          <img className="pi-icon" src={AddI5} alt="" />
                          <img
                            className="pi-icon image-hover"
                            src={Addhover5}
                            alt=""
                          />
                        </div>
                        <li onClick={() => remove()}>Admin Management</li>
                      </NavLink>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}

            {state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.EmployeeRole === true ||
              specificUser?.pageAccess?.pageAccess?.EmployeeManagement ===
              true ? (
              <div className="dropdown drop-admin-nav">
                <a
                  className=" dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="figure">
                    <img className="pi-icon" src={AddI4} alt="" />
                    <img
                      className="pi-icon image-hover"
                      src={Addhover4}
                      alt=""
                    />
                  </div>
                  <li onClick={() => remove()}>Employee Management</li>
                </a>

                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink1"
                >
                  <li>
                    {state?.auth?.auth?.role === "superadmin" ||
                      specificUser?.pageAccess?.pageAccess?.EmployeeRole ===
                      true ? (
                      <NavLink
                        className="dropdown-item"
                        to="/EmployeeRoleManagement"
                      >
                        <div className="figure">
                          <img className="pi-icon" src={AddI4} alt="" />
                          <img
                            className="pi-icon image-hover"
                            src={Addhover4}
                            alt=""
                          />
                        </div>
                        <li onClick={() => remove()}>Employee Role</li>
                      </NavLink>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    {state?.auth?.auth?.role === "superadmin" ||
                      specificUser?.pageAccess?.pageAccess?.EmployeeManagement ===
                      true ? (
                      <NavLink
                        className="dropdown-item"
                        to="/EmployeeManagement"
                      >
                        <div className="figure">
                          <img className="pi-icon" src={AddI3} alt="" />
                          <img
                            className="pi-icon image-hover"
                            src={Addhover3}
                            alt=""
                          />
                        </div>
                        <li onClick={() => remove()}>Employee Management</li>
                      </NavLink>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}

            {state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.UserManagement === true ||
              specificUser?.pageAccess?.pageAccess?.NewsLetter === true ||
              specificUser?.pageAccess?.pageAccess?.ContactUsers === true ? (
              <div className="dropdown drop-admin-nav">
                <a
                  className=" dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="figure">
                    <img className="pi-icon" src={AddI4} alt="" />
                    <img
                      className="pi-icon image-hover"
                      src={Addhover4}
                      alt=""
                    />
                  </div>
                  <li onClick={() => remove()}>User Management</li>
                </a>

                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    {state?.auth?.auth?.role === "superadmin" ||
                      specificUser?.pageAccess?.pageAccess?.UserManagement ===
                      true ? (
                      <NavLink to="/UserManagement">
                        <span className="material-symbols-rounded filled-icon">
                          person
                        </span>
                        <li onClick={() => remove()}>User Management</li>
                      </NavLink>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    {state?.auth?.auth?.role === "superadmin" ||
                      specificUser?.pageAccess?.pageAccess?.NewsLetter ===
                      true ? (
                      <NavLink to="/NewsLetter">
                        <div className="figure">
                          <img className="pi-icon" src={AddI4} alt="" />
                          <img
                            className="pi-icon image-hover"
                            src={Addhover4}
                            alt=""
                          />
                        </div>
                        <li onClick={() => remove()}>News Letter</li>
                      </NavLink>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    {state?.auth?.auth?.role === "superadmin" ||
                      specificUser?.pageAccess?.pageAccess?.ContactUsers ===
                      true ? (
                      <NavLink to="/ContactUsers">
                        <span className="material-symbols-rounded filled-icon">
                          person
                        </span>
                        <li onClick={() => remove()}>Contact Users</li>
                      </NavLink>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
            {state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.PropertyAuction === true ||
              specificUser?.pageAccess?.pageAccess?.GoldAuction === true ||
              specificUser?.pageAccess?.pageAccess?.VehicleAuction === true
              // || specificUser?.pageAccess?.pageAccess?.FourWheeler === true 
              ? (
                <div className="dropdown drop-admin-nav">
                  <a
                    className=" dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink4"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="figure">
                      <img className="pi-icon" src={AddI4} alt="" />
                      <img
                        className="pi-icon image-hover"
                        src={Addhover4}
                        alt=""
                      />
                    </div>
                    <li onClick={() => remove()}>Auction</li>
                  </a>

                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink4"
                  >
                    <li>
                      {state?.auth?.auth?.role === "superadmin" ||
                        specificUser?.pageAccess?.pageAccess?.PropertyAuction ===
                        true ? (
                        <NavLink to="/Property">
                          <div className="figure">
                            <img className="pi-icon" src={AddI3} alt="" />
                            <img
                              className="pi-icon image-hover"
                              src={Addhover3}
                              alt=""
                            />
                          </div>
                          <li onClick={() => remove()}>Property Auction</li>
                        </NavLink>
                      ) : (
                        ""
                      )}
                    </li>
                    <li>
                      {state?.auth?.auth?.role === "superadmin" ||
                        specificUser?.pageAccess?.pageAccess?.GoldAuction ===
                        true ? (
                        <NavLink className="dropdown-item" to="/GoldAuction">
                          <div className="figure">
                            <img className="pi-icon" src={SI1} alt="" />
                            <img
                              className="pi-icon image-hover"
                              src={SIhover1}
                              alt=""
                            />
                          </div>
                          <li onClick={() => remove()}>Gold Auction</li>
                        </NavLink>
                      ) : (
                        ""
                      )}
                    </li>
                    <li>
                      {state?.auth?.auth?.role === "superadmin" ||
                        specificUser?.pageAccess?.pageAccess?.VehicleAuction === true ||
                        specificUser?.pageAccess?.pageAccess?.FourWheeler ===
                        true ? (
                        <NavLink
                          className="dropdown-item"
                          to="/Vehicle"
                        >
                          <div className="figure">
                            <img className="pi-icon" src={SI1} alt="" />
                            <img
                              className="pi-icon image-hover"
                              src={SIhover1}
                              alt=""
                            />
                          </div>
                          <li onClick={() => remove()}>Vehicle Auction</li>
                        </NavLink>
                      ) : (
                        ""
                      )}
                    </li>
                  </ul>
                </div>
              ) : (
                ""
              )}

            {state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.ServicePackage === true ||
              specificUser?.pageAccess?.pageAccess?.Package === true ? (
              <NavLink to="/ServicePackage">
                <div className="figure">
                  <img className="pi-icon" src={AddI2} alt="" />
                  <img className="pi-icon image-hover" src={Addhover2} alt="" />
                </div>
                <li onClick={() => remove()}>Subscription Management</li>
              </NavLink>
            ) : (
              ""
            )}

            {state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.JobManagement === true ? (
              <NavLink to="/JobManagement">
                <div className="figure">
                  <img className="pi-icon" src={AddI4} alt="" />
                  <img className="pi-icon image-hover" src={Addhover4} alt="" />
                </div>
                <li onClick={() => remove()}>Job Management</li>
              </NavLink>
            ) : (
              // (
              //   ""
              // )}

              // {state?.auth?.auth?.role === "superadmin" ||
              // specificUser?.pageAccess?.pageAccess?.PaymentServices === true ||
              // specificUser?.pageAccess?.pageAccess?.PaymentPackages === true ?
              //  (
              //   <div className="dropdown drop-admin-nav">
              //     <a
              //       className=" dropdown-toggle"
              //       href="#"
              //       role="button"
              //       id="dropdownMenuLink3"
              //       data-bs-toggle="dropdown"
              //       aria-expanded="false"
              //     >
              //       <div className="figure">
              //         <img className="pi-icon" src={AddI4} alt="" />
              //         <img
              //           className="pi-icon image-hover"
              //           src={Addhover4}
              //           alt=""
              //         />
              //       </div>
              //       <li onClick={()=>remove()}>Subscription Payment</li>
              //     </a>

              //     <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink3">
              //       <li>
              //         {state?.auth?.auth?.role === "superadmin" ||
              //         specificUser?.pageAccess?.pageAccess?.PaymentServices ===
              //           true ? (
              //           <NavLink className="dropdown-item" to="/PaymentServices">
              //             <div className="figure">
              //               <img className="pi-icon" src={AddI3} alt="" />
              //               <img
              //                 className="pi-icon image-hover"
              //                 src={Addhover3}
              //                 alt=""
              //               />
              //             </div>
              //             <li onClick={()=>remove()}>Payment Services</li>
              //           </NavLink>
              //         ) : (
              //           ""
              //         )}
              //       </li>
              //       <li>
              //         {state?.auth?.auth?.role === "superadmin" ||
              //         specificUser?.pageAccess?.pageAccess?.PaymentPackages ===
              //           true ? (
              //           <NavLink className="dropdown-item" to="/PaymentPackages">
              //             <div className="figure">
              //               <img className="pi-icon" src={AddI3} alt="" />
              //               <img
              //                 className="pi-icon image-hover"
              //                 src={Addhover3}
              //                 alt=""
              //               />
              //             </div>
              //             <li onClick={()=>remove()}>Payment Packages</li>
              //           </NavLink>
              //         ) : (
              //           ""
              //         )}
              //       </li>
              //     </ul>
              //   </div>
              // )
              //  :
              ""
            )}
            {state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.ScheduleMeeting === true ? (
              <NavLink to="/Schedule-Meeting-Registration">
                <div className="figure">
                  <img className="pi-icon" src={AddI1} alt="" />
                  <img className="pi-icon image-hover" src={Addhover1} alt="" />
                </div>
                <li onClick={() => remove()}>Schedule Meeting</li>
              </NavLink>
            ) : (
              ""
            )}
            {state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.Lead === true ? (
              <NavLink to="/Lead">
                <div className="figure">
                  <img className="pi-icon" src={AddI1} alt="" />
                  <img className="pi-icon image-hover" src={Addhover1} alt="" />
                </div>
                <li onClick={() => remove()}>Leads</li>
              </NavLink>
            ) : (
              ""
            )}
            {state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.PartnerWithUs === true ? (
              <NavLink to="/partner-with-us">
                <div className="figure">
                  <img className="pi-icon" src={AddI1} alt="" />
                  <img className="pi-icon image-hover" src={Addhover1} alt="" />
                </div>
                <li onClick={() => remove()}>Partner With Us</li>
              </NavLink>
            ) : (
              ""
            )}
            {state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.PartnerWithUs === true ? (
              <NavLink to="/become-entrepreneur">
                <div className="figure">
                  <img className="pi-icon" src={AddI1} alt="" />
                  <img className="pi-icon image-hover" src={Addhover1} alt="" />
                </div>
                <li onClick={() => remove()}>Become Entrepreneur</li>
              </NavLink>
            ) : (
              ""
            )}
            {state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.WebsiteLeads === true ? (
              <NavLink to="/website-leads">
                <div className="figure">
                  <img className="pi-icon" src={AddI1} alt="" />
                  <img className="pi-icon image-hover" src={Addhover1} alt="" />
                </div>
                <li onClick={() => remove()}>Website Leads</li>
              </NavLink>
            ) : (
              ""
            )}
            {state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.auctionLeads === true ? (
              <NavLink to="/auction-leads">
                <div className="figure">
                  <img className="pi-icon" src={AddI1} alt="" />
                  <img className="pi-icon image-hover" src={Addhover1} alt="" />
                </div>
                <li onClick={() => remove()}>Auction Leads</li>
              </NavLink>
            ) : (
              ""
            )}
            {state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.auctionPurchaseHistory === true ? (
              <NavLink to="/auction-purchase-history">
                <div className="figure">
                  <img className="pi-icon" src={AddI1} alt="" />x1
                  <img className="pi-icon image-hover" src={Addhover1} alt="" />
                </div>
                <li onClick={() => remove()}>Auction Purchase History</li>
              </NavLink>
            ) : (
              ""
            )}
            {state?.auth?.auth?.role === "user" ? (
              <NavLink to="/Purchase">
                <div className="figure">
                  <img className="pi-icon" src={AI1} alt="" />
                  <img className="pi-icon image-hover" src={AIhover1} alt="" />
                </div>
                <li onClick={() => remove()}>Package</li>
              </NavLink>
            ) : (
              ""
            )}
            {/* {state?.auth?.auth?.role === "user" ||
            specificUser?.pageAccess?.pageAccess?.Package === true ? (
              <NavLink to="/Purchase">
                <div className="figure">
                  <img className="pi-icon" src={AI1} alt="" />
                  <img className="pi-icon image-hover" src={AIhover1} alt="" />
                </div>
                <li onClick={() => remove()}>Package</li>
              </NavLink>
            ) : (
              ""
            )} */}
            {state?.auth?.auth?.role === "user" ||
              specificUser?.pageAccess?.pageAccess?.History === true ? (
              <NavLink to="/History">
                <div className="figure">
                  <img className="pi-icon" src={AI3} alt="" />
                  <img className="pi-icon image-hover" src={AIhover3} alt="" />
                </div>
                <li onClick={() => remove()}>Purchase History</li>
              </NavLink>
            ) : (
              ""
            )}
            {state?.auth?.auth?.role === "superadmin" ? (
              <NavLink to="/History">
                <div className="figure">
                  <img className="pi-icon" src={AI3} alt="" />
                  <img className="pi-icon image-hover" src={AIhover3} alt="" />
                </div>
                <li onClick={() => remove()}>Purchase History</li>
              </NavLink>
            ) : (
              ""
            )}

            {/* {state?.auth?.auth?.role === "superadmin" ||
            specificUser?.pageAccess?.pageAccess?.Pricing === true ? (
              <NavLink to="/Pricing">
                <div className="figure">
                  <img className="pi-icon" src={AddI2} alt="" />
                  <img className="pi-icon image-hover" src={Addhover2} alt="" />
                </div>
                <li>Price List</li>
              </NavLink>
            ) : (
              ""
            )} */}

            {state?.auth?.auth?.role === "superadmin" ||
              specificUser?.pageAccess?.pageAccess?.LogManagement === true ? (
              <NavLink to="/LogManagement">
                <div className="figure">
                  <img className="pi-icon" src={AddI7} alt="" />
                  <img className="pi-icon image-hover" src={Addhover7} alt="" />
                </div>
                <li onClick={() => remove()}>Log Management</li>
              </NavLink>
            ) : (
              ""
            )}

            {state?.auth?.auth?.role === "user" ||
              specificUser?.pageAccess?.pageAccess?.MyServices === true ? (
              <NavLink to="/MyServices">
                <div className="figure">
                  <img className="pi-icon" src={SI1} alt="" />
                  <img className="pi-icon image-hover" src={SIhover1} alt="" />
                </div>
                <li onClick={() => remove()}>Purchased Services</li>
              </NavLink>
            ) : (
              ""
            )}

            {state?.auth?.auth?.role === "user" ||
              specificUser?.pageAccess?.pageAccess?.ProfileManagement === true ||
              state?.auth?.auth?.role === "subadmin" ||
              state?.auth?.auth?.role === "superadmin" ? (
              <NavLink to="/Profile">
                <div className="figure">
                  <img className="pi-icon" src={AI4} alt="" />
                  <img className="pi-icon image-hover" src={AIhover4} alt="" />
                </div>
                <li onClick={() => remove()}>Profile Management</li>
              </NavLink>
            ) : (
              ""
            )}

            <a
              href="javascript:void(0);"
              className="logout_text"
              onClick={(e) => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "You want to logout !",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#540326",
                  cancelButtonColor: "#e08638",
                  confirmButtonText: "Yes, logout",
                }).then((result) => {
                  if (result.isConfirmed) {
                    Logout(e);
                  }
                });
              }}
              id="logout-hover"
            >
              <span className="material-symbols-rounded">logout</span>
              <li>Logout</li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
}
