import React from 'react';
import logo from '../../../Assets/dalmaf-vertical-logo.png';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faUser, faTachometerAlt, faList, faUserFriends, faDollarSign, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './FreelancerPartnerNav.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { logOutSuccess } from '../../../Store/auth/action';
import Swal from "sweetalert2";

const FreelancerPartnerNav = ({ showFullNav, onToggle,userTypeProps,routeProps }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = useSelector((state) => state);

  const roleType = state?.auth?.auth?.role;
console.log('userTypeProps',userTypeProps)
  const isDashboardActive = location.pathname.includes(userTypeProps+'/dashboard');
  const isTaskActive = location.pathname.includes(userTypeProps+'/task');
  const isClientActive = location.pathname.includes(userTypeProps+'/client');
  const isLeadActive = location.pathname.includes(userTypeProps+'/lead');
  const isEarningsActive = location.pathname.includes(userTypeProps+'/my-earning');

  const Logout = (e) => {
    e.preventDefault();
    dispatch(logOutSuccess(state?.auth?.isLoggedIn === false));
    localStorage.clear();
    navigate("/");
  }

  return (
    <>
      <div className={`${styles.topNav} ${showFullNav ? styles.topNavBig : styles.topNavShort}`}>
        <div className={styles.topNavIcons}>
          <div className={styles.topNavNotificationIcon}>
            <FontAwesomeIcon icon={faBell} />
            <span className={styles.notificationBadge}>3</span>
          </div>
          <NavDropdown
            title={
              <span>
                <FontAwesomeIcon icon={faUser} size="lg" className="me-2" />
                {state?.auth?.auth?.name}
              </span>
            }
            id="company-dropdown"
          >
            <NavDropdown.Item as={NavLink} to={`/${userTypeProps}/update-Profile`}>Update Profile</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to={`/${userTypeProps}/update-password`}>Change Password</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} onClick={(e) => {
              Swal.fire({
                title: "Are you sure?",
                text: "You want to logout !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#540326",
                cancelButtonColor: "#e08638",
                confirmButtonText: "Yes, logout",
              }).then((result) => {
                if (result.isConfirmed) {
                  Logout(e);
                }
              });
            }}>Logout</NavDropdown.Item>
          </NavDropdown>
        </div>
      </div>

      <div className={`${styles.sideNav} ${showFullNav ? styles.show : styles.short}`}>
        <div className={styles.header}>
          <Navbar.Brand
            className={styles.navbarBrand}
            onClick={() => navigate("/")}
            style={{cursor:'pointer'}}
          >
            <img
              className={styles.logoImg}
              src={logo}
              alt="Logo"
            />
          </Navbar.Brand>
          <div className={styles.toggleButton} onClick={onToggle}>
            <FontAwesomeIcon icon={faBars} size="lg" className={styles.toggleIcon} />
          </div>
        </div>
        {!showFullNav && <div className={styles.navItems} style={{ cursor: 'pointer' }} onClick={onToggle}>
          <FontAwesomeIcon icon={faBars} size="lg" className={styles.toggleIcon} />
        </div>}
        <Nav className={styles.navItems}>
          <Nav.Link as={NavLink} to={`/${userTypeProps}/dashboard`} className={`${styles.navLink} ${isDashboardActive ? styles.active : ''}`}>
            <FontAwesomeIcon icon={faTachometerAlt} size="lg" className={styles.navIcon} />
            <span className={styles.navText}>Dashboard</span>
          </Nav.Link>
          <Nav.Link as={NavLink} to={`/${userTypeProps}/task/my-task-list`} className={`${styles.navLink} ${isTaskActive ? styles.active : ''}`}>
            <FontAwesomeIcon icon={faList} size="lg" className={styles.navIcon} />
            <span className={styles.navText}>My Task List</span>
          </Nav.Link>
          <Nav.Link as={NavLink} to={`/${userTypeProps}/client/my-client-list`} className={`${styles.navLink} ${isClientActive ? styles.active : ''}`}>
            <FontAwesomeIcon icon={faUser} size="lg" className={styles.navIcon} />
            <span className={styles.navText}>My Clients</span>
          </Nav.Link>
          <Nav.Link as={NavLink} to={`/${userTypeProps}/lead/my-lead-list`} className={`${styles.navLink} ${isLeadActive ? styles.active : ''}`}>
            <FontAwesomeIcon icon={faUserFriends} size="lg" className={styles.navIcon} />
            <span className={styles.navText}>My Leads</span>
          </Nav.Link>
          <Nav.Link as={NavLink} to={`/${userTypeProps}/my-earning`} className={`${styles.navLink} ${isEarningsActive ? styles.active : ''}`}>
            <FontAwesomeIcon icon={faDollarSign} size="lg" className={styles.navIcon} />
            <span className={styles.navText}>My Earnings</span>
          </Nav.Link>
          {
            roleType === 'partner' &&
            <Nav.Link as={NavLink} to={`/${userTypeProps}/employee-management`} className={`${styles.navLink} ${isEarningsActive ? styles.active : ''}`}>
              <FontAwesomeIcon icon={faDollarSign} size="lg" className={styles.navIcon} />
              <span className={styles.navText}>Employee Management</span>
            </Nav.Link>
          }
          {
            roleType === 'partner' &&
            <Nav.Link as={NavLink} to={`/${userTypeProps}/leave-management`} className={`${styles.navLink} ${isEarningsActive ? styles.active : ''}`}>
              <FontAwesomeIcon icon={faDollarSign} size="lg" className={styles.navIcon} />
              <span className={styles.navText}>Leave Management</span>
            </Nav.Link>
          }
          <Nav.Link as={NavLink} onClick={(e) => {
            Swal.fire({
              title: "Are you sure?",
              text: "You want to logout !",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#540326",
              cancelButtonColor: "#e08638",
              confirmButtonText: "Yes, logout",
            }).then((result) => {
              if (result.isConfirmed) {
                Logout(e);
              }
            });
          }} className={styles.navLink}>
            <FontAwesomeIcon icon={faSignOutAlt} size="lg" className={styles.navIcon} />
            <span className={styles.navText}>Logout</span>
          </Nav.Link>
        </Nav>
      </div>
    </>
  );
};

export default FreelancerPartnerNav;
