import { useEffect, useState } from "react";
import CreateLeadComponent from "../../../../Components/v1/FreelancerPartner/Leads/CreateLeadComponent";
import FreelancerPartnerNav from "../../../../Components/v1/NavBar/FreelancerPartnerNav";
import CreateContactComponent from "../../../../Components/v1/FreelancerPartner/Leads/CreateContactComponent";


export default function CreateContact({ userTypeProps, lableProps,isClient,routeProps }) {
  useEffect(() => { 
    window.scroll(0, 0)
  }, [])

  const [show, setShow] = useState(true);
  const handleToggle = () => {setShow(!show);};


  return (
    <>
      <FreelancerPartnerNav showFullNav={show} onToggle={handleToggle} userTypeProps={userTypeProps} routeProps={routeProps}/>
      <CreateContactComponent showFullNav={show} lableProps={lableProps} userTypeProps={userTypeProps} isClient={isClient} routeProps={routeProps}/>
    </>
  );
}
