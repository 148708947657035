//React Default packageImport
import React, { useState, useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";

//Reusable Component Import
import CustomModal from "../CustomModal";
import {EmailValidation,PasswordValidation,PhoneNumberValidation,RePasswordValidation,StringValidation,NonEmptyValidation} from "../../Store/validate";
import { HandleCCAvenuePaymentPkg, HandleCCAvenuePaymentPkgNL } from "../../Store/util";
import { showToastErrorMsg,showToastSuccessMsg } from "../../Store/toastMsg";
import {loginSuccess,registerDetails,registerDetailsDelete} from "../../Store/auth/action";
//API Include
import { SignUp,ChangePassword,ForgotPassword,GetUser,VerifyOtp,ForgotOtp,UserLogin } from "../../Api/Api"


//Assets Import
import SplImg from "../../Assets/Images/special-offer-img.webp";
import Norecord from "../../Assets/Images/MicrosoftTeams-image.webp";


export function LoginSignPass(props) {

    //---------------------------- Array Starts 
    const [userProfile, setUserProfile] = useState([]);
    //---------------------------- Array Ends
    //---------------------------- Boolean Starts 
    const [loginModal, setloginModal] = useState(false);
    const [BookNowForm, setBookNowForm] = useState(false);
    const [createModal, setcreateModal] = useState(false);
    const [fpwModal, setfpwModal] = useState(false);
    const [otpModal, setotpModal] = useState(false);
    const [otpFgtModal, setotpFgtModal] = useState(false);
    const [changepswModal, setchangepswModal] = useState(false);
    const [openTag, setOpenTag] = useState(true);
    //---------------------------- Boolean Ends 
    //---------------------------- Numberic Starts 
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    //---------------------------- Numberic Ends 
    //---------------------------- String Starts 
      const [code, setOtp] = useState("");
      const [passwordStrength, setPasswordStrength] = useState("");
      const [password, setPassword] = useState();
    //---------------------------- String Ends
    //---------------------------- Object Starts 
    const [validation, setValidation] = useState({});
    const [registerDetail1, setRegisterDetails1] = useState({});
    const [registerDetail, setRegisterDetails] = useState({});
    const [registerValidation1, setRegisterValidation1] = useState({});
    const [registerDetail2, setRegisterDetails2] = useState({});
    const [registerValidation2, setRegisterValidation2] = useState({});
    const [registerValidation, setRegisterValidation] = useState({})
    const [registerDetail3, setRegisterDetails3] = useState({});
    const [registerValidation3, setRegisterValidation3] = useState({});
    //---------------------------- Object Ends
    //Props
    //Props
    const { onClickOutside, open, children } = props;
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (open) {
            document.documentElement.classList.add("modal-open");
        } else {
            document.documentElement.classList.remove("modal-open");
        }
    }, [open]);
 
    //---------------------------- User Defined Functions
    //---------------------------- Change Password
    
    const getUserProfile = async () => {
        const id = state?.auth?.auth?._id;
        const token = state.auth.auth.token;
        const response = await GetUser(token, id);
        if (response.statusCode === 200) {
          setUserProfile(response.data);
        } else {
          //console.log("error while getting user profile info");
        }
      };
    const setRegisterDetailsValue = (key, value) => {
      setRegisterDetails({ ...registerDetail, [key]: value });
      if (registerValidation[key]) delete registerValidation[key];
    };

    //Otp Submit
  const handleChange = (code) => {
    setOtp(code);
    setValidation({});
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let validate = {};
    validate.name = StringValidation(registerDetail?.name);
    validate.email = EmailValidation(registerDetail?.email?.toLowerCase());
    validate.password = PasswordValidation(registerDetail?.password);
    validate.phoneNumber = PhoneNumberValidation(registerDetail?.phoneNumber);
    setRegisterValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        name: registerDetail.name,
        email: registerDetail.email?.toLowerCase(),
        password: registerDetail.password,
        phoneNumber: registerDetail.phoneNumber,
        role: "user",
      };

      const response = await SignUp(Payload);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setcreateModal(false);
        setRegisterDetails({});
        setRegisterValidationValue({});
        setotpModal(true);
        dispatch(registerDetails(registerDetail));
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while register as user");
    }
  };
  const setRegisterValidationValue = (key, value) => {
    setRegisterValidation({ ...registerValidation, [key]: value });
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.email = EmailValidation(registerDetail1?.email?.toLowerCase());
    validate.password = PasswordValidation(registerDetail1?.password);
    setRegisterValidation1(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: registerDetail1.email?.toLowerCase(),
        password: registerDetail1.password,
      };

      const response = await UserLogin(Payload);
      if (response && response.statusCode === 200) {
        dispatch(loginSuccess(response.data, response?.data?.token));
        if (localStorage.getItem("tab")) {
          navigate(`/${localStorage.getItem("tab")}`);
          setloginModal(false);
        } else {
          setloginModal(false);
          navigate("/");
        }

        setRegisterDetails1({});
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while login as user");
    }
  };
  const handleSubmitForgotPassword = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.email = EmailValidation(registerDetail2?.email?.toLowerCase());
    setRegisterValidation2(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: registerDetail2.email?.toLowerCase(),
      };

      const response = await ForgotPassword(Payload);
      if (response && response.statusCode === 200) {
        dispatch(registerDetailsDelete(registerDetail2));
        showToastSuccessMsg(response.message);
        setfpwModal(false);
        setotpFgtModal(true);
        setRegisterDetails2({});
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while reset password");
    }
  };
  
    const setRegisterDetailsValue2 = (key, value) => {
      setRegisterDetails2({ ...registerDetail2, [key]: value });
      if (registerValidation2[key]) delete registerValidation2[key];
    };
  
    const setRegisterValidationValue2 = (key, value) => {
      setRegisterValidation2({ ...registerValidation2, [key]: value });
    };
    const setRegisterDetailsValue1 = (key, value) => {
        setRegisterDetails1({ ...registerDetail1, [key]: value });
        if (registerValidation1[key]) delete registerValidation1[key];
      };
    
      const setRegisterValidationValue1 = (key, value) => {
        setRegisterValidation1({ ...registerValidation1, [key]: value });
      };
    const viewPassword = () => {
        setPassword(!password);
      };
  const setRegisterDetailsValue3 = (key, value) => {
    setRegisterDetails3({ ...registerDetail3, [key]: value });
    if (registerValidation3[key]) delete registerValidation3[key];
  };

  const setRegisterValidationValue3 = (key, value) => {
    setRegisterValidation3({ ...registerValidation3, [key]: value });
  };
    const handleSubmitChangePassword = async (e) => {
        e.preventDefault();
        let validate = {};
        validate.password = PasswordValidation(registerDetail3?.password);
        validate.re_password = RePasswordValidation(
          registerDetail3?.re_password,
          registerDetail3?.password
        );
        setRegisterValidation3(validate);
        if (Object.values(validate).every((v) => v.status === true)) {
          const Payload = {
            email: state.auth.registerData.email,
            password: registerDetail3?.password,
          };
    
          const response = await ChangePassword(Payload);
          if (response && response.statusCode === 200) {
            showToastSuccessMsg(response.message);
            setchangepswModal(false);
            setloginModal(true);
            setRegisterDetails3({});
            getUserProfile();
          } else {
            showToastErrorMsg(
              response.message || response.error.response.data.message
            );
            //setfailureModal(true);
          }
        } else {
          //console.log("Error while chenge password");
        }
      };
      const handleSubmitOtpFgt = async (data) => {
        data.preventDefault();
        let validate = {};
        validate.code = NonEmptyValidation(code);
        setValidation(validate);
        if (Object.values(validate).every((v) => v.status === true)) {
          const Payload = {
            email: state.auth.registerData.email,
            otp: parseInt(code),
          };
          let response = await ForgotOtp(Payload);
          if (response && response.statusCode === 200) {
            showToastSuccessMsg(response.message);
            setotpFgtModal(false);
            setchangepswModal(true);
            setOtp("");
          } else {
            showToastErrorMsg(
              response.message || response.error.response.data.message
            );
            // setFailureModal(true);
          }
        }
        
      };

      const handleSubmitResend = async (e) => {
        e.preventDefault();
        const Payload = {
          name: state.auth.registerData.name,
          email: state.auth.registerData.email,
          password: state.auth.registerData.password,
          phoneNumber: state.auth.registerData.phoneNumber,
          role: "user",
        };
    
        const response = await SignUp(Payload);
        if (response && response.statusCode === 200) {
          showToastSuccessMsg(response.message);
          setMinutes(0);
          setSeconds(59);
        } else {
          showToastErrorMsg(
            response.message || response.error.response.data.message
          );
          //setfailureModal(true);
        }
      };

      const handleSubmitOtp = async (data, value) => {
        data.preventDefault();
        let validate = {};
        validate.code = NonEmptyValidation(code);
        setValidation(validate);
        if (Object.values(validate).every((v) => v.status === true)) {
          const Payload = {
            email: state.auth.registerData.email,
            otp: parseInt(code),
          };
          let response = await VerifyOtp(Payload);
          if (response && response.statusCode === 200) {
            setotpModal(false);
            setOtp("");
            setloginModal(true);
            showToastSuccessMsg(response.message);
          } else {
            showToastErrorMsg(
              response.message || response.error.response.data.message
            );
            // setFailureModal(true);
          }
        } else {
          //console.log("Error while verify otp");
        }
      };
     


    return (
        <>
            <CustomModal
                open={open}
                onClickOutside={() => {
                    setloginModal(false);
                }}
            >
                <div className="modal-content w-50 m-auto">
                    <div className="modal-body p-0 ">
                        <div className="container ">
                            <span
                                className="material-symbols-rounded filled-icon close-icon"
                                style={{
                                    marginRight: "30px",
                                    marginTop: "50px",
                                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                                }}
                                onClick={() => {
                                    setloginModal(false);
                                }}
                            >
                                close
                            </span>
                            <div className="row d-flex align-items-center justify-content-center  mt-0">
                                <div className="container ">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className=" my-5 px-3">
                                                <div className="row justify-content-center mt-2">
                                                    <div className="col-lg-10">
                                                        <div className="mb-5">
                                                            <h4 className="fw-bold mt-2">Login</h4>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label for="" className="form-label">
                                                                Email
                                                            </label>

                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                placeholder="example@mail.com"
                                                                required
                                                                aria-invalid={Boolean(
                                                                    registerValidation1?.email?.status === false
                                                                )}
                                                                defaultValue={
                                                                    registerDetail1?.email?.toLowerCase() || ""
                                                                }
                                                                onChange={(e) => {
                                                                    setRegisterDetailsValue1(
                                                                        "email",
                                                                        e.target.value?.toLowerCase()
                                                                    );
                                                                }}
                                                                onBlur={(e) => {
                                                                    setRegisterValidationValue1(
                                                                        "email",
                                                                        EmailValidation(
                                                                            e.target.value?.toLowerCase()
                                                                        )
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            <small className="text-danger">
                                                                {registerValidation1?.email?.message
                                                                    ? `Email ${registerValidation1?.email?.message}`
                                                                    : ""}
                                                            </small>
                                                        </div>
                                                        <div className="mb-3 psw-icon-section">
                                                            <label for="" className="form-label">
                                                                Password
                                                            </label>
                                                            <input
                                                                type={password ? "text" : "password"}
                                                                className="form-control"
                                                                required
                                                                placeholder="******"
                                                                aria-invalid={Boolean(
                                                                    registerValidation1?.password?.status ===
                                                                    false
                                                                )}
                                                                defaultValue={registerDetail1?.password || ""}
                                                                onChange={(e) => {
                                                                    setRegisterDetailsValue1(
                                                                        "password",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                onBlur={(e) => {
                                                                    setRegisterValidationValue1(
                                                                        "password",
                                                                        PasswordValidation(e.target.value)
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            <small className="text-danger">
                                                                {registerValidation1?.password?.message
                                                                    ? `Password ${registerValidation1?.password?.message}`
                                                                    : ""}
                                                            </small>
                                                            <div className="psw-icon">
                                                                {password ? (
                                                                    <span
                                                                        className="material-symbols-rounded cursor align-middle"
                                                                        onClick={() => viewPassword()}
                                                                    >
                                                                        visibility
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className="material-symbols-rounded cursor align-middle"
                                                                        onClick={() => viewPassword()}
                                                                    >
                                                                        visibility_off
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <button
                                                                type="button"
                                                                className="btn  w-100 my-2  login-btn"
                                                                onClick={(e) => handleSubmit1(e)}
                                                            >
                                                                Login
                                                            </button>
                                                        </div>
                                                        <div className="mb-3 ">
                                                            <p className="text-center mb-1">
                                                                <span className="text-secondary">
                                                                    Don’t have account? ?{" "}
                                                                </span>
                                                                <span
                                                                    className="mx-2 cursor text-decoration-underline"
                                                                    onClick={() => {
                                                                        setloginModal(false);
                                                                        setcreateModal(true);
                                                                        setRegisterDetails1({});
                                                                        setRegisterValidation1({});
                                                                    }}
                                                                >
                                                                    Sign Up
                                                                </span>{" "}
                                                            </p>
                                                            <p className="text-center">
                                                                <span className="text-secondary">
                                                                    Forgot your password?
                                                                </span>
                                                                <span
                                                                    className="mx-2 cursor text-decoration-underline"
                                                                    onClick={() => {
                                                                        setloginModal(false);
                                                                        setfpwModal(true);
                                                                        setRegisterDetails1({});
                                                                        setRegisterValidation1({});
                                                                    }}
                                                                >
                                                                    Reset
                                                                </span>{" "}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomModal>
            <CustomModal
                open={createModal}
                onClickOutside={() => {
                    setcreateModal(false);
                    setRegisterValidation({});
                    setfpwModal(false);
                    setotpModal(false);
                    setloginModal(false);
                    setchangepswModal(false);
                }}
            >
                <div className="modal-content w-50 h-50  m-auto">
                    <div className="modal-body p-0 ">
                        <div className="container">
                            <span
                                className="material-symbols-rounded filled-icon close-icon"
                                style={{
                                    marginRight: "30px",
                                    marginTop: "50px",
                                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                                }}
                                onClick={() => {
                                    setcreateModal(false);
                                }}
                            >
                                close
                            </span>
                            <div className="row d-flex align-items-center justify-content-center  mt-0">
                                <div className="container ">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className=" my-5 px-3">
                                                <div className="row justify-content-center mt-2">
                                                    {/* <form> */}
                                                    <div className="col-lg-10">
                                                        <div className="mb-5">
                                                            <h4 className="fw-bold mt-2">
                                                                Create an account
                                                            </h4>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label for="" className="form-label">
                                                                Name
                                                            </label>
                                                            <input
                                                                // id="name"
                                                                // name="name"
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter your Name"
                                                                required
                                                                aria-invalid={Boolean(
                                                                    registerValidation?.name?.status === false
                                                                )}
                                                                defaultValue={registerDetail?.name || ""}
                                                                onChange={(e) => {
                                                                    setRegisterDetailsValue(
                                                                        "name",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                onBlur={(e) => {
                                                                    setRegisterValidationValue(
                                                                        "name",
                                                                        StringValidation(e.target.value)
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            <small className="text-danger">
                                                                {registerValidation?.name?.message
                                                                    ? `Name ${registerValidation?.name?.message}`
                                                                    : ""}
                                                            </small>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label for="" className="form-label">
                                                                Email
                                                            </label>
                                                            <input
                                                                // id="email"
                                                                type="email"
                                                                className="form-control"
                                                                placeholder="example@mail.com"
                                                                required
                                                                aria-invalid={Boolean(
                                                                    registerValidation?.email?.status === false
                                                                )}
                                                                defaultValue={
                                                                    registerDetail?.email?.toLowerCase() || ""
                                                                }
                                                                onChange={(e) => {
                                                                    setRegisterDetailsValue(
                                                                        "email",
                                                                        e.target.value?.toLowerCase()
                                                                    );
                                                                }}
                                                                onBlur={(e) => {
                                                                    setRegisterValidationValue(
                                                                        "email",
                                                                        EmailValidation(
                                                                            e.target.value?.toLowerCase()
                                                                        )
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            <small className="text-danger">
                                                                {registerValidation?.email?.message
                                                                    ? `Email ${registerValidation?.email?.message}`
                                                                    : ""}
                                                            </small>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label for="" className="form-label">
                                                                Phone No
                                                            </label>
                                                            <input
                                                                // id="phoneNumber"
                                                                type="tel"
                                                                className="form-control"
                                                                maxLength={10}
                                                                placeholder="Phone Number"
                                                                required
                                                                aria-invalid={Boolean(
                                                                    registerValidation?.phoneNumber?.status ===
                                                                    false
                                                                )}
                                                                defaultValue={
                                                                    registerDetail?.phoneNumber || ""
                                                                }
                                                                onChange={(e) => {
                                                                    setRegisterDetailsValue(
                                                                        "phoneNumber",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                onBlur={(e) => {
                                                                    setRegisterValidationValue(
                                                                        "phoneNumber",
                                                                        PhoneNumberValidation(e.target.value)
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            <small className="text-danger">
                                                                {registerValidation?.phoneNumber?.message
                                                                    ? `Phone no ${registerValidation?.phoneNumber?.message}`
                                                                    : ""}
                                                            </small>
                                                        </div>
                                                        <div className="mb-3 psw-icon-section">
                                                            <label for="" className="form-label">
                                                                Password
                                                            </label>
                                                            <input
                                                                // id="password"
                                                                type={password ? "text" : "password"}
                                                                className="form-control"
                                                                required
                                                                placeholder="*********"
                                                                aria-invalid={Boolean(
                                                                    registerValidation?.password?.status ===
                                                                    false
                                                                )}
                                                                defaultValue={registerDetail?.password || ""}
                                                                onChange={(e) => {
                                                                    setRegisterDetailsValue(
                                                                        "password",
                                                                        e.target.value
                                                                    );
                                                                    setPasswordStrength(e.target.value);
                                                                }}
                                                                onBlur={(e) => {
                                                                    setRegisterValidationValue(
                                                                        "password",
                                                                        PasswordValidation(e.target.value)
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                            <small className="text-danger">
                                                                {registerValidation?.password?.message
                                                                    ? `Password ${registerValidation?.password?.message}`
                                                                    : ""}
                                                            </small>
                                                            <div className="psw-icon">
                                                                {password ? (
                                                                    <span
                                                                        className="material-symbols-rounded cursor align-middle"
                                                                        onClick={() => viewPassword()}
                                                                    >
                                                                        visibility
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        className="material-symbols-rounded cursor align-middle"
                                                                        onClick={() => viewPassword()}
                                                                    >
                                                                        visibility_off
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {passwordStrength ? (
                                                            <div className="mt-3">
                                                                <b>Create a password with the following</b>
                                                                <br />
                                                                {
                                                                    <p
                                                                        className={`${passwordStrength.trim().length < 8
                                                                            ? "text-danger"
                                                                            : "text-success"
                                                                            } d-flex`}
                                                                    >
                                                                        {passwordStrength.trim().length < 8 ? (
                                                                            <span className="material-symbols-rounded filled-icon close-icon">
                                                                                close
                                                                            </span>
                                                                        ) : (
                                                                            <span className="material-symbols-rounded filled-icon close-icon">
                                                                                check
                                                                            </span>
                                                                        )}
                                                                        At least 8 Characters
                                                                    </p>
                                                                }
                                                                {
                                                                    <p
                                                                        className={`${!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                                                            passwordStrength
                                                                        )
                                                                            ? "text-danger"
                                                                            : "text-success"
                                                                            } d-flex`}
                                                                    >
                                                                        {!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                                                            passwordStrength
                                                                        ) ? (
                                                                            <span className="material-symbols-rounded filled-icon close-icon">
                                                                                close
                                                                            </span>
                                                                        ) : (
                                                                            <span className="material-symbols-rounded filled-icon close-icon">
                                                                                check
                                                                            </span>
                                                                        )}
                                                                        An Uppercase &amp; Lowercase character
                                                                    </p>
                                                                }
                                                                {
                                                                    <p
                                                                        className={`${!/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                                                                            passwordStrength
                                                                        )
                                                                            ? "text-danger"
                                                                            : "text-success"
                                                                            } d-flex`}
                                                                    >
                                                                        {!/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                                                                            passwordStrength
                                                                        ) ? (
                                                                            <span className="material-symbols-rounded filled-icon close-icon">
                                                                                close
                                                                            </span>
                                                                        ) : (
                                                                            <span className="material-symbols-rounded filled-icon close-icon">
                                                                                check
                                                                            </span>
                                                                        )}
                                                                        A special character
                                                                    </p>
                                                                }
                                                                {
                                                                    <p
                                                                        className={`${!/[0-9]/.test(passwordStrength)
                                                                            ? "text-danger"
                                                                            : "text-success"
                                                                            } d-flex`}
                                                                    >
                                                                        {!/[0-9]/.test(passwordStrength) ? (
                                                                            <span className="material-symbols-rounded filled-icon close-icon">
                                                                                close
                                                                            </span>
                                                                        ) : (
                                                                            <span className="material-symbols-rounded filled-icon close-icon">
                                                                                check
                                                                            </span>
                                                                        )}
                                                                        A number
                                                                    </p>
                                                                }
                                                            </div>
                                                        ) : null}
                                                        <div className="mb-3">
                                                            <button
                                                                // type="button"
                                                                className="btn  w-100 my-2  login-btn"
                                                                onClick={(e) => handleSubmit(e)}
                                                            >
                                                                Create account
                                                            </button>
                                                        </div>
                                                        <div className="mb-3 ">
                                                            <p className="text-center mb-1">
                                                                <span className="text-secondary">
                                                                    Already have an account ?
                                                                </span>
                                                                <span
                                                                    className="mx-2 cursor text-decoration-underline"
                                                                    onClick={() => {
                                                                        setcreateModal(false);
                                                                        setloginModal(true);
                                                                        setRegisterDetails({});
                                                                        setRegisterValidation({});
                                                                    }}
                                                                >
                                                                    Log in
                                                                </span>{" "}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {/* </form> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomModal>
            <CustomModal
                open={fpwModal}
                onClickOutside={() => {
                    setfpwModal(false);
                    setRegisterValidation2({});
                    setcreateModal(false);
                    setotpModal(false);
                    setloginModal(false);
                    setchangepswModal(false);
                }}
            >
                <div className="modal-content w-50 m-auto">
                    <div className="modal-body p-0 ">
                        <div className="container">
                            <span
                                className="material-symbols-rounded filled-icon close-icon"
                                style={{
                                    marginRight: "30px",
                                    marginTop: "50px",
                                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                                }}
                                onClick={() => {
                                    setfpwModal(false);
                                }}
                            >
                                close
                            </span>
                            <div className="row d-flex align-items-center justify-content-center  mt-0">
                                <div className="container ">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className=" my-5 px-3">
                                                <div className="row justify-content-center mt-2">
                                                    <div className="col-lg-10">
                                                        <div className="mb-5">
                                                            <h4 className="fw-bold mt-2">
                                                                Forgot Password
                                                            </h4>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label for="email" className="form-label">
                                                                Email
                                                            </label>
                                                            <input
                                                                id="email"
                                                                type="email"
                                                                className="form-control"
                                                                placeholder="example@mail.com"
                                                                required
                                                                aria-invalid={Boolean(
                                                                    registerValidation2?.email?.status === false
                                                                )}
                                                                defaultValue={
                                                                    registerDetail2?.email?.toLowerCase() || ""
                                                                }
                                                                onChange={(e) => {
                                                                    setRegisterDetailsValue2(
                                                                        "email",
                                                                        e.target.value?.toLowerCase()
                                                                    );
                                                                }}
                                                                onBlur={(e) => {
                                                                    setRegisterValidationValue2(
                                                                        "email",
                                                                        EmailValidation(
                                                                            e.target.value?.toLowerCase()
                                                                        )
                                                                    );
                                                                }}
                                                            />
                                                            <small className="text-danger">
                                                                {registerValidation2?.email?.message
                                                                    ? `Email ${registerValidation2?.email?.message}`
                                                                    : ""}
                                                            </small>
                                                        </div>
                                                        <div className="mb-3">
                                                            <button
                                                                type="button"
                                                                className="btn  w-100 my-2  login-btn"
                                                                onClick={(e) => {
                                                                    handleSubmitForgotPassword(e);
                                                                }}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                        <div className="mb-3 ">
                                                            <p className="text-center mb-1">
                                                                <span className="text-secondary">
                                                                    Already have an account ?
                                                                </span>
                                                                <span
                                                                    className="mx-2 cursor text-decoration-underline"
                                                                    onClick={() => {
                                                                        setfpwModal(false);
                                                                        setloginModal(true);
                                                                        setRegisterDetails2({});
                                                                        setRegisterValidation2({});
                                                                    }}
                                                                >
                                                                    Log in
                                                                </span>{" "}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomModal>
            <CustomModal
                open={otpModal}
                onClickOutside={() => {
                    setotpModal(false);
                    setValidation({});
                    setcreateModal(false);
                    setfpwModal(false);
                    setloginModal(false);
                    setchangepswModal(false);
                }}
            >
                <div className="modal-content w-50 m-auto">
                    <div className="modal-body p-0 ">
                        <div className="container">
                            <span
                                className="material-symbols-rounded filled-icon close-icon"
                                style={{
                                    marginRight: "30px",
                                    marginTop: "50px",
                                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                                }}
                                onClick={() => {
                                    setotpModal(false);
                                }}
                            >
                                close
                            </span>
                            <div className="row d-flex align-items-center justify-content-center  mt-0">
                                <div className="container ">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className=" my-5 px-3">
                                                <div className="row justify-content-center mt-2">
                                                    <div className="col-lg-10">
                                                        <div className="mb-5">
                                                            <h4 className="fw-bold mt-3">
                                                                Verify your email ID
                                                            </h4>
                                                            <small>Enter the code we just sent to</small>
                                                        </div>
                                                        <div className="mb-4 psw-icon-section">
                                                            <OtpInput
                                                                className="otpInput"
                                                                value={code}
                                                                onChange={handleChange}
                                                                numInputs={6}
                                                                separator={
                                                                    <span style={{ width: "8px" }}></span>
                                                                }
                                                                isInputNum={true}
                                                                shouldAutoFocus={true}
                                                                inputStyle={{
                                                                    border: "1px solid #CFD3DB",
                                                                    borderRadius: "8px",
                                                                    width: "45px",
                                                                    height: "45px",
                                                                    fontSize: "12px",
                                                                    color: "#000",
                                                                    fontWeight: "400",
                                                                    caretColor: "blue",
                                                                }}
                                                                focusStyle={{
                                                                    border: "1px solid black ",
                                                                    outline: "none",
                                                                }}
                                                            />
                                                        </div>
                                                        <small className="text-danger">
                                                            {validation?.code?.message
                                                                ? `OTP ${validation?.code?.message}`
                                                                : ""}
                                                        </small>
                                                        {seconds > 0 || minutes > 0 ? (
                                                            <div className="top-12">
                                                                Resend OTP in&nbsp;
                                                                <span className="text-green">
                                                                    {minutes < 10 ? `0${minutes}` : minutes}:
                                                                    {seconds < 10 ? `0${seconds}` : seconds}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <div className="top-12">
                                                                Didn't receive code?&nbsp;
                                                                <span
                                                                    className="fgtpwd"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={(e) => handleSubmitResend(e)}
                                                                >
                                                                    Resend
                                                                </span>
                                                            </div>
                                                        )}
                                                        {/* <div className="mb-3 ">
                              <p
                                className="text-decoration-underline  text-pink"
                                style={{ cursor: "pointer" }}
                              >
                                Resend
                              </p>
                            </div> */}
                                                        <div className="mb-3">
                                                            <button
                                                                type="button"
                                                                className="btn  w-100 my-2  login-btn"
                                                                onClick={(e) => {
                                                                    if (
                                                                        localStorage.getItem(
                                                                            "accountcreation" === true
                                                                        )
                                                                    ) {
                                                                        handleSubmitOtp(e, "accountcreation");
                                                                    } else {
                                                                        handleSubmitOtp(e, "forgot");
                                                                    }

                                                                    // setchangepswModal(true);
                                                                }}
                                                            >
                                                                Verify
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomModal>
            <CustomModal
                open={otpFgtModal}
                onClickOutside={() => {
                    setotpFgtModal(false);
                    setValidation({});
                    setcreateModal(false);
                    setfpwModal(false);
                    setloginModal(false);
                    setchangepswModal(false);
                }}
            >
                <div className="modal-content w-50 m-auto">
                    <div className="modal-body p-0 ">
                        <div className="container">
                            <span
                                className="material-symbols-rounded filled-icon close-icon"
                                style={{
                                    marginRight: "30px",
                                    marginTop: "50px",
                                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                                }}
                                onClick={() => {
                                    setotpFgtModal(false);
                                }}
                            >
                                close
                            </span>
                            <div className="row d-flex align-items-center justify-content-center  mt-0">
                                <div className="container ">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className=" my-5 px-3">
                                                <div className="row justify-content-center mt-2">
                                                    <div className="col-lg-10">
                                                        <div className="mb-5">
                                                            <h4 className="fw-bold mt-3">
                                                                Verify your email ID
                                                            </h4>
                                                            <small>Enter the code we just sent to</small>
                                                        </div>
                                                        <div className="mb-4 psw-icon-section">
                                                            <OtpInput
                                                                className="otpInput"
                                                                value={code}
                                                                onChange={handleChange}
                                                                numInputs={6}
                                                                separator={
                                                                    <span style={{ width: "8px" }}></span>
                                                                }
                                                                isInputNum={true}
                                                                shouldAutoFocus={true}
                                                                inputStyle={{
                                                                    border: "1px solid #CFD3DB",
                                                                    borderRadius: "8px",
                                                                    width: "45px",
                                                                    height: "45px",
                                                                    fontSize: "12px",
                                                                    color: "#000",
                                                                    fontWeight: "400",
                                                                    caretColor: "blue",
                                                                }}
                                                                focusStyle={{
                                                                    border: "1px solid black ",
                                                                    outline: "none",
                                                                }}
                                                            />
                                                        </div>
                                                        <small className="text-danger">
                                                            {validation?.code?.message
                                                                ? `OTP ${validation?.code?.message}`
                                                                : ""}
                                                        </small>
                                                        {seconds > 0 || minutes > 0 ? (
                                                            <div className="top-12">
                                                                Resend OTP in&nbsp;
                                                                <span className="text-green">
                                                                    {minutes < 10 ? `0${minutes}` : minutes}:
                                                                    {seconds < 10 ? `0${seconds}` : seconds}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <div className="top-12">
                                                                Didn't receive code?&nbsp;
                                                                <span
                                                                    className="fgtpwd"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={(e) => handleSubmitResend(e)}
                                                                >
                                                                    Resend
                                                                </span>
                                                            </div>
                                                        )}
                                                        {/* <div className="mb-3 ">
                              <p
                                className="text-decoration-underline  text-pink"
                                style={{ cursor: "pointer" }}
                              >
                                Resend
                              </p>
                            </div> */}
                                                        <div className="mb-3">
                                                            <button
                                                                type="button"
                                                                className="btn  w-100 my-2  login-btn"
                                                                onClick={(e) => {
                                                                    handleSubmitOtpFgt(e);
                                                                }}
                                                            >
                                                                Verify
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomModal>
            <CustomModal
                open={changepswModal}
                onClickOutside={() => {
                    setchangepswModal(false);
                    setRegisterValidation3({});
                    setcreateModal(false);
                    setfpwModal(false);
                    setotpModal(false);
                    setloginModal(false);
                }}
            >
                <div className="modal-content w-50 m-auto">
                    <div className="modal-body p-0 ">
                        <div className="container">
                            <span
                                className="material-symbols-rounded filled-icon close-icon"
                                style={{
                                    marginRight: "30px",
                                    marginTop: "50px",
                                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                                }}
                                onClick={() => {
                                    setchangepswModal(false);
                                }}
                            >
                                close
                            </span>
                            <div className="row d-flex align-items-center justify-content-center  mt-0">
                                <div className="container ">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className=" my-5 px-3">
                                                <div className="row justify-content-center mt-2">
                                                    <div className="col-lg-10">
                                                        <div className="mb-5">
                                                            <h4 className="fw-bold mt-3">
                                                                Change Password
                                                            </h4>
                                                        </div>
                                                        <div className="mb-3 ">
                                                            <label for="password" className="form-label">
                                                                New Password
                                                            </label>
                                                            <input
                                                                id="password"
                                                                type="password"
                                                                className="form-control"
                                                                placeholder="*********"
                                                                required
                                                                aria-invalid={Boolean(
                                                                    registerValidation3?.password?.status ===
                                                                    false
                                                                )}
                                                                onChange={(e) => {
                                                                    setRegisterDetailsValue3(
                                                                        "password",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                onBlur={(e) => {
                                                                    setRegisterValidationValue3(
                                                                        "password",
                                                                        PasswordValidation(e.target.value)
                                                                    );
                                                                }}
                                                            />
                                                            <small className="text-danger">
                                                                {registerValidation3?.password?.message
                                                                    ? `Password ${registerValidation3?.password?.message}`
                                                                    : ""}
                                                            </small>
                                                        </div>
                                                        <div className="mb-3 ">
                                                            <label for="" className="form-label">
                                                                Re-Enter Password
                                                            </label>
                                                            <input
                                                                type="password"
                                                                className="form-control"
                                                                placeholder="example@mail.com"
                                                                required
                                                                aria-invalid={Boolean(
                                                                    registerValidation3?.re_password?.status ===
                                                                    false
                                                                )}
                                                                onChange={(e) => {
                                                                    setRegisterDetailsValue3(
                                                                        "re_password",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                onBlur={(e) => {
                                                                    setRegisterValidationValue3(
                                                                        "re_password",
                                                                        RePasswordValidation(
                                                                            e.target.value,
                                                                            registerDetail3?.password
                                                                        )
                                                                    );
                                                                }}
                                                            />
                                                            <small className="text-danger">
                                                                {registerValidation3?.re_password?.message
                                                                    ? `Re-Password ${registerValidation3?.re_password?.message}`
                                                                    : ""}
                                                            </small>
                                                        </div>
                                                        <div className="mb-3">
                                                            <button
                                                                type="button"
                                                                className="btn  w-100 my-2  login-btn"
                                                                onClick={(e) => {
                                                                    handleSubmitChangePassword(e);
                                                                }}
                                                            >
                                                                Change
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomModal>
        </>
    )
};