import React, { useEffect } from 'react';
import AuctionBanner from '../Components/Auction/AuctionBanner'
import AuctionContent from '../Components/Auction/AuctionContent'
import Footer from '../Components/Footer/Footer'
import Navigation from '../Components/Navbar/Navigation'

export default function Auction() {
    useEffect(() =>{
        window.scroll(0,0)
      },[])

  return (
    <div className="innerpages-nav">
      <div  id="container">
      <Navigation/>
      <AuctionBanner/>
      <AuctionContent/>
      <Footer/>
      </div>
      
    </div>
  )
}
