import React, { useState } from 'react';
import styles from './UpdateProfileComponent.module.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default function UpdateProfileComponent({ showFullNav }) {
    const [formData, setFormData] = useState({
        profileImage: '',
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        pan: '',
        aadhaar: '',
        bankName: '',
        accountName: '',
        accountNumber: '',
        confirmAccountNumber: '',
        ifsc: ''
    });

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let formErrors = {};
        if (!formData.firstName) formErrors.firstName = 'First name is required';
        if (!formData.lastName) formErrors.lastName = 'Last name is required';
        if (!formData.email) formErrors.email = 'Email is required';
        if (!formData.mobile) formErrors.mobile = 'Mobile number is required';
        if (!formData.pan) formErrors.pan = 'PAN is required';
        if (!formData.panName) formErrors.panName = 'Name on PAN is required';
        if (!formData.aadhaar) formErrors.aadhaar = 'Aadhaar is required';
        if (!formData.aadhaarName) formErrors.aadhaarName = 'Name on Aadhaar is required';
        if (!formData.bankName) formErrors.bankName = 'Bank name is required';
        if (!formData.accountName) formErrors.accountName = 'Account name is required';
        if (!formData.accountNumber) formErrors.accountNumber = 'Account number is required';
        if (formData.accountNumber !== formData.confirmAccountNumber) formErrors.confirmAccountNumber = 'Account numbers do not match';
        if (!formData.ifsc) formErrors.ifsc = 'IFSC code is required';
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, profileImage: e.target.files[0] });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Handle profile update logic here
            console.log('Profile updated successfully', formData);
        }
    };

    return (
        <>
            <div className={showFullNav ? styles.container : styles.containerSmallNavBar}>
                <Tabs>
                    <TabList>
                        <Tab>Basic Profile</Tab>
                        <Tab>KYC & Bank Details</Tab>
                    </TabList>
                    <TabPanel>
                        <form onSubmit={handleSubmit} className={styles.form}>
                            <div className='row'>
                                <div className='col-md-6 col-lg-6 col-sm-12'>
                                    <div className={styles.formGroup}>
                                        <div className={styles.inputContainer}>
                                            <input
                                                type="text"
                                                id="firstName"
                                                name="firstName"
                                                value={formData.firstName}
                                                onChange={handleInputChange}
                                                className={`${styles.input} ${errors.firstName ? styles.errorInput : ''}`}
                                                placeholder=" "
                                            />
                                            <label htmlFor="firstName" className={`${styles.placeholder} ${formData.firstName ? styles.filled : ''}`}>
                                                First Name
                                            </label>
                                        </div>
                                        {errors.firstName && <span className={styles.error}>{errors.firstName}</span>}
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6 col-sm-12'>
                                    <div className={styles.formGroup}>
                                        <div className={styles.inputContainer}>
                                            <input
                                                type="text"
                                                id="lastName"
                                                name="lastName"
                                                value={formData.lastName}
                                                onChange={handleInputChange}
                                                className={`${styles.input} ${errors.lastName ? styles.errorInput : ''}`}
                                                placeholder=" "
                                            />
                                            <label htmlFor="lastName" className={`${styles.placeholder} ${formData.lastName ? styles.filled : ''}`}>
                                                Last Name
                                            </label>
                                        </div>
                                        {errors.lastName && <span className={styles.error}>{errors.lastName}</span>}
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6 col-sm-12'>
                                    <div className={styles.formGroup}>
                                        <div className={styles.inputContainer}>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                className={`${styles.input} ${errors.email ? styles.errorInput : ''}`}
                                                placeholder=" "
                                            />
                                            <label htmlFor="email" className={`${styles.placeholder} ${formData.email ? styles.filled : ''}`}>
                                                Email
                                            </label>
                                        </div>
                                        {errors.email && <span className={styles.error}>{errors.email}</span>}
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6 col-sm-12'>
                                    <div className={styles.formGroup}>
                                        <div className={styles.inputContainer}>
                                            <input
                                                type="number"
                                                id="mobile"
                                                name="mobile"
                                                value={formData.mobile}
                                                onChange={handleInputChange}
                                                className={`${styles.input} ${errors.mobile ? styles.errorInput : ''}`}
                                                placeholder=" "
                                            />
                                            <label htmlFor="mobile" className={`${styles.placeholder} ${formData.mobile ? styles.filled : ''}`}>
                                                Mobile
                                            </label>
                                        </div>
                                        {errors.mobile && <span className={styles.error}>{errors.mobile}</span>}
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className={styles.updateButton}>Update Profile</button>
                        </form>
                    </TabPanel>
                    <TabPanel>
                        <form onSubmit={handleSubmit} className={styles.form}>
                            <div className='row'>
                                <div className='col-md-4 col-lg-4 col-sm-12'>
                                    <div className={styles.formGroup}>
                                        <div className={styles.inputContainer}>
                                            <input
                                                type="text"
                                                id="pan"
                                                name="pan"
                                                value={formData.pan}
                                                onChange={handleInputChange}
                                                className={`${styles.input} ${errors.pan ? styles.errorInput : ''}`}
                                                placeholder=" "
                                            />
                                            <label htmlFor="pan" className={`${styles.placeholder} ${formData.pan ? styles.filled : ''}`}>
                                                PAN
                                            </label>
                                        </div>
                                        {errors.pan && <span className={styles.error}>{errors.pan}</span>}
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-12'>
                                    <div className={styles.formGroup}>
                                        <div className={styles.inputContainer}>
                                            <input
                                                type="text"
                                                id="panName"
                                                name="panName"
                                                value={formData.panName}
                                                onChange={handleInputChange}
                                                className={`${styles.input} ${errors.panName ? styles.errorInput : ''}`}
                                                placeholder=" "
                                            />
                                            <label htmlFor="panName" className={`${styles.placeholder} ${formData.panName ? styles.filled : ''}`}>
                                                Name on the PAN
                                            </label>
                                        </div>
                                        {errors.panName && <span className={styles.error}>{errors.panName}</span>}
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-12'>
                                    <div className={styles.formGroup}>
                                        <div className={styles.inputContainer}>
                                            <input
                                                type="text"
                                                id="aadhaar"
                                                name="aadhaar"
                                                value={formData.aadhaar}
                                                onChange={handleInputChange}
                                                className={`${styles.input} ${errors.aadhaar ? styles.errorInput : ''}`}
                                                placeholder=" "
                                            />
                                            <label htmlFor="aadhaar" className={`${styles.placeholder} ${formData.aadhaar ? styles.filled : ''}`}>
                                                Aadhaar No
                                            </label>
                                        </div>
                                        {errors.aadhaar && <span className={styles.error}>{errors.aadhaar}</span>}
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-12'>
                                    <div className={styles.formGroup}>
                                        <div className={styles.inputContainer}>
                                            <input
                                                type="text"
                                                id="aadhaarName"
                                                name="aadhaarName"
                                                value={formData.aadhaarName}
                                                onChange={handleInputChange}
                                                className={`${styles.input} ${errors.aadhaarName ? styles.errorInput : ''}`}
                                                placeholder=" "
                                            />
                                            <label htmlFor="aadhaarName" className={`${styles.placeholder} ${formData.aadhaarName ? styles.filled : ''}`}>
                                                Name on Aadhaar
                                            </label>
                                        </div>
                                        {errors.aadhaarName && <span className={styles.error}>{errors.aadhaarName}</span>}
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-12'>
                                    <div className={styles.formGroup}>
                                        <div className={styles.inputContainer}>
                                            <input
                                                type="text"
                                                id="bankName"
                                                name="bankName"
                                                value={formData.bankName}
                                                onChange={handleInputChange}
                                                className={`${styles.input} ${errors.bankName ? styles.errorInput : ''}`}
                                                placeholder=" "
                                            />
                                            <label htmlFor="bankName" className={`${styles.placeholder} ${formData.bankName ? styles.filled : ''}`}>
                                                Bank Name
                                            </label>
                                        </div>
                                        {errors.bankName && <span className={styles.error}>{errors.bankName}</span>}
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-12'>
                                    <div className={styles.formGroup}>
                                        <div className={styles.inputContainer}>
                                            <input
                                                type="text"
                                                id="accountName"
                                                name="accountName"
                                                value={formData.accountName}
                                                onChange={handleInputChange}
                                                className={`${styles.input} ${errors.accountName ? styles.errorInput : ''}`}
                                                placeholder=" "
                                            />
                                            <label htmlFor="accountName" className={`${styles.placeholder} ${formData.accountName ? styles.filled : ''}`}>
                                                Account Name
                                            </label>
                                        </div>
                                        {errors.accountName && <span className={styles.error}>{errors.accountName}</span>}
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-12'>
                                    <div className={styles.formGroup}>
                                        <div className={styles.inputContainer}>
                                            <input
                                                type="text"
                                                id="accountNumber"
                                                name="accountNumber"
                                                value={formData.accountNumber}
                                                onChange={handleInputChange}
                                                className={`${styles.input} ${errors.accountNumber ? styles.errorInput : ''}`}
                                                placeholder=" "
                                            />
                                            <label htmlFor="accountNumber" className={`${styles.placeholder} ${formData.accountNumber ? styles.filled : ''}`}>
                                                Account Number
                                            </label>
                                        </div>
                                        {errors.accountNumber && <span className={styles.error}>{errors.accountNumber}</span>}
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-12'>
                                    <div className={styles.formGroup}>
                                        <div className={styles.inputContainer}>
                                            <input
                                                type="text"
                                                id="confirmAccountNumber"
                                                name="confirmAccountNumber"
                                                value={formData.confirmAccountNumber}
                                                onChange={handleInputChange}
                                                className={`${styles.input} ${errors.confirmAccountNumber ? styles.errorInput : ''}`}
                                                placeholder=" "
                                            />
                                            <label htmlFor="confirmAccountNumber" className={`${styles.placeholder} ${formData.confirmAccountNumber ? styles.filled : ''}`}>
                                                Confirm Account Number
                                            </label>
                                        </div>
                                        {errors.confirmAccountNumber && <span className={styles.error}>{errors.confirmAccountNumber}</span>}
                                    </div>
                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-12'>
                                    <div className={styles.formGroup}>
                                        <div className={styles.inputContainer}>
                                            <input
                                                type="text"
                                                id="ifsc"
                                                name="ifsc"
                                                value={formData.ifsc}
                                                onChange={handleInputChange}
                                                className={`${styles.input} ${errors.ifsc ? styles.errorInput : ''}`}
                                                placeholder=" "
                                            />
                                            <label htmlFor="ifsc" className={`${styles.placeholder} ${formData.ifsc ? styles.filled : ''}`}>
                                                IFSC Code
                                            </label>
                                        </div>
                                        {errors.ifsc && <span className={styles.error}>{errors.ifsc}</span>}
                                    </div>
                                </div>
                            </div>
                        </form>
                        <button type="submit" className={styles.updateButton}>Update Profile</button>
                    </TabPanel>
                </Tabs>
            </div>
        </>
    );
}
