import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchWithQueryAPI } from '../../../../Api/v1/Util/Api';
import { lead_overall_dashboard, lead_specific_dashboard } from '../../../../Api/APIUrl';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import styles from './css/ListLeadComponent.module.css';

export default function MainDashboardComponent({ typeProps, showFullNav, routeProps, lableProps, userTypeProps, isClientProps }) {
    const state = useSelector((state) => state);
    const { id } = useParams();
    const navigate = useNavigate();

    const [dashboardData, setDashboardData] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchLeadDashboard = async () => {
        try {
            const response = await fetchWithQueryAPI(state.auth.auth.token, lead_specific_dashboard);
            if (response && response.statusCode === 200) {
                setDashboardData(response.data);
            } else {
                toast.error("Failed to fetch " + lableProps);
            }
        } catch (error) {
            toast.error("An error occurred while fetching " + lableProps);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLeadDashboard();
    }, []);

    const handleCardClick = (route) => {
        navigate(route);
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card text-white bg-primary">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title">Total Contacts</h5>
                            <p className="card-text text-bold text-white flex-grow-1 d-flex align-items-end">{dashboardData.total_contacts}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card text-white bg-secondary">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title">Total Tasks</h5>
                            <p className="card-text text-bold text-white flex-grow-1 d-flex align-items-end">{dashboardData.total_task}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card text-white bg-warning">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title">To-do Tasks</h5>
                            <p className="card-text text-bold text-white flex-grow-1 d-flex align-items-end">{dashboardData.todo_task}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card text-white bg-danger">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title">Hold Tasks</h5>
                            <p className="card-text text-bold text-white flex-grow-1 d-flex align-items-end">{dashboardData.hold_task}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card text-white bg-info">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title">In-progress Tasks</h5>
                            <p className="card-text text-bold text-white flex-grow-1 d-flex align-items-end">{dashboardData.inprogress_task}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card text-white bg-success">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title">Done Tasks</h5>
                            <p className="card-text text-bold text-white flex-grow-1 d-flex align-items-end">{dashboardData.done_task}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card text-white bg-dark">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title">Today's Due Tasks</h5>
                            <p className="card-text text-bold text-white flex-grow-1 d-flex align-items-end">{dashboardData.today_due_task}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card text-white bg-secondary">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title">Expired Tasks</h5>
                            <p className="card-text text-bold text-white flex-grow-1 d-flex align-items-end">{dashboardData.already_task_expired}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card text-white bg-primary">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title">Tomorrow's Due Tasks</h5>
                            <p className="card-text text-bold text-white flex-grow-1 d-flex align-items-end">{dashboardData.tomorrow_due_task}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card text-white bg-warning">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title">Total Estimation Raised</h5>
                            <p className="card-text text-bold text-white flex-grow-1 d-flex align-items-end">{dashboardData.total_estimation_raised}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card text-white bg-info">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title">Total Proposals Raised</h5>
                            <p className="card-text text-bold text-white flex-grow-1 d-flex align-items-end">{dashboardData.total_proposal_raised}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card text-white bg-success">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title">Total Estimation Earned</h5>
                            <p className="card-text text-bold text-white flex-grow-1 d-flex align-items-end">{dashboardData.total_estimation_earned}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                    <div className="card text-white bg-dark">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title">Total Proposals Earned</h5>
                            <p className="card-text text-bold text-white flex-grow-1 d-flex align-items-end">{dashboardData.total_proposa_earned}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
