import React, { useState } from "react";
import AdminNavbar from "../../../../Admin/Component/Navbar/AdminNavbar";
import SpecificEmployeeAttendanceReport from "../../../../Admin/Component/v1/AttendanceReport/SpecificEmployeeAttendanceReport";

export default function SpecificAttendanceReport() {
  return (
    <>
      <AdminNavbar title={'Employee Attendance Report'} />
      <div className="main">
        <SpecificEmployeeAttendanceReport/>
      </div>
    </>
  );
}
