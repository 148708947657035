import axios from "axios";
import { url } from "../../BaseUrl";

const APIBaseUrl = url;

export const fetchLeadsAPI = async (token) => {
    const response = await axios
        .get(`${APIBaseUrl}/v1/leads/find`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

export const fetchSpecificLeadsAPI = async (token,id) => {
    const response = await axios
        .get(`${APIBaseUrl}/v1/leads/find_particular?_id=`+id, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

export const createNewLeadAPI = async (token,payload) => {
    const response = await axios
        .post(`${APIBaseUrl}/v1/leads/create`,payload, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => ({ error: error }));
    return response;
};

