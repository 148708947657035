import React, { useEffect, useState } from "react";
import RFA from "../../Assets/Images/audit1.webp";
import RFA2 from "../../Assets/Images/audit2.webp";
import Ser1 from "../../Assets/Images/ser1.webp";
import Ser2 from "../../Assets/Images/ser2.webp";
import Ser3 from "../../Assets/Images/ser3.webp";
import Si1 from "../../Assets/Images/si-1.webp";
import Si2 from "../../Assets/Images/si-2.webp";
import Si3 from "../../Assets/Images/si-3.webp";
import AC1 from "../../Assets/Images/ac1.webp";
import AC2 from "../../Assets/Images/ac2.webp";
import AC3 from "../../Assets/Images/ac3.webp";
import { sections } from "../../Datas";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ChangePassword,
  CreateOrder,
  CreatePaymentPackage,
  CreatePaymentService,
  ForgotOtp,
  ForgotPassword,
  GetContent,
  GetContentMP,
  GetPricingWithName,
  GetUser,
  SignUp,
  UserLogin,
  UserServiceRequest,
  UseraboutContent,
  VerifyOtp,
} from "../../Api/Api";
import {
  EmailValidation,
  NonEmptyValidation,
  PasswordValidation,
  PhoneNumberValidation,
  RePasswordValidation,
  StringValidation,
} from "../../Store/validate";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess, registerDetails } from "../../Store/auth/action";
import CustomModal from "../CustomModal";
import OtpInput from "react-otp-input";
import ServicesAllpages from "../ServicesAllpages";

export default function ManPowerContent() {
  const locationSearch = useLocation();
  const [selectedTab, setSelectedTab] = useState("");
  const [BookNow, setBookNow] = useState(false);
  useEffect(() => {
    AOS.init();
    window.scroll(0, 0);
    const search = locationSearch.search;
    const tab = new URLSearchParams(search).get("tab");

    if (tab) {
      setSelectedTab(tab);
    }
    return () => {};
  }, [locationSearch]);

  const state = useSelector((state) => state);

  const [manPower, setManPower] = useState({});

  const [manPowerValidation, setManPowerValidation] = useState({});

  const setManPowerValue = (key, value) => {
    setManPower({ ...manPower, [key]: value });
    if (manPowerValidation[key]) delete manPowerValidation[key];
  };

  const setmanPowerValidationValue = (key, value) => {
    setManPowerValidation({ ...manPowerValidation, [key]: value });
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 3000,
    });
  };

  const naviGation = useNavigate();

  //User Registration api call

  let cat = localStorage?.getItem("category")?.split("?");

  useEffect(() => {
    getPriceList();
    if (state?.auth?.isLoggedIn === true) {
      getUserProfile();
    }
    GetMyTsks();
  }, []);
  const [userProfile, setUserProfile] = useState([]);
  const getUserProfile = async () => {
    const id = state?.auth?.auth?._id;
    const token = state.auth.auth.token;
    const response = await GetUser(token, id);
    if (response.statusCode === 200) {
      setUserProfile(response.data);
    } else {
      //console.log("error while getting user profile info");
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    let validate = {};
    validate.name = StringValidation(manPower?.name);
    validate.emailId = EmailValidation(manPower?.emailId?.toLowerCase());
    validate.subject = NonEmptyValidation(manPower?.subject);
    validate.question = NonEmptyValidation(manPower?.question);
    validate.mobileNumber = PhoneNumberValidation(manPower?.mobileNumber);
    setManPowerValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        name: manPower.name,
        emailId: manPower.emailId?.toLowerCase(),
        subject: manPower.subject,
        question: manPower.question,
        mobileNumber: manPower.mobileNumber,
        userId: state?.auth?.auth?._id,
      };
      // const token = state?.auth?.auth?.token
      const response = await UseraboutContent(Payload);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        manPower({});
        setManPower({});
        // window.location.reload();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while submit a service request");
    }

    // }
  };

  const handleSubmitService = async (e) => {
    e.preventDefault();
    const Payload = {
      name: userProfile.name,
      email: userProfile.email,
      subject: " ",
      question: " ",
      mobileNumber: userProfile.phoneNumber,
      userId: state?.auth?.auth?._id,
      type: "service",
      servicesCategory: cat[0].substring(cat[0].lastIndexOf("/") + 1),
      servicesSubcategory: localStorage?.getItem("subcategory"),
      servicesTitle: localStorage?.getItem("title"),
    };
    // const token = state?.auth?.auth?.token
    const response = await UserServiceRequest(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const [loginModal, setloginModal] = useState(false);
  const [createModal, setcreateModal] = useState(false);
  const [fpwModal, setfpwModal] = useState(false);
  const [otpModal, setotpModal] = useState(false);
  const [otpFgtModal, setotpFgtModal] = useState(false);
  const [changepswModal, setchangepswModal] = useState(false);

  const [password, setPassword] = useState();
  const viewPassword = () => {
    setPassword(!password);
  };

  const [passwordStrength, setPasswordStrength] = useState("");
  const [manPowerDetail, setmanPowerDetails] = useState({});

  const [registerValidation, setRegisterValidation] = useState({});

  const setValue = (key, value) => {
    setmanPowerDetails({ ...manPowerDetail, [key]: value });
    if (registerValidation[key]) delete registerValidation[key];
  };

  const setManPowerValidationValue1 = (key, value) => {
    setRegisterValidation({ ...registerValidation, [key]: value });
  };

  const dispatch = useDispatch();
  // const location = useLocation();
  //error

  //User Registration api call

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.name = StringValidation(manPowerDetail?.name);
    validate.email = EmailValidation(manPowerDetail?.email?.toLowerCase());
    validate.password = PasswordValidation(manPowerDetail?.password);
    validate.phoneNumber = PhoneNumberValidation(manPowerDetail?.phoneNumber);
    validate.servicesType = NonEmptyValidation(manPowerDetail?.servicesType);

    setRegisterValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        name: manPowerDetail.name,
        email: manPowerDetail.email?.toLowerCase(),
        password: manPowerDetail.password,
        phoneNumber: manPowerDetail.phoneNumber,
        role: "user",
        servicesType: manPowerDetail.servicesType,
      };

      const response = await SignUp(Payload);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setcreateModal(false);
        manPowerDetail({});
        setmanPowerDetails({});
        setManPowerValidationValue1({});
        setotpModal(true);
        dispatch(registerDetails(manPowerDetail));
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while register as user");
    }
  };

  const handleSubmitResend = async (e) => {
    e.preventDefault();
    const Payload = {
      name: state.auth.registerData.name,
      email: state.auth.registerData.email,
      password: state.auth.registerData.password,
      phoneNumber: state.auth.registerData.phoneNumber,
      role: "user",
    };

    const response = await SignUp(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setMinutes(0);
      setSeconds(59);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const [code, setOtp] = useState("");
  const [validation, setValidation] = useState({});
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  //Otp Submit
  const handleChange = (code) => {
    setOtp(code);
    setValidation({});
  };

  // otp
  const handleSubmitOtp = async (data, value) => {
    data.preventDefault();
    let validate = {};
    validate.code = NonEmptyValidation(code);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: state.auth.registerData.email,
        otp: parseInt(code),
      };
      let response = await VerifyOtp(Payload);
      if (response && response.statusCode === 200) {
        setotpModal(false);
        setOtp("");
        setloginModal(true);
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        // setFailureModal(true);
      }
    } else {
      //console.log("Error while verify otp");
    }
  };

  const handleSubmitOtpFgt = async (data) => {
    data.preventDefault();
    let validate = {};
    validate.code = NonEmptyValidation(code);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: state.auth.registerData.email,
        otp: parseInt(code),
      };
      let response = await ForgotOtp(Payload);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setotpFgtModal(false);
        setchangepswModal(true);
        setOtp("");
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        // setFailureModal(true);
      }
    }
  };
  const [manPower1, setManPower1] = useState({});

  const [manPowerValidation1, setManPowerValidation1] = useState({});

  const setManPowerValue1 = (key, value) => {
    setManPower1({ ...manPower1, [key]: value });
    if (manPowerValidation1[key]) delete manPowerValidation1[key];
  };

  const setmanPowerValidation1 = (key, value) => {
    setManPowerValidation1({ ...manPowerValidation1, [key]: value });
  };

  //login api call
  const navigate = useNavigate();

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.email = EmailValidation(manPower1?.email?.toLowerCase());
    validate.password = PasswordValidation(manPower1?.password);
    setManPowerValidation1(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: manPower1.email?.toLowerCase(),
        password: manPower1.password,
      };

      const response = await UserLogin(Payload);
      if (response && response.statusCode === 200) {
        dispatch(loginSuccess(response.data, response?.data?.token));
        // setTimeout(() => {
        handleSubmitService("", response.data, "before");
        // }, 5000);
        setloginModal(false);

        setManPower1({});
        navigate("/");
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while login as user");
    }
  };

  const [manPower2, setmanPower2] = useState({});

  const [manPowerValidation2, setmanPowerValidation2] = useState({});

  const setmanPowerValue2 = (key, value) => {
    setmanPower2({ ...manPower2, [key]: value });
    if (manPowerValidation2[key]) delete manPowerValidation2[key];
  };

  const setManPowerValidationValue2 = (key, value) => {
    setmanPowerValidation2({ ...manPowerValidation2, [key]: value });
  };

  //forgot password api call

  const handleSubmitForgotPassword = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.email = EmailValidation(manPower2?.email?.toLowerCase());
    setmanPowerValidation2(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: manPower2.email?.toLowerCase(),
      };

      const response = await ForgotPassword(Payload);
      if (response && response.statusCode === 200) {
        dispatch(registerDetails(manPower2));
        showToastSuccessMsg(response.message);
        setfpwModal(false);
        setotpFgtModal(true);
        setmanPower2({});
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while reset password");
    }
  };

  const [manPower3, setmanPower3] = useState({});

  const [manPowerValidation3, setManPowerValidation3] = useState({});

  const setManPowerValue3 = (key, value) => {
    setmanPower3({ ...manPower3, [key]: value });
    if (manPowerValidation3[key]) delete manPowerValidation3[key];
  };

  const setManPowerValidationValue3 = (key, value) => {
    setManPowerValidation3({ ...manPowerValidation3, [key]: value });
  };

  //forgot password api call

  const handleSubmitChangePassword = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.password = PasswordValidation(manPower3?.password);
    validate.re_password = RePasswordValidation(
      manPower3?.re_password,
      manPower3?.password
    );
    setManPowerValidation3(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: state.auth.registerData.email,
        password: manPower3?.password,
      };

      const response = await ChangePassword(Payload);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setchangepswModal(false);
        setloginModal(true);
        setmanPower3({});
        getUserProfile();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while chenge password");
    }
  };

  const handleSubmitPayment = (e, amount) => {
    e.preventDefault();
    if (amount === "") {
      alert("please enter amount");
    } else {
      var options = {
        key: "rzp_test_BPipIoC6fqmzK2",
        key_secret: "vEMlC3fZ6XTSh4i8kq03FYXj",
        amount: amount * 100,
        currency: "INR",
        name: "STARTUP_PROJECTS",
        description: "for testing purpose",
        handler: function (response) {
          alert(response.razorpay_payment_id);
        },
        prefill: {
          name: "Test",
          email: "pavithrasethuraman92@gmail.com",
          contact: "9876543210",
        },
        notes: {
          address: "Razorpay Corporate office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  };

  const handlePaymentPackage = async (
    e,
    amount,
    paymentId,
    packageId,
    paymentStatus,
    paymentResponse,
    paymentType
  ) => {
    e.preventDefault();

    const Payload = {
      profileId: state.auth.auth._id,
      packageId: packageId,
      price: amount,
      paymentId: paymentId,
      paymentStatus: paymentStatus,
      paymentResponse: { paymentResponse },
      paymentType: paymentType,
    };
    const token = state?.auth?.auth?.token;
    const response = await CreatePaymentPackage(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setBookNow(false);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const [price, setPrice] = useState([]);
  const getPriceList = async () => {
    let name = window.location.href;
    let pagename = name?.split("=");

    // const token = state.auth.auth.token
    const response = await GetPricingWithName(pagename[1]);
    if (response.statusCode === 200) {
      setPrice(response.data);
    } else {
      //console.log("error while getting user profile info");
    }
  };

  const [ManPowerReq1, setmanPowerReq1] = useState({});
  const [form, setForm] = useState(false);

  const [manPowerReqValidation1, setManPowerReqValidation1] = useState({});

  const setManPowerReqValue1 = (key, value) => {
    setmanPowerReq1({ ...ManPowerReq1, [key]: value });
    if (manPowerReqValidation1[key]) delete manPowerReqValidation1[key];
  };

  const setServiceReqValidationValue1 = (key, value) => {
    setManPowerReqValidation1({ ...manPowerReqValidation1, [key]: value });
  };

  const handleSubmitServiceform = async (e) => {
    e.preventDefault();
    const Payload = {
      name: ManPowerReq1.name,
      email: ManPowerReq1.email,
      subject: ManPowerReq1.subject,
      question: ManPowerReq1.question,
      mobileNumber: ManPowerReq1.mobileNumber,
      type: "service",
      servicesCategory: cat[0].substring(cat[0].lastIndexOf("/") + 1),
      servicesSubcategory: localStorage?.getItem("subcategory"),
      servicesTitle: localStorage?.getItem("title"),
    };
    // const token = state?.auth?.auth?.token
    //console.log(Payload);
    const response = await UserServiceRequest(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setmanPowerReq1({});
      setForm(false);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };
  const [auditContent, setAuditContent] = useState([]);
  const GetMyTsks = async () => {
    let id = localStorage.getItem("level2Id");
    const token = state.auth.auth.token;
    let data = await GetContent("active", id);
    if (data && data.statusCode === 200) {
      setAuditContent(data.data);
    } else {
      setAuditContent([]);
      //console.log("Error while getting my task");
    }
  };

  return (
    <div className="overflow-pages">
      <div className="req-call-back-fixed">
        <button
          type="button"
          className="btn login-btn w-100"
          onClick={(e) => {
            if (state?.auth?.isLoggedIn) {
              handleSubmitService(e);
            } else {
              setForm(true);
            }
          }}
        >
          Request Call Back
        </button>
      </div>

      <div>
        <div className="about-banner2 ">
          <div className="container">
            {/* <div className="row">
              <div className="col-8"></div>
              <div className="col-4 text-end">
                <button
                  type="button"
                  className="btn login-btn w-100"
                  onClick={(e) => {
                    if (state?.auth?.isLoggedIn) {
                      handleSubmitService(e);
                    } else {
                      setForm(true);
                    }
                  }}
                >
                  Request Call Back
                </button>
              </div>
            </div> */}
            {/* <div className="row">
              <div className="col-lg-4 col-md-6  m-auto" data-aos="fade-left">
                <div className="about-img">
                  <img className="w-100 h-auto" src={RFA} alt="" />
                </div>
              </div>
              <div className="col-lg-5 col-md-6  m-auto" data-aos="fade-left">
                <div className="auditcontent-box">
                  {sections[selectedTab]?.map((item, index) => {
                    return (
                      <h4 className="banner-heading mb-2 mt-3">
                        {item.pagename}
                      </h4>
                    );
                  })}
                  <div className="bg-grey">
                    <div className="d-flex ">
                      <div className="form-check mx-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          value="solo"
                          onChange={(e) => {
                            setSolo(e.target.value);
                          }}
                          defaultChecked={solo === "solo"}
                        />
                        <label
                          className="form-check-label fw-bold"
                          for="flexRadioDefault1"
                        >
                          Solo Startup
                        </label>
                      </div>
                      <div className="form-check mx-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          value="company"
                          onChange={(e) => {
                            setSolo(e.target.value);
                          }}
                        />
                        <label
                          className="form-check-label fw-bold"
                          for="flexRadioDefault1"
                        >
                          Company or LLP
                        </label>
                      </div>
                    </div>
                    <div className="m-2">
                      <p className="small mb-0 inline-audit">
                        <span className="material-symbols-rounded color-maroon align-middle">
                          double_arrow
                        </span>
                        <span className="align-middle color-maroon fw-bold">
                          GST Registration
                        </span>
                      </p>
                      <p className="small mb-0 inline-audit">
                        <span className="material-symbols-rounded color-maroon align-middle">
                          double_arrow
                        </span>
                        <span className="align-middle color-maroon fw-bold">
                          Electricity Bill
                        </span>
                      </p>
                      <p className="small mb-0 inline-audit">
                        <span className="material-symbols-rounded color-maroon align-middle">
                          double_arrow
                        </span>
                        <span className="align-middle color-maroon fw-bold">
                          Passport
                        </span>
                      </p>
                      <p className="small mb-0 inline-audit">
                        <span className="material-symbols-rounded color-maroon align-middle">
                          double_arrow
                        </span>
                        <span className="align-middle color-maroon fw-bold">
                          Aadhaar Copy
                        </span>
                      </p>
                      <p className="small mb-0 inline-audit">
                        <span className="material-symbols-rounded color-maroon align-middle">
                          double_arrow
                        </span>
                        <span className="align-middle color-maroon fw-bold">
                          Gas Bill
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="audit-content-horiz mt-3">
                    <h6 className="fw-bold">Required Document</h6>
                    <ul>
                      <li>Pan card Copy</li>
                      <li>Electricity Bill</li>
                      <li>Passport</li>
                      <li>Aadhaar Copy</li>
                      <li>Gas Bill</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-8  m-auto" data-aos="fade-left">
                {price?.map((e) =>
                  e.price.map((p) => (
                    <div className="price-details">
                      <h6 className="fw-bold py-3 px-2 border-bottom">
                        Price Details
                      </h6>
                      <div className=" d-flex justify-content-between">
                        <p className="price-details-content">Market Price</p>
                        {solo === "solo" ? (
                          <p className="price-details-content">
                            ₹{p.solo.marketPrice}
                          </p>
                        ) : (
                          <p className="price-details-content">
                            ₹{p.company.marketPrice}
                          </p>
                        )}
                      </div>
                      <div className=" d-flex justify-content-between">
                        <p className="price-details-content">Dalmaf Price</p>
                        {solo === "solo" ? (
                          <p className="price-details-content text-secondary">
                            ₹{p.solo.dalmafPrice}
                          </p>
                        ) : (
                          <p className="price-details-content text-secondary">
                            ₹{p.company.dalmafPrice}
                          </p>
                        )}
                      </div>
                      <div className=" d-flex justify-content-between border-bottom">
                        <p className="price-details-content">GST </p>
                        {solo === "solo" ? (
                          <p className="price-details-content">₹{p.solo.GST}</p>
                        ) : (
                          <p className="price-details-content">
                            ₹{p.company.GST}
                          </p>
                        )}
                      </div>
                      <div className=" d-flex justify-content-between border-bottom">
                        <p className="price-details-content color-maroon">
                          Total Amount
                        </p>
                        {solo === "solo" ? (
                          <p className="price-details-content color-maroon">
                            ₹{p.solo.dalmafPrice + p.solo.GST}
                          </p>
                        ) : (
                          <p className="price-details-content color-maroon">
                            ₹{p.company.dalmafPrice + p.company.GST}
                          </p>
                        )}
                      </div>
                      <p className="py-3 px-2 small mb-0">
                        You will save{" "}
                        <span className="text-primary">$3000</span> on this
                        order
                      </p>
                      <div>
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                          style={{borderTopLeftRadius:"6px",
                            borderTopRightRadius: "6px"}}
                            type="button"
                            className="btn login-btn w-auto mb-2 
                            "
                            onClick={() => {
                              setBookNow(true);
                            }}
                          >
                            Buy Now
                          </button>
                          <button
                          style={{borderTopLeftRadius:"6px",
                          borderTopRightRadius: "6px"}}
                          type="button"
                          className="btn login-btn w-auto mb-2"
                          onClick={() => {
                            setBookNow(true);
                          }}
                        >
                          Buy Now
                        </button>
                        </div>

                        <button
                          type="button"
                          className="btn login-btn w-100"
                          onClick={(e) => {
                            if (state?.auth?.isLoggedIn) {
                              handleSubmitService(e, "", "after");
                            } else {
                              setloginModal(true);
                            }
                          }}
                        >
                          Request Service
                        </button>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div> */}
          </div>
        </div>
        <div className=" py-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 m-auto" data-aos="fade-left">
                {auditContent?.map((item, index) => {
                  return (
                    <div key={index} className="about-content my-5">
                      {/* {item.mainServiceId.title ? (
                        <h3 className="banner-heading after-line mb-3">
                          {item.mainServiceId.title}
                        </h3>
                      ) : null} */}
                      {item.subServiceL1Id.title && (
                        <p className="fw-bold">{item.subServiceL1Id.title}</p>
                      )}
                      {item?.content ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item?.content,
                          }}
                        ></p>
                      ) : null}
                      {/* {item?.points?.map((point, ind) => {
                        return <p key={ind}>{point}</p>;
                      })} */}
                      {/* {item?.footer && <p>{item.footer}</p>}
                      {item?.table && item.table} */}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className=" py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 m-auto" data-aos="fade-left">
              <h3 className="banner-heading after-line mb-3">
                 eway bill validity
              </h3>
              <p className="fw-bold">What is the minimum distance required for E-way bill?</p>
              <p>The significant amendment made effective video notification no. 12/2018- Central tax dated 7th March 2018 changes in the validity period of E-way bill. The new validity period provisions of the E-way bill are tabulated here:</p>
              <div className="table">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" >
                      Type of Conveyance
                      </th>
                      <th scope="col" >
                      Distance
                      </th>
                      <th scope="col" >
                      E-way bill
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowspan='2'>Other than Over dimensional cargo</td>
                      <td>Less than 100 km</td>
                      <td>1 day</td>
                    </tr>
                    <tr>
                      <td>For every additional 100 km and thereof</td>
                      <td>Additional 1 day</td>
                    </tr>
                    <tr>
                      <td rowspan='2'>For Over dimensional cargo</td>
                      <td>Up to 20 km
                      </td>
                      <td>1 day
                      </td>
                    </tr>
                    <tr>
                      <td>For every additional 20 km and thereof</td>
                      <td>Additional 1 day</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p>The relevant date on which the E-way bill has been generated and the period of validity would be counted from the time at which the E-way bill has been generated, and each day would be counted as the period expiring at midnight of the day immediately following the date of generation of the E-way bill.</p>
              <p>'Over Dimensional Cargo' is a cargo carried as a single indivisible unit and which exceeds the dimensional limits prescribed in rule 93 of the Central Motor Vehicle Rules,1989, made under the Motor Vehicles Act,1988 (59 of 1988).</p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="client-left-section py-5">
        <div className="container my-4">
          <h2 className="banner-heading after-line mb-5">FEEL FREE TO ASK</h2>
          <div className="row">
            <div className="col-lg-6 " data-aos="fade-left">
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.823571029211!2d80.23700327559305!3d13.046899587275508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267c25ec3b519%3A0xb10841584ccc73c4!2sDALMAF%20Private%20Limited!5e0!3m2!1sen!2sin!4v1688118977225!5m2!1sen!2sin"
                  width="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-left">
              {/* {state?.auth?.isLoggedIn ? (<button type="button" className="btn login-btn w-100"
                  onClick={(e)=>{
                    handleSubmit(e)
                  }}
                  >
                  Request Callback
                </button>) : ( */}
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Name"
                      aria-invalid={Boolean(
                        manPowerValidation?.name?.status === false
                      )}
                      value={manPower?.name || ""}
                      onChange={(e) => {
                        setManPowerValue("name", e.target.value);
                      }}
                      onBlur={(e) => {
                        setmanPowerValidationValue(
                          "name",
                          StringValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {manPowerValidation?.name?.message
                        ? `Name ${manPowerValidation?.name?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Mobile No <span className="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      maxLength={10}
                      className="form-control"
                      placeholder="Enter Your Mobile No"
                      aria-invalid={Boolean(
                        manPowerValidation?.mobileNumber?.status === false
                      )}
                      value={manPower?.mobileNumber || ""}
                      onChange={(e) => {
                        setManPowerValue("mobileNumber", e.target.value);
                      }}
                      onBlur={(e) => {
                        setmanPowerValidationValue(
                          "mobileNumber",
                          PhoneNumberValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {manPowerValidation?.mobileNumber?.message
                        ? `Mobile no ${manPowerValidation?.mobileNumber?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      E- Mail ID <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your E- Mail ID"
                      aria-invalid={Boolean(
                        manPowerValidation?.emailId?.status === false
                      )}
                      value={manPower?.emailId?.toLowerCase() || ""}
                      onChange={(e) => {
                        setManPowerValue(
                          "emailId",
                          e.target.value?.toLowerCase()
                        );
                      }}
                      onBlur={(e) => {
                        setmanPowerValidationValue(
                          "emailId",
                          EmailValidation(e.target.value)?.toLowerCase()
                        );
                      }}
                    />
                    <small className="text-danger">
                      {manPowerValidation?.emailId?.message
                        ? `Email ${manPowerValidation?.emailId?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Questions <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Questions "
                      aria-invalid={Boolean(
                        manPowerValidation?.question?.status === false
                      )}
                      value={manPower?.question || ""}
                      onChange={(e) => {
                        setManPowerValue("question", e.target.value);
                      }}
                      onBlur={(e) => {
                        setmanPowerValidationValue(
                          "question",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {manPowerValidation?.question?.message
                        ? `Question ${manPowerValidation?.question?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Service Type
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <select
                      className="form-control form-control-lg"
                      value={manPower?.servicesType || ""}
                      onChange={(e) => {
                        setManPowerValue("servicesType", e.target.value);
                      }}
                      onBlur={(e) => {
                        setmanPowerValidationValue(
                          "servicesType",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    >
                      <option value="">---Select Service Type---</option>
                      <option value="Legal">Legal</option>
                      <option value="Audit">Audit</option>
                      <option value="Manpower">Manpower</option>
                      <option value="Auction">Auction</option>
                      <option value="Buy/Sell">Buy/Sell</option>
                    </select>
                    {/* <small className="text-danger">
                      {manPowerValidation?.servicesType?.message
                        ? `Service Type ${manPowerValidation?.servicesType?.message}`
                        : ""}
                    </small> */}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Subject <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows="6"
                      className="form-control"
                      placeholder="Enter Subject"
                      aria-invalid={Boolean(
                        manPowerValidation?.subject?.status === false
                      )}
                      value={manPower?.subject || ""}
                      onChange={(e) => {
                        setManPowerValue("subject", e.target.value);
                      }}
                      onBlur={(e) => {
                        setmanPowerValidationValue(
                          "subject",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    ></textarea>
                    <small className="text-danger">
                      {manPowerValidation?.subject?.message
                        ? `Subject ${manPowerValidation?.subject?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  
                  <p>
                    {/* <span className="material-symbols-rounded filled-icon tick-icon me-2 align-middle">
                      check_box
                    </span>
                    <span className="small">
                      I am interested in talking to a lawyer for my legal issue
                    </span> */}
                  </p>

                  <button
                    type="button"
                    className="btn login-btn w-100"
                    onClick={(e) => {
                      handleSubmitForm(e);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      <div className="number-counter py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <span className="material-symbols-rounded align-middle about-call filled-icon">
                        call
                      </span>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">Toll Free</h5>
                    <h5 className="mb-0 fs-4">1800-309-8445</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <span className="material-symbols-rounded align-middle about-call filled-icon">
                        mail
                      </span>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">Email ID</h5>
                    <h5 className="mb-0 fs-4">info@dalmaf.com</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <i className="fa-brands fa-whatsapp about-whatsapp-icon"></i>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">WhatsApp Us</h5>
                    <h5 className="mb-0 fs-4">+91 96000 38059</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ServicesAllpages />
      </div>
      <CustomModal
        open={BookNow}
        onClickOutside={() => {
          setBookNow(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container ">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setBookNow(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-10">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-2">Packages</h4>
                            </div>
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="admin-service2">
                                  <div className="small-banner-img-head">
                                    <img
                                      style={{ width: "60px", height: "60px" }}
                                      src={AC3}
                                      alt="..."
                                    />

                                    <p className="mb-0 cost-pack">₹300</p>
                                  </div>
                                  <div className="mt-3">
                                    <h6 className="color-maroon fw-bold">
                                      Audit
                                    </h6>
                                    <p className="small">Lorem ipsum...</p>
                                  </div>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="button"
                                    className="btn  w-100 my-2  login-btn"
                                    onClick={(e) => {
                                      if (state?.auth?.isLoggedIn) {
                                        handleSubmitPayment(e, "300");
                                      } else {
                                        setloginModal(true);
                                      }
                                    }}
                                  >
                                    Buy Now
                                  </button>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="admin-service2">
                                  <div className="small-banner-img-head">
                                    <img
                                      style={{ width: "60px", height: "60px" }}
                                      src={AC3}
                                      alt="..."
                                    />

                                    <p className="mb-0 cost-pack">₹300</p>
                                  </div>
                                  <div className="mt-3">
                                    <h6 className="color-maroon fw-bold">
                                      Audit
                                    </h6>
                                    <p className="small">Lorem ipsum...</p>
                                  </div>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="button"
                                    className="btn  w-100 my-2  login-btn"
                                  >
                                    Buy Now
                                  </button>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="admin-service2">
                                  <div className="small-banner-img-head">
                                    <img
                                      style={{ width: "60px", height: "60px" }}
                                      src={AC3}
                                      alt="..."
                                    />

                                    <p className="mb-0 cost-pack">₹300</p>
                                  </div>
                                  <div className="mt-3">
                                    <h6 className="color-maroon fw-bold">
                                      Audit
                                    </h6>
                                    <p className="small">Lorem ipsum...</p>
                                  </div>
                                </div>
                                <div className="mt-3">
                                  <button
                                    type="button"
                                    className="btn  w-100 my-2  login-btn"
                                  >
                                    Buy Now
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={loginModal}
        onClickOutside={() => {
          setloginModal(false);
          setManPowerValidation1({});
          setcreateModal(false);
          setfpwModal(false);
          setotpModal(false);
          setchangepswModal(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container ">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setloginModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-10">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-2">Login</h4>
                            </div>
                            <div className="mb-3">
                              <label for="" className="form-label">
                                Email
                              </label>

                              <input
                                type="email"
                                className="form-control"
                                placeholder="example@mail.com"
                                required
                                aria-invalid={Boolean(
                                  manPowerValidation1?.email?.status === false
                                )}
                                defaultValue={
                                  manPower1?.email?.toLowerCase() || ""
                                }
                                onChange={(e) => {
                                  setManPowerValue1(
                                    "email",
                                    e.target.value?.toLowerCase()
                                  );
                                }}
                                onBlur={(e) => {
                                  setmanPowerValidation1(
                                    "email",
                                    EmailValidation(
                                      e.target.value?.toLowerCase()
                                    )
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {manPowerValidation1?.email?.message
                                  ? `Email ${manPowerValidation1?.email?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3 psw-icon-section">
                              <label for="" className="form-label">
                                Password
                              </label>
                              <input
                                type={password ? "text" : "password"}
                                className="form-control"
                                required
                                placeholder="******"
                                aria-invalid={Boolean(
                                  manPowerValidation1?.password?.status ===
                                    false
                                )}
                                defaultValue={manPower1?.password || ""}
                                onChange={(e) => {
                                  setManPowerValue1(
                                    "password",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setmanPowerValidation1(
                                    "password",
                                    PasswordValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {manPowerValidation1?.password?.message
                                  ? `Password ${manPowerValidation1?.password?.message}`
                                  : ""}
                              </small>
                              <div className="psw-icon">
                                {password ? (
                                  <span
                                    className="material-symbols-rounded cursor align-middle"
                                    onClick={() => viewPassword()}
                                  >
                                    visibility
                                  </span>
                                ) : (
                                  <span
                                    className="material-symbols-rounded cursor align-middle"
                                    onClick={() => viewPassword()}
                                  >
                                    visibility_off
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => handleSubmit1(e)}
                              >
                                Login
                              </button>
                            </div>
                            <div className="mb-3 ">
                              <p className="text-center mb-1">
                                <span className="text-secondary">
                                  Don’t have account? ?{" "}
                                </span>
                                <span
                                  className="mx-2 cursor text-decoration-underline"
                                  onClick={() => {
                                    setloginModal(false);
                                    setcreateModal(true);
                                    setManPower1({});
                                    setManPowerValidation1({});
                                  }}
                                >
                                  Sign Up
                                </span>{" "}
                              </p>
                              <p className="text-center">
                                <span className="text-secondary">
                                  Forgot your password?
                                </span>
                                <span
                                  className="mx-2 cursor text-decoration-underline"
                                  onClick={() => {
                                    setloginModal(false);
                                    setfpwModal(true);
                                    setManPower1({});
                                    setManPowerValidation1({});
                                  }}
                                >
                                  Reset
                                </span>{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={createModal}
        onClickOutside={() => {
          setcreateModal(false);
          setRegisterValidation({});
          setfpwModal(false);
          setotpModal(false);
          setloginModal(false);
          setchangepswModal(false);
        }}
      >
        <div className="modal-content w-50 h-50  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setcreateModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          {/* <form> */}
                          <div className="col-lg-10">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-2">
                                Create an account
                              </h4>
                            </div>
                            <div className="mb-3">
                              <label for="" className="form-label">
                                Name
                              </label>
                              <input
                                // id="name"
                                // name="name"
                                type="text"
                                className="form-control"
                                placeholder="Enter your Name"
                                required
                                aria-invalid={Boolean(
                                  registerValidation?.name?.status === false
                                )}
                                defaultValue={manPowerDetail?.name || ""}
                                onChange={(e) => {
                                  setValue("name", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setManPowerValidationValue1(
                                    "name",
                                    StringValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {registerValidation?.name?.message
                                  ? `Name ${registerValidation?.name?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3">
                              <label for="" className="form-label">
                                Email
                              </label>
                              <input
                                // id="email"
                                type="email"
                                className="form-control"
                                placeholder="example@mail.com"
                                required
                                aria-invalid={Boolean(
                                  registerValidation?.email?.status === false
                                )}
                                defaultValue={
                                  manPowerDetail?.email?.toLowerCase() || ""
                                }
                                onChange={(e) => {
                                  setValue(
                                    "email",
                                    e.target.value?.toLowerCase()
                                  );
                                }}
                                onBlur={(e) => {
                                  setManPowerValidationValue1(
                                    "email",
                                    EmailValidation(
                                      e.target.value?.toLowerCase()
                                    )
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {registerValidation?.email?.message
                                  ? `Email ${registerValidation?.email?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3">
                              <label for="" className="form-label">
                                Phone No
                              </label>
                              <input
                                // id="phoneNumber"
                                type="tel"
                                className="form-control"
                                maxLength={10}
                                placeholder="Phone Number"
                                required
                                aria-invalid={Boolean(
                                  registerValidation?.phoneNumber?.status ===
                                    false
                                )}
                                defaultValue={manPowerDetail?.phoneNumber || ""}
                                onChange={(e) => {
                                  setValue("phoneNumber", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setManPowerValidationValue1(
                                    "phoneNumber",
                                    PhoneNumberValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {registerValidation?.phoneNumber?.message
                                  ? `Phone no ${registerValidation?.phoneNumber?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3 psw-icon-section">
                              <label for="" className="form-label">
                                Password
                              </label>
                              <input
                                // id="password"
                                type={password ? "text" : "password"}
                                className="form-control"
                                required
                                placeholder="*********"
                                aria-invalid={Boolean(
                                  registerValidation?.password?.status === false
                                )}
                                defaultValue={manPowerDetail?.password || ""}
                                onChange={(e) => {
                                  setValue("password", e.target.value);
                                  setPasswordStrength(e.target.value);
                                }}
                                onBlur={(e) => {
                                  setManPowerValidationValue1(
                                    "password",
                                    PasswordValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {registerValidation?.password?.message
                                  ? `Password ${registerValidation?.password?.message}`
                                  : ""}
                              </small>
                              <div className="psw-icon">
                                {password ? (
                                  <span
                                    className="material-symbols-rounded cursor align-middle"
                                    onClick={() => viewPassword()}
                                  >
                                    visibility
                                  </span>
                                ) : (
                                  <span
                                    className="material-symbols-rounded cursor align-middle"
                                    onClick={() => viewPassword()}
                                  >
                                    visibility_off
                                  </span>
                                )}
                              </div>
                            </div>
                            {passwordStrength ? (
                              <div className="mt-3">
                                <b>Create a password with the following</b>
                                <br />
                                {
                                  <p
                                    className={`${
                                      passwordStrength.trim().length < 8
                                        ? "text-danger"
                                        : "text-success"
                                    } d-flex`}
                                  >
                                    {passwordStrength.trim().length < 8 ? (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        close
                                      </span>
                                    ) : (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        check
                                      </span>
                                    )}
                                    At least 8 Characters
                                  </p>
                                }
                                {
                                  <p
                                    className={`${
                                      !/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                        passwordStrength
                                      )
                                        ? "text-danger"
                                        : "text-success"
                                    } d-flex`}
                                  >
                                    {!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                      passwordStrength
                                    ) ? (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        close
                                      </span>
                                    ) : (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        check
                                      </span>
                                    )}
                                    An Uppercase &amp; Lowercase character
                                  </p>
                                }
                                {
                                  <p
                                    className={`${
                                      !/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                                        passwordStrength
                                      )
                                        ? "text-danger"
                                        : "text-success"
                                    } d-flex`}
                                  >
                                    {!/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                                      passwordStrength
                                    ) ? (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        close
                                      </span>
                                    ) : (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        check
                                      </span>
                                    )}
                                    A special character
                                  </p>
                                }
                                {
                                  <p
                                    className={`${
                                      !/[0-9]/.test(passwordStrength)
                                        ? "text-danger"
                                        : "text-success"
                                    } d-flex`}
                                  >
                                    {!/[0-9]/.test(passwordStrength) ? (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        close
                                      </span>
                                    ) : (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        check
                                      </span>
                                    )}
                                    A number
                                  </p>
                                }
                              </div>
                            ) : null}
                            <div className="mb-3">
                              <button
                                // type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => handleSubmit(e)}
                              >
                                Create account
                              </button>
                            </div>
                            <div className="mb-3 ">
                              <p className="text-center mb-1">
                                <span className="text-secondary">
                                  Already have an account ?
                                </span>
                                <span
                                  className="mx-2 cursor text-decoration-underline"
                                  onClick={() => {
                                    setcreateModal(false);
                                    setloginModal(true);
                                    setmanPowerDetails({});
                                    setRegisterValidation({});
                                  }}
                                >
                                  Log in
                                </span>{" "}
                              </p>
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={fpwModal}
        onClickOutside={() => {
          setfpwModal(false);
          setmanPowerValidation2({});
          setcreateModal(false);
          setotpModal(false);
          setloginModal(false);
          setchangepswModal(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setfpwModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-10">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-2">Forgot Password</h4>
                            </div>
                            <div className="mb-3">
                              <label for="email" className="form-label">
                                Email
                              </label>
                              <input
                                id="email"
                                type="email"
                                className="form-control"
                                placeholder="example@mail.com"
                                required
                                aria-invalid={Boolean(
                                  manPowerValidation2?.email?.status === false
                                )}
                                defaultValue={
                                  manPower2?.email?.toLowerCase() || ""
                                }
                                onChange={(e) => {
                                  setmanPowerValue2(
                                    "email",
                                    e.target.value?.toLowerCase()
                                  );
                                }}
                                onBlur={(e) => {
                                  setManPowerValidationValue2(
                                    "email",
                                    EmailValidation(
                                      e.target.value?.toLowerCase()
                                    )
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {manPowerValidation2?.email?.message
                                  ? `Email ${manPowerValidation2?.email?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => {
                                  handleSubmitForgotPassword(e);
                                }}
                              >
                                Submit
                              </button>
                            </div>
                            <div className="mb-3 ">
                              <p className="text-center mb-1">
                                <span className="text-secondary">
                                  Already have an account ?
                                </span>
                                <span
                                  className="mx-2 cursor text-decoration-underline"
                                  onClick={() => {
                                    setfpwModal(false);
                                    setloginModal(true);
                                    setmanPower2({});
                                    setmanPowerValidation2({});
                                  }}
                                >
                                  Log in
                                </span>{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={otpModal}
        onClickOutside={() => {
          setotpModal(false);
          setValidation({});
          setcreateModal(false);
          setfpwModal(false);
          setloginModal(false);
          setchangepswModal(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setotpModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-10">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-3">
                                Verify your email ID
                              </h4>
                              <small>Enter the code we just sent to</small>
                            </div>
                            <div className="mb-4 psw-icon-section">
                              <OtpInput
                                className="otpInput"
                                value={code}
                                onChange={handleChange}
                                numInputs={6}
                                separator={
                                  <span style={{ width: "8px" }}></span>
                                }
                                isInputNum={true}
                                shouldAutoFocus={true}
                                inputStyle={{
                                  border: "1px solid #CFD3DB",
                                  borderRadius: "8px",
                                  width: "45px",
                                  height: "45px",
                                  fontSize: "12px",
                                  color: "#000",
                                  fontWeight: "400",
                                  caretColor: "blue",
                                }}
                                focusStyle={{
                                  border: "1px solid black ",
                                  outline: "none",
                                }}
                              />
                            </div>
                            <small className="text-danger">
                              {validation?.code?.message
                                ? `OTP ${validation?.code?.message}`
                                : ""}
                            </small>
                            {seconds > 0 || minutes > 0 ? (
                              <div className="top-12">
                                Resend OTP in&nbsp;
                                <span className="text-green">
                                  {minutes < 10 ? `0${minutes}` : minutes}:
                                  {seconds < 10 ? `0${seconds}` : seconds}
                                </span>
                              </div>
                            ) : (
                              <div className="top-12">
                                Didn't receive code?&nbsp;
                                <span
                                  className="fgtpwd"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => handleSubmitResend(e)}
                                >
                                  Resend
                                </span>
                              </div>
                            )}
                            {/* <div className="mb-3 ">
                              <p
                                className="text-decoration-underline  text-pink"
                                style={{ cursor: "pointer" }}
                              >
                                Resend
                              </p>
                            </div> */}
                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => {
                                  if (
                                    localStorage.getItem(
                                      "accountcreation" === true
                                    )
                                  ) {
                                    handleSubmitOtp(e, "accountcreation");
                                  } else {
                                    handleSubmitOtp(e, "forgot");
                                  }

                                  // setchangepswModal(true);
                                }}
                              >
                                Verify
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={otpFgtModal}
        onClickOutside={() => {
          setotpFgtModal(false);
          setValidation({});
          setcreateModal(false);
          setfpwModal(false);
          setloginModal(false);
          setchangepswModal(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setotpFgtModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-10">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-3">
                                Verify your email ID
                              </h4>
                              <small>Enter the code we just sent to</small>
                            </div>
                            <div className="mb-4 psw-icon-section">
                              <OtpInput
                                className="otpInput"
                                value={code}
                                onChange={handleChange}
                                numInputs={6}
                                separator={
                                  <span style={{ width: "8px" }}></span>
                                }
                                isInputNum={true}
                                shouldAutoFocus={true}
                                inputStyle={{
                                  border: "1px solid #CFD3DB",
                                  borderRadius: "8px",
                                  width: "45px",
                                  height: "45px",
                                  fontSize: "12px",
                                  color: "#000",
                                  fontWeight: "400",
                                  caretColor: "blue",
                                }}
                                focusStyle={{
                                  border: "1px solid black ",
                                  outline: "none",
                                }}
                              />
                            </div>
                            <small className="text-danger">
                              {validation?.code?.message
                                ? `OTP ${validation?.code?.message}`
                                : ""}
                            </small>
                            {seconds > 0 || minutes > 0 ? (
                              <div className="top-12">
                                Resend OTP in&nbsp;
                                <span className="text-green">
                                  {minutes < 10 ? `0${minutes}` : minutes}:
                                  {seconds < 10 ? `0${seconds}` : seconds}
                                </span>
                              </div>
                            ) : (
                              <div className="top-12">
                                Didn't receive code?&nbsp;
                                <span
                                  className="fgtpwd"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => handleSubmitResend(e)}
                                >
                                  Resend
                                </span>
                              </div>
                            )}
                            {/* <div className="mb-3 ">
                              <p
                                className="text-decoration-underline  text-pink"
                                style={{ cursor: "pointer" }}
                              >
                                Resend
                              </p>
                            </div> */}
                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => {
                                  handleSubmitOtpFgt(e);
                                }}
                              >
                                Verify
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={changepswModal}
        onClickOutside={() => {
          setchangepswModal(false);
          setManPowerValidation3({});
          setcreateModal(false);
          setfpwModal(false);
          setotpModal(false);
          setloginModal(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setchangepswModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-10">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-3">Change Password</h4>
                            </div>
                            <div className="mb-3 ">
                              <label for="password" className="form-label">
                                New Password
                              </label>
                              <input
                                id="password"
                                type="password"
                                className="form-control"
                                placeholder="*********"
                                required
                                aria-invalid={Boolean(
                                  manPowerValidation3?.password?.status ===
                                    false
                                )}
                                onChange={(e) => {
                                  setManPowerValue3(
                                    "password",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setManPowerValidationValue3(
                                    "password",
                                    PasswordValidation(e.target.value)
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {manPowerValidation3?.password?.message
                                  ? `Password ${manPowerValidation3?.password?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3 ">
                              <label for="" className="form-label">
                                Re-Enter Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder="example@mail.com"
                                required
                                aria-invalid={Boolean(
                                  manPowerValidation3?.re_password?.status ===
                                    false
                                )}
                                onChange={(e) => {
                                  setManPowerValue3(
                                    "re_password",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setManPowerValidationValue3(
                                    "re_password",
                                    RePasswordValidation(
                                      e.target.value,
                                      manPower3?.password
                                    )
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {manPowerValidation3?.re_password?.message
                                  ? `Re-Password ${manPowerValidation3?.re_password?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => {
                                  handleSubmitChangePassword(e);
                                }}
                              >
                                Change
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={form}
        onClickOutside={() => {
          setForm(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container ">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setForm(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container px-4 ">
                  <div className="row justify-content-center reg-page-scroll">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="mb-2">
                            <b>
                              Please enter your information below, <br />
                              Our staff will contact you as soon as possible.
                            </b>
                          </div>
                          <hr />
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">
                                Name <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Name"
                                aria-invalid={Boolean(
                                  manPowerReqValidation1?.name?.status === false
                                )}
                                defaultValue={ManPowerReq1?.name}
                                onChange={(e) => {
                                  setManPowerReqValue1("name", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setServiceReqValidationValue1(
                                    "name",
                                    StringValidation(e.target.value)
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {manPowerReqValidation1?.name?.message
                                  ? `Name ${manPowerReqValidation1?.name?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">
                                Mobile No <span className="text-danger">*</span>
                              </label>
                              <input
                                type="tel"
                                maxLength={10}
                                className="form-control"
                                defaultValue={ManPowerReq1?.mobileNumber}
                                placeholder="Enter Your Mobile No"
                                aria-invalid={Boolean(
                                  manPowerReqValidation1?.mobileNumber
                                    ?.status === false
                                )}
                                onChange={(e) => {
                                  setManPowerReqValue1(
                                    "mobileNumber",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setServiceReqValidationValue1(
                                    "mobileNumber",
                                    PhoneNumberValidation(e.target.value)
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {manPowerReqValidation1?.mobileNumber?.message
                                  ? `Mobile no ${manPowerReqValidation1?.mobileNumber?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">
                                E- Mail ID{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Enter Your E- Mail ID"
                                aria-invalid={Boolean(
                                  manPowerReqValidation1?.email?.status === false
                                )}
                                defaultValue={
                                  ManPowerReq1?.email?.toLowerCase() || ""
                                }
                                onChange={(e) => {
                                  setManPowerReqValue1(
                                    "email",
                                    e.target.value?.toLowerCase()
                                  );
                                }}
                                onBlur={(e) => {
                                  setServiceReqValidationValue1(
                                    "emailId",
                                    EmailValidation(
                                      e.target.value
                                    )?.toLowerCase()
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {manPowerReqValidation1?.email?.message
                                  ? `Email ${manPowerReqValidation1?.email?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">Questions</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Questions "
                                aria-invalid={Boolean(
                                  manPowerReqValidation1?.question?.status ===
                                    false
                                )}
                                onChange={(e) => {
                                  setManPowerReqValue1(
                                    "question",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">
                                Service Type{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control form-control-lg"
                                value={ManPowerReq1?.servicesType}
                                onChange={(e) => {
                                  setManPowerReqValue1(
                                    "servicesType",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setServiceReqValidationValue1(
                                    "servicesType",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="">
                                  ---Select Service Type---
                                </option>
                                <option value="Legal">Legal</option>
                                <option value="Audit">Audit</option>
                                <option value="Manpower">Manpower</option>
                                <option value="Auction">Auction</option>
                                <option value="Buy/Sell">Buy/Sell</option>
                              </select>
                              <small className="text-danger">
                                {manPowerReqValidation1?.servicesType?.message
                                  ? `Service Type ${manPowerReqValidation1?.servicesType?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Subject</label>
                              <textarea
                                rows="6"
                                className="form-control"
                                placeholder="Enter Subject"
                                aria-invalid={Boolean(
                                  manPowerReqValidation1?.subject?.status ===
                                    false
                                )}
                                onChange={(e) => {
                                  setManPowerReqValue1(
                                    "subject",
                                    e.target.value
                                  );
                                }}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <button
                              type="button"
                              className="btn login-btn w-100"
                              onClick={(e) => {
                                handleSubmitServiceform(e);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <ToastContainer />
    </div>
  );
}
