import React, { useEffect } from 'react';
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import VillaBanner from "../Components/Property/VillaBanner";
import VillaSpecific from "../Components/Property/VillaSpecific";
import { Helmet } from "react-helmet";
export default function VillaDetails() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="innerpages-nav">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Villa Details</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="" />
        <meta name="keywords" content="HTML, CSS, JavaScript" />
        <meta name="author" content="DALMaf" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
      <div id="container">
        <Navigation />
        <VillaBanner />
        <VillaSpecific />
        <Footer />
      </div>
    </div>
  );
}
