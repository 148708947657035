import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../../Spinner/Spinner";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { fetchWithQueryAPI } from "../../../Api/v1/Util/Api";
import { auction_purchase_history_admin_url } from "../../../Api/APIUrl";
// import { useHistory } from "react-router-dom"; // Assuming you are using react-router-dom for navigation

export default function AuctionPurchaseHistoryDetails({ auctionType }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noofRecords, setNoOfRecords] = useState(0);
  const state = useSelector((state) => state);
  // const history = useHistory();

  const fetchData = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    const response = await fetchWithQueryAPI(token, auction_purchase_history_admin_url + "&auction_type=" + auctionType);

    if (response && response.statusCode === 200) {
      // Map the response data based on your API structure
      const formattedData = response.data.transaction_details.map(item => ({
        reference_id: item.reference_id,
        name: item.user_name,
        email: item.email,
        auction_name: item.auction_name,
        auction_type: capitalizeFirstLetter(item.auction_type),
        purchased_on: new Date(item.createdAt).toLocaleString(), // Using createdAt as Purchased On
        payment_status: capitalizeFirstLetter(item.payment_status)
      }));
      
      setData(formattedData);
      setNoOfRecords(formattedData.length);
    }
    setLoading(false);
  };

  useEffect(() => {
    setData([]);
    fetchData();
  }, [auctionType]);

  const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const handleNavigate = (referenceId) => {
    let url;
    if (auctionType === 'property') {
        url = `https://www.dalmaf.com/PropertyAuctionDetail/${referenceId}`;
    } else if (auctionType === 'gold') {
        url = `https://www.dalmaf.com/GoldAuctionDetail/${referenceId}`;
    } else if (auctionType === 'vehicle') {
        url = `https://www.dalmaf.com/VechileAuctionDetail/${referenceId}`;
    }
    if (url) {
      window.open(url, '_blank');
    }
  };

  const columns = [
    { name: "Name", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Email", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Auction Name", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Auction Type", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Purchased On", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "Payment Status", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    {
      name: "Actions", // Adding the reference_id as "Actions" for navigation
      options: {
        customBodyRender: (value, tableMeta) => {
          const referenceId = data[tableMeta.rowIndex].reference_id;
          return (
            <button
              onClick={() => handleNavigate(referenceId)}
              style={{
                backgroundColor: "#4CAF50", 
                color: "white",
                border: "none",
                padding: "5px 10px",
                cursor: "pointer",
                borderRadius: "5px"
              }}
            >
              View Details
            </button>
          );
        },
        setCellProps: () => ({ style: { textAlign: 'center' } }),
        filter: false,
        sort: false,
      },
    }
  ];

  const options = {
    selectableRows: "none",
    responsive: "standard",
    filter: true,
    search: true,
    pagination: false,
    tableBodyHeight: "500px",
    download: true,
    viewColumns: true,
    print: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            head: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
            },
            body: {
              textAlign: 'center',
            },
          },
        },
      },
    });

  return (
    <div>
      <div style={{ padding: "20px" }}>
        {loading && <Spinner />}
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={`All ${auctionType.charAt(0).toUpperCase() + auctionType.slice(1)} Purchase History (${noofRecords})`}
            data={data.map(item => [
              item.name,
              item.email,
              item.auction_name,
              item.auction_type,
              item.purchased_on,
              item.payment_status,
              "" // Placeholder for the Actions button, handled by customBodyRender
            ])}
            columns={columns}
            options={options}
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              border: "1px solid #ddd",
            }}
          />
        </ThemeProvider>
      </div>
    </div>
  );
}
