import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';  // Import the default CSS
import styles from './css/LeadInfoComponent.module.css';
import { lead_fetch_particular, lead_update } from '../../../../Api/APIUrl';
import { TextField, Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { fetchWithQueryAPI, updateAPI } from '../../../../Api/v1/Util/Api';

export default function LeadInfoComponent() {
    const { id } = useParams();  // Extract the lead ID from the URL parameters
    const state = useSelector((state) => state);

    const [lead, setLead] = useState(null);  // State to store lead details
    const [isEditMode, setIsEditMode] = useState(false);  // State to manage edit mode
    const [formValues, setFormValues] = useState({
        _id: '',
        type: '',
        company_name: '',
        email_id: '',
        phone_number: '',
        city: '',
        state: '',
        lead_type: '',
        country: '',
        address: '',
        zip: '',
        source: '',
        vat_number: '',
        gst_number: '',
        currency: '',
        currency_symbol: '',
        label_title: '',
        status: ''
    });

    // Fetch the specific lead details
    const fetchSpecificLeads = async () => {
        try {
            const response = await fetchWithQueryAPI(state.auth.auth.token, lead_fetch_particular + id);
            if (response && response.statusCode === 200) {
                setLead(response.data);  // Store lead details in the state
                setFormValues({
                    _id: id,
                    type: response.data.type,
                    lead_type: response.data.lead_type,
                    company_name: response.data.company_name,
                    email_id: response.data.email_id,
                    phone_number: response.data.phone_number,
                    city: response.data.city,
                    state: response.data.state,
                    country: response.data.country,
                    address: response.data.address,
                    zip: response.data.zip,
                    source: response.data.source,
                    vat_number: response.data.vat_number,
                    gst_number: response.data.gst_number,
                    currency: response.data.currency,
                    currency_symbol: response.data.currency_symbol,
                    status: response.data.status
                });
            } else {
                toast.error("Failed to fetch lead details");
            }
        } catch (error) {
            toast.error("An error occurred while fetching lead details");
        }
    };

    useEffect(() => {
        fetchSpecificLeads();
    }, [id]);

    const handleEdit = () => {
        setIsEditMode(!isEditMode);
    };

    const handleSave = async () => {
        try {
            const response = await updateAPI(state.auth.auth.token, lead_update, formValues);
            if (response && response.statusCode === 200) {
                toast.success("Lead updated successfully");
                setIsEditMode(false);  // Switch back to view mode
            } else {
                toast.error(response.message || 'Failed to update lead');
            }
        } catch (error) {
            toast.error("An error occurred while updating lead");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    };

    const statusOptions = ["New", "Qualified", "Discussion", "Negotiation", "Converted", "NotConverted"];
    const typeOptions = ["Person", "Organisation"];
    const typeLead = ["Cold", "Hot"];
    const currencyOptions = ["INR", "USD", "EURO"];
    const currencySymbols = ["₹", "$", "€"];

    return (
        <>
            <ToastContainer />
            <div className={styles.leadInfoContainer}>
                <div className={styles.leadInfo}>
                    {!lead ? (
                        <div className={styles.skeletonContainer}>
                            <div className={styles.skeletonCard}>
                                <Skeleton width={300} height={30} />
                                <Skeleton width={200} height={20} />
                                <Skeleton height={20} count={10} />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='row'>
                                {Object.keys(formValues).filter(key => !key.endsWith('_id')).map((key) => (
                                    <div className='col-md-4 col-lg-4 col-sm-12' key={key}>
                                        {key === "status" ? (
                                            isEditMode ? (
                                                <FormControl fullWidth margin="normal" variant="outlined">
                                                    <InputLabel>{key.replace(/_/g, ' ')}</InputLabel>
                                                    {
                                                        formValues[key] === "Converted" ?
                                                            <Select
                                                                value={formValues[key]}
                                                                name={key}
                                                                label={key.replace(/_/g, ' ')}
                                                                // onChange={handleChange}
                                                                disabled
                                                            >
                                                                {statusOptions.map(option => (
                                                                    <MenuItem key={option} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select> :
                                                            <Select
                                                                value={formValues[key]}
                                                                name={key}
                                                                label={key.replace(/_/g, ' ')}
                                                                onChange={handleChange}
                                                                // disabled
                                                            >
                                                                {statusOptions.map(option => (
                                                                    <MenuItem key={option} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                    }

                                                </FormControl>
                                            ) : (
                                                <TextField
                                                    label={key.replace(/_/g, ' ')}
                                                    value={formValues[key]}
                                                    name={key}
                                                    onChange={handleChange}
                                                    disabled={!isEditMode}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            )
                                        ) : key === "type" ? (
                                            isEditMode ? (
                                                <FormControl fullWidth margin="normal" variant="outlined">
                                                    <InputLabel>{key.replace(/_/g, ' ')}</InputLabel>
                                                    <Select
                                                        value={formValues[key]}
                                                        name={key}
                                                        onChange={handleChange}
                                                        label={key.replace(/_/g, ' ')}
                                                    >
                                                        {typeOptions.map(option => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                <TextField
                                                    label={key.replace(/_/g, ' ')}
                                                    value={formValues[key]}
                                                    name={key}
                                                    onChange={handleChange}
                                                    disabled={!isEditMode}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            )
                                        ) : key === "lead_type" ? (
                                            isEditMode ? (
                                                <FormControl fullWidth margin="normal" variant="outlined">
                                                    <InputLabel>{key.replace(/_/g, ' ')}</InputLabel>
                                                    <Select
                                                        value={formValues[key]}
                                                        name={key}
                                                        onChange={handleChange}
                                                        label={key.replace(/_/g, ' ')}
                                                    >
                                                        {typeLead.map(option => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                <TextField
                                                    label={key.replace(/_/g, ' ')}
                                                    value={formValues[key]}
                                                    name={key}
                                                    onChange={handleChange}
                                                    disabled={!isEditMode}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            )
                                        ) : key === "currency" ? (
                                            isEditMode ? (
                                                <FormControl fullWidth margin="normal" variant="outlined">
                                                    <InputLabel>{key.replace(/_/g, ' ')}</InputLabel>
                                                    <Select
                                                        value={formValues[key]}
                                                        name={key}
                                                        onChange={handleChange}
                                                        label={key.replace(/_/g, ' ')}
                                                    >
                                                        {currencyOptions.map(option => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                <TextField
                                                    label={key.replace(/_/g, ' ')}
                                                    value={formValues[key]}
                                                    name={key}
                                                    onChange={handleChange}
                                                    disabled={!isEditMode}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            )
                                        ) : key === "currency_symbol" ? (
                                            isEditMode ? (
                                                <FormControl fullWidth margin="normal" variant="outlined">
                                                    <InputLabel>{key.replace(/_/g, ' ')}</InputLabel>
                                                    <Select
                                                        value={formValues[key]}
                                                        name={key}
                                                        onChange={handleChange}
                                                        label={key.replace(/_/g, ' ')}
                                                    >
                                                        {currencySymbols.map((symbol, index) => (
                                                            <MenuItem key={symbol} value={symbol}>
                                                                {symbol}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                <TextField
                                                    label={key.replace(/_/g, ' ')}
                                                    value={formValues[key]}
                                                    name={key}
                                                    onChange={handleChange}
                                                    disabled={!isEditMode}
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            )
                                        ) : (
                                            <TextField
                                                label={key.replace(/_/g, ' ')}
                                                value={formValues[key]}
                                                name={key}
                                                onChange={handleChange}
                                                disabled={!isEditMode}
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className={styles.buttonContainer}>
                                {isEditMode ? (
                                    <>
                                        <button
                                            className='btn btn-primary'
                                            onClick={handleSave}
                                        // className={styles.saveButton}
                                        >
                                            Update
                                        </button>
                                        <button
                                            className='btn'
                                            onClick={handleEdit}
                                        // className={styles.saveButton}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        className='btn btn-primary'
                                        onClick={handleEdit}
                                    // className={styles.saveButton}
                                    >
                                        Edit
                                    </button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
