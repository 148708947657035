import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import { useSelector } from "react-redux";
import { GetSpecificPricing, UpdatePricing } from "../../../Api/Api";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate, useParams } from "react-router-dom";
import { NonEmptyValidation } from "../../../Store/validate";

export default function PricingEdit() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { id } = useParams();
  useEffect(() => {
    window.scroll(0, 0);

    GetPricingSpecificTsks();
  }, []);

  const [pricingEdit, setPricingEdit] = useState({});
  const [pricingEdit1, setPricingEdit1] = useState({});
  const [pricingEditValidation, setPricingEditValidation] = useState({});
  const [pricingEditValidation1, setPricingEditValidation1] = useState({});

  const setPricingEditValue = (key, value) => {
    setPricingEdit({ ...pricingEdit, [key]: value });
    if (pricingEditValidation[key]) delete pricingEditValidation[key];
  };

  const setPricingValidationValue = (key, value) => {
    setPricingEditValidation({ ...pricingEditValidation, [key]: value });
  };

  const setPricingValue1 = (parentKey, childKey, value, type) => {
    if (type === "object") {
      setPricingEdit1({
        ...pricingEdit1,
        ...{ [parentKey]: { ...pricingEdit1[parentKey], [childKey]: value } },
      });
      if (pricingEditValidation[parentKey]) delete pricingEditValidation[parentKey];
      if (pricingEditValidation[childKey]) delete pricingEditValidation[childKey];
    }
  };

  const setPricingValidationValue1 = (parentKey, childKey, value, type) => {
    if (type === "object") {
      setPricingEditValidation1({
        ...pricingEditValidation1,
        ...{
          [parentKey]: { ...pricingEditValidation1[parentKey], [childKey]: value },
        },
      });
    }
  };

  const GetPricingSpecificTsks = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GetSpecificPricing(id);
    if (data && data.statusCode === 200) {
      setPricingEdit(data.data);
      setPricingEdit1(data.data.price[0]);
      setLoading(false);
    } else {
      setPricingEdit1([]);
      setLoading(false);
      //console.log("Error while getting my specific task");
    }
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    let validate1 = {};

    validate1.marketPrice = NonEmptyValidation(pricingEdit1?.solo?.marketPrice);
    validate1.dalmafPrice = NonEmptyValidation(pricingEdit1?.solo?.dalmafPrice);
    validate1.GST = NonEmptyValidation(pricingEdit1?.solo?.GST);
    validate1.marketPrice = NonEmptyValidation(pricingEdit1?.company?.marketPrice);
    validate1.dalmafPrice = NonEmptyValidation(pricingEdit1?.company?.dalmafPrice);
    validate1.GST = NonEmptyValidation(pricingEdit1?.company?.GST);
    setPricingEditValidation1(validate1);
    if (Object.values(validate1).every((v) => v.status === true)) {
      const Payload = {
        pricingId: id,
        pageName: pricingEdit?.pageName,
        price: [
          {
            solo: {
              dalmafPrice: Number(pricingEdit1?.solo?.dalmafPrice),
              marketPrice: Number(pricingEdit1?.solo?.marketPrice),
              GST: Number(pricingEdit1?.solo?.GST),
            },
            company: {
              dalmafPrice: Number(pricingEdit1?.company?.dalmafPrice),
              marketPrice: Number(pricingEdit1?.company?.marketPrice),
              GST: Number(pricingEdit1?.company?.GST),
            },
          },
        ],
      };

      const token = state?.auth?.auth?.token;
      const response = await UpdatePricing(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        window.scrollTo(0, 0);
        GetPricingSpecificTsks();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };

  return (
    <div>
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        <div>
          <div className="">
            <button
              type="button"
              className="btn login-btn ms-2 "
              onClick={(e) => {
                navigate("/Pricing");
              }}
            >
              Back
            </button>
          </div>
          <div className=" my-4">
            <div className="bg-historytablehead rounded-3 py-3 px-3">
              <h6 className="mb-0">Update Pricing</h6>
            </div>
            <div className="container">
              <div className="row justify-content-center py-5">
                <div className="col-lg-11">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Service Name</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter page name"
                          aria-invalid={Boolean(
                            pricingEditValidation?.pageName?.status === false
                          )}
                          defaultValue={pricingEdit?.pageName}
                          onChange={(e) => {
                            //console.log(e.target.value);
                            setPricingEditValue("pageName", e.target.value);
                          }}
                          onBlur={(e) => {
                            setPricingValidationValue(
                              "pageName",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          disabled
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {pricingEditValidation?.pageName?.message
                            ? `Page Name ${pricingEditValidation?.pageName?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div>
                          <h5>Solo</h5>
                        </div>
                        <div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Market Price</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter market price"
                                aria-invalid={Boolean(
                                  pricingEditValidation1?.solo?.marketPrice
                                    ?.status === false
                                )}
                                defaultValue={pricingEdit1?.solo?.marketPrice}
                                onChange={(e) => {
                                  setPricingValue1(
                                    "solo",
                                    "marketPrice",
                                    e.target.value,
                                    "object"
                                  );
                                }}
                                onBlur={(e) => {
                                  setPricingValidationValue1(
                                    "solo",
                                    "marketPrice",
                                    NonEmptyValidation(e.target.value),
                                    "object"
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {pricingEditValidation1?.solo?.marketPrice?.message
                                  ? `Market Price ${pricingEditValidation1?.solo?.marketPrice?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label"> Dalmaf Price </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter dalmaf price"
                                aria-invalid={Boolean(
                                  pricingEditValidation1?.solo?.dalmafPrice
                                    ?.status === false
                                )}
                                defaultValue={pricingEdit1?.solo?.dalmafPrice}
                                onChange={(e) => {
                                  setPricingValue1(
                                    "solo",
                                    "dalmafPrice",
                                    e.target.value,
                                    "object"
                                  );
                                }}
                                onBlur={(e) => {
                                  setPricingValidationValue1(
                                    "solo",
                                    "dalmafPrice",
                                    NonEmptyValidation(e.target.value),
                                    "object"
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {pricingEditValidation1?.solo?.dalmafPrice?.message
                                  ? `Dalmaf Price ${pricingEditValidation1?.solo?.dalmafPrice?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">GST</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter GST"
                                aria-invalid={Boolean(
                                  pricingEditValidation1?.solo?.GST?.status === false
                                )}
                                defaultValue={pricingEdit1?.solo?.GST}
                                onChange={(e) => {
                                  setPricingValue1(
                                    "solo",
                                    "GST",
                                    e.target.value,
                                    "object"
                                  );
                                }}
                                onBlur={(e) => {
                                  setPricingValidationValue1(
                                    "solo",
                                    "GST",
                                    NonEmptyValidation(e.target.value),
                                    "object"
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {pricingEditValidation1?.solo?.GST?.message
                                  ? `GST ${pricingEditValidation1?.solo?.GST?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-6">
                        <div>
                          <h5>Company</h5>
                        </div>
                        <div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Market Price</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter market price"
                                aria-invalid={Boolean(
                                  pricingEditValidation1?.company?.marketPrice
                                    ?.status === false
                                )}
                                defaultValue={pricingEdit1?.company?.marketPrice}
                                onChange={(e) => {
                                  setPricingValue1(
                                    "company",
                                    "marketPrice",
                                    e.target.value,
                                    "object"
                                  );
                                }}
                                onBlur={(e) => {
                                  setPricingValidationValue1(
                                    "company",
                                    "marketPrice",
                                    NonEmptyValidation(e.target.value),
                                    "object"
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {pricingEditValidation1?.company?.marketPrice
                                  ?.message
                                  ? `Market Price ${pricingEditValidation1?.company?.marketPrice?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label"> Dalmaf Price </label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter dalmaf price"
                                aria-invalid={Boolean(
                                  pricingEditValidation1?.company?.dalmafPrice
                                    ?.status === false
                                )}
                                defaultValue={pricingEdit1?.company?.dalmafPrice}
                                onChange={(e) => {
                                  setPricingValue1(
                                    "company",
                                    "dalmafPrice",
                                    e.target.value,
                                    "object"
                                  );
                                }}
                                onBlur={(e) => {
                                  setPricingValidationValue1(
                                    "company",
                                    "dalmafPrice",
                                    NonEmptyValidation(e.target.value),
                                    "object"
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {pricingEditValidation1?.company?.dalmafPrice
                                  ?.message
                                  ? `Dalmaf Price ${pricingEditValidation1?.company?.dalmafPrice?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">GST</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter GST"
                                aria-invalid={Boolean(
                                  pricingEditValidation1?.company?.GST?.status ===
                                    false
                                )}
                                defaultValue={pricingEdit1?.company?.GST}
                                onChange={(e) => {
                                  setPricingValue1(
                                    "company",
                                    "GST",
                                    e.target.value,
                                    "object"
                                  );
                                }}
                                onBlur={(e) => {
                                  setPricingValidationValue1(
                                    "company",
                                    "GST",
                                    NonEmptyValidation(e.target.value),
                                    "object"
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {pricingEditValidation1?.company?.GST?.message
                                  ? `GST ${pricingEditValidation1?.company?.GST?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mt-4">
                      <button
                        type="button"
                        className="btn white-btn me-2 "
                        onClick={() => {
                          setPricingEditValidation({});
                          setPricingEdit({});
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn login-btn ms-2 "
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
