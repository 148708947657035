import React, { useEffect, useState } from "react";

import Norecord from "../../Assets/Images/MicrosoftTeams-image.webp";

import Si1 from "../../Assets/Images/si-1.webp";
import Si2 from "../../Assets/Images/si-2.webp";
import Si3 from "../../Assets/Images/si-3.webp";
import LegalPageImg from "../../Assets/Images/legal-page-img.webp";
import LegalBG from "../../Assets/Images/legal-page-bg.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  EmailValidation,
  NonEmptyValidation,
  PhoneNumberValidation,
  StringValidation,
} from "../../Store/validate";
import { GetProperty, GetUser, UserServiceRequest, UseraboutContent } from "../../Api/Api";
import ServicesAllpages from "../ServicesAllpages";

export default function BSPlotContent() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [selectedTab, setSelectedTab] = React.useState("Whats Included");
  const AllTabs = [
    "Whats Included",
    "Whats Not Included",
    "You May Also Want To Know",
  ];

  const state = useSelector((state) => state);

  const navigate = useNavigate()

  const [bsPlotCont, setBsPlotCont] = useState({});

  const [bsPlotContValidation, setBsPlotContValidation] = useState({});

  const setBsPlotContValue = (key, value) => {
    setBsPlotCont({ ...bsPlotCont, [key]: value });
    if (bsPlotContValidation[key]) delete bsPlotContValidation[key];
  };

  const setBsPlotContValidationValue = (key, value) => {
    setBsPlotContValidation({ ...bsPlotContValidation, [key]: value });
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const [userProfile, setBsPlotContProfile] = useState([]);
  useEffect(() => {
    if (state?.auth?.isLoggedIn === true) {
      getUserProfile();
    }
    getBsPlotContfilterList();
  }, []);
  const getUserProfile = async () => {
    const id = state?.auth?.auth?._id;
    const token = state.auth.auth.token;
    const response = await GetUser(token, id);
    if (response.statusCode === 200) {
      setBsPlotContProfile(response.data);
    } else {
      //console.log("error while getting user profile info");
    }
  };

  const naviGation = useNavigate();

  //User Registration api call

  const handleSubmit = async (e) => {
    let local = localStorage?.getItem("category")?.split("&")
    let local1 = local[0]?.split("/")
    e.preventDefault();
   
      let validate = {};
      validate.name = StringValidation(bsPlotCont?.name);
      validate.emailId = EmailValidation(bsPlotCont?.emailId);
      validate.subject = NonEmptyValidation(bsPlotCont?.subject);
      validate.question = NonEmptyValidation(bsPlotCont?.question);
      validate.mobileNumber = PhoneNumberValidation(bsPlotCont?.mobileNumber);
  validate.servicesType = NonEmptyValidation(bsPlotCont?.servicesType);

      setBsPlotContValidation(validate);
      if (Object.values(validate).every((v) => v.status === true)) {
        const Payload = {
          name: bsPlotCont.name,
          emailId: bsPlotCont.emailId,
          subject: bsPlotCont.subject,
          question: bsPlotCont.question,
          mobileNumber: bsPlotCont.mobileNumber,
          userId: state?.auth?.auth?._id,
          servicesType: bsPlotCont.servicesType,

        };
       
        const response = await UseraboutContent(Payload);
        if (response && response.statusCode === 200) {
          showToastSuccessMsg(response.message);
          window.location.reload();
          bsPlotCont({});
          setBsPlotCont({});
          localStorage?.removeItem("category");
          localStorage?.removeItem("subcategory");
          localStorage?.removeItem("title");
        } else {
          showToastErrorMsg(
            response.message || response.error.response.data.message
          );
         
         
        }
      } else {
       
      }
 
  };

  const [projectBsPlotContList, setProjectBsPlotContList] = useState([]);
  const [filterBsPlotContList, setFilterBsPlotContList] = useState([]);
  const [metatag, setMetatag] = useState([]);
  const filterDataChange = async (key,key1, value,value1,e,e1) => {
    if(key==="parking" && e.target.checked===true){
      
        let finddata = projectBsPlotContList?.filter((e)=>{
          return e.parking === value
        })
        if(finddata?.length){
          setFilterBsPlotContList(finddata)
        }else{
          setFilterBsPlotContList(projectBsPlotContList);
        }
      
    }
    
    if(key1==="furnished_type" && e1.target.checked===true){

        let finddata = projectBsPlotContList?.filter((e)=>{
          return e.furnished_type === value1
        })
        if(finddata?.length){
          setFilterBsPlotContList(finddata)
        }else{
          setFilterBsPlotContList(projectBsPlotContList);
        }
    
    }
    
    if(key1==="furnished_type" && key==="parking" && e.target.checked===true && e1.target.checked===true){
     
        let finddata = projectBsPlotContList?.filter((e)=>{
          return e.furnished_type === value1 && e.parking === value
        })
        if(finddata?.length){
          setFilterBsPlotContList(finddata)
        }else{
          setFilterBsPlotContList(projectBsPlotContList);
        }
      
    }
    if(key1==="furnished_type" && e1.target.checked===false){
      setFilterBsPlotContList(projectBsPlotContList);
    }
    if(key==="parking" && e.target.checked===false){
      setFilterBsPlotContList(projectBsPlotContList);
    }
   
  }
  const getBsPlotContfilterList = async () => {
    
    const response = await GetProperty("Active");
    if (response && response.statusCode === 200) {
      setProjectBsPlotContList(
        response.data.filter((e) => e.property_type === "Plot" && e.created_property_type==="Buy-Sell")
      );
    } else {
      showToastErrorMsg(response.message);
    }
  };
 


  return (
    <div className="overflow-pages">
      <ToastContainer />
      <div className="about-banner2 py-5">
        <div className="container">
          <section className="property">
            <div className="container">
              <div className="propertycardbottomalign">
                <h1
                  className="blogtitle my-auto"
                  data-aos="zoom-out-right"
                  data-aos-duration="2000"
                >
                  Plot
                </h1>
                <div className="text-end">
                  <button
                    className="btn login-btn"
                    type="button"
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Filter
                  </button>
                  <ul
                    style={{ width: "400px", cursor: "pointer" }}
                    className="dropdown-menu top-drop-menu m-2 "
                    aria-labelledby="dropdownMenuButton2"
                  >
                    <h4 className="text-start" style={{ marginLeft: "10px" }}>
                      Advanced Search
                    </h4>
                    <div style={{ marginLeft: "10px" }}>
                      <h5>Parking</h5>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="mb-4">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                                //console.log(e.target.checked, "2");
                                filterDataChange(
                                  "parking",
                                  "",
                                  "2 wheeler",
                                  "",
                                  e,
                                  ""
                                );
                              }}
                            />{" "}
                            <label className="form-label"> 2 Wheeler</label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="mb-4">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                                //console.log(e.target.checked, "4");
                                filterDataChange(
                                  "parking",
                                  "",
                                  "4 wheeler",
                                  "",
                                  e,
                                  ""
                                );
                              }}
                            />{" "}
                            <label className="form-label"> 4 Wheeler</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <h5>Furnishing</h5>
                      <div className="row">
                        <div className="col-lg-4 col-md-4">
                          <div className="mb-4">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                               
                                filterDataChange(
                                  "",
                                  "furnished_type",
                                  "",
                                  "full",
                                  "",
                                  e
                                );
                              }}
                            />{" "}
                            <label className="form-label"> Full</label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="mb-4">
                            <input
                              type="checkbox"
                              onClick={(e) => {

                                filterDataChange(
                                  "",
                                  "furnished_type",
                                  "",
                                  "semi",
                                  "",
                                  e
                                );
                              }}
                            />{" "}
                            <label className="form-label"> Semi</label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="mb-4">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                              
                                filterDataChange(
                                  "",
                                  "furnished_type",
                                  "",
                                  "none",
                                  "",
                                  e
                                );
                              }}
                            />{" "}
                            <label className="form-label"> None</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                
                </div>
              </div>
              <div className="projectrow">
                <div className="row">
                  <div className="col-lg-11 mx-auto ms-auto">
                    {projectBsPlotContList.length === 0 &&
                      filterBsPlotContList.length === 0 && (
                        <img
                          src={Norecord}
                          alt=""
                          style={{
                            width: "200px",
                            height: "200px",
                            marginLeft: "500px",
                          }}
                          className="img-fluid"
                        />
                      )}

                    <div className="row">
                      {filterBsPlotContList && filterBsPlotContList?.length ? (
                        <>
                          {filterBsPlotContList?.map((e, i) => (
                            <div className="col-lg-3 mb-3" key={i}>
                              <div className="admin-service">
                                <div className="small-banner-img-head">
                                  <img
                                    style={{ width: "100%", height: "200px" }}
                                    // className="w-100 h-auto"
                                    src={e.cover_image}
                                    alt=""
                                  />
                                </div>
                                <div className="mt-3">
                                  <h6 className="color-maroon fw-bold">
                                    {e?.property_name}
                                  </h6>
                                  <p className="small">
                                    <span className="material-symbols-rounded align-middle me-1 filled-icon">
                                      location_on
                                    </span>
                                    {e?.property_information.address}
                                  </p>

                                  <p className="small">
                                    <b>Room:</b>{" "}
                                    {e?.property_information.room_size}
                                    BHK
                                  </p>
                                  <p className="small">
                                    <b>SqFt :</b>{" "}
                                    {e?.property_information.sq_ft}
                                  </p>

                                  <button
                                    type="button"
                                    className="btn btn-admin w-100"
                                    onClick={() => {
                                      navigate(`/PlotDetails/${e._id}`);
                                    }}
                                  >
                                    View Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          {projectBsPlotContList?.map((e, i) => (
                            <div className="col-lg-3 mb-3" key={i}>
                              <div className="admin-service">
                                <div className="small-banner-img-head">
                                  <img
                                    style={{ width: "100%", height: "200px" }}
                                    // className="w-100 h-auto"
                                    src={e.cover_image}
                                    alt=""
                                  />
                                </div>
                                <div className="mt-3">
                                  <h6 className="color-maroon fw-bold">
                                    {e?.property_name}
                                  </h6>
                                  <p className="small">
                                    <span className="material-symbols-rounded align-middle me-1 filled-icon">
                                      location_on
                                    </span>
                                    {e?.property_information.address}
                                  </p>

                                  <p className="small">
                                    <b>Room:</b>{" "}
                                    {e?.property_information.room_size}
                                    BHK
                                  </p>
                                  <p className="small">
                                    <b>SqFt :</b>{" "}
                                    {e?.property_information.sq_ft}
                                  </p>

                                  <button
                                    type="button"
                                    className="btn btn-admin w-100"
                                    onClick={() => {
                                      navigate(`/PlotDetails/${e._id}`);
                                    }}
                                  >
                                    View Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="client-left-section py-5">
        <div className="container my-4">
          <h1 className="banner-heading after-line mb-5">FEEL FREE TO ASK</h1>
          <div className="row">
            <div className="col-lg-6" data-aos="fade-left">
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.823571029211!2d80.23700327559305!3d13.046899587275508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267c25ec3b519%3A0xb10841584ccc73c4!2sDALMAF%20Private%20Limited!5e0!3m2!1sen!2sin!4v1688118977225!5m2!1sen!2sin"
                  width="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6 col-md-12" data-aos="fade-left">
              
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Name"
                      aria-invalid={Boolean(
                        bsPlotContValidation?.name?.status === false
                      )}
                      value={bsPlotCont?.name || ""}
                      onChange={(e) => {
                        setBsPlotContValue("name", e.target.value);
                      }}
                      onBlur={(e) => {
                        setBsPlotContValidationValue(
                          "name",
                          StringValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {bsPlotContValidation?.name?.message
                        ? `Name ${bsPlotContValidation?.name?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Mobile No <span className="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      maxLength={10}
                      className="form-control"
                      placeholder="Enter Your Mobile No"
                      aria-invalid={Boolean(
                        bsPlotContValidation?.mobileNumber?.status === false
                      )}
                      value={bsPlotCont?.mobileNumber || ""}
                      onChange={(e) => {
                        setBsPlotContValue("mobileNumber", e.target.value);
                      }}
                      onBlur={(e) => {
                        setBsPlotContValidationValue(
                          "mobileNumber",
                          PhoneNumberValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {bsPlotContValidation?.mobileNumber?.message
                        ? `Mobile no ${bsPlotContValidation?.mobileNumber?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      E- Mail ID <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your E- Mail ID"
                      aria-invalid={Boolean(
                        bsPlotContValidation?.emailId?.status === false
                      )}
                      value={bsPlotCont?.emailId?.toLowerCase() || ""}
                      onChange={(e) => {
                        setBsPlotContValue("emailId", e.target.value);
                      }}
                      onBlur={(e) => {
                        setBsPlotContValidationValue(
                          "emailId",
                          EmailValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {bsPlotContValidation?.emailId?.message
                        ? `Email ${bsPlotContValidation?.emailId?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Questions <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Questions "
                      aria-invalid={Boolean(
                        bsPlotContValidation?.question?.status === false
                      )}
                      value={bsPlotCont?.question || ""}
                      onChange={(e) => {
                        setBsPlotContValue("question", e.target.value);
                      }}
                      onBlur={(e) => {
                        setBsPlotContValidationValue(
                          "question",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {bsPlotContValidation?.question?.message
                        ? `Question ${bsPlotContValidation?.question?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Service Type
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <select
                      className="form-control form-control-lg"
                      value={bsPlotCont?.servicesType || ""}
                      onChange={(e) => {
                        setBsPlotContValue("servicesType", e.target.value);
                      }}
                      onBlur={(e) => {
                        setBsPlotContValidationValue(
                          "servicesType",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    >
                      <option value="">---Select Service Type---</option>
                      <option value="Legal">Legal</option>
                      <option value="Audit">Audit</option>
                      <option value="Manpower">Manpower</option>
                      <option value="Auction">Auction</option>
                      <option value="Buy/Sell">Buy/Sell</option>
                    </select>
                    
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Subject <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows="6"
                      className="form-control"
                      placeholder="Enter Subject"
                      aria-invalid={Boolean(
                        bsPlotContValidation?.subject?.status === false
                      )}
                      value={bsPlotCont?.subject || ""}
                      onChange={(e) => {
                        setBsPlotContValue("subject", e.target.value);
                      }}
                      onBlur={(e) => {
                        setBsPlotContValidationValue(
                          "subject",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    ></textarea>
                    <small className="text-danger">
                      {bsPlotContValidation?.subject?.message
                        ? `Subject ${bsPlotContValidation?.subject?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  

                  <button
                    type="button"
                    className="btn login-btn w-100"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      <div className="number-counter py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <span className="material-symbols-rounded align-middle about-call filled-icon">
                        call
                      </span>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">Toll Free</h5>
                    <h5 className="mb-0 fs-4">1800-309-8445</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <span className="material-symbols-rounded align-middle about-call filled-icon">
                        mail
                      </span>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">Email ID</h5>
                    <h5 className="mb-0 fs-4">info@dalmaf.com</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <i className="fa-brands fa-whatsapp about-whatsapp-icon"></i>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">WhatsApp Us</h5>
                    <h5 className="mb-0 fs-4">+91 96000 38059</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ServicesAllpages />
      </div>
    </div>
  );
}
