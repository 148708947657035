import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import { GetUser, CreatePricing, GetPricing } from "../../../Api/Api";
import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NonEmptyValidation } from "../../../Store/validate";

import Spinner from "../../../Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";

export default function Pricing() {
  const [selectedPricingTab, setSelectedPricingTab] = React.useState("All");
  const AllTabs = ["All"];

  const navigate = useNavigate();
  const [newPricingTask, setNewPricingTask] = useState(true);

  const [loading, setLoading] = useState(true);

  const [getPricingMyTask, setGetPrincingMyTask] = useState([]);

  const state = useSelector((state) => state);

  //getlead
  const GetPricingTsks = async () => {
    setLoading(true);
    // const token = state.auth.auth.token;
    // const creator_id = state.auth.auth._id;
    let data = await GetPricing();
    if (data && data.statusCode === 200) {
      setGetPrincingMyTask(data.data);
      setLoading(false);
    } else {
      setGetPrincingMyTask([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };

  const [profilePrincingdetails, setProfilePrincingDetails] = useState([]);
  async function fetchDataProfile() {
    setLoading(true);
    const id = state?.auth?.auth?._id;
    const token = state?.auth?.auth?.token;
    const getBasicPricingDetails = await GetUser(token, id);
    if (getBasicPricingDetails && getBasicPricingDetails?.statusCode === 200) {
      setProfilePrincingDetails(getBasicPricingDetails.data);
      setLoading(false);
    } else {
      setProfilePrincingDetails([]);
      setLoading(false);
      //console.log("error while getting user profile info");
    }
  }

  useEffect(() => {
    window.scroll(0, 0);

    GetPricingTsks();
    fetchDataProfile();
  }, []);

  const [myPricingTask, setMyPricingTask] = useState({});
  const [myTaskPricingValidation, setMyTaskPricingValidation] = useState({});

  const setprincingValue = (key, value) => {
    setMyPricingTask({ ...myPricingTask, [key]: value });
    if (myTaskPricingValidation[key]) delete myTaskPricingValidation[key];
  };

  const setPricingValidationValue = (key, value) => {
    setMyTaskPricingValidation({ ...myTaskPricingValidation, [key]: value });
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.pageName = NonEmptyValidation(myPricingTask?.pageName);
    validate.marketPrice = NonEmptyValidation(myPricingTask?.marketPrice);
    validate.dalmafPrice = NonEmptyValidation(myPricingTask?.dalmafPrice);
    validate.GST = NonEmptyValidation(myPricingTask?.GST);
    validate.marketPrice1 = NonEmptyValidation(myPricingTask?.marketPrice1);
    validate.dalmafPrice1 = NonEmptyValidation(myPricingTask?.dalmafPrice1);
    validate.GST1 = NonEmptyValidation(myPricingTask?.GST1);

    setMyTaskPricingValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        pageName: myPricingTask.pageName,
        price: [
          {
            solo: {
              dalmafPrice: Number(myPricingTask.dalmafPrice),
              marketPrice: Number(myPricingTask.marketPrice),
              GST: Number(myPricingTask.GST),
            },
            company: {
              dalmafPrice: Number(myPricingTask.dalmafPrice1),
              marketPrice: Number(myPricingTask.marketPrice1),
              GST: Number(myPricingTask.GST1),
            },
          },
        ],
      };

      const token = state?.auth?.auth?.token;
      const response = await CreatePricing(Payload, token);
      if (response && response.statusCode === 200) {
        setNewPricingTask(true);
        GetPricingTsks();
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "pageName",
      label: "Service Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                className=""
                onClick={(val) => {
                  navigate(`/PricingEdit/${tableMeta.rowData[4]}`);
                }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },

    {
      name: "price",
      label: "Solo Price",
      options: {
        customBodyRender: (value) => {
          return (
            <div className="row">
              {value?.map((e, i) => (
                <div className="col-6" key={i}>
                  Market: {e?.solo.marketPrice}
                </div>
              ))}
              {value?.map((e, i) => (
                <div className="col-6" key={i}>
                  Dalmaf: {e?.solo?.dalmafPrice}
                </div>
              ))}
            </div>
          );
        },
      },
    },

    {
      name: "price",
      label: "Company Price",
      options: {
        customBodyRender: (value) => {
          return (
            <div className="row">
              {value?.map((e, i) => (
                <div className="col-6" key={i}>
                  Market: {e?.company.marketPrice}
                </div>
              ))}
              {value?.map((e, i) => (
                <div className="col-6" key={i}>
                  Dalmaf: {e?.company?.dalmafPrice}
                </div>
              ))}
            </div>
          );
        },
      },
    },
    {
      name: "pricingId",
      label: "PricingId",
      options: {
        display: false,
      },
    },
    {
      name: "subadmin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[5] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {newPricingTask ? (
              <div>
                <div className="d-flex justify-content-between">
                  <h5 className="mb-0 fw-bold color-maroon">Pricing</h5>
                  {/* <div>
                    <button
                      type="button"
                      className="btn py-1 login-btn"
                      onClick={() => {
                        setNewPricingTask(false);
                      }}
                    >
                      Create Pricing
                    </button>
                  </div> */}
                </div>
                <div className="my-4">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7 px-0">
                        <div className="tab-section">
                          <ul className="ps-0">
                            {AllTabs.map((tab, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    selectedPricingTab === tab
                                      ? "serviceTab active"
                                      : "serviceTab inactive"
                                  }
                                  onClick={() => {
                                    // if (tab === "Active") {
                                    //   GetPricingTsks("rejected");
                                    // } else if (tab === "Converted") {
                                    //   GetPricingTsks("converted");
                                    // }

                                    setSelectedPricingTab(tab);
                                  }}
                                >
                                  <li>
                                    <span className="align-middle">{tab}</span>
                                  </li>
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <MUIDataTable
                    title={"Dalmaf - Pricing"}
                    data={getPricingMyTask}
                    columns={columns}
                    options={options}
                  />
                  {/* <table className="table">
                    <thead className="bg-historytablehead">
                      <tr>
                        <th scope="col" style={{ borderTopLeftRadius: "0" }}>
                          S. No
                        </th>
                        <th scope="col">client name</th>
                        <th scope="col">service request</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>

                    {getPricingMyTask?.map((e, index) => (
                      <tbody className="historytablebody">
                        <tr
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(`/LeadEdit/${e._id}`);
                          }}
                        >
                         
                          <td>
                            <div className="">
                              <h6 className="fw-bold">{index + 1}</h6>
                            </div>
                          </td>
                          <td scope="row">
                            <div className="d-flex align-items-center ">
                              <div className=" ms-3">
                                <h6 className="fw-bold">{e?.clientName}</h6>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className=" ms-3">
                              <h6 className="fw-bold">{e?.serviceRequest}</h6>
                            </div>
                          </td>

                          <td>
                            <h6 className="fw-bold">
                              {moment(e.createdAt).format("DD/MM/YYYY")}
                            </h6>
                          </td>

                          <td>
                            <h6 className="fw-bold color-red">{e.status}</h6>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table> */}
                </div>
              </div>
            ) : (
              <div>
                <div className="">
                  <button
                    type="button"
                    className="btn login-btn ms-2 "
                    onClick={() => {
                      setNewPricingTask(true);
                      setMyTaskPricingValidation({});
                      setMyPricingTask({});
                    }}
                  >
                    Back
                  </button>
                </div>
                <div className=" my-4">
                  <div className="bg-historytablehead rounded-3 py-3 px-3">
                    <h6 className="mb-0">Create Pricing</h6>
                  </div>
                  <div className="container">
                    <div className="row justify-content-center py-5">
                      <div className="col-lg-11">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Page Name</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter page name"
                                aria-invalid={Boolean(
                                  myTaskPricingValidation?.pageName?.status === false
                                )}
                                defaultValue={myPricingTask?.pageName}
                                onChange={(e) => {
                                  setprincingValue("pageName", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setPricingValidationValue(
                                    "pageName",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {myTaskPricingValidation?.pageName?.message
                                  ? `Page Name ${myTaskPricingValidation?.pageName?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div>
                                <h5>Solo</h5>
                              </div>
                              <div>
                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <label className="form-label">
                                      Market Price
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-lg"
                                      placeholder="Enter market price"
                                      aria-invalid={Boolean(
                                        myTaskPricingValidation?.marketPrice
                                          ?.status === false
                                      )}
                                      defaultValue={myPricingTask?.marketPrice}
                                      onChange={(e) => {
                                        setprincingValue(
                                          "marketPrice",
                                          e.target.value
                                        );
                                      }}
                                      onBlur={(e) => {
                                        setPricingValidationValue(
                                          "marketPrice",
                                          NonEmptyValidation(e.target.value)
                                        );
                                      }}
                                      autoComplete="off"
                                    />
                                    <small className="text-danger">
                                      {myTaskPricingValidation?.marketPrice?.message
                                        ? `Market Price ${myTaskPricingValidation?.marketPrice?.message}`
                                        : ""}
                                    </small>
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <label className="form-label">
                                      {" "}
                                      Dalmaf Price{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-lg"
                                      placeholder="Enter dalmaf price"
                                      aria-invalid={Boolean(
                                        myTaskPricingValidation?.dalmafPrice
                                          ?.status === false
                                      )}
                                      defaultValue={myPricingTask?.dalmafPrice}
                                      onChange={(e) => {
                                        setprincingValue(
                                          "dalmafPrice",
                                          e.target.value
                                        );
                                      }}
                                      onBlur={(e) => {
                                        setPricingValidationValue(
                                          "dalmafPrice",
                                          NonEmptyValidation(e.target.value)
                                        );
                                      }}
                                      autoComplete="off"
                                    />
                                    <small className="text-danger">
                                      {myTaskPricingValidation?.dalmafPrice?.message
                                        ? `Dalmaf Price ${myTaskPricingValidation?.dalmafPrice?.message}`
                                        : ""}
                                    </small>
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <label className="form-label">GST</label>
                                    <input
                                      type="text"
                                      className="form-control form-control-lg"
                                      placeholder="Enter GST"
                                      aria-invalid={Boolean(
                                        myTaskPricingValidation?.GST?.status === false
                                      )}
                                      defaultValue={myPricingTask?.GST}
                                      onChange={(e) => {
                                        setprincingValue("GST", e.target.value);
                                      }}
                                      onBlur={(e) => {
                                        setPricingValidationValue(
                                          "GST",
                                          NonEmptyValidation(e.target.value)
                                        );
                                      }}
                                      autoComplete="off"
                                    />
                                    <small className="text-danger">
                                      {myTaskPricingValidation?.GST?.message
                                        ? `GST ${myTaskPricingValidation?.GST?.message}`
                                        : ""}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div>
                                <h5>Company</h5>
                              </div>
                              <div>
                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <label className="form-label">
                                      Market Price
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-lg"
                                      placeholder="Enter market price"
                                      aria-invalid={Boolean(
                                        myTaskPricingValidation?.marketPrice1
                                          ?.status === false
                                      )}
                                      defaultValue={myPricingTask?.marketPrice1}
                                      onChange={(e) => {
                                        setprincingValue(
                                          "marketPrice1",
                                          e.target.value
                                        );
                                      }}
                                      onBlur={(e) => {
                                        setPricingValidationValue(
                                          "marketPrice1",
                                          NonEmptyValidation(e.target.value)
                                        );
                                      }}
                                      autoComplete="off"
                                    />
                                    <small className="text-danger">
                                      {myTaskPricingValidation?.marketPrice1?.message
                                        ? `Market Price ${myTaskPricingValidation?.marketPrice1?.message}`
                                        : ""}
                                    </small>
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <label className="form-label">
                                      {" "}
                                      Dalmaf Price{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-lg"
                                      placeholder="Enter dalmaf price"
                                      aria-invalid={Boolean(
                                        myTaskPricingValidation?.dalmafPrice1
                                          ?.status === false
                                      )}
                                      defaultValue={myPricingTask?.dalmafPrice1}
                                      onChange={(e) => {
                                        setprincingValue(
                                          "dalmafPrice1",
                                          e.target.value
                                        );
                                      }}
                                      onBlur={(e) => {
                                        setPricingValidationValue(
                                          "dalmafPrice1",
                                          NonEmptyValidation(e.target.value)
                                        );
                                      }}
                                      autoComplete="off"
                                    />
                                    <small className="text-danger">
                                      {myTaskPricingValidation?.dalmafPrice1?.message
                                        ? `Dalmaf Price ${myTaskPricingValidation?.dalmafPrice1?.message}`
                                        : ""}
                                    </small>
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="mb-4">
                                    <label className="form-label">GST</label>
                                    <input
                                      type="text"
                                      className="form-control form-control-lg"
                                      placeholder="Enter GST"
                                      aria-invalid={Boolean(
                                        myTaskPricingValidation?.GST1?.status === false
                                      )}
                                      defaultValue={myPricingTask?.GST1}
                                      onChange={(e) => {
                                        setprincingValue("GST1", e.target.value);
                                      }}
                                      onBlur={(e) => {
                                        setPricingValidationValue(
                                          "GST1",
                                          NonEmptyValidation(e.target.value)
                                        );
                                      }}
                                      autoComplete="off"
                                    />
                                    <small className="text-danger">
                                      {myTaskPricingValidation?.GST1?.message
                                        ? `GST ${myTaskPricingValidation?.GST1?.message}`
                                        : ""}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-4">
                            <button
                              type="button"
                              className="btn white-btn me-2 "
                              onClick={() => {
                                setMyTaskPricingValidation({});
                                setMyPricingTask({});
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn login-btn ms-2 "
                              onClick={(e) => {
                                handleSubmit(e);
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
