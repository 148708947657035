import React, { useState, useEffect } from "react";
import { attendance_overall_specific_employee_listing, generic_employee_id, generic_filterBy, generic_fromDate, generic_toDate } from "../../../../Api/APIUrl";
import { fetchWithQueryAPI } from "../../../../Api/v1/Util/Api";
import { useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Form, Button } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function SpecificEmployeeAttendanceReport() {
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  const token = state.auth.auth.token;

  const { employeeId } = useParams();
  const [attendanceData, setAttendanceData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    fetchEmployeeAttendanceReport(selectedDate, selectedDate);
  }, [selectedDate]);

  const fetchEmployeeAttendanceReport = async (fromDate, toDate) => {
    try {
      let response = await fetchWithQueryAPI(
        token,
        `${attendance_overall_specific_employee_listing}?${generic_employee_id}${employeeId}&${generic_filterBy}custom&${generic_fromDate}${fromDate}&${generic_toDate}${toDate}`
      );
      if (response.statusCode === 200) {
        setAttendanceData(response.data);
      } else {
        console.error('Failed to fetch employee attendance report:', response.status);
      }
    } catch (error) {
      console.error('Error fetching employee attendance report:', error);
    }
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const formatDateTime = (dateTime) => {
    return moment.utc(dateTime).format('MMMM Do YYYY, h:mm:ss a');
  };
  

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-8 col-lg-8 col-sm-6">
          <h2>Employee Attendance Report</h2>
        </div>
        <div className="col-md-4 col-lg-4 col-sm-6">
          <button onClick={()=>{navigate(`/admin/employee-clock-in-out`);}} className='btn btn-primary' style={{float:'right'}}>
              <ArrowBackIcon />
          </button>
        </div>
      </div>
      

      <Form className="mb-3">
        <Form.Group controlId="formDate">
          <Form.Label>Select Date</Form.Label>
          <Form.Control
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            max={moment().format('YYYY-MM-DD')} // Prevent selecting future dates
          />
        </Form.Group>
        {/* <Button
          variant="primary"
          onClick={() => fetchEmployeeAttendanceReport(selectedDate, selectedDate)}
          className="mt-2"
        >
          Filter
        </Button> */}
      </Form>

      {attendanceData.length > 0 ? (
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>Employee Name</th>
              <th>Action Type</th>
              <th>Action Time</th>
              <th>Action Through Mobile</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            {attendanceData.map((entry, index) => (
              <tr key={index}>
                <td>{entry.employee_name}</td>
                <td>{entry.action_type === 'clock_in' ? 'Clock In' : 'Clock Out'}</td>
                <td>{formatDateTime(entry.action_time)}</td>
                <td>{entry.action_through_mobile ? 'Yes' : 'No'}</td>
                <td>{entry.address}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No attendance data found for the selected date.</p>
      )}
    </div>
  );
}
