import React, { useState, useEffect } from "react";
import { attendance_overall_employee_listing, generic_specific_month } from "../../../../Api/APIUrl";
import { fetchWithQueryAPI } from "../../../../Api/v1/Util/Api";
import { useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Form, Row, Col } from 'react-bootstrap';

// Define styles as JavaScript objects
const styles = {
  tableWrapper: {
    overflowX: 'auto',
    maxWidth: '100%',
    marginTop: '1rem',
    position: 'relative',
  },
  table: {
    borderCollapse: 'collapse',
    width: 'max-content', // Allow the table to extend its width based on content
    tableLayout: 'fixed', // Ensure columns have fixed widths
  },
  thTd: {
    border: '1px solid #dee2e6',
    padding: '8px',
    textAlign: 'center',
  },
  fixedCol: {
    position: 'sticky',
    left: 0,
    backgroundColor: '#f8f9fa',
    zIndex: 0, // Higher z-index to stay on top
    borderRight: '2px solid #dee2e6',
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#e9ecef',
    zIndex: 4, // Higher z-index for header
    borderBottom: '2px solid #dee2e6',
  },
  present: {
    backgroundColor: '#d4edda',
  },
  absent: {
    backgroundColor: '#f8d7da',
  },
  holiday: {
    backgroundColor: '#f1f1f1',
    fontStyle: 'italic',
  },
  summaryColumn: {
    backgroundColor: '#f1f1f1',
    fontWeight: 'bold',
    minWidth: '150px', // Increase width of Summary column
    zIndex: 2, // Ensure summary column is also fixed
  },
  nameColumn: {
    minWidth: '200px', // Increase width of Name column
    zIndex: 2, // Ensure name column is also fixed
  },
  statusCell: {
    padding: '4px 0',
  },
};

export default function EmployeeAttendanceReport() {
  const state = useSelector((state) => state);
  const token = state.auth.auth.token;

  // Get the current year and month in "YYYY-MM" format
  const getCurrentYearMonth = () => {
    const date = new Date();
    return {
      year: date.getFullYear(),
      month: String(date.getMonth() + 1).padStart(2, '0'),
    };
  };

  const currentYearMonth = getCurrentYearMonth();

  const [selectedYear, setSelectedYear] = useState(currentYearMonth.year);
  const [selectedMonth, setSelectedMonth] = useState(currentYearMonth.month);
  const [reportData, setReportData] = useState([]);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    fetchEmployeeAttendanceReport(selectedYear, selectedMonth);
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    if (reportData.length > 0 && reportData[0].attendance) {
      // Extract unique dates from the attendance data
      const uniqueDates = [...new Set(reportData.flatMap(employee => employee.attendance.map(entry => entry.date)))];
      setHeaders(uniqueDates);
    }
  }, [reportData]);

  const fetchEmployeeAttendanceReport = async (year, month) => {
    try {
      let response = await fetchWithQueryAPI(token, `${attendance_overall_employee_listing}?${generic_specific_month}${year}-${month}`);
      if (response.statusCode === 200) {
        console.log('Fetched data:', response.data);
        setReportData(response.data);
      } else {
        console.error('Failed to fetch employee attendance report:', response.status);
      }
    } catch (error) {
      console.error('Error fetching employee attendance report:', error);
    }
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  // Format the date using Intl.DateTimeFormat
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(date);
  };

  // Check if a date is a Sunday
  const isSunday = (dateStr) => {
    const date = new Date(dateStr);
    return date.getDay() === 0; // 0 represents Sunday
  };

  // Generate headers based on attendance data
  const renderAttendanceHeaders = () => {
    return headers.map((date, index) => (
      <th
        key={index}
        style={{
          ...styles.thTd,
          ...(isSunday(date) ? styles.holiday : {}),
          minWidth: '120px' // Ensure consistent header width
        }}
      >
        {formatDate(date)}
      </th>
    ));
  };

  // Render each row's attendance status dynamically
  const renderAttendanceData = (attendance) => {
    const today = new Date();
    const attendanceCount = { present: 0, absent: 0 };

    return headers.map((headerDate, index) => {
      const entry = attendance.find(item => item.date === headerDate);
      const date = new Date(headerDate);
      let status = "";

      if (date <= today) {
        if (isSunday(headerDate)) {
          if (entry && entry.status === "Present") {
            status = "Present"; // Override holiday if present
          } else {
            status = "Holiday"; // Mark as Holiday for Sundays
          }
        } else {
          status = entry ? entry.status : "Absent"; // Default to absent if no entry
        }
        if (status === "Present") attendanceCount.present++;
        if (status === "Absent") attendanceCount.absent++;
      }

      return (
        <td
          key={index}
          style={{
            ...styles.thTd,
            ...(status === "Present" ? styles.present : status === "Absent" ? styles.absent : status === "Holiday" ? styles.holiday : {}),
            minWidth: '120px' // Ensure consistent cell width
          }}
        >
          <div style={styles.statusCell}>{status}</div>
          {status !== "Holiday" && entry && (
            <div style={styles.statusCell}>{entry.total_hours}</div>
          )}
        </td>
      );
    });
  };

  return (
    <div className="container mt-3">
      <h2>Employee Attendance Report</h2>
      <Row>
        <Col md={8}></Col>
        <Col md={2}>
          <Form.Label>Select Year</Form.Label>
          <Form.Control
            as="select"
            value={selectedYear}
            onChange={handleYearChange}
          >
            {/* Populate year options dynamically or with a fixed range */}
            {Array.from({ length: 10 }, (_, i) => currentYearMonth.year - i).map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </Form.Control>
        </Col>
        <Col md={2}>
          <Form.Label>Select Month</Form.Label>
          <Form.Control
            as="select"
            value={selectedMonth}
            onChange={handleMonthChange}
          >
            {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
              <option key={month} value={String(month).padStart(2, '0')}>{month}</option>
            ))}
          </Form.Control>
        </Col>
      </Row>
      <div style={styles.tableWrapper}>
        <table style={styles.table}>
          <thead style={styles.stickyHeader}>
            <tr>
              <th style={{ ...styles.thTd, ...styles.fixedCol, width: '80px' }}>SNo</th>
              <th style={{ ...styles.thTd, ...styles.fixedCol, ...styles.nameColumn }}>Name</th>
              {/* <th style={{ ...styles.thTd, ...styles.fixedCol, ...styles.summaryColumn }}>Summary</th> */}
              {renderAttendanceHeaders()}
            </tr>
          </thead>
          <tbody>
            {reportData.map((data, index) => (
              <tr key={data._id}>
                <td style={{ ...styles.thTd, ...styles.fixedCol }}>{index + 1}</td>
                <td style={{ ...styles.thTd, ...styles.fixedCol, ...styles.nameColumn }}>{data.name}</td>
                {/* <td style={{ ...styles.thTd, ...styles.fixedCol, ...styles.summaryColumn }}>
                  Present: {data.attendance.filter(a => a.status === "Present").length}<br />
                  Absent: {data.attendance.filter(a => a.status === "Absent").length}
                </td> */}
                {renderAttendanceData(data.attendance)}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
