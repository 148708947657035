import React from "react";

import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import AdminNavbar from "../Navbar/AdminNavbar";

import CustomModal from "../../../Components/CustomModal";
import { NonEmptyValidation, StringValidation } from "../../../Store/validate";
import {
  CreateEmployeeRole,

  GetAllEmployeeRole,
  GetAllEmployeeRoleSpecific,
  UpdateRole,
} from "../../../Api/Api";
import { useSelector } from "react-redux";
import Spinner from "../../../Spinner/Spinner";
import MUIDataTable from "mui-datatables";


import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// import { ListEmployeerolemanagment } from "../../../Api/Api";
let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

export default function EmployeeRoleManagement() {

  const [createRoleModal, setCreateRoleModal] = useState(false);
  const [updateRoleModal, setUpdateRoleModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [listRoleEmployee, setListRoleEmployee] = useState([]);

  const [selectedTab, setSelectedTab] = React.useState("Active Role");
  const AllTabs = ["Active Role", "Inactive Role"];
  const MAX_LENGTH = 30;
  useEffect(() => {
    window.scroll(0, 0);

    getListEmployee("active");
  }, []);

  // const handleSubmitDelete = async (e, id, value) => {
  //   e.preventDefault();

  //   const token = state?.auth?.auth?.token;
  //   const response = await Deleterole(id, token);
  //   if (response && response.statusCode === 200) {
  //     showToastSuccessMsg(response.message);

  //     //  window.location.reload()
  //   } else {
  //     showToastErrorMsg(
  //       response.message || response.error.response.data.message
  //     );
  //     //setfailureModal(true);
  //   }
  // };

  const getListEmployee = async (status) => {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    const response = await GetAllEmployeeRole(token, status);
    if (response.statusCode === 200) {
      setListRoleEmployee(response.data);
      setLoading(false);
    } else {

      setListRoleEmployee([]);
      setLoading(false);
    }
  };

  const [employeeRole, setEmployeeRole] = useState({});
  const [employeeRole1, setEmployeeRole1] = useState({});

  const getListEmployeeSpecific = async (id) => {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    const response = await GetAllEmployeeRoleSpecific(token, id);
    if (response.statusCode === 200) {
      let dummy = response.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.description) {
        let blocksFromHtml = htmlToDraft(dummy.description);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.description = cmsDescription;
      setEmployeeRole1(dummy);
      setLoading(false);
    } else {

      setEmployeeRole1({});
      setLoading(false);
    }
  };

  const [employeeRoleValidate, setEmployeeRoleValidate] = useState({});
  const setEmployeeRoleValue = (key, value) => {
    setEmployeeRole({ ...employeeRole, [key]: value });
    if (employeeRoleValidate[key]) delete employeeRoleValidate[key];
  };

  const setEmployeeRoleValidateValue = (key, value) => {
    setEmployeeRoleValidate({ ...employeeRoleValidate, [key]: value });
  };

  const [employeeRoleValidate1, setEmployeeRoleValidate1] = useState({});
  const setEmployeeRoleValue1 = (parentKey, childKey, value, type) => {
    if (type === "object") {
      setEmployeeRole1({
        ...employeeRole1,
        ...{ [parentKey]: { ...employeeRole1[parentKey], [childKey]: value } },
      });
      if (employeeRoleValidate1[parentKey])
        delete employeeRoleValidate1[parentKey];
      if (employeeRoleValidate1[childKey])
        delete employeeRoleValidate1[childKey];
    } else {
      setEmployeeRole1({
        ...employeeRole1,
        [childKey]: value,
      });
      if (employeeRoleValidate1[parentKey])
        delete employeeRoleValidate1[parentKey];
      if (employeeRoleValidate1[childKey])
        delete employeeRoleValidate1[childKey];
    }
  };

  const setEmployeeRoleValidateValue1 = (parentKey, childKey, value, type) => {
    if (type === "object") {
      setEmployeeRoleValidate1({
        ...employeeRoleValidate1,
        ...{
          [parentKey]: {
            ...employeeRoleValidate1[parentKey],
            [childKey]: value,
          },
        },
      });
    } else {
      setEmployeeRoleValidate1({
        ...employeeRoleValidate1,
        [childKey]: value,
      });
    }
  };

  const state = useSelector((state) => state);

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };




  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setEmployeeRoleValue("description", html);
    }
  };
  console.log(employeeRole?.Dashboard)

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.description = NonEmptyValidation(employeeRole?.description);
    // validate.role = NonEmptyValidation(employeeRole?.role);
    validate.title = NonEmptyValidation(employeeRole?.title);
    validate.pageAccess = NonEmptyValidation(employeeRole?.pageAccess);
    if (employeeRole?.Dashboard || employeeRole?.PurchasedServices || employeeRole?.MyTask || employeeRole?.ServiceRequest || employeeRole?.ServicePackage || employeeRole?.UserManagement || employeeRole?.AdminManagement || employeeRole?.AdminRole || employeeRole?.EmployeeRole ||
      employeeRole?.Property || employeeRole?.History || employeeRole?.Package || employeeRole?.Lead
      || employeeRole?.EmployeeManagement || employeeRole?.TaskList || employeeRole?.LogManagement ||
      employeeRole?.ProfileManagement || employeeRole?.JobManagement || employeeRole?.cms ||
      employeeRole?.leaveapprove || employeeRole?.NewsLetter || employeeRole?.ContactUsers || employeeRole?.GoldAuction ||
      employeeRole?.TowWheeler || employeeRole?.FourWheeler || employeeRole?.PropertyRequest ||
      employeeRole?.PaymentService || employeeRole?.PaymentPackage) {

      validate.pageAccess.status = true
    }
    console.log(validate, validate)
    setEmployeeRoleValidate(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        description: employeeRole.description,
        status: "active",
        role: "subadmin",
        title: employeeRole?.title,
        pageAccess: {
          Dashboard: employeeRole?.Dashboard === false ? false : true,
          PurchasedServices: employeeRole?.PurchasedServices
            ? employeeRole?.PurchasedServices
            : false,
          MyTask: employeeRole?.MyTask === false ? false : true,
          ServiceRequest: employeeRole?.ServiceRequest
            ? employeeRole?.ServiceRequest
            : false,
          ServicePackage: employeeRole?.ServicePackage
            ? employeeRole?.ServicePackage
            : false,
          UserManagement: employeeRole?.UserManagement
            ? employeeRole?.UserManagement
            : false,
          AdminManagement: employeeRole?.AdminManagement
            ? employeeRole?.AdminManagement
            : false,
          AdminRole: employeeRole?.AdminRole ? employeeRole?.AdminRole : false,
          EmployeeRole: employeeRole?.EmployeeRole
            ? employeeRole?.EmployeeRole
            : false,
          // Property: employeeRole?.Property ? employeeRole?.Property : false,
          History: employeeRole?.History ? employeeRole?.History : false,
          Package: employeeRole?.Package ? employeeRole?.Package : false,
          Lead: employeeRole?.Lead ? employeeRole?.Lead : false,
          EmployeeManagement: employeeRole?.EmployeeManagement
            ? employeeRole?.EmployeeManagement
            : false,
          TaskList: employeeRole?.TaskList ? employeeRole?.TaskList : false,
          LogManagement: employeeRole?.LogManagement
            ? employeeRole?.LogManagement
            : false,
          ProfileManagement: employeeRole?.ProfileManagement
            ? employeeRole?.ProfileManagement
            : false,
          JobManagement: employeeRole?.JobManagement
            ? employeeRole?.JobManagement
            : false,
          cms: employeeRole?.cms ? employeeRole?.cms : false,
          // JobApplications:employeeRole?.Job ? employeeRole?.Job : false,
          LeaveApprove: employeeRole?.LeaveApprove
            === false ? false : true,
          NewsLetter: employeeRole?.NewsLetter
            ? employeeRole?.NewsLetter
            : false,
          ContactUsers: employeeRole?.ContactUsers
            ? employeeRole?.ContactUsers
            : false,
          GoldAuction: employeeRole?.GoldAuction
            ? employeeRole?.GoldAuction
            : false,
          TowWheeler: employeeRole?.TowWheeler ? employeeRole?.TowWheeler : false,
          FourWheeler: employeeRole?.FourWheeler ? employeeRole?.FourWheeler : false,
          WebsiteLeads: employeeRole?.WebsiteLeads ? employeeRole?.WebsiteLeads : false,
          auctionLeads: employeeRole?.auctionLeads ? employeeRole?.auctionLeads : false,
          PropertyRequest: employeeRole?.PropertyRequest
            ? employeeRole?.PropertyRequest
            : false,
          PaymentService: employeeRole?.PaymentService
            ? employeeRole?.PaymentService
            : false,
          PaymentPackage: employeeRole?.PaymentPackage
            ? employeeRole?.PaymentPackage
            : false,
        },
      };

      const token = state?.auth?.auth?.token;
      const response = await CreateEmployeeRole(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setCreateRoleModal(false);
        setEmployeeRole({});
        getListEmployee("active");
        window.location.reload()
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while create role");
    }
  };

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    let validate = {};
    // validate.description = NonEmptyValidation(employeeRole1?.description);
    // validate.role = NonEmptyValidation(employeeRole1?.role);
    // validate.title = NonEmptyValidation(employeeRole1?.title);
    setEmployeeRoleValidate1(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let html;
      let Payload;
      let isNotEmpty =
        employeeRole1?.description &&
        employeeRole1?.description.getCurrentContent().hasText();
      if (isNotEmpty) {
        html = draftToHtml(
          convertToRaw(employeeRole1?.description.getCurrentContent())
        );
        Payload = {
          _id: employeeRole1._id,
          description: html,
          status: "active",
          role: "subadmin",
          title: employeeRole1?.title,
          pageAccess: {
            Dashboard: employeeRole1?.pageAccess?.Dashboard
              ? employeeRole1?.pageAccess.Dashboard
              : false,
            PurchasedServices: employeeRole1?.pageAccess.PurchasedServices
              ? employeeRole1?.pageAccess.PurchasedServices
              : false,
            MyTask: employeeRole1?.pageAccess.MyTask ? employeeRole1?.pageAccess.MyTask : false,
            ServiceRequest: employeeRole1?.pageAccess.ServiceRequest
              ? employeeRole1?.pageAccess.ServiceRequest
              : false,
            ServicePackage: employeeRole1?.pageAccess.ServicePackage
              ? employeeRole1?.pageAccess.ServicePackage
              : false,
            UserManagement: employeeRole1?.pageAccess.UserManagement
              ? employeeRole1?.pageAccess.UserManagement
              : false,
            AdminManagement: employeeRole1?.pageAccess.AdminManagement
              ? employeeRole1?.pageAccess.AdminManagement
              : false,
            AdminRole: employeeRole1?.pageAccess.AdminRole
              ? employeeRole1?.pageAccess.AdminRole
              : false,
            EmployeeRole: employeeRole1?.pageAccess.EmployeeRole
              ? employeeRole1?.pageAccess.EmployeeRole
              : false,
            PropertyAuction: employeeRole1?.pageAccess.PropertyAuction ? employeeRole1?.pageAccess.PropertyAuction : false,
            History: employeeRole1?.pageAccess.History ? employeeRole1?.pageAccess.History : false,
            Package: employeeRole1?.pageAccess.Package ? employeeRole1?.pageAccess.Package : false,
            Lead: employeeRole1?.pageAccess.Lead ? employeeRole1?.pageAccess.Lead : false,
            EmployeeManagement: employeeRole1?.pageAccess.EmployeeManagement
              ? employeeRole1?.pageAccess.EmployeeManagement
              : false,
            TaskList: employeeRole1?.pageAccess.TaskList ? employeeRole1?.pageAccess.TaskList : false,
            LogManagement: employeeRole1?.pageAccess.LogManagement
              ? employeeRole1?.pageAccess.LogManagement
              : false,
            ProfileManagement: employeeRole1?.pageAccess.ProfileManagement
              ? employeeRole1?.pageAccess.ProfileManagement
              : false,
            JobManagement: employeeRole1?.pageAccess.JobManagement
              ? employeeRole1?.pageAccess.JobManagement
              : false,
            cms: employeeRole1?.pageAccess.cms ? employeeRole1?.pageAccess.cms : false,
            // JobApplications:employeeRole1?.Job ? employeeRole1?.Job : false,
            LeaveApprove: employeeRole1?.pageAccess.LeaveApprove
              ? employeeRole1?.pageAccess.LeaveApprove
              : false,
            NewsLetter: employeeRole1?.pageAccess.NewsLetter
              ? employeeRole1?.pageAccess.NewsLetter
              : false,
            ContactUsers: employeeRole1?.pageAccess.ContactUsers
              ? employeeRole1?.pageAccess.ContactUsers
              : false,
            GoldAuction: employeeRole1?.pageAccess.GoldAuction
              ? employeeRole1?.pageAccess.GoldAuction
              : false,
            VehicleAuction: employeeRole1?.pageAccess.VehicleAuction
              ? employeeRole1?.pageAccess.VehicleAuction
              : false,
            // TowWheeler: employeeRole1?.pageAccess.TowWheeler ? employeeRole1?.pageAccess.TowWheeler : false,
            // FourWheeler: employeeRole1?.pageAccess.FourWheeler ? employeeRole1?.pageAccess.FourWheeler : false,
            PropertyRequest: employeeRole1?.pageAccess.PropertyRequest
              ? employeeRole1?.pageAccess.PropertyRequest
              : false,
            PaymentService: employeeRole1?.pageAccess.PaymentService
              ? employeeRole1?.pageAccess.PaymentService
              : false,
            PaymentPackage: employeeRole1?.pageAccess.PaymentPackage
              ? employeeRole1?.pageAccess.PaymentPackage
              : false,
          },
        };
      }
      const token = state?.auth?.auth?.token;
      const response = await UpdateRole(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setUpdateRoleModal(false);
        setEmployeeRole1({});
        getListEmployee("active");
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while update role");
    }
  };

  const handleSubmitStatusUpdate = async (e, value, id) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
    };
    const token = state?.auth?.auth?.token;
    const response = await UpdateRole(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);

      if (value !== "active") {
        setSelectedTab("Active Role");
        getListEmployee("active");
      } else {
        setSelectedTab("Inactive Role");
        getListEmployee("inactive");
      }
      // getListEmployee("active");
      //  window.location.reload()
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: pink[600],
      "&:hover": {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: pink[600],
    },
  }));

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                onClick={() => {
                  getListEmployeeSpecific(tableMeta.rowData[0]);
                  setUpdateRoleModal(true);
                }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        customBodyRender: (value) => {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: value?.substring(0, 100),
              }}
            >
              {/* {`${value.substring(
                0,
                MAX_LENGTH
              )}...`} */}
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {value === "inactive" ? (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <PinkSwitch
                        {...label}
                        onChange={(e) => {
                          handleSubmitStatusUpdate(
                            e,
                            "active",
                            tableMeta.rowData[0]
                          );
                        }}
                      />
                    }
                  />
                </FormGroup>
              ) : (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <PinkSwitch
                        {...label}
                        defaultChecked
                        onChange={(e) => {
                          handleSubmitStatusUpdate(
                            e,
                            "inactive",
                            tableMeta.rowData[0]
                          );
                        }}
                      />
                    }
                  />
                </FormGroup>
              )}
            </>
          );
        },
      },
    },

    {
      name: "admin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
    {
      name: "subadmin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
    // {
    //   name: "Action",
    //   label: "Action",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <div className="text-center">
    //           <i
    //             onClick={(e) => {
    //               Swal.fire({
    //                 title: "Are you sure?",
    //                 text: "You want to delete !",
    //                 // icon: "warning",
    //                 showCancelButton: true,
    //                 confirmButtonColor: "#3085d6",
    //                 cancelButtonColor: "#d33",
    //                 confirmButtonText: "Yes, delete",
    //               }).then((result) => {
    //                 if (result.isConfirmed) {
    //                   handleSubmitDelete(
    //                     e,
    //                     tableMeta.rowData[0],
    //                     tableMeta.rowData[5]
    //                   );
    //                 }
    //               });
    //             }}
    //             // onClick={(e) => {
    //             //   handleSubmitDelete(e,tableMeta.rowData[0],value)
    //             // }}
    //             className="fa fa-trash-can"
    //             style={{
    //               color: "#E34242",
    //               fontSize: "16px",
    //               cursor: "pointer",
    //             }}
    //           ></i>
    //         </div>
    //       );
    //     },
    //   },
    // },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[5] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  return (
    <div>
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="mb-0 fw-bold color-maroon">
                  Employee Role Management
                </h5>
                <div>
                  <button
                    type="button"
                    className="btn py-1 login-btn me-1"
                    onClick={() => {
                      setCreateRoleModal(true);
                    }}
                  >
                    Create Role
                  </button>
                </div>
              </div>
              <div className="my-4">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7 px-0">
                      <div className="tab-section">
                        <ul className="ps-0">
                          {AllTabs.map((tab, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  selectedTab === tab
                                    ? "serviceTab active"
                                    : "serviceTab inactive"
                                }
                                onClick={() => {
                                  getListEmployee(
                                    tab === "Inactive Role"
                                      ? "inactive"
                                      : "active"
                                  );
                                  setSelectedTab(tab);
                                }}
                              >
                                <li>
                                  <span className="align-middle">{tab}</span>
                                </li>
                              </div>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <MUIDataTable
                  title={"Employee Role Management"}
                  data={listRoleEmployee}
                  columns={columns}
                  options={options}
                />
                {/* <table className="table">
                  <thead className="bg-historytablehead">
                    <tr>
                      <th scope="col" style={{ borderTopLeftRadius: "0" }}>
                        S. No
                      </th>
                      <th scope="col">Role</th>
                      <th scope="col">Description </th>
                      <th scope="col">Status </th>
                    </tr>
                  </thead>
                  <tbody className="historytablebody">
                    {listRoleEmployee &&
                      listRoleEmployee.length > 0 &&
                      listRoleEmployee.map((data, index) => {
                        return (
                          <tr>
                            <td>
                              <div className="">
                                <h6 className="fw-bold">{index + 1}</h6>
                              </div>
                            </td>
                            <td scope="row">
                              <div className="d-flex align-items-center ">
                                <div className="flex-shrink-0">
                                  <h6 className="fw-bold">{data.role}</h6>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className=" ">
                                <h6 className="fw-bold">{`${data.description.substring(
                                  0,
                                  MAX_LENGTH
                                )}...`}</h6>
                              </div>
                            </td>
                            <td>
                              <div className=" ">
                                <h6 className="fw-bold">{data.status}</h6>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    
                  </tbody>
                </table> */}
              </div>
            </div>
          </>
        )}
        <CustomModal
          open={createRoleModal}
          onClickOutside={() => {
            setCreateRoleModal(false);
          }}
        >
          <div className="modal-content  m-auto">
            <div className="modal-body p-0 ">
              <div className="container">
                <span
                  className="material-symbols-rounded filled-icon close-icon"
                  style={{
                    marginRight: "30px",
                    marginTop: "50px",
                    textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                  }}
                  onClick={() => {
                    setCreateRoleModal(false);
                    setEmployeeRole({});
                    window.location.reload()
                  }}
                >
                  close
                </span>
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className=" my-5 px-3">
                          <div className="row justify-content-center mt-2">
                            <div className="col-lg-12">
                              <div className="admin-service">
                                <div
                                  className="small-banner-img-head"
                                  style={{ cursor: "pointer" }}
                                >
                                  <h5 className="w-100 h-auto text-center">
                                    Create Role
                                  </h5>
                                </div>
                                <div className="mb-3">
                                  <label for="" className="form-label">
                                    Title
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    required
                                    aria-invalid={Boolean(
                                      employeeRoleValidate?.title?.status ===
                                      false
                                    )}
                                    defaultValue={employeeRole?.title || ""}
                                    onChange={(e) => {
                                      setEmployeeRoleValue(
                                        "title",
                                        e.target.value
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setEmployeeRoleValidateValue(
                                        "title",
                                        NonEmptyValidation(e.target.value)
                                      );
                                    }}
                                    autoComplete="off"
                                  />
                                  <small className="text-danger">
                                    {employeeRoleValidate?.title?.message
                                      ? `Title ${employeeRoleValidate?.title?.message}`
                                      : ""}
                                  </small>
                                </div>
                                {/* <div className="mb-3">
                                              <label
                                                for=""
                                                className="form-label"
                                              >
                                                Role
                                              </label>
                                              <select
                                  className="form-control"
                                 
                                  defaultValue={ employeeRole?.role ||
                                    ""}
                                  onChange={(e) =>
                                    setEmployeeRoleValue(
                                      "role",
                                      e.target.value
                                    )
                                  }
                                  onBlur={(e) => {
                                    setEmployeeRoleValidateValue(
                                      "role",
                                      NonEmptyValidation(
                                        e.target.value
                                      )
                                    );
                                  }}
                                >
                                  <option value="">---Select Role---</option>
                                  <option value="admin">Admin</option>
                                  <option value="subadmin">Subadmin</option>
                                </select> */}
                                {/* <input
                                                type="text"
                                                className="form-control"
                                                required
                                                aria-invalid={Boolean(
                                                  employeeRoleValidate
                                                    ?.role?.status ===
                                                    false
                                                )}
                                                defaultValue={
                                                  employeeRole?.role ||
                                                  ""
                                                }
                                                onChange={(e) => {
                                                  setEmployeeRoleValue(
                                                    "role",
                                                    e.target.value
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setEmployeeRoleValidateValue(
                                                    "role",
                                                    NonEmptyValidation(
                                                      e.target.value.toLowerCase()
                                                    )
                                                  );
                                                }}
                                                autoComplete="off"
                                              /> */}
                                {/* <small className="text-danger">
                                                {employeeRoleValidate
                                                  ?.role?.message
                                                  ? `Role ${employeeRoleValidate?.role?.message}`
                                                  : ""}
                                              </small>
                                            </div> */}
                                <div className="mb-3">
                                  <label for="" className="form-label">
                                    Description
                                  </label>
                                  <div
                                    className="form-control form-control-lg"
                                    style={{ height: "auto" }}
                                  >
                                    <Editor
                                      wrapperClassName="wrapper-class"
                                      editorClassName="editor-class"
                                      toolbarClassName="toolbar-class"
                                      editorState={editorState}
                                      onEditorStateChange={(e) =>
                                        handleEditorChange(e)
                                      }
                                    />
                                  </div>
                                  {/* <textarea
                                                type="text"
                                                className="form-control"
                                                required
                                                aria-invalid={Boolean(
                                                  employeeRoleValidate
                                                    ?.description?.status ===
                                                    false
                                                )}
                                                defaultValue={
                                                  employeeRole?.description ||
                                                  ""
                                                }
                                                onChange={(e) => {
                                                  setEmployeeRoleValue(
                                                    "description",
                                                    e.target.value
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setEmployeeRoleValidateValue(
                                                    "description",
                                                    NonEmptyValidation(
                                                      e.target.value
                                                    )
                                                  );
                                                }}
                                                autoComplete="off"
                                              ></textarea> */}
                                  <small className="text-danger">
                                    {employeeRoleValidate?.description?.message
                                      ? `Description ${employeeRoleValidate?.description?.message}`
                                      : ""}
                                  </small>
                                </div>
                                <div className="mb-3">
                                  <label for="" className="form-label">
                                    Page Access
                                  </label>
                                </div>
                                <div className="row mb-2">
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.Dashboard || ""
                                      }
                                      defaultChecked
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "Dashboard",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Dashboard
                                  </div>

                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.PurchasedServices ||
                                        ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "PurchasedServices",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Purchased Services
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.MyTask || ""
                                      }
                                      defaultChecked
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "MyTask",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    My Task
                                  </div>
                                </div>
                                <div className="row mb-2">
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.ServiceRequest || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "ServiceRequest",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Service Request
                                  </div>

                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.ServicePackage || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "ServicePackage",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Service Package
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.UserManagement || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "UserManagement",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    User Management
                                  </div>
                                </div>
                                <div className="row mb-2">
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.AdminManagement || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "AdminManagement",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Admin Management
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.AdminRole || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "AdminRole",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Admin Role
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.EmployeeRole || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "EmployeeRole",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Employee Role
                                  </div>
                                </div>
                                <div className="row mb-2">
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.Property || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "Property",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Property
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.History || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "History",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    History
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.Package || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "Package",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Package
                                  </div>
                                </div>
                                <div className="row mb-2">
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.Lead || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "Lead",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Lead
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.JobManagement || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "JobManagement",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Job Management
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.EmployeeManagement ||
                                        ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "EmployeeManagement",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Employee Management
                                  </div>
                                </div>
                                <div className="row mb-2">
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.TaskList || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "TaskList",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Global Task List
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.LogManagement || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "LogManagement",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Log Management
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.ProfileManagement ||
                                        ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "ProfileManagement",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Profile Management
                                  </div>
                                </div>
                                <div className="row mb-2">
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.cms || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "cms",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    CMS
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.ContactUsers || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "ContactUsers",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Contact Users
                                  </div>
                                  {/* <div className="col-4">
                                        <input
                                          type="checkbox"
                                          defaultValue={
                                            employeeRole?.PropertyRequest
                                          }
                                          onChange={(e) => {
                                            setEmployeeRoleValue(
                                              "PropertyRequest",
                                              e.target.checked
                                            );
                                            if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                          }}
                                        />{" "}
                                        Property Request
                                      </div> */}
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.LeaveApprove
                                      }
                                      defaultChecked
                                      // checked
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "LeaveApprove",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Leave Approve
                                  </div>

                                </div>
                                <div className="row mb-2">
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.NewsLetter || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "NewsLetter",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    News Letter
                                  </div>


                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.GoldAuction || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "GoldAuction",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Gold Auction
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.TwoWheeler || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          " TwoWheeler",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    TwoWheeler
                                  </div>
                                </div>
                                <div className="row mb-2">


                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.FourWheeler || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "FourWheeler",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Four Wheeler
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.auctionLeads || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "auctionLeads",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Auction Leads
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole?.WebsiteLeads || ""
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue(
                                          "WebsiteLeads",
                                          e.target.checked
                                        );
                                        if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                      }}
                                    />{" "}
                                    Website Leads
                                  </div>
                                  {/* <div className="col-4">
                                        <input
                                          type="checkbox"
                                          
                                          defaultValue={
                                            employeeRole?.PaymentService || ""
                                          }
                                          onChange={(e) => {
                                            setEmployeeRoleValue(
                                              "PaymentService",
                                              e.target.checked
                                            );
                                            if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                          }}
                                        />{" "}
                                        Payment Service
                                      </div> */}


                                </div>
                                <div className="row mb-2">


                                  {/* <div className="col-4">
                                        <input
                                          type="checkbox"
                                          
                                          defaultValue={
                                            employeeRole?.PaymentPackage || ""
                                          }
                                          onChange={(e) => {
                                            setEmployeeRoleValue(
                                              "PaymentPackage",
                                              e.target.checked
                                            );
                                            if (employeeRoleValidate["pageAccess"]) delete employeeRoleValidate["pageAccess"];
                                          }}
                                        />{" "}
                                        Payment Package
                                      </div> */}
                                </div>
                              </div>
                            </div>

                            <div className="mb-3 text-center mt-5">
                              <button
                                type="button"
                                className="btn mx-2 white-btn"
                                onClick={() => {
                                  setCreateRoleModal(false);
                                  setEmployeeRole({});
                                  window.location.reload()
                                }}
                              >
                                {" "}
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn  my-2  login-btn"
                                onClick={(e) => handleSubmit(e)

                                }
                              >
                                Create
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
        <CustomModal
          open={updateRoleModal}
          onClickOutside={() => {
            setUpdateRoleModal(false);
          }}
        >
          <div className="modal-content w-75 m-auto">
            <div className="modal-body p-0 ">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className=" my-5 px-3">
                          <div className="row justify-content-center mt-2">
                            <div className="col-lg-12">
                              <div className="admin-service">
                                <div
                                  className="small-banner-img-head"
                                  style={{ cursor: "pointer" }}
                                >
                                  <h5 className="w-100 h-auto text-center">
                                    Update Role
                                  </h5>
                                </div>
                                <div className="mb-3">
                                  <label for="" className="form-label">
                                    Title
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    required
                                    aria-invalid={Boolean(
                                      employeeRoleValidate1?.title?.status ===
                                      false
                                    )}
                                    defaultValue={employeeRole1?.title || ""}
                                    onChange={(e) => {
                                      setEmployeeRoleValue1(
                                        "",
                                        "title",
                                        e.target.value,
                                        ""
                                      );
                                    }}
                                    onBlur={(e) => {
                                      setEmployeeRoleValidateValue1(
                                        "",
                                        "title",
                                        StringValidation(e.target.value),
                                        ""
                                      );
                                    }}
                                    // onBlur={(e) => {
                                    //   setEmployeeRoleValidateValue1(
                                    //     "title",
                                    //     NonEmptyValidation(
                                    //       e.target.value
                                    //     )
                                    //   );
                                    // }}
                                    autoComplete="off"
                                  />
                                  {/* <small className="text-danger">
                                                {employeeRoleValidate1
                                                  ?.title?.message
                                                  ? `Title ${employeeRoleValidate1?.title?.message}`
                                                  : ""}
                                              </small> */}
                                </div>
                                {/* <div className="mb-3">
                                              <label
                                                for=""
                                                className="form-label"
                                              >
                                                Role
                                              </label>
                                              <select
                                  className="form-control"
                                 
                                  value={
                                    employeeRole1?.role
                                  }
                                  onChange={(e) =>
                                    {
                                      //console.log(e.target.value)
                                      setEmployeeRoleValue1(
                                      "role",
                                      e.target.value
                                    )}
                                  }
                                  onBlur={(e) => {
                                    setEmployeeRoleValidateValue1(
                                      "role",
                                      NonEmptyValidation(
                                        e.target.value
                                      )
                                    )
                                  }}
                                >
                                  <option value="">---Select Role---</option>
                                  <option value="admin">Admin</option>
                                  <option value="subadmin">Subadmin</option>
                                </select> */}
                                {/* <input
                                                type="text"
                                                className="form-control"
                                                required
                                                aria-invalid={Boolean(
                                                  employeeRoleValidate1
                                                    ?.role?.status ===
                                                    false
                                                )}
                                                defaultValue={
                                                  employeeRole1?.role ||
                                                  ""
                                                }
                                                onChange={(e) => {
                                                  setEmployeeRoleValue1(
                                                    "role",
                                                    e.target.value
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setEmployeeRoleValidateValue1(
                                                    "role",
                                                    NonEmptyValidation(
                                                      e.target.value
                                                    )
                                                  );
                                                }}
                                                autoComplete="off"
                                              /> */}
                                {/* <small className="text-danger">
                                                {employeeRoleValidate1
                                                  ?.role?.message
                                                  ? `Role ${employeeRoleValidate1?.role?.message}`
                                                  : ""}
                                              </small>
                                            </div> */}

                                <div className="mb-3">
                                  <label for="" className="form-label">
                                    Description
                                  </label>
                                  <div
                                    className="form-control form-control-lg"
                                    style={{ height: "300px" }}
                                  >
                                    <Editor
                                      wrapperClassName="wrapper-class"
                                      editorClassName="editor-class"
                                      toolbarClassName="toolbar-class"
                                      editorState={employeeRole1?.description}
                                      onEditorStateChange={(e) =>
                                        setEmployeeRoleValue1(
                                          "",
                                          "description",
                                          e,
                                          ""
                                        )
                                      }
                                    />
                                    <small className="text-danger">
                                      {employeeRoleValidate1?.description
                                        ?.message
                                        ? `Description ${employeeRoleValidate1?.description?.message}`
                                        : ""}
                                    </small>
                                  </div>
                                  {/* <textarea
                                                type="text"
                                                className="form-control"
                                                required
                                                aria-invalid={Boolean(
                                                  employeeRoleValidate1
                                                    ?.description?.status ===
                                                    false
                                                )}
                                                defaultValue={
                                                  employeeRole1?.description ||
                                                  ""
                                                }
                                                onChange={(e) => {
                                                  setEmployeeRoleValue1(
                                                    "",
                                                    "description",
                                                    e.target.value,
                                                    ""
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  setEmployeeRoleValidateValue1(
                                                    "",
                                                    "description",
                                                    NonEmptyValidation(
                                                      e.target.value,
                                                    ),
                                                    ""

                                                  );
                                                }}
                                                autoComplete="off"
                                              ></textarea> */}
                                  {/* <small className="text-danger">
                                                {employeeRoleValidate1
                                                  ?.description?.message
                                                  ? `Description ${employeeRoleValidate1?.description?.message}`
                                                  : ""}
                                              </small> */}
                                </div>

                                <div className="mb-3">
                                  <label for="" className="form-label">
                                    Page Access
                                  </label>
                                </div>
                                <div className="row mb-2">
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess?.Dashboard
                                      }
                                      // defaultChecked={
                                      //   employeeRole1?.pageAccess?.Dashboard
                                      // }
                                      checked
                                      onChange={(e) => {
                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "Dashboard",
                                          true,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Dashboard
                                  </div>

                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess
                                          ?.PurchasedServices
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess
                                          ?.PurchasedServices
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "PurchasedServices",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Purchased Services
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess?.MyTask
                                      }
                                      // defaultChecked={
                                      //   employeeRole1?.pageAccess?.MyTask
                                      // }
                                      checked
                                      onChange={(e) => {
                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "MyTask",
                                          true,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    My Task
                                  </div>
                                </div>
                                <div className="row mb-2">
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess
                                          ?.ServiceRequest
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess
                                          ?.ServiceRequest
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "ServiceRequest",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Service Request
                                  </div>

                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess
                                          ?.ServicePackage
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess
                                          ?.ServicePackage
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "ServicePackage",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Service Package
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess
                                          ?.UserManagement
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess
                                          ?.UserManagement
                                      }
                                      onChange={(e) => {

                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "UserManagement",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    User Management
                                  </div>
                                </div>
                                <div className="row mb-2">
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess
                                          ?.AdminManagement
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess
                                          ?.AdminManagement
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "AdminManagement",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Admin Management
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess?.AdminRole
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess?.AdminRole
                                      }
                                      onChange={(e) => {

                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "AdminRole",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Admin Role
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess
                                          ?.EmployeeRole
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess
                                          ?.EmployeeRole
                                      }
                                      onChange={(e) => {
                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "EmployeeRole",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Employee Role
                                  </div>
                                </div>
                                <div className="row mb-2">
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess
                                          ?.NewsLetter
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess
                                          ?.NewsLetter
                                      }
                                      onChange={(e) => {

                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "NewsLetter",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    News Letter
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess?.History
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess?.History
                                      }
                                      onChange={(e) => {

                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "History",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    History
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"
                                      defaultValue={
                                        employeeRole1?.pageAccess?.Package
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess?.Package
                                      }
                                      onChange={(e) => {

                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "Package",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Package
                                  </div>
                                </div>
                                <div className="row mb-2">
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess?.Lead
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess?.Lead
                                      }
                                      onChange={(e) => {

                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "Lead",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Lead
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess
                                          ?.JobManagement
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess
                                          ?.JobManagement
                                      }
                                      onChange={(e) => {

                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "JobManagement",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Job Management
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess
                                          ?.EmployeeManagement
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess
                                          ?.EmployeeManagement
                                      }
                                      onChange={(e) => {

                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "EmployeeManagement",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Employee Management
                                  </div>
                                </div>
                                <div className="row mb-2">
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess?.TaskList
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess?.TaskList
                                      }
                                      onChange={(e) => {

                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "TaskList",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Global Task List
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess
                                          ?.LogManagement
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess
                                          ?.LogManagement
                                      }
                                      onChange={(e) => {

                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "LogManagement",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Log Management
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess
                                          ?.ProfileManagement
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess
                                          ?.ProfileManagement
                                      }
                                      onChange={(e) => {

                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "ProfileManagement",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Profile Management
                                  </div>
                                </div>
                                <div className="row mb-2">
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess?.cms
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess?.cms
                                      }
                                      onChange={(e) => {

                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "cms",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    CMS
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess
                                          ?.ContactUsers
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess
                                          ?.ContactUsers
                                      }
                                      onChange={(e) => {

                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "ContactUsers",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Contact Users
                                  </div>

                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess
                                          ?.LeaveApprove
                                      }
                                      checked
                                      onChange={(e) => {

                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "LeaveApprove",
                                          true,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Leave Approve
                                  </div>
                                </div>
                                <div className="row mb-2">

                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess?.PropertyAuction
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess?.PropertyAuction
                                      }
                                      onChange={(e) => {

                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "PropertyAuction",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Property Auction
                                  </div>


                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess
                                          ?.GoldAuction
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess
                                          ?.GoldAuction
                                      }
                                      onChange={(e) => {

                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "GoldAuction",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Gold Auction
                                  </div>
                                  <div className="col-4">
                                    <input
                                      type="checkbox"

                                      defaultValue={
                                        employeeRole1?.pageAccess?.VehicleAuction
                                      }
                                      defaultChecked={
                                        employeeRole1?.pageAccess?.VehicleAuction
                                      }
                                      onChange={(e) => {

                                        setEmployeeRoleValue1(
                                          "pageAccess",
                                          "VehicleAuction",
                                          e.target.checked,
                                          "object"
                                        );
                                      }}
                                    />{" "}
                                    Vehicle Auction
                                  </div>
                                </div>
                                <div className="mb-3 text-center mt-5">
                                  <button
                                    type="button"
                                    className="btn mx-2 white-btn"
                                    onClick={() => {
                                      setUpdateRoleModal(false);
                                      setEmployeeRole1({});
                                      getListEmployee("active");
                                    }}
                                  >
                                    {" "}
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="btn  my-2  login-btn"
                                    onClick={(e) => handleSubmitUpdate(e)}
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
}
