import React, { useEffect } from 'react';

import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import PlotBanner from "../Components/Property/PlotBanner";
import PlotContent from "../Components/Property/PlotContent";
import {Helmet} from "react-helmet";
export default function Plot() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);


  return (
    <div className="innerpages-nav">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Plot Information | DALMaf Private Limited</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Plot" />
        <meta name="keywords" content="Plot" />
        <meta name="author" content="DALMaf" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
       <div id="container">
      <Navigation />
      <PlotBanner />
      <PlotContent />
      <Footer />
      </div>
    </div>
  );
}
