import React from "react";

export default function VillaBanner() {
  return (
    <div>
      <div className="">
        <div className="auctionpage-banner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="head-banner-posi">
                  <h1 className="banner-heading after-line ">Independent Villa</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
