import React, { useEffect, useState } from "react";

import Ser1 from "../../Assets/Images/ser1.webp";
import Ser2 from "../../Assets/Images/ser2.webp";
import Norecord from "../../Assets/Images/MicrosoftTeams-image.webp";
import Ser3 from "../../Assets/Images/ser3.webp";
import Si1 from "../../Assets/Images/si-1.webp";
import Si2 from "../../Assets/Images/si-2.webp";
import Si3 from "../../Assets/Images/si-3.webp";

import AOS from "aos";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "aos/dist/aos.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  EmailValidation,
  NonEmptyValidation,
  PhoneNumberValidation,
  StringValidation,
} from "../../Store/validate";
import {
  GetProperty,
  GetUser,
  
  UseraboutContent,
} from "../../Api/Api";
import ServicesAllpages from "../ServicesAllpages";

export default function ApartmentContent() {
  useEffect(() => {
    AOS.init();
  }, []);

  

  const state = useSelector((state) => state);

  const navigate = useNavigate()

  const [serviceReq, setServiceReq] = useState({});

  const [serviceReqValidation, setServiceReqValidation] = useState({});

  const setServiceReqValue = (key, value) => {
    setServiceReq({ ...serviceReq, [key]: value });
    if (serviceReqValidation[key]) delete serviceReqValidation[key];
  };

  const setServiceReqValidationValue = (key, value) => {
    setServiceReqValidation({ ...serviceReqValidation, [key]: value });
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const [userProfile, setUserProfile] = useState([]);
  useEffect(() => {
    if (state?.auth?.isLoggedIn === true) {
      getUserProfile();
    }
    getPropertytypefilterList();
  }, []);
  const getUserProfile = async () => {
    const id = state?.auth?.auth?._id;
    const token = state.auth.auth.token;
    const response = await GetUser(token, id);
    if (response.statusCode === 200) {
      setUserProfile(response.data);
    } else {
      //console.log("error while getting user profile info");
    }
  };

  

  //User Registration api call

  const handleSubmit = async (e) => {
    
   
    e.preventDefault();
    // if (state?.auth?.isLoggedIn) {
    //   const Payload = {
    //     name: userProfile.name,
    //     emailId: userProfile.email,
    //     subject: " ",
    //     question: " ",
    //     mobileNumber: userProfile.phoneNumber,
    //     userId: state?.auth?.auth?._id,
    //     servicesCategory: local1[1],
    //     servicesSubcategory: localStorage?.getItem("subcategory"),
    //     servicesTitle: localStorage?.getItem("title"),
    //   };
    //   // const token = state?.auth?.auth?.token
    //   const response = await UserServiceRequest(Payload);
    //   if (response && response.statusCode === 200) {
    //     setServiceReq({});
    //     showToastSuccessMsg(response.message);
    //     window.location.reload();
    //     localStorage?.removeItem("category");
    //     localStorage?.removeItem("subcategory");
    //     localStorage?.removeItem("title");
    //   } else {
    //     showToastErrorMsg(
    //       response.message || response.error.response.data.message
    //     );
    //     //setfailureModal(true);
    //   }
    // } else {
      let validate = {};
      validate.name = StringValidation(serviceReq?.name);
      validate.emailId = EmailValidation(serviceReq?.emailId);
      validate.subject = NonEmptyValidation(serviceReq?.subject);
      validate.question = NonEmptyValidation(serviceReq?.question);
      validate.mobileNumber = PhoneNumberValidation(serviceReq?.mobileNumber);
  validate.servicesType = NonEmptyValidation(serviceReq?.servicesType);

      setServiceReqValidation(validate);
      if (Object.values(validate).every((v) => v.status === true)) {
        const Payload = {
          name: serviceReq.name,
          emailId: serviceReq.emailId,
          subject: serviceReq.subject,
          question: serviceReq.question,
          mobileNumber: serviceReq.mobileNumber,
          userId: state?.auth?.auth?._id,
          servicesType: serviceReq.servicesType,

          // servicesCategory: local1[1],
          // servicesSubcategory: localStorage?.getItem("subcategory"),
          // servicesTitle: localStorage?.getItem("title"),
        };
        // const token = state?.auth?.auth?.token
        const response = await UseraboutContent(Payload);
        if (response && response.statusCode === 200) {
           showToastSuccessMsg(response.message);
           window.location.reload();
          serviceReq({});
          setServiceReq({});
         
          // window.location.reload();
          localStorage?.removeItem("category");
          localStorage?.removeItem("subcategory");
          localStorage?.removeItem("title");
        } else {
          showToastErrorMsg(
            response.message || response.error.response.data.message
          );
          //setfailureModal(true);
        }
      } else {
        //console.log("Error while submit a service request");
      }
    // }
  };

  const [projectList, setProjectList] = useState([]);
  const [filterList, setFilterList] = useState(projectList);
  const filterDataChange = async (key,key1, value,value1,e,e1) => {
    if(key==="parking" && e.target.checked===true){
      // const response = await GetProperty("Active");
      // if (response && response.statusCode === 200) {
        // let filterData = projectList?.filter((e) => e.property_type === "Apartment" && e.created_property_type==="Auction")
        let finddata = projectList?.filter((e)=>{
          return e.parking === value
        })
      
        if(finddata?.length){
          setFilterList(finddata)
        }else{
          setFilterList(projectList);
        }
        
      // } else {
      //   setFilterList(projectList);
      // }
    }
    
    if(key1==="furnished_type" && e1.target.checked===true){
      // const response = await GetProperty("Active");
      // if (response && response.statusCode === 200) {
      //   let filterData = response.data.filter((e) => e.property_type === "Apartment" && e.created_property_type==="Auction")
        let finddata = projectList?.filter((e)=>{
          return e.furnished_type === value1
        })
        if(finddata?.length){
          setFilterList(finddata)
        }else{
          setFilterList(projectList);
        }
      //   setFilterList(finddata)
      // } else {
      //   setFilterList(projectList);
      // }
    }
    
    if(key1==="furnished_type" && key==="parking" && e.target.checked===true && e1.target.checked===true){
      // const response = await GetProperty("Active");
      // if (response && response.statusCode === 200) {
      //   let filterData = response.data.filter((e) => e.property_type === "Apartment" && e.created_property_type==="Auction")
        let finddata = projectList?.filter((e)=>{
          return e.furnished_type === value1 && e.parking === value
        })
      
        if(finddata?.length){
          setFilterList(finddata)
        }else{
          setFilterList(projectList);
        }
      //   setFilterList(finddata)
      // } else {
      //   setFilterList(projectList);
      // }
    }
    if(key1==="furnished_type" && e1.target.checked===false){
      setFilterList(projectList);
    }
    if(key==="parking" && e.target.checked===false){
      setFilterList(projectList);
    }
   
  }

 
  const getPropertytypefilterList = async () => {
    const response = await GetProperty("Active");
    if (response && response.statusCode === 200) {
      setProjectList(
        response.data.filter((e) => e.property_type === "Apartment" && e.created_property_type==="Auction")
      );
    } else {
      showToastErrorMsg(response.message);
    }
  };
 
  return (
    <div className="overflow-pages">
      <ToastContainer />
      <div className="about-banner2 py-5">
        <div className="container">
          <section className="property">
            <div className="container">
              <div className="propertycardbottomalign">
                <h1
                  className="blogtitle my-auto"
                  data-aos="zoom-out-right"
                  data-aos-duration="2000"
                >
                  New Apartments
                </h1>
                <div className="text-end">
                  <button
                    className="btn login-btn"
                    type="button"
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Filter
                  </button>
                  <ul
                    style={{ width: "400px", cursor: "pointer" }}
                    className="dropdown-menu top-drop-menu m-2 p-3 "
                    aria-labelledby="dropdownMenuButton2"
                  >
                    <h4
                      className="text-start fw-bold color-maroon"
                      style={{ marginLeft: "10px" }}
                    >
                      Advanced Search
                    </h4>
                    <div style={{ marginLeft: "10px" }}>
                      <h5 className="fw-bold">Parking</h5>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="mb-4">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                                //console.log(e.target.checked, "2");
                                filterDataChange(
                                  "parking",
                                  "",
                                  "2 wheeler",
                                  "",
                                  e,
                                  ""
                                );
                              }}
                            />{" "}
                            <label className="form-label"> 2 Wheeler</label>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="mb-4">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                                //console.log(e.target.checked, "4");
                                filterDataChange(
                                  "parking",
                                  "",
                                  "4 wheeler",
                                  "",
                                  e,
                                  ""
                                );
                              }}
                            />{" "}
                            <label className="form-label"> 4 Wheeler</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <h5 className="fw-bold">Furnishing</h5>
                      <div className="row">
                        <div className="col-lg-4 col-md-4">
                          <div className="mb-4">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                                //console.log(e.target.checked, "full");
                                filterDataChange(
                                  "",
                                  "furnished_type",
                                  "",
                                  "full",
                                  "",
                                  e
                                );
                              }}
                            />{" "}
                            <label className="form-label"> Full</label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="mb-4">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                                //console.log(e.target.checked, "semi");
                                filterDataChange(
                                  "",
                                  "furnished_type",
                                  "",
                                  "semi",
                                  "",
                                  e
                                );
                              }}
                            />{" "}
                            <label className="form-label"> Semi</label>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="mb-4">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                                //console.log(e.target.checked, "none");
                                filterDataChange(
                                  "",
                                  "furnished_type",
                                  "",
                                  "none",
                                  "",
                                  e
                                );
                              }}
                            />{" "}
                            <label className="form-label"> None</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                  {/* <div
                className="offcanvas offcanvas-start"
                data-bs-scroll="true"
                tabIndex="-1"
                id="offcanvasWithBothOptions"
                aria-labelledby="offcanvasWithBothOptionsLabel"
              >
                <div className="offcanvas-header">
                  <h5
                    className="offcanvas-title"
                    id="offcanvasWithBothOptionsLabel"
                  >
                    Advanced Search
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    
                  ></button>
                 
                </div>
                <div className="offcanvas-body">
                  <div className="my-3">
                    <label className="label">Parking</label>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="d-flex">
                          <input
                            type="checkbox"
                            onClick={() => {
                              filterDataChange("parking", "2 wheeler");
                            }}
                          />
                          <h6 className="checkboxtext1">2 Wheeler</h6>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="d-flex">
                          <input
                            type="checkbox"
                            onClick={() => {
                              filterDataChange("parking", "4 wheeler");
                            }}
                          />
                          <h6 className="checkboxtext1">4 Wheeler</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="my-3">
                    <label className="label">Furnishing</label>
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="d-flex">
                          <input
                            type="checkbox"
                            onClick={() => {
                              filterDataChange("furnished_type", "full");
                            }}
                          />
                          <h6 className="checkboxtext2">Full</h6>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="d-flex">
                          <input
                            type="checkbox"
                            onClick={() => {
                              filterDataChange("furnished_type", "semi");
                            }}
                          />
                          <h6 className="checkboxtext2">Semi</h6>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="d-flex">
                          <input
                            type="checkbox"
                            onClick={() => {
                              filterDataChange("furnished_type", "none");
                            }}
                          />
                          <h6 className="checkboxtext2">None</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                </div>
              </div>
              <div className="projectrow">
                <div className="row">
                  <div className="col-lg-11 mx-auto ms-auto">
                    {projectList?.length === 0 && (
                      <img
                        src={Norecord}
                        alt=""
                        style={{
                          width: "200px",
                          height: "200px",
                          marginLeft: "500px",
                        }}
                        className="img-fluid"
                      />
                    )}
                    <div className="row">
                      {filterList && filterList?.length > 0 ? (
                        <>
                          {filterList?.map((e, index) => {
                            return (
                              <div className="col-lg-3 mb-3" key={index}>
                                <div className="admin-service">
                                  <div className="small-banner-img-head">
                                    <img
                                      style={{ width: "100%", height: "200px" }}
                                      // className="w-100 h-auto"
                                      src={e.cover_image}
                                      alt=""
                                    />
                                  </div>
                                  <div className="mt-3">
                                    <h6 className="color-maroon fw-bold">
                                      {e?.property_name}
                                    </h6>
                                    <p className="small">
                                      <span className="material-symbols-rounded align-middle me-1 filled-icon">
                                        location_on
                                      </span>
                                      {e?.property_information.address}
                                    </p>

                                    <p className="small">
                                      <b>Room:</b>{" "}
                                      {e?.property_information.room_size}
                                      BHK
                                    </p>
                                    <p className="small">
                                      <b>SqFt :</b>{" "}
                                      {e?.property_information.sq_ft}
                                    </p>

                                    <button
                                      type="button"
                                      className="btn btn-admin w-100"
                                      onClick={() => {
                                        navigate(`/ApartmentDeatails/${e._id}`);
                                      }}
                                    >
                                      View Details
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {projectList?.map((e ,i ) => {
                            return (
                              <div className="col-lg-3 mb-3" key={i}>
                                <div className="admin-service">
                                  <div className="small-banner-img-head">
                                    <img
                                      style={{ width: "100%", height: "200px" }}
                                      // className="w-100 h-auto"
                                      src={e.cover_image}
                                      alt=""
                                    />
                                  </div>
                                  <div className="mt-3">
                                    <h6 className="color-maroon fw-bold">
                                      {e?.property_name}
                                    </h6>
                                    <p className="small">
                                      <span className="material-symbols-rounded align-middle me-1 filled-icon">
                                        location_on
                                      </span>
                                      {e?.property_information.address}
                                    </p>

                                    <button
                                      type="button"
                                      className="btn btn-admin w-100"
                                      onClick={() => {
                                        navigate(`/ApartmentDeatails/${e._id}`);
                                      }}
                                    >
                                      View Details
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="client-left-section py-5">
        <div className="container my-4">
          <h1 className="banner-heading after-line mb-5">FEEL FREE TO ASK</h1>
          <div className="row">
            <div className="col-lg-6" data-aos="fade-left">
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.823571029211!2d80.23700327559305!3d13.046899587275508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267c25ec3b519%3A0xb10841584ccc73c4!2sDALMAF%20Private%20Limited!5e0!3m2!1sen!2sin!4v1688118977225!5m2!1sen!2sin"
                  width="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6 col-md-12" data-aos="fade-left">
              {/* {state?.auth?.isLoggedIn ? (
                <button
                  type="button"
                  className="btn login-btn w-100"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  Request Callback
                </button>
              ) : ( */}
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Name"
                      aria-invalid={Boolean(
                        serviceReqValidation?.name?.status === false
                      )}
                      value={serviceReq?.name || ""}
                      onChange={(e) => {
                        setServiceReqValue("name", e.target.value);
                      }}
                      onBlur={(e) => {
                        setServiceReqValidationValue(
                          "name",
                          StringValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {serviceReqValidation?.name?.message
                        ? `Name ${serviceReqValidation?.name?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Mobile No <span className="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      maxLength={10}
                      className="form-control"
                      placeholder="Enter Your Mobile No"
                      aria-invalid={Boolean(
                        serviceReqValidation?.mobileNumber?.status === false
                      )}
                      value={serviceReq?.mobileNumber || ""}
                      onChange={(e) => {
                        setServiceReqValue("mobileNumber", e.target.value);
                      }}
                      onBlur={(e) => {
                        setServiceReqValidationValue(
                          "mobileNumber",
                          PhoneNumberValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {serviceReqValidation?.mobileNumber?.message
                        ? `Mobile no ${serviceReqValidation?.mobileNumber?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      E- Mail ID <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your E- Mail ID"
                      aria-invalid={Boolean(
                        serviceReqValidation?.emailId?.status === false
                      )}
                      value={serviceReq?.emailId?.toLowerCase() || ""}
                      onChange={(e) => {
                        setServiceReqValue("emailId", e.target.value);
                      }}
                      onBlur={(e) => {
                        setServiceReqValidationValue(
                          "emailId",
                          EmailValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {serviceReqValidation?.emailId?.message
                        ? `Email ${serviceReqValidation?.emailId?.message}`
                        : ""}
                    </small>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Service Type
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <select
                      className="form-control form-control-lg"
                      value={serviceReq?.servicesType || ""}
                      onChange={(e) => {
                        setServiceReqValue("servicesType", e.target.value);
                      }}
                      onBlur={(e) => {
                        setServiceReqValidationValue(
                          "servicesType",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    >
                      <option value="">---Select Service Type---</option>
                      <option value="Legal">Legal</option>
                      <option value="Audit">Audit</option>
                      <option value="Manpower">Manpower</option>
                      <option value="Auction">Auction</option>
                      <option value="Buy/Sell">Buy/Sell</option>
                    </select>
                    {/* <small className="text-danger">
                      {serviceReqValidation?.servicesType?.message
                        ? `Service Type ${serviceReqValidation?.servicesType?.message}`
                        : ""}
                    </small> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Questions <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Questions "
                      aria-invalid={Boolean(
                        serviceReqValidation?.question?.status === false
                      )}
                      value={serviceReq?.question || ""}
                      onChange={(e) => {
                        setServiceReqValue("question", e.target.value);
                      }}
                      onBlur={(e) => {
                        setServiceReqValidationValue(
                          "question",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {serviceReqValidation?.question?.message
                        ? `Question ${serviceReqValidation?.question?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Subject <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows="6"
                      className="form-control"
                      placeholder="Enter Subject"
                      aria-invalid={Boolean(
                        serviceReqValidation?.subject?.status === false
                      )}
                      value={serviceReq?.subject || ""}
                      onChange={(e) => {
                        setServiceReqValue("subject", e.target.value);
                      }}
                      onBlur={(e) => {
                        setServiceReqValidationValue(
                          "subject",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    ></textarea>
                    <small className="text-danger">
                      {serviceReqValidation?.subject?.message
                        ? `Subject ${serviceReqValidation?.subject?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  

                  <button
                    type="button"
                    className="btn login-btn w-100"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      <div className="number-counter py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <span className="material-symbols-rounded align-middle about-call filled-icon">
                        call
                      </span>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">Toll Free</h5>
                    <h5 className="mb-0 fs-4">1800-309-8445</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <span className="material-symbols-rounded align-middle about-call filled-icon">
                        mail
                      </span>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">Email ID</h5>
                    <h5 className="mb-0 fs-4">info@dalmaf.com</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <i className="fa-brands fa-whatsapp about-whatsapp-icon"></i>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">WhatsApp Us</h5>
                    <h5 className="mb-0 fs-4">+91 96000 38059</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ServicesAllpages />
      </div>
    </div>
  );
}
