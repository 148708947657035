import React, { useEffect } from 'react';
import {Helmet} from "react-helmet";
import Footer from '../../../Components/Footer/Footer';
import Navigation from '../../../Components/v1/NavBar/Navigaton';
import ListAllServices from '../../../Components/v1/Services/ListAllServices';
import ScheduleMeetBanner from '../../../Components/v1/Home/ScheduleMeetBanner';
import Testimonial from '../../../Components/v1/Home/Testimonial';
import AppDownload from '../../../Components/v1/Home/AppDownload';
import FAQ from '../../../Components/v1/Home/FAQ';
import Newsletter from '../../../Components/v1/Home/Newletter';
import SpecificServicesComponent from '../../../Components/v1/Services/SpecificServicesComponent';

export default function SpecificService() {
     useEffect(() =>{
    window.scroll(0,0)
  },[])

 

  return (
    <div className="innerpages-nav">
      <Helmet>
          <meta charSet="utf-8" />
          <title>DALMaf Service Listing | DALMaf Private Limited</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content="We are happy to Introduce the Dalmaf Company, the one-stop destination for Legal, Auditing, Bank Auction Support, Buying and Selling, and Marketing Assistance. Founded in June of 2022 as a Global Market Pioneer for offering comprehensive company solutions in all aspects, Our team of Professionals is Passionate about aiding businesses in Resolving Difficulties, Strengthening their Internal Operations, and Realising their ultimate goal." />
          <meta name="keywords" content="We are happy to Introduce the Dalmaf Company, the one-stop destination for Legal, Auditing, Bank Auction Support, Buying and Selling, and Marketing Assistance. Founded in June of 2022 as a Global Market Pioneer for offering comprehensive company solutions in all aspects, Our team of Professionals is Passionate about aiding businesses in Resolving Difficulties, Strengthening their Internal Operations, and Realising their ultimate goal." />
          <meta name="author" content="DALMaf" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>   
      <div id="container" >
        <Navigation/>
            <SpecificServicesComponent/>
            <ScheduleMeetBanner/>
            <Testimonial/>
            <AppDownload/>
            <Newsletter/>
            <FAQ/>
        <Footer />
      </div>
    </div>
  );
}
