import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import ADclient from "../../../Assets/Images/adclient.webp";
import Norecord from "../../../Assets/Images/MicrosoftTeams-image.webp";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  ChangePassword,
  GetParticularServiceRequestUser,
  GetParticularServiceRequestUserr,
  GetServiceRequestByRole,
  GetSpecificHistory,
  GetSpecificHistoryUser,
  GetUser,
  UserProfileUpdate,
  getUserHistorySpecific,
} from "../../../Api/Api";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import CustomModal from "../../../Components/CustomModal";

export default function UserProfileDetails() {
  useEffect(() => {
    getSpecificUserManagement();
    getSpecificUserHistory("service");
  }, []);
  let { id } = useParams();
  const [selectedTaskListTab, setSelectedTaskListTab] =
    React.useState("Service");
  const AllTabs = ["Service", "Package", "Custom"];
  const [specificHistoryModal, setSpecificHistoryModal] = useState(false);
  let userId = window.location.href;
  const [userManagementUser, setUserManagementUser] = useState([]);
  const state = useSelector((state) => state);
  const getSpecificUserManagement = async () => {
    const token = state?.auth?.auth?.token;
    const data = await GetUser(
      token,
      userId?.substring(userId?.lastIndexOf("/") + 1)
    );
    if (data && data.statusCode === 200) {
      setUserManagementUser(data.data);
    } else {
      setUserManagementUser([]);

      //console.log("Error while getting user details");
    }
  };

  const [userManagementHistory, setUserManagementHistory] = useState([]);
  const getSpecificUserHistory = async (value) => {
    const token = state?.auth?.auth?.token;
    const data = await getUserHistorySpecific(token, id, value);
    if (data && data.statusCode === 200) {
      let temp = [];
      data.data.map((data, i) => {
        let dummy = {
          _id: data?._id,
          price: data?.price,
          // createdAt: data?.createdAt,
          createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
          paymentInfo: data?.paymentInfo?.razorpay_payment_id,

          status: data?.status,
        };
        temp.push(dummy);
        return null;
      });
      setUserManagementHistory(temp);
    } else {
      setUserManagementHistory([]);

      //console.log("Error while getting user details");
    }
  };
  useEffect(() => {
    // getUserManagement();
    getSpecificUser();
  }, []);
  const [specificUser, setSpecificUser] = useState([]);
  const getSpecificUser = async () => {
    const token = state?.auth?.auth?.token;
    const data = await GetParticularServiceRequestUserr(token, id);
    if (data && data.statusCode === 200) {
      setSpecificUser(data.data);
    } else {
      setSpecificUser([]);

      //console.log("Error while getting user details");
    }
  };

  // const [userProfileReq, setUserProfileReq] = useState([]);
  // const getUserManagement = async () => {
  //   const token = state.auth.auth.token;
  //   let response = await GetServiceRequestByRole(token, "user");

  //   if (response.statusCode === 200) {
  //     setUserProfileReq(response.data);
  //   } else {
  //     setUserProfileReq([]);

  //     //console.log("error while getting user profile info");
  //   }
  // };

  const navigate = useNavigate();

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const handleSubmitUpdate = async (e, value) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
    };
    const token = state?.auth?.auth?.token;
    const response = await UserProfileUpdate(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      window.location.reload();
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };
  const handleSubmitChangePassword = async (e) => {
    e.preventDefault();

    const Payload = {
      email: userManagementUser?.email,
      password: "Qwerty@123",
    };

    const response = await ChangePassword(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const [specificHistory, setSpecificHistory] = useState([]);

  async function fetchDataSpecificHistory(id) {
    const token = state?.auth?.auth?.token;
    const getBasicDetails = await GetSpecificHistory(token, id);
    if (getBasicDetails && getBasicDetails?.statusCode === 200) {
      setSpecificHistory(getBasicDetails.data);
    } else {
      setSpecificHistory([]);
      //console.log("error while getting user profile info");
    }
  }

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "paymentInfo",
      label: "Payment ID",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer", textTransform: "capitalize" }}
                onClick={(val) => {
                  fetchDataSpecificHistory(tableMeta.rowData[0]);
                  setSpecificHistoryModal(true);
                }}
              >
                {value ? value : "-"}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Payment Date",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              {value}
              {/* <div>{moment(value).format("DD/MM/YYYY")}</div>
              <p className="mb-0 small">
                At {moment(value).format("h:mm:ss a")}
              </p> */}
            </div>
          );
        },
      },
    },

    {
      name: "price",
      label: "Price",
      options: {
        customBodyRender: (value) => {
          return <div>₹{value}</div>;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "admin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
    {
      name: "subadmin_read",
      label: "Status",
      options: {
        display: false,
      },
    },
  ];

  return (
    <div>
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        <div>
          <div className="">
            <div className="row">
              <div className="col-6 text-start">
                <h5 className="mb-0 fw-bold color-maroon">Profile Details</h5>
              </div>
              <div className="col-6 text-end">
                <button
                  className="btn py-1  login-btn"
                  type="button"
                  onClick={(e) => {
                    navigate("/UserManagement");
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
          <div className="">
            <div className="container py-4">
              <div className="row">
                <div className="col-lg-12">
                  <div className="">
                    <div className="profile-head">
                      <div className="profile-head1"></div>
                      <div className="d-flex profile-head2  align-items-center">
                        <div className="flex-shrink-0 ">
                          {
                            userManagementUser?.profileImage?.length ? 
                            <img
                            className="admin-pro-img"
                            style={{ width: "100px", height: "100px" }}
                            src={userManagementUser?.profileImage}
                            alt="..."
                          />
                          :
                          <img
                            className="admin-pro-img"
                            style={{ width: "100px", height: "100px" }}
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_xwO2Lbr28IM5Yy5jHDaEPjEWOIdzt34nNg&usqp=CAU"
                            alt="..."
                          />
                          }
                          
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h6>
                            {userManagementUser?.name}{" "}
                            {userManagementUser?.lastName}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="pro-body">
                      <div className="container">
                        <div className="row justify-content-center pt-5">
                          <div className="col-lg-5">
                            <div
                              className="bg-grey"
                              style={{
                                padding: "0",
                              }}
                            >
                              <h6 className="color-maroon  fw-bold p-3">
                                Info
                              </h6>
                              <div className="">
                                <table className="table table-borderless admin-info">
                                  <tr>
                                    <th>Full Name :</th>
                                    <td>
                                      {userManagementUser?.name}{" "}
                                      {userManagementUser?.lastName}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Mobile No :</th>
                                    <td>{userManagementUser?.phoneNumber}</td>
                                  </tr>
                                  <tr>
                                    <th>E- Mail ID :</th>
                                    <td>{userManagementUser?.email}</td>
                                  </tr>
                                  <tr>
                                    <th>Created On :</th>
                                    <td>
                                      {moment(
                                        userManagementUser?.createdAt
                                      ).format("DD.MM.YYYY")}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Status :</th>
                                    <td>
                                      <div className="toggle-switch">
                                        {userManagementUser?.status ===
                                        "active" ? (
                                          <input
                                            type="checkbox"
                                            defaultChecked
                                            onChange={(e) => {
                                              handleSubmitUpdate(e, "inactive");
                                            }}
                                            id="switch"
                                          />
                                        ) : (
                                          <input
                                            type="checkbox"
                                            onChange={(e) => {
                                              handleSubmitUpdate(e, "active");
                                            }}
                                            id="switch"
                                          />
                                        )}
                                        <label for="switch">Toggle</label>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <th></th>
                                      <div>
                                        <button
                                          className="reset-btn"
                                          style={{
                                            marginLeft: "14px",
                                          }}
                                          onClick={(e) => {
                                            handleSubmitChangePassword(e);
                                          }}
                                        >
                                          Reset Password
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="bg-grey p-4">
                              <div className="d-flex justify-content-between">
                                <h6 className="color-maroon  fw-bold">
                                  Servises
                                </h6>
                                <p
                                  className="mb-0 color-maroon fw-bold cursor text-decoration-underline"
                                  onClick={(e) => {
                                    navigate(`/UserServiceDetails/${id}`);
                                  }}
                                >
                                  View All
                                </p>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  {specificUser.length ? (
                                    <>
                                      <div className="task-details-carou pt-4">
                                        <OwlCarousel
                                          className="owl-theme"
                                          loop
                                          autoplay={true}
                                          items="3"
                                          dots={false}
                                          smartSpeed={2000}
                                          nav={true}
                                          margin={20}
                                          center={false}
                                          autoplayTimeout={5000}
                                          responsive={{
                                            360: {
                                              items: "1",
                                            },
                                            414: {
                                              items: "1",
                                            },
                                            670: {
                                              items: "2",
                                            },
                                            992: {
                                              items: "2",
                                            },
                                            1200: {
                                              items: "2",
                                            },
                                          }}
                                        >
                                          {specificUser?.map((e, i) => (
                                            <>
                                              {e.status === "assigned" ? (
                                                <>
                                                  <div
                                                    className="assigned-section"
                                                    key={i}
                                                  >
                                                    <div className="d-flex justify-content-between align-items-start">
                                                      <h6 className="fw-bold color-maroon">
                                                        {e.servicesCategory}{" "}
                                                      </h6>
                                                      <p className="mb-0 small color-blue">
                                                        {e.status}
                                                      </p>
                                                    </div>
                                                    <div>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Assigned To :
                                                        </span>{" "}
                                                        <span>
                                                          {e?.assignedTo?.name}
                                                        </span>
                                                      </p>
                                                      <div className="d-flex justify-content-between">
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            Created At :
                                                          </span>{" "}
                                                          <span>
                                                            {" "}
                                                            {moment(
                                                              e.createdAt
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </span>
                                                        </p>
                                                      </div>
                                                      {/* <p className="mb-2 small">
                                         <span className="fw-bold ">
                                           Last Login :
                                         </span>{" "}
                                         <span>4 Hours Ago</span>
                                       </p> */}
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {e.status === "unassigned" ? (
                                                <div className="inprogress-section">
                                                  <div className="d-flex justify-content-between align-items-start">
                                                    <h6 className="fw-bold color-maroon">
                                                      {e.servicesCategory}{" "}
                                                    </h6>
                                                    <p className="mb-0 small color-yellow">
                                                      {e.status}
                                                    </p>
                                                  </div>
                                                  <div>
                                                    <p className="mb-2 small">
                                                      <span className="fw-bold ">
                                                        Assigned To :
                                                      </span>{" "}
                                                      <span>
                                                        {e?.assignedTo?.name}
                                                      </span>
                                                    </p>
                                                    <div className="d-flex justify-content-between">
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Created At :
                                                        </span>{" "}
                                                        <span>
                                                          {" "}
                                                          {moment(
                                                            e.createdAt
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </span>
                                                      </p>
                                                    </div>
                                                    {/* <p className="mb-2 small">
                                              <span className="fw-bold ">
                                                Last Login :
                                              </span>{" "}
                                              <span>4 Hours Ago</span>
                                            </p> */}
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}

                                              {e.status === "inprogress" ? (
                                                <div className="completed-section">
                                                  <div className="d-flex justify-content-between align-items-start">
                                                    <h6 className="fw-bold color-maroon">
                                                      {e.servicesCategory}{" "}
                                                    </h6>
                                                    <p className="mb-0 color-green2">
                                                      {e.status}
                                                    </p>
                                                  </div>
                                                  <div>
                                                    <p className="mb-2 small">
                                                      <span className="fw-bold ">
                                                        Assigned To :
                                                      </span>{" "}
                                                      <span>
                                                        {e?.assignedTo?.name}
                                                      </span>
                                                    </p>
                                                    <div className="d-flex justify-content-between">
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Created At :
                                                        </span>{" "}
                                                        <span>
                                                          {" "}
                                                          {moment(
                                                            e.createdAt
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </span>
                                                      </p>
                                                    </div>
                                                    {/* <p className="mb-2 small">
                                              <span className="fw-bold ">
                                                Last Login :
                                              </span>{" "}
                                              <span>4 Hours Ago</span>
                                            </p> */}
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}

                                              {e.status === "completed" ? (
                                                <div className="rejected-section">
                                                  <div className="d-flex justify-content-between align-items-start">
                                                    <h6 className="fw-bold color-maroon">
                                                      legal Service{" "}
                                                    </h6>
                                                    <p className="mb-0 color-redish">
                                                      Rejected
                                                    </p>
                                                  </div>
                                                  <div>
                                                    <p className="mb-2 small">
                                                      <span className="fw-bold ">
                                                        Assigned To :
                                                      </span>{" "}
                                                      <span>
                                                        {e?.assignedTo?.name}
                                                      </span>
                                                    </p>
                                                    <div className="d-flex justify-content-between">
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Created At :
                                                        </span>{" "}
                                                        <span>
                                                          {" "}
                                                          {moment(
                                                            e.createdAt
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </span>
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ))}
                                        </OwlCarousel>
                                      </div>
                                    </>
                                  ) : (
                                    "No Service Request Found"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <h5 className="mb-0 fw-bold color-maroon">Payment Details</h5>
          </div>
          <div className="my-4">
            <>
              <div className="my-4">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7 px-0">
                      <div className="tab-section">
                        <ul className="ps-0">
                          {AllTabs.map((tab, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  selectedTaskListTab === tab
                                    ? "serviceTab active"
                                    : "serviceTab inactive"
                                }
                                onClick={() => {
                                  if (tab === "Service") {
                                    getSpecificUserHistory("service");
                                  } else if (tab === "Package") {
                                    getSpecificUserHistory("package");
                                  } else if (tab === "Custom") {
                                    getSpecificUserHistory("custom-package");
                                  }
                                  setSelectedTaskListTab(tab);
                                }}
                              >
                                <li>
                                  <span className="align-middle">{tab}</span>
                                </li>
                              </div>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div style={{height:"200px"}}>  */}
                <MUIDataTable
                  title={"Dalmaf - Purchase History"}
                  data={userManagementHistory}
                  columns={columns}
                />
              </div>
            </>
            {/*     // <>
              //   <table className="table">
              //     <thead className="bg-historytablehead">
              //       <tr>
              //         <th scope="col">S. No</th>
              //         <th scope="col">User Name</th>
              //         <th scope="col">Date</th>
              //         <th scope="col">Transfer ID </th>
              //         <th scope="col">Amount</th>
              //         <th scope="col">Status</th>
              //       </tr>
              //     </thead>
              //     <tbody className="historytablebody">
              //       {userManagementHistory?.map((e, index) => (
              //         <tr>
              //           <td>
              //             <p className="mb-0">{index + 1}</p>
              //           </td>
              //           <td scope="row">
              //             <div
              //               className="d-flex align-items-center "
              //               key={index}
              //             >
              //               <div className="flex-shrink-0">
              //                 <img
              //                   style={{ width: "100px", height: "100px" }}
              //                   // className="w-100 h-auto"
              //                   src={
              //                     userManagementUser?.profileImage
              //                       ? userManagementUser?.profileImage
              //                       : ADclient
              //                   }
              //                   alt="..."
              //                 />
              //               </div>
              //               <div className=" ms-3">
              //                 <h6 className="fw-bold">
              //                   {userManagementUser?.name}
              //                 </h6>
              //                 <p className="mb-0 small">
              //                   {userManagementUser?.phoneNumber}
              //                 </p>
              //               </div>
              //             </div>
              //           </td>
              //           <td>
              //             <h6 className="fw-bold">
              //               {" "}
              //               {moment(e.createdAt).format("DD-MM-YYYY")}
              //             </h6>
              //             <p className="mb-0">
              //               At {moment(e.createdAt).format("h:mm:ss a")}
              //             </p>
              //           </td>
              //           <td>
              //             <div className=" ">
              //               <h6 className="fw-bold">{e.transferId}</h6>
              //             </div>
              //           </td>
              //           <td>
              //             <h6 className="fw-bold">₹{e.amount}</h6>
              //           </td>

              //           <td>
              //             <div className="">
              //               <h6 className="fw-bold color-green"> {e.status}</h6>
              //             </div>
              //           </td>
              //         </tr>
              //       ))}
              //     </tbody>
              //   </table>
              // </>*/}
          </div>
        </div>
        <CustomModal
          open={specificHistoryModal}
          onClickOutside={() => {
            setSpecificHistoryModal(false);
          }}
        >
          <div className="modal-content w-50 m-auto">
            <div className="modal-body p-0 ">
              <div className="container">
                <span
                  className="material-symbols-rounded filled-icon close-icon"
                  // style={{ textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px" }}

                  onClick={() => {
                    setSpecificHistoryModal(false);
                  }}
                >
                  close
                </span>

                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className=" my-5 px-3">
                          <div className="row justify-content-center mt-2">
                            {/* {specificHistory?.map((e, index) => ( */}

                            <div className="col-lg-12">
                              <div className="admin-service">
                                <div className="mt-3 ">
                                  {/* <h6 className="color-maroon fw-bold text-center">

                                {specificHistory?.servicesRequestId?.servicesTitle}

                              </h6> */}

                                  <p className="small">
                                    <h6 className="color-maroon fw-bold">
                                      Payment Id:
                                    </h6>{" "}
                                    {
                                      specificHistory?.paymentInfo
                                        ?.razorpay_payment_id
                                    }
                                  </p>

                                  <p className="small">
                                    <h6 className="color-maroon fw-bold">
                                      Transfer Id:
                                    </h6>{" "}
                                    {specificHistory?.transferId}
                                  </p>

                                  <p className="small">
                                    <h6 className="color-maroon fw-bold">
                                      Purchase Type:
                                    </h6>{" "}
                                    {specificHistory?.purchaseType}
                                  </p>

                                  <p className="small">
                                    <h6 className="color-maroon fw-bold">
                                      Price :
                                    </h6>{" "}
                                    {specificHistory?.price}
                                  </p>

                                  <p className="small">
                                    <h6 className="color-maroon fw-bold">
                                      Purchase Type:
                                    </h6>{" "}
                                    {specificHistory?.purchaseType}
                                  </p>
                                  <p className="small">
                                    <h6 className="color-maroon fw-bold">
                                      Price :
                                    </h6>{" "}
                                    {specificHistory?.price}
                                  </p>
                                  <p className="small">
                                    <h6 className="color-maroon fw-bold">
                                      Package Info :
                                    </h6>{" "}
                                    {specificHistory?.PackageInfo}
                                  </p>
                                  <p className="small">
                                    <h6 className="color-maroon fw-bold">
                                      status:
                                    </h6>{" "}
                                    {specificHistory?.status}
                                  </p>

                                  <p className="small">
                                    <h6 className="color-maroon fw-bold">
                                      createdAt:
                                    </h6>{" "}
                                    {moment(specificHistory.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>

                                  <p className="small">
                                    <h6 className="color-maroon fw-bold">
                                      Updated At:
                                    </h6>{" "}
                                    {moment(specificHistory.updatedAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                </div>{" "}
                              </div>
                            </div>

                            {/* ))} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
}
