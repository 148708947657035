import moment from "moment";
import React, { useState, useEffect } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  Adminspecificlist,
  AdminspecificlistSRScroll,
  AdminspecificlistScroll,
  ChangePassword,
  GetAllAdminRole,
  GetAllEmployeeRole,
  GetParticularServiceRequestUser,
  GetUser,
  UserProfileUpdate,
} from "../../../Api/Api";
import { useParams } from "react-router-dom";
import { GetInitials } from "../../../Store/Constants";

export default function AdminProfileDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scroll(0, 0);

    getSpecificUser();
    getSpecificService();
    GetMyTsksScroll();
    getListEmployeeRole();
  }, []);
  const [specificTaskScroll, setSpecificTaskScroll] = useState([]);
  const GetMyTsksScroll = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await AdminspecificlistScroll(token, id);
    if (data && data.statusCode === 200) {
      setSpecificTaskScroll(data.data);
      setLoading(false);
    } else {
      setSpecificTaskScroll([]);
      setLoading(false);
      //console.log("Error while getting my task");
    }
  };
  //console.log(specificTaskScroll);
  let userId = window.location.href;
  const [specificUser, setSpecificUser] = useState([]);
  const state = useSelector((state) => state);
  const getSpecificUser = async () => {
    const token = state?.auth?.auth?.token;
    const data = await GetUser(
      token,
      userId?.substring(userId?.lastIndexOf("/") + 1)
    );
    if (data && data.statusCode === 200) {
      setSpecificUser(data?.data);
    } else {
      //console.log("Error while getting user details");
    }
  };

  const [employeeroleList, setEmployeeRoleList] = useState([]);
  const getListEmployeeRole = async () => {
    const token = state?.auth?.auth?.token;
    const response = await GetAllAdminRole(token, "active");
    if (response.statusCode === 200) {
      setEmployeeRoleList(response.data);
    } else {
      setEmployeeRoleList([]);
    }
  };

  const [specificService, setSpecificService] = useState([]);
  const getSpecificService = async () => {
    const token = state?.auth?.auth?.token;
    const data = await AdminspecificlistSRScroll(token, id);
    if (data && data.statusCode === 200) {
      setSpecificService(data.data);
    } else {
      //console.log("Error while getting user details");
    }
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const handleSubmitUpdate = async (e, value) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      status: value,
    };
    const token = state?.auth?.auth?.token;
    const response = await UserProfileUpdate(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      window.location.reload();
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const handleSubmitChangePassword = async (e) => {
    e.preventDefault();

    const Payload = {
      email: specificUser?.email,
      password: "Qwerty@123",
    };

    const response = await ChangePassword(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const handleSubmitRole = async (e, value) => {
    e.preventDefault();
    const Payload = {
      _id: id,
      pageAccess: value,
    };
    const token = state?.auth?.auth?.token;
    const response = await UserProfileUpdate(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      window.location.reload();
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  return (
    <div>
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        <div>
          <div className="">
            <div className="row">
              <div className="col-6 text-start">
                <h5 className="mb-0 fw-bold color-maroon">Profile Details</h5>
              </div>
              <div className="col-6 text-end">
                <button
                  className="btn py-1  login-btn"
                  type="button"
                  onClick={(e) => {
                    navigate("/AdminManagement");
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
          <div className="">
            <div className="container py-4">
              <div className="row">
                <div className="col-lg-12">
                  <div className="">
                    <div className="profile-head">
                      <div className="profile-head1"></div>
                      <div className="d-flex profile-head2  align-items-center">
                        <div className="flex-shrink-0 ">
                          {/* {specificUser?.profileImage ? (<img
                            className="admin-pro-img "
                            style={{ width: "100px", height: "100px" }}
                            src={specificUser?.profileImage}
                            alt="..."
                          />):(
                            <h1
                            style={{ width: "100px", height: "100px",textAlign:"center"}}
                            className="admin-pro-img"
                            
                          >
                            {GetInitials(specificUser?.name)}
                          </h1>
                          )}   */}
                          {specificUser?.profileImage ? (
                            <img
                              style={{ width: "100px", height: "100px" }}
                              className="admin-pro-img"
                              src={specificUser?.profileImage}
                              alt="..."
                            />
                          ) : (
                            <h1
                              style={{
                                width: "100px",
                                height: "100px",
                                textAlign: "center",
                              }}
                              className="admin-pro-img"
                            >
                              {" "}
                              {GetInitials(specificUser?.name)}
                            </h1>
                          )}
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h6>
                            {specificUser?.name} {specificUser?.lastName}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="pro-body">
                      <div className="container">
                        <div className="row justify-content-center pt-5">
                          <div className="col-lg-5">
                            <div
                              className="bg-grey"
                              style={{
                                padding: "0",
                              }}
                            >
                              <h6 className="color-maroon  fw-bold p-3">
                                Info
                              </h6>

                              {specificUser && (
                                <>
                                  <div className="">
                                    <table className="table table-borderless admin-info">
                                      <tr>
                                        <th>Full Name :</th>
                                        <td>{specificUser?.name}</td>
                                      </tr>
                                      <tr>
                                        <th>Mobile No :</th>
                                        <td>{specificUser?.phoneNumber}</td>
                                      </tr>
                                      <tr>
                                        <th>E- Mail ID :</th>
                                        <td>{specificUser?.email}</td>
                                      </tr>
                                      <tr>
                                        <th>Created On :</th>
                                        <td>
                                          {moment(
                                            specificUser?.createdAt
                                          ).format("DD.MM.YYYY")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Role :</th>
                                        <td>
                                          <div className="toggle-switch">
                                            <select
                                              className="form-control form-control-lg"
                                              value={
                                                specificUser?.pageAccess?._id
                                              }
                                              onChange={(e) => {
                                                handleSubmitRole(
                                                  e,
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              {employeeroleList?.map((e) => (
                                                <option value={e?._id}>
                                                  {e?.title}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Status :</th>
                                        <td>
                                          <div className="toggle-switch">
                                            {specificUser?.status ===
                                            "active" ? (
                                              <input
                                                type="checkbox"
                                                defaultChecked
                                                onChange={(e) => {
                                                  handleSubmitUpdate(
                                                    e,
                                                    "inactive"
                                                  );
                                                }}
                                                id="switch"
                                              />
                                            ) : (
                                              <input
                                                type="checkbox"
                                                onChange={(e) => {
                                                  handleSubmitUpdate(
                                                    e,
                                                    "active"
                                                  );
                                                }}
                                                id="switch"
                                              />
                                            )}
                                            <label for="switch">Toggle</label>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <th></th>
                                          <div>
                                            <button
                                              className="reset-btn"
                                              style={{
                                                marginLeft: "14px",
                                              }}
                                              onClick={() => {
                                                handleSubmitChangePassword();
                                              }}
                                            >
                                              Reset Password
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <div className="bg-grey p-4">
                              <div className="d-flex justify-content-between">
                                <h6 className="color-maroon  fw-bold">
                                  Task Details
                                </h6>
                                <p
                                  className="mb-0 color-maroon fw-bold cursor text-decoration-underline"
                                  onClick={() =>
                                    navigate(`/AdminTaskDetails/${id}`)
                                  }
                                >
                                  View All
                                </p>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  {" "}
                                  {specificTaskScroll.length ? (
                                    <>
                                      <div className="task-details-carou pt-4">
                                        <OwlCarousel
                                          className="owl-theme"
                                          loop
                                          autoplay={true}
                                          items="3"
                                          dots={false}
                                          smartSpeed={2000}
                                          nav={true}
                                          margin={20}
                                          center={false}
                                          autoplayTimeout={5000}
                                          responsive={{
                                            360: {
                                              items: "1",
                                            },
                                            414: {
                                              items: "1",
                                            },
                                            670: {
                                              items: "2",
                                            },
                                            992: {
                                              items: "2",
                                            },
                                            1200: {
                                              items: "2",
                                            },
                                          }}
                                        >
                                          {specificTaskScroll?.map((e, i) => (
                                            <>
                                              {e?.status === "Completed" ? (
                                                <div
                                                  key={i}
                                                  className="completed-section"
                                                >
                                                  <div className="d-flex justify-content-between">
                                                    <h6 className="fw-bold color-maroon">
                                                      {e.servicesCategory}{" "}
                                                    </h6>
                                                    <p className="mb-0 color-green2">
                                                      {e.status}
                                                    </p>
                                                  </div>
                                                  <div>
                                                    <p className="mb-2 small">
                                                      <span className="fw-bold ">
                                                        Assigned To :
                                                      </span>{" "}
                                                      <span>
                                                        {e?.assignedTo?.name}
                                                      </span>
                                                    </p>
                                                    <div className="d-flex justify-content-between">
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Start Date :
                                                        </span>{" "}
                                                        <span>
                                                          {" "}
                                                          {moment(
                                                            e.startDate
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </span>
                                                      </p>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          End Date :
                                                        </span>{" "}
                                                        <span>
                                                          {moment(
                                                            e.endDate
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </span>
                                                      </p>
                                                    </div>
                                                    {/* <p className="mb-2 small">
                                               <span className="fw-bold ">
                                                 Last Login :
                                               </span>{" "}
                                               <span>4 Hours Ago</span>
                                             </p> */}
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              {e?.status === "Inprogress" ? (
                                                <div className="inprogress-section">
                                                  <div className="d-flex justify-content-between align-items-start">
                                                    <h6 className="fw-bold color-maroon">
                                                      {e.servicesCategory}{" "}
                                                    </h6>
                                                    <p className="mb-0 small color-yellow">
                                                      {e.status}
                                                    </p>
                                                  </div>
                                                  <div>
                                                    <p className="mb-2 small">
                                                      <span className="fw-bold ">
                                                        Assigned To :
                                                      </span>{" "}
                                                      <span>
                                                        {e?.assignedTo?.name}
                                                      </span>
                                                    </p>
                                                    <div className="d-flex justify-content-between">
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Start Date :
                                                        </span>{" "}
                                                        <span>
                                                          {" "}
                                                          {moment(
                                                            e.startDate
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </span>
                                                      </p>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          End Date :
                                                        </span>{" "}
                                                        <span>
                                                          {moment(
                                                            e.endDate
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </span>
                                                      </p>
                                                    </div>
                                                    {/* <p className="mb-2 small">
                                               <span className="fw-bold ">
                                                 Last Login :
                                               </span>{" "}
                                               <span>4 Hours Ago</span>
                                             </p> */}
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              {e?.status === "Todo" ? (
                                                <>
                                                  <div className="assigned-section">
                                                    <div className="d-flex justify-content-between">
                                                      <h6 className="fw-bold color-maroon">
                                                        {e.servicesCategory}{" "}
                                                      </h6>
                                                      <p className="mb-0 small color-blue">
                                                        {e.status}
                                                      </p>
                                                    </div>
                                                    <div>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Assigned To :
                                                        </span>{" "}
                                                        <span>
                                                          {e?.assignedTo?.name}
                                                        </span>
                                                      </p>
                                                      <div className="d-flex justify-content-between">
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            Start Date :
                                                          </span>{" "}
                                                          <span>
                                                            {moment(
                                                              e.startDate
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </span>
                                                        </p>
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            End Date :
                                                          </span>{" "}
                                                          <span>
                                                            {moment(
                                                              e.endDate
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </span>
                                                        </p>
                                                      </div>
                                                      {/* <p className="mb-2 small">
                                          <span className="fw-bold ">
                                            Last Login :
                                          </span>{" "}
                                          <span>4 Hours Ago</span>
                                        </p> */}
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {e?.status === "Hold" ? (
                                                <div className="rejected-section">
                                                  <div className="d-flex justify-content-between">
                                                    <h6 className="fw-bold color-maroon">
                                                      {e.servicesCategory}{" "}
                                                    </h6>
                                                    <p className="mb-0 small color-redish">
                                                      {e.status}
                                                    </p>
                                                  </div>
                                                  <div>
                                                    <p className="mb-2 small">
                                                      <span className="fw-bold ">
                                                        Assigned To :
                                                      </span>{" "}
                                                      <span>
                                                        {e?.assignedTo?.name}
                                                      </span>
                                                    </p>
                                                    <div className="d-flex justify-content-between">
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Start Date :
                                                        </span>{" "}
                                                        <span>
                                                          {" "}
                                                          {moment(
                                                            e.startDate
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </span>
                                                      </p>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          End Date :
                                                        </span>{" "}
                                                        <span>
                                                          {moment(
                                                            e.endDate
                                                          ).format(
                                                            "DD/MM/YYYY"
                                                          )}
                                                        </span>
                                                      </p>
                                                    </div>
                                                    {/* <p className="mb-2 small">
                                               <span className="fw-bold ">
                                                 Last Login :
                                               </span>{" "}
                                               <span>4 Hours Ago</span>
                                             </p> */}
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}

                                              {e?.status === "Rejected" ? (
                                                <div className="rejected-section">
                                                  <div className="d-flex justify-content-between">
                                                    <h6 className="fw-bold color-maroon">
                                                      legal Service{" "}
                                                    </h6>
                                                    <p className="mb-0 color-redish">
                                                      Rejected
                                                    </p>
                                                  </div>
                                                  <div>
                                                    <p className="mb-2 small">
                                                      <span className="fw-bold ">
                                                        Assigned To :
                                                      </span>{" "}
                                                      <span>
                                                        {e?.assignedTo?.name}
                                                      </span>
                                                    </p>
                                                    <div className="d-flex justify-content-between">
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Start Date :
                                                        </span>{" "}
                                                        <span>04/08/2023</span>
                                                      </p>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          End Date :
                                                        </span>{" "}
                                                        <span>04/08/2023</span>
                                                      </p>
                                                    </div>
                                                    <p className="mb-2 small">
                                                      <span className="fw-bold ">
                                                        Last Login :
                                                      </span>{" "}
                                                      <span>4 Hours Ago</span>
                                                    </p>
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ))}
                                        </OwlCarousel>
                                      </div>{" "}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-6"></div>
                            <div className="col-lg-6">
                              <div className="bg-grey p-4">
                                <div className="d-flex justify-content-between">
                                  <h6 className="color-maroon  fw-bold">
                                    Service Request
                                  </h6>
                                  <p
                                    className="mb-0 color-maroon fw-bold cursor text-decoration-underline"
                                    onClick={() =>
                                      navigate(`/AdminServiceDetails/${id}`)
                                    }
                                  >
                                    View All
                                  </p>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12">
                                    {specificService?.length ? (
                                      <>
                                        <div className="task-details-carou pt-4">
                                          <OwlCarousel
                                            className="owl-theme"
                                            loop
                                            autoplay={true}
                                            items="3"
                                            dots={false}
                                            smartSpeed={2000}
                                            nav={true}
                                            margin={20}
                                            center={false}
                                            autoplayTimeout={5000}
                                            responsive={{
                                              360: {
                                                items: "1",
                                              },
                                              414: {
                                                items: "1",
                                              },
                                              670: {
                                                items: "2",
                                              },
                                              992: {
                                                items: "2",
                                              },
                                              1200: {
                                                items: "2",
                                              },
                                            }}
                                          >
                                            {/* AdminTaskDetails */}
                                            {specificService?.map((e, i) => (
                                              <>
                                                {e.status === "assigned" ? (
                                                  <>
                                                    <div className="assigned-section">
                                                      <div className="d-flex justify-content-between">
                                                        <h6 className="fw-bold color-maroon">
                                                          {e.servicesCategory}{" "}
                                                        </h6>
                                                        <p className="mb-0 small color-blue">
                                                          {e.status}
                                                        </p>
                                                      </div>
                                                      <div>
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            User Name :
                                                          </span>{" "}
                                                          <span>{e.name}</span>
                                                        </p>
                                                        <div className="d-flex justify-content-between">
                                                          <p className="mb-2 small">
                                                            <span className="fw-bold ">
                                                              Start Date :
                                                            </span>{" "}
                                                            <span>
                                                              {moment(
                                                                e.startDate
                                                              ).format(
                                                                "DD/MM/YYYY"
                                                              )}
                                                            </span>
                                                          </p>
                                                          <p className="mb-2 small">
                                                            <span className="fw-bold ">
                                                              End Date :
                                                            </span>{" "}
                                                            <span>
                                                              {moment(
                                                                e.endDate
                                                              ).format(
                                                                "DD/MM/YYYY"
                                                              )}
                                                            </span>
                                                          </p>
                                                        </div>
                                                        {/* <p className="mb-2 small">
                                          <span className="fw-bold ">
                                            Last Login :
                                          </span>{" "}
                                          <span>4 Hours Ago</span>
                                        </p> */}
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                                {e.status === "inprogress" ? (
                                                  <div className="inprogress-section">
                                                    <div className="d-flex justify-content-between">
                                                      <h6 className="fw-bold color-maroon">
                                                        {e.servicesCategory}{" "}
                                                      </h6>
                                                      <p className="mb-0 small color-yellow">
                                                        {e.status}
                                                      </p>
                                                    </div>
                                                    <div>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Assigned To :
                                                        </span>{" "}
                                                        <span>{e.name}</span>
                                                      </p>
                                                      <div className="d-flex justify-content-between">
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            Start Date :
                                                          </span>{" "}
                                                          <span>
                                                            {" "}
                                                            {moment(
                                                              e.startDate
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </span>
                                                        </p>
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            End Date :
                                                          </span>{" "}
                                                          <span>
                                                            {moment(
                                                              e.endDate
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </span>
                                                        </p>
                                                      </div>
                                                      {/* <p className="mb-2 small">
                                               <span className="fw-bold ">
                                                 Last Login :
                                               </span>{" "}
                                               <span>4 Hours Ago</span>
                                             </p> */}
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                {e.status === "unassigned" ? (
                                                  <div className="rejected-section">
                                                    <div className="d-flex justify-content-between align-items-start">
                                                      <h6 className="fw-bold color-maroon">
                                                        {e.servicesCategory}{" "}
                                                      </h6>
                                                      <p className="mb-0 small color-redish">
                                                        {e.status}
                                                      </p>
                                                    </div>
                                                    <div>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Assigned To :
                                                        </span>{" "}
                                                        <span>{e.name}</span>
                                                      </p>
                                                      <div className="d-flex justify-content-between">
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            Start Date :
                                                          </span>{" "}
                                                          <span>
                                                            {" "}
                                                            {moment(
                                                              e.startDate
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </span>
                                                        </p>
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            End Date :
                                                          </span>{" "}
                                                          <span>
                                                            {moment(
                                                              e.endDate
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </span>
                                                        </p>
                                                      </div>
                                                      {/* <p className="mb-2 small">
                                               <span className="fw-bold ">
                                                 Last Login :
                                               </span>{" "}
                                               <span>4 Hours Ago</span>
                                             </p> */}
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                {e.status === "completed" ? (
                                                  <div className="completed-section">
                                                    <div className="d-flex justify-content-between align-items-start">
                                                      <h6 className="fw-bold color-maroon">
                                                        {e.servicesCategory}{" "}
                                                      </h6>
                                                      <p className="mb-0 color-green2">
                                                        {e.status}
                                                      </p>
                                                    </div>
                                                    <div>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Assigned To :
                                                        </span>{" "}
                                                        <span>{e.name}</span>
                                                      </p>
                                                      <div className="d-flex justify-content-between">
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            Start Date :
                                                          </span>{" "}
                                                          <span>
                                                            {" "}
                                                            {moment(
                                                              e.startDate
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </span>
                                                        </p>
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            End Date :
                                                          </span>{" "}
                                                          <span>
                                                            {moment(
                                                              e.endDate
                                                            ).format(
                                                              "DD/MM/YYYY"
                                                            )}
                                                          </span>
                                                        </p>
                                                      </div>
                                                      {/* <p className="mb-2 small">
                                               <span className="fw-bold ">
                                                 Last Login :
                                               </span>{" "}
                                               <span>4 Hours Ago</span>
                                             </p> */}
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                {e.status === "rejected" ? (
                                                  <div className="rejected-section">
                                                    <div className="d-flex justify-content-between align-items-start">
                                                      <h6 className="fw-bold color-maroon">
                                                        legal Service{" "}
                                                      </h6>
                                                      <p className="mb-0 color-redish">
                                                        Rejected
                                                      </p>
                                                    </div>
                                                    <div>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Assigned To :
                                                        </span>{" "}
                                                        <span>Deepak</span>
                                                      </p>
                                                      <div className="d-flex justify-content-between">
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            Start Date :
                                                          </span>{" "}
                                                          <span>
                                                            04/08/2023
                                                          </span>
                                                        </p>
                                                        <p className="mb-2 small">
                                                          <span className="fw-bold ">
                                                            End Date :
                                                          </span>{" "}
                                                          <span>
                                                            04/08/2023
                                                          </span>
                                                        </p>
                                                      </div>
                                                      <p className="mb-2 small">
                                                        <span className="fw-bold ">
                                                          Last Login :
                                                        </span>{" "}
                                                        <span>4 Hours Ago</span>
                                                      </p>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </>
                                            ))}
                                          </OwlCarousel>
                                        </div>
                                      </>
                                    ) : (
                                      "No Record Found"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="">
            <h5 className="mb-0 fw-bold color-maroon">Payment Details</h5>
          </div>
          <div className="my-4">
            <table className="table">
              <thead className="bg-historytablehead">
                <tr>
                  <th scope="col">User Name</th>
                  <th scope="col">Date</th>
                  <th scope="col">Transfer ID </th>
                  <th scope="col">Amount</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody className="historytablebody">
                <tr>
                  <td scope="row">
                    <div className="d-flex align-items-center ">
                      <div className="flex-shrink-0">
                        <img
                          className="w-100 h-auto"
                          src={ADclient}
                          alt="..."
                        />
                      </div>
                      <div className=" ms-3">
                        <h6 className="fw-bold">John Daniel</h6>
                        <p className="mb-0 small">98945761130</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <h6 className="fw-bold">21 March 2023</h6>
                    <p className="mb-0">At 8.00Pm</p>
                  </td>
                  <td>
                    <div className=" ">
                      <h6 className="fw-bold">0213546877</h6>
                    </div>
                  </td>
                  <td>
                    <h6 className="fw-bold">₹5201456</h6>
                  </td>

                  <td>
                    <div className="">
                      <h6 className="fw-bold color-green">Transferred</h6>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
        </div>
      </div>
    </div>
  );
}
