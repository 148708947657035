import React, { useEffect, useState } from 'react';
import Typewriter from '../../Home/Typewriter';
import { useNavigate } from 'react-router-dom';

export default function HomeBanner() {
    const navigate = useNavigate();
    const [currentText, setCurrentText] = useState('Legal Advisory');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const images = {
        'Legal Advisory': 'img/hero/legal1.png',
        'Auditing Advisory': 'img/hero/audit1.png',
        'Human Resources': 'img/hero/hr1.png',
        'Bank Auction Support': 'img/hero/buysell1.png',
        // 'Buying and Selling': 'img/hero/hero_img_3_1.png',
        'Marketing Assistance': 'img/hero/marketing.png'
    };

    return (
        <>
            <div className="th-hero-wrapper hero-3" id="hero" style={{ marginTop: '-120px' }}>
                <div className="hero-img">
                    <img src={images[currentText]} alt={currentText}  style={{height:'821px',width:'821px'}}/>
                    <div className="shape-blur"></div>
                </div>
                <div className="hero-img-phone">
                    <img src={images[currentText]} alt={currentText} />
                    <div className="shape-blur"></div>
                </div>
                <div className="container">
                    <div className="hero-style3">
                        <span className="hero-subtitle" style={{ color: 'black' }}>DALMAF Your Reliable Partner</span>
                        <div style={{ fontSize: '50px', fontWeight: '400', animation: 'bounceInDown 2s' }}>
                            <Typewriter 
                                texts={[
                                    'Legal Advisory',
                                    'Auditing Advisory',
                                    'Human Resources',
                                    'Bank Auction Support',
                                    // 'Buying and Selling',
                                    'Marketing Assistance'
                                ]}
                                onTextChange={(text) => setCurrentText(text)} // Update image when new text starts typing
                            />
                        </div>
                        <p className="hero-text" style={{ color: 'black' }}>
                            At DALMAF, we deliver sustainable outcomes and solutions for your business needs through our tech-enabled and purposeful services. Our offerings span audit & assurance, advisory, consulting, and regulatory domains, ensuring your business operates compliantly and cohesively.
                        </p>
                        <div className="btn-group">
                            <a href="" onClick={(e) => { e.preventDefault(); navigate('/schedule-meet') }} className="th-btn">
                                Make Appointment
                                <i className="fa-regular fa-arrow-right ms-2"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="hero-shape1"><img src="img/hero/hero_shape_3_1.png" alt="shape" /></div>
            </div>
        </>
    );
}
