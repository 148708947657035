import React, { useEffect } from 'react';
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import GoldBanner from "../Components/Property/GoldBanner";
import BSGoldContent from "../Components/Buy_Sell/BSGoldContent";
export default function BSGold() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="innerpages-nav">
      <div  id="container">
      <Navigation />
      <GoldBanner />
      <BSGoldContent />
      <Footer />
      </div>
    
    </div>
  );
}
