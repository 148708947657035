import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ReactQuill from 'react-quill'; // Importing ReactQuill
import 'react-quill/dist/quill.snow.css'; // Importing styles for ReactQuill
import styles from './css/CreateLeadComponent.module.css';  // Adjust the path as necessary
import { useSelector } from 'react-redux';
import { createAPI, createLabelAPI, fetchLabelAPI } from '../../../../Api/v1/Util/Api';
import { showErrorMsg, showSuccessMsg } from '../../../../Store/util';
import { createNewLeadAPI } from '../../../../Api/v1/FreelancerPartner/leads';
import { useNavigate, useParams } from 'react-router-dom';
import { notes_create } from '../../../../Api/APIUrl';
import { ToastContainer } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function CreateNotesComponent({showFullNav,routeProps,lableProps,userTypeProps,isClient}) {
    const navigate = useNavigate();
    const state = useSelector((state) => state);
    const { id } = useParams();

    const initialLeadState = {
        reference_id: id,
        title: '',
        description: '',
        file: '',
    };

    const [notes, setNotes] = useState(initialLeadState);
    const [errors, setErrors] = useState({});
    const [allLabels, setAllLabels] = useState([]);

    useEffect(() => {
        handleFetchLabels();
    }, []);

    const handleInputChange = (e, field) => {
        setNotes({ ...notes, [field]: e.target.value });
    };

    const handleSelectChange = (selectedOption, field) => {
        setNotes({ ...notes, [field]: selectedOption });
    };

    const handleCreateOption = async (inputValue) => {
        try {
            const response = await createLabelAPI(state.auth.auth.token, { title: inputValue, label_type: 'leads' });
            setAllLabels([...allLabels, { value: response.data.label._id, label: inputValue }]);
            setNotes({
                ...notes,
                labels: [...notes.labels, { value: response.data.label._id, label: inputValue }]
            });
        } catch (error) {
            console.error('Error creating label:', error);
        }
    };

    const handleFetchLabels = async () => {
        try {
            const response = await fetchLabelAPI(state.auth.auth.token, 'leads');
            setAllLabels(response.data.label.map(label => ({ value: label._id, label: label.title })));
        } catch (error) {
            console.error('Error fetching labels:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};
        if (!notes.title) newErrors.title = 'Title is required';
        if (!notes.description) newErrors.description = 'Description is required';
        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) return;

        const response = await createAPI(state.auth.auth.token, notes_create, notes);
        if (response && response.statusCode === 200) {
            showSuccessMsg(response.message);
            setNotes(initialLeadState);
            navigate(`/${userTypeProps}/${routeProps}/view-${routeProps}/${id}`);
        } else {
            showErrorMsg(response.message || response.error.response.data.message);
        }
    };

    const handleDescriptionChange = (value) => {
        setNotes({ ...notes, description: value });
    };

    return (
        <form className={styles.leadForm} onSubmit={handleSubmit}>
            <ToastContainer />
            <div className='row'>
                <div className='col-md-6'>
                    <h2>Create Notes</h2>
                </div>
                <div className='col-md-6'>
                    <button onClick={() => { navigate(`/${userTypeProps}/${routeProps}/view-${routeProps}/${id}`); }} className='btn btn-primary' style={{ float: 'right' }}>
                        <ArrowBackIcon />
                    </button>
                </div>
            </div>
            
            <div className="row">
                <div className="col-md-6">
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <input
                                type="text"
                                id="title"
                                value={notes.title}
                                onChange={(e) => handleInputChange(e, 'title')}
                                className={`${styles.input} ${errors.title ? styles.errorInput : ''}`}
                                placeholder=" "
                            />
                            <label htmlFor="title" className={`${styles.placeholder} ${notes.title ? styles.filled : ''}`}>
                                Title
                            </label>
                        </div>
                        {errors.title && <span className={styles.error}>{errors.title}</span>}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <ReactQuill
                                value={notes.description}
                                onChange={handleDescriptionChange}
                                placeholder="Description"
                                className={styles.editor}
                                style={{height:"200px"}}
                            />
                        </div>
                        {errors.description && <span className={styles.error}>{errors.description}</span>}
                    </div>
                </div>
                <div className='col-md-12' style={{marginTop:'40px'}}>
                    <button type="submit" className="btn btn-primary">Create Notes</button>
                    <button type="button" className="btn" onClick={() => { navigate(`/${userTypeProps}/${routeProps}/view-${routeProps}/${id}`); }}>Cancel</button>
                </div>
            </div>
            
        </form>
    );
}
