import React, { useEffect, useState } from 'react';
import appDownload from '../../../Assets/mobile.png';

export default function AppDownload() {
    const [backgroundImage, setBackgroundImage] = useState(`url('https://dalmaf.com/img/hero/app-download1.png')`);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                // Change background image for mobile view
                setBackgroundImage(`url('https://dalmaf.com/img/hero/app-download2.png')`);
            } else {
                // Desktop and tablet view
                setBackgroundImage(`url('https://dalmaf.com/img/hero/app-download1.png')`);
            }
        };

        // Set the initial background image based on current screen size
        handleResize();

        // Add event listener to handle window resizing
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section
            className="py-5 th-hero-wrapper hero-3"
            style={{
                backgroundImage: backgroundImage,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                paddingTop: '50px',
                paddingBottom: '50px',
            }}
        >
            <div className="container">
                <div className="row mt-5 align-items-center">
                    <div className="col-md-6">
                        <div className="p-5 rounded" style={{ opacity: 0.9 }}>
                            <h2 className="mb-3 text-primary">Manage your services globally from anywhere!</h2>
                            <p className="lead">
                                Download our app to manage and track your services seamlessly. Stay connected with our experts and monitor your progress effortlessly. Don't delay—download our app today!
                            </p>
                            <p className="mt-4 fw-bold">Get the App</p>
                            <div className="mt-3">
                                <a
                                    href="https://apps.apple.com/in/app/dalmaf/id6467872452"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="d-inline-block me-3"
                                >
                                    <img
                                        src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                                        alt="Download on the App Store"
                                        style={{ height: '50px' }}
                                    />
                                </a>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.dalmaf&pli=1"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="d-inline-block"
                                >
                                    <img
                                        src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                                        alt="Get it on Google Play"
                                        style={{ height: '50px' }}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
