import React, { useEffect, useState } from "react";
import Si1 from "../Assets/Images/si-1.webp";
import Si2 from "../Assets/Images/si-2.webp";
import Si3 from "../Assets/Images/si-3.webp";

import MarketingIcon from "../Assets/Images/marketing-icon-nav.webp";
import BuySellIcon from "../Assets/Images/buysell-icon.webp";
import AuditIcon from "../Assets/Images/audit-icon.webp";
import BS1 from "../Assets/Images/bs1.webp";
import BS2 from "../Assets/Images/bs2.webp";
import BS3 from "../Assets/Images/bs3.webp";
import BS4 from "../Assets/Images/bs4.webp";
import BS5 from "../Assets/Images/bs5.webp";
import BS6 from "../Assets/Images/bs6.webp";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function ServicesAllpages() {

  
  return (
    <div>
      <div className="service-banner py-5">
        <div className="container">
          <div class="container-fluid service py-6">
            <div class="container">
                <div class="text-center wow bounceInUp" data-wow-delay="0.1s">
                  <h2 className="banner-heading text-center after-line">
                    Services
                  </h2>
                    {/* <small class="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Our Services</small> */}
                    <h1 class="display-5 mb-5">What We Offer</h1>
                </div>
                <div class="row g-4">
                    <div class="col-lg-4 col-md-6 col-sm-12 wow bounceInUp" data-wow-delay="0.1s">
                        <div class="bg-light rounded service-item">
                            <div class="service-content d-flex align-items-center justify-content-center p-4">
                                <div class="service-content-icon text-center">
                                    <img className="si-icon" src={AuditIcon} alt="" />
                                    {/* <i class="fas fa-cheese fa-7x text-primary mb-4"></i> */}
                                    <h4 class="mb-3">Audit</h4>
                                    <p class="mb-4">Our auditing solutions guarantee that your financial records and statements are extensively reviewed and assessed in order to provide shareholders, investors, and other stakeholders with accurate assurance.</p>
                                    {/* <a href="#" class="btn btn-primary px-4 py-2 rounded-pill">Read More</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 wow bounceInUp" data-wow-delay="0.1s">
                        <div class="bg-light rounded service-item">
                            <div class="service-content d-flex align-items-center justify-content-center p-4">
                                <div class="service-content-icon text-center">
                                    <img className="si-icon" src={Si3} alt="" />
                                    {/* <i class="fas fa-cheese fa-7x text-primary mb-4"></i> */}
                                    <h4 class="mb-3">Legal</h4>
                                    <p class="mb-4">We acknowledge the importance of legal compliance as well as risk governance in today's competitive business environment. Our experienced legal team offers counsel on a wide range of legal complications, such as contract drafting and review, intellectual property protection, regulatory compliance, and dispute resolution.</p>
                                    {/* <a href="#" class="btn btn-primary px-4 py-2 rounded-pill">Read More</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 wow bounceInUp" data-wow-delay="0.1s">
                        <div class="bg-light rounded service-item">
                            <div class="service-content d-flex align-items-center justify-content-center p-4">
                                <div class="service-content-icon text-center">
                                    <img className="si-icon" src={MarketingIcon} alt="" />
                                    {/* <i class="fas fa-cheese fa-7x text-primary mb-4"></i> */}
                                    <h4 class="mb-3">Marketing</h4>
                                    <p class="mb-4">Our team of professionals tackles every project with comprehensive expertise in the sector, decades of experience, and a client-focused mindset. We acknowledge that each organisation has unique demands, so we tailor our services to meet your specific requirements and provide bespoke solutions for guaranteeing your business's achievement.</p>
                                    {/* <a href="#" class="btn btn-primary px-4 py-2 rounded-pill">Read More</a> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
          {/* <div className="row">
            <OwlCarousel
              className="owl-theme"
              loop
              autoplay={true}
              items="3"
              dots={true}
              smartSpeed={2000}
              nav={false}
              margin={20}
              center={true}
              autoplayTimeout={5000}
              responsive={{
                360: {
                  items: "1",
                },
                414: {
                  items: "1",
                },
                670: {
                  items: "2",
                },
                992: {
                  items: "2",
                },
                1200: {
                  items: "3",
                },
              }}
            >
              <div
                className=" ps-rel m-auto"
                data-aos="zoom-in"
              >
                <div className="about-img">
                  <img className="w-100 h-auto rounded-top" src={BS2} alt="" />
                </div>
                <div className="ser-section">
                  <div className="d-flex mb-3 align-items-center">
                    <img className="si-icon" src={AuditIcon} alt="" />
                    <h5 className="ms-2 banner-heading ">Audit</h5>
                  </div>
                  <p className="mb-0 ser-content-height">
                    Our auditing solutions guarantee that your financial records and statements are extensively reviewed and assessed in order to provide shareholders, investors, and other stakeholders with accurate assurance.
                  </p>
                </div>
              </div>
              <div
                className=" ps-rel m-auto"
                data-aos="zoom-in"
              >
                <div className="about-img">
                  <img className="w-100 h-auto rounded-top" src={BS3} alt="" />
                </div>
                <div className="ser-section">
                  <div className="d-flex mb-3 align-items-center">
                    <img className="si-icon" src={Si3} alt="" />
                    <h5 className="ms-2 banner-heading ">Legal </h5>
                  </div>
                  <p className="mb-0 ser-content-height">
                  We acknowledge the importance of legal compliance as well as risk governance in today's competitive business environment. Our experienced legal team offers counsel on a wide range of legal complications, such as contract drafting and review, intellectual property protection, regulatory compliance, and dispute resolution. We coordinate closely with our clients to fulfil their statutory obligations efficiently and effectively.
                  </p>
                </div>
              </div>
              <div
                className=" ps-rel m-auto"
                data-aos="zoom-in"
              >
                <div className="about-img">
                  <img className="w-100 h-auto rounded-top" src={BS4} alt="" />
                </div>
                <div className="ser-section">
                  <div className="d-flex mb-3 align-items-center">
                    <img className="si-icon" src={MarketingIcon} alt="" />
                    <h5 className="ms-2 banner-heading ">Marketing </h5>
                  </div>
                  <p className="mb-0 ser-content-height">
                    Our team of professionals tackles every project with comprehensive expertise in the sector, decades of experience, and a client-focused mindset. We acknowledge that each organisation has unique demands, so we tailor our services to meet your specific requirements and provide bespoke solutions for guaranteeing your business's achievement.
                  </p>
                </div>
              </div>
            </OwlCarousel>
          </div> */}
        </div>
      </div>
    </div>
  );
}
