import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import { useSelector } from "react-redux";
import { GoldSpecific, UpdateGold } from "../../../Api/Api";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate, useParams } from "react-router-dom";
import { NonEmptyValidation, NumberValidation } from "../../../Store/validate";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

export default function GoldEdit() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { id } = useParams();
  useEffect(() => {
    GetMyGoldSpecificTsks();
    window.scroll(0, 0);
  }, []);

  const [goldTask, setGoldTask] = useState({});
  const [goldTaskValidation, setgoldTaskValidation] = useState({});

  const setGoldValue = (key, value) => {
    setGoldTask({ ...goldTask, [key]: value });
    if (goldTaskValidation[key]) delete goldTaskValidation[key];
  };

  const setGoldValidationValue = (key, value) => {
    setgoldTaskValidation({ ...goldTaskValidation, [key]: value });
  };
  const GetMyGoldSpecificTsks = async () => {
    setLoading(true);
    const token = state.auth.auth.token;
    let data = await GoldSpecific(token, id);
    if (data && data.statusCode === 200) {
      let dummy = data.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.description) {
        let blocksFromHtml = htmlToDraft(dummy.description);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.description = cmsDescription;
      setGoldTask(dummy);
      setLoading(false);
    } else {
      setGoldTask([]);
      setLoading(false);
      //console.log("Error while getting my specific task");
    }
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  const handleGoldSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.goldType = NonEmptyValidation(goldTask?.goldType);
    validate.referenceNo = NonEmptyValidation(goldTask?.referenceNo);
    validate.carat = NonEmptyValidation(goldTask?.carat);
    validate.gram = NonEmptyValidation(goldTask?.gram);
    // validate.reservedPrice = NonEmptyValidation(goldTask?.reservedPrice);
    validate.endDate = NonEmptyValidation(goldTask?.endDate);
    // validate.type = NonEmptyValidation(goldTask?.type);

    setgoldTaskValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let html;
      let Payload;
      let isNotEmpty =
        goldTask.description && goldTask.description.getCurrentContent().hasText();
      if (isNotEmpty) {
        html = draftToHtml(
          convertToRaw(goldTask.description.getCurrentContent())
        );
        Payload = {
          _id: id,
          goldType: goldTask.goldType,
          referenceNo: goldTask.referenceNo,
          carat: goldTask.carat,
          gram: goldTask.gram,
          reservedPrice: goldTask.reservedPrice,
          endDate: goldTask.endDate,
          type: goldTask.type,
          description: html,
        };
      }
      const token = state?.auth?.auth?.token;
      const response = await UpdateGold(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        window.scrollTo(0, 0);
        GetMyGoldSpecificTsks();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };

  return (
    <div>
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        <div>
          <div className="">
            <button
              type="button"
              className="btn login-btn ms-2 "
              onClick={(e) => {
                navigate("/goldAuction");
              }}
            >
              Back
            </button>
          </div>
          <div className=" my-4">
            <div className="bg-historytablehead rounded-3 py-3 px-3">
              <h6 className="mb-0">Update Gold</h6>
            </div>
            <div className="container">
              <div className="row justify-content-center py-5">
                <div className="col-lg-11">
                  <div className="row">
                  <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Reference No</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the Reference No"
                          aria-invalid={Boolean(
                            goldTaskValidation?.referenceNo?.status === false
                          )}
                          value={goldTask?.referenceNo}
                          onChange={(e) => {
                            setGoldValue("referenceNo", e.target.value);
                          }}
                          onBlur={(e) => {
                            setGoldValidationValue(
                              "referenceNo",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {goldTaskValidation?.referenceNo?.message
                            ? `Reference No ${goldTaskValidation?.referenceNo?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Gold Type</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the Gold Type"
                          aria-invalid={Boolean(
                            goldTaskValidation?.goldType?.status === false
                          )}
                          value={goldTask?.goldType}
                          onChange={(e) => {
                            setGoldValue("goldType", e.target.value);
                          }}
                          onBlur={(e) => {
                            setGoldValidationValue(
                              "goldType",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {goldTaskValidation?.goldType?.message
                            ? `Gold Type ${goldTaskValidation?.goldType?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Auction End Date</label>
                        <input
                          type="date"
                          className="form-control form-control-lg"
                          placeholder="Enter end date"
                          aria-invalid={Boolean(
                            goldTaskValidation?.endDate?.status === false
                          )}
                          value={moment(goldTask?.endDate).format("YYYY-MM-DD")}
                          onChange={(e) => {
                            setGoldValue("endDate", e.target.value);
                          }}
                          onBlur={(e) => {
                            setGoldValidationValue(
                              "endDate",
                              NumberValidation(e.target.value)
                            );
                          }}
                        />
                        <small className="text-danger">
                          {goldTaskValidation?.endDate?.message
                            ? `End Date${goldTaskValidation?.endDate?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Carat</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the Carat"
                          aria-invalid={Boolean(
                            goldTaskValidation?.carat?.status === false
                          )}
                          value={goldTask?.carat}
                          onChange={(e) => {
                            setGoldValue("carat", e.target.value);
                          }}
                          onBlur={(e) => {
                            setGoldValidationValue(
                              "carat",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {goldTaskValidation?.carat?.message
                            ? `Carat ${goldTaskValidation?.carat?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Listing Type</label>

                        <select
                          className="form-control form-control-lg"
                          defaultValue={goldTask?.type}
                          onChange={(e) => {
                            setGoldValue("type", e.target.value);
                          }}
                          onBlur={(e) => {
                            setGoldValidationValue(
                              "type",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                        >
                          <option value="Aution">Auction</option>
                          <option value="Buy/Sell">Buy/Sell</option>
                        </select>

                        <small className="text-danger">
                          {goldTaskValidation?.type?.message
                            ? `Type ${goldTaskValidation?.type?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label"> Gram </label>
                        <input
                          type="tel"
                          className="form-control form-control-lg"
                          placeholder="Enter Your Gram"
                          aria-invalid={Boolean(
                            goldTaskValidation?.gram?.status === false
                          )}
                          value={goldTask?.gram}
                          onChange={(e) => {
                            setGoldValue("gram", e.target.value);
                          }}
                          onBlur={(e) => {
                            setGoldValidationValue(
                              "gram",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {goldTaskValidation?.gram?.message
                            ? `gram ${goldTaskValidation?.gram?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-4">
                        <label className="form-label">Reserved Price</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the reserved Price"
                          aria-invalid={Boolean(
                            goldTaskValidation?.reservedPrice?.status === false
                          )}
                          defaultValue={goldTask?.reservedPrice}
                          onChange={(e) => {
                            setGoldValue("reservedPrice", e.target?.value);
                          }}
                          onBlur={(e) => {
                            setGoldValidationValue(
                              "reservedPrice",
                              NumberValidation(e.target.value)
                            );
                          }}
                          autoComplete="off"
                        />
                        <small className="text-danger">
                          {goldTaskValidation?.reservedPrice?.message
                            ? `Reserved price ${goldTaskValidation?.reservedPrice?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="mb-4">
                        <label className="form-label"> Description</label>
                        <div
                          className="form-control form-control-lg"
                          style={{ height: "250px" }}
                        >
                          <Editor
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            editorState={goldTask?.description}
                            onEditorStateChange={(e) =>
                              setGoldValue("description", e)
                            }
                          />
                        </div>
                        <div className="col-lg-6">
                        <div className="mb-4">
                    <label className="form-label">
                     Status
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <select
                      className="form-control form-control-lg"
                      value={goldTask?.status || ""}
                      onChange={(e) => {
                        setGoldValue("status", e.target.value);
                      }}
                      onBlur={(e) => {
                        setGoldValidationValue(
                          "status",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    >
                     
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                      {/* <option value="sold">Sold</option> */}
                    </select>
                    {/* <small className="text-danger">
                      {auctionValidation?.servicesType?.message
                        ? `Service Type ${auctionValidation?.servicesType?.message}`
                        : ""}
                    </small> */}
                  </div>
                  </div>
                        {/* <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter the Professional"
                          aria-invalid={Boolean(
                            goldTaskValidation?.description?.status === false
                          )}
                          value={goldTask?.description}
                          onChange={(e) => {
                            setGoldValue("description", e.target.value);
                          }}
                          onBlur={(e) => {
                            setGoldValidationValue(
                              "description",
                              NonEmptyValidation(e.target.value)
                            );
                          }}
                          autoComplete="off"
                        /> */}
                        <small className="text-danger">
                          {goldTaskValidation?.description?.message
                            ? `description ${goldTaskValidation?.description?.message}`
                            : ""}
                        </small>
                      </div>
                    </div>

                    <div className="col-lg-12 mt-4">
                      {/* <button
                        type="button"
                        className="btn white-btn me-2 "
                        onClick={() => {
                          setgoldTaskValidation({});
                          setGoldTask({});
                          window.location.reload();
                        }}
                      >
                        Cancel
                      </button> */}

                      <button
                        type="button"
                        className="btn login-btn ms-2 "
                        onClick={(e) => {
                          handleGoldSubmit(e);
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
