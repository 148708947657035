import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import UserNavigation from '../../../Components/v1/NavBar/Navigaton';
import {
  GetSpecificProperty,
  GetSpecificVechile,
  auctionCreateLog,
} from "../../../Api/Api";
import Navigation from "../../../Components/Navbar/Navigation";
import Footer from "../../../Components/Footer/Footer";
import ScheduleMeetBanner from "../../../Components/v1/Home/ScheduleMeetBanner";
import Testimonial from "../../../Components/v1/Home/Testimonial";
import AppDownload from "../../../Components/v1/Home/AppDownload";
import FAQ from "../../../Components/v1/Home/FAQ";
import Newsletter from "../../../Components/v1/Home/Newletter";
import { createAPI, fetchWithQueryAPI } from "../../../Api/v1/Util/Api";
import { auction_payment_initiate, auction_user_findOneTwoWheelerDetails_url } from "../../../Api/APIUrl";
let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

const VechileAuctionDetail = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const { id } = useParams();

  const [loginModal, setLoginModal] = useState(false);
  const [showResponse, setShowResponse] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
    getSpecificAuctionList();
    createAuctionView();
  }, []);

  const createAuctionView = async () => {
    const token = state?.auth?.auth?.token;
    const response = await auctionCreateLog(token, id);
  }
  const [vechile, setVechile] = useState({
    floor_information: [{ floor_no: "", floor_image: "", floor_desc: "" }],
  });

  const makePayment = async () => {
    const token = state?.auth?.auth?.token;
    const fd = new FormData(); // Corrected syntax
    fd.append('reference_id', id); // Corrected method to append data

    try {
      const response = await createAPI(token, auction_payment_initiate, fd);
      // Handle the response if needed
      if (response.statusCode === 200) {
        if (response.data.url) {
          // Redirect the user to the URL returned in the API response
          window.location.href = response.data.url;
        } else {
          console.error('No URL provided in the response');
        }
      }
    } catch (error) {
      // Handle error if the API call fails
      console.error('Payment initiation failed:', error);
    }
  };
  const getSpecificAuctionList = async () => {
    const token = state?.auth?.auth?.token;
    const response = await fetchWithQueryAPI(token, auction_user_findOneTwoWheelerDetails_url + '?_id=' + id)
    if (response && response.statusCode === 200) {
      let dummy = response.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.property_desc) {
        let blocksFromHtml = htmlToDraft(dummy.property_desc);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.property_desc = cmsDescription;
      setShowResponse(true)
    } else if (response && response.statusCode === 201) {
      setLoginModal(true);
      setShowResponse(false)
    } else {
      setVechile({});
      setShowResponse(false)
      errorMsg(response.message);
    }
  };
  const [validation, setValidation] = useState({});
  const setData = (e, key) => {
    setVechile({ ...vechile, [key]: e });
    if (validation[key]) delete validation[key];
  };

  const errorMsg = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <>
      {/* <Navigation /> */}
      <UserNavigation />

      <div
        className={`modal fade ${loginModal ? 'show d-block' : ''}`}
        tabIndex="-1"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header text-white" style={{ backgroundColor: '#dc3545' }}>
              <h5 className="modal-title">Validate Purchase History</h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                onClick={() => setLoginModal(!loginModal)}
              ></button>
            </div>
            <div className="modal-body text-center">
              <h5 className="mb-4">To know more about this vechile auction, you need to purchase access for ₹20</h5>
              <button className="btn text-white" style={{ backgroundColor: '#dc3545', color: "white" }} onClick={makePayment}>Buy Now ₹20 </button>
            </div>
          </div>
        </div>
      </div>
      {
        showResponse && (
          <div className="container">
            <div className="d-flex justify-content-between align-items-center mb-4 bg-light rounded-3 py-3 px-3 mb-4 shadow-sm">
              <h6 className="mb-0" style={{ color: '#dc3545', fontSize: '1.25rem' }}>Vehicle Details</h6>
              <button
                type="button"
                className="btn btn-primary btn-lg shadow-sm rounded-pill px-4"
                onClick={() =>
                  navigate(
                    "/Service?tab=More%20Services&subtab=Auctions%20|%20Vehicle%20Auction&id=6650d950b06b79817d3c49ca"
                  )
                }
              >
                Back
              </button>
            </div>

            <div className="row justify-content-center py-5">
              <div className="col-lg-12">
                <div className="admincard shadow-sm p-4 rounded-3">
                  <div className="row">
                    {/* Row 1 */}
                    <div className="col-lg-4 mb-4">
                      <div className="card service-card">
                        <div className="card-body">
                          <h6 className="card-title" style={{ fontSize: '1.1rem' }}>Vehicle Id</h6>
                          <p className="card-text" style={{ fontSize: '1rem' }}>{vechile.reference_id}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                      <div className="card service-card">
                        <div className="card-body">
                          <h6 className="card-title" style={{ fontSize: '1.1rem' }}>Vehicle Type</h6>
                          <p className="card-text" style={{ fontSize: '1rem' }}>{vechile.asset_type}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                      <div className="card service-card">
                        <div className="card-body">
                          <h6 className="card-title" style={{ fontSize: '1.1rem' }}>Bank Name</h6>
                          <p className="card-text" style={{ fontSize: '1rem' }}>{vechile.institution}</p>
                        </div>
                      </div>
                    </div>

                    {/* Row 2 */}
                    <div className="col-lg-4 mb-4">
                      <div className="card service-card">
                        <div className="card-body">
                          <h6 className="card-title" style={{ fontSize: '1.1rem' }}>Reserve Price</h6>
                          <p className="card-text" style={{ fontSize: '1rem' }}>{vechile.reserve_price}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                      <div className="card service-card">
                        <div className="card-body">
                          <h6 className="card-title" style={{ fontSize: '1.1rem' }}>EMD Amount</h6>
                          <p className="card-text" style={{ fontSize: '1rem' }}>{vechile.emd}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                      <div className="card service-card">
                        <div className="card-body">
                          <h6 className="card-title" style={{ fontSize: '1.1rem' }}>Auction Date</h6>
                          <p className="card-text" style={{ fontSize: '1rem' }}>{vechile.auction_date}</p>
                        </div>
                      </div>
                    </div>

                    {/* Row 3 */}
                    <div className="col-lg-4 mb-4">
                      <div className="card service-card">
                        <div className="card-body">
                          <h6 className="card-title" style={{ fontSize: '1.1rem' }}>Location</h6>
                          <p className="card-text" style={{ fontSize: '1rem' }}>{vechile.city}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                      <div className="card service-card">
                        <div className="card-body">
                          <h6 className="card-title" style={{ fontSize: '1.1rem' }}>City</h6>
                          <p className="card-text" style={{ fontSize: '1rem' }}>{vechile.city}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 mb-4">
                      <div className="card service-card">
                        <div className="card-body">
                          <h6 className="card-title" style={{ fontSize: '1.1rem' }}>Created By</h6>
                          <p className="card-text" style={{ fontSize: '1rem' }}>{vechile?.userId}</p>
                        </div>
                      </div>
                    </div>

                    {/* Row 4 */}
                    <div className="col-lg-12 mb-4">
                      <div className="card service-card">
                        <div className="card-body">
                          <h6 className="card-title" style={{ fontSize: '1.1rem' }}>Vehicle Description</h6>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: vechile?.description,
                            }}
                            style={{ fontSize: '1rem' }}
                          ></div>
                          {validation.description && (
                            <p className="text-danger" style={{ fontSize: '1rem' }}>{validation.description}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <div className="card service-card">
                        <div className="card-body">
                          <h6 className="card-title" style={{ fontSize: '1.1rem' }}>Sale Notice URL</h6>
                          {vechile.sale_notice_url?.length ? vechile.sale_notice_url.map((data) => (
                            <img src={data} alt={vechile.reference_id} className="img-fluid mb-2" key={data} />
                          )) : "No data found"}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <div className="card service-card">
                        <div className="card-body">
                          <h6 className="card-title" style={{ fontSize: '1.1rem' }}>Vehicle Documents</h6>
                          {vechile.document?.length ? vechile.document.map((data) => (
                            <img src={data} alt={vechile.reference_id} className="img-fluid mb-2" key={data} />
                          )) : "No data found"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        )
      }


      <ScheduleMeetBanner />
      <Testimonial />
      <AppDownload />
      <Newsletter />
      <FAQ />
      <Footer />


    </>
  );
};

export default VechileAuctionDetail;
