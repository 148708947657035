import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import styles from './css/CreateContact.module.css';  // Adjust the path as necessary
import { useSelector } from 'react-redux';
import { showErrorMsg, showSuccessMsg } from '../../../../Store/util';
import { createNewLeadAPI } from '../../../../Api/v1/FreelancerPartner/leads';
import { useNavigate, useParams } from 'react-router-dom';
import { createAPI } from '../../../../Api/v1/Util/Api';
import { contacts_create } from '../../../../Api/APIUrl';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function CreateContactComponent({ showFullNav, userTypeProps,isClient,lableProps,routeProps }) {
    const navigate = useNavigate();
    const state = useSelector((state) => state);
    const { id } = useParams();

    const initialLeadState = {
        reference_id:id,
        first_name: '',
        last_name: '',
        email_id: '',
        phone_number: '',
        job_title: '',
        gender: ''
    };

    const [contact, setContact] = useState(initialLeadState);
    const [errors, setErrors] = useState({});

    const [gender] = useState([
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' }
    ]);

    const handleInputChange = (e, field) => {
        setContact({ ...contact, [field]: e.target.value });
    };
    const handleSelectChange = (selectedOption, field) => {
        setContact({ ...contact, [field]: selectedOption });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Validate fields
        const newErrors = {};
        if (!contact.first_name) newErrors.first_name = 'First Name is required';
        if (!contact.last_name) newErrors.last_name = 'Last Name is required';
        if (!contact.email_id) newErrors.email_id = 'Email ID is required';
        if (contact.email_id && !contact.email_id.includes('@')) newErrors.email_id = 'Email ID is invalid';
        if (!contact.phone_number) newErrors.phone_number = 'Phone Number is required';
        if (!contact.job_title) newErrors.job_title = 'Job Title is required';
        if (!contact.gender) newErrors.gender = 'Gender is required';
        setErrors(newErrors);
        
        // If there are errors, stop the form submission
        if (Object.keys(newErrors).length > 0) return;
    
        // Prepare data for submission
        contact.reference_id = id;
        // contact.type='Lead';
        contact.gender = contact.gender?.value; // Safe navigation operator
        try {
            // Submit form data
            const response = await createAPI(state.auth.auth.token,contacts_create, contact);
            if (response && response.statusCode === 200) {
                showSuccessMsg(response.message);
                setContact(initialLeadState);
                navigate(`/${userTypeProps}/${routeProps}/view-${routeProps}/${id}`)
            } else {
                // Handle API error response
                showErrorMsg(response.message || response.error?.response?.data?.message || 'An error occurred');
            }
        } catch (error) {
            // Handle network or unexpected errors
            showErrorMsg('Network error or unexpected issue occurred.');
        }
    };
    

    return (
        <form className={styles.leadForm} onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-10"><h2>Create New Contact </h2></div>
                <div className="col-md-2">
                    <button onClick={()=>{navigate(`/${userTypeProps}/${routeProps}/view-${routeProps}/${id}`);}} className='btn btn-primary' style={{float:'right'}}>
                        <ArrowBackIcon />
                    </button>
                </div>
            </div>
            
            <div className="row">
                <div className="col-md-4">
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <input
                                type="text"
                                id="first_name"
                                value={contact.first_name}
                                onChange={(e) => handleInputChange(e, 'first_name')}
                                className={`${styles.input} ${errors.first_name ? styles.errorInput : ''}`}
                                placeholder=" "
                            />
                            <label htmlFor="first_name" className={`${styles.placeholder} ${contact.first_name ? styles.filled : ''}`}>
                                First Name
                            </label>
                        </div>
                        {errors.first_name && <span className={styles.error}>{errors.first_name}</span>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <input
                                type="text"
                                id="last_name"
                                value={contact.last_name}
                                onChange={(e) => handleInputChange(e, 'last_name')}
                                className={`${styles.input} ${errors.last_name ? styles.errorInput : ''}`}
                                placeholder=" "
                            />
                            <label htmlFor="last_name" className={`${styles.placeholder} ${contact.last_name ? styles.filled : ''}`}>
                                Last Name
                            </label>
                        </div>
                        {errors.last_name && <span className={styles.error}>{errors.last_name}</span>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className={styles.formGroup}>
                        <label htmlFor="type" className={`${styles.placeholder} ${contact.gender ? styles.filled : ''}`}>
                            Gender
                        </label>
                        <Select
                            id="type"
                            value={gender.find(type => type.value === contact.gender)}
                            onChange={(selectedOption) => handleSelectChange(selectedOption, 'gender')}
                            options={gender}
                            placeholder="Select Gender"
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <input
                                type="text"
                                id="email_id"
                                value={contact.email_id}
                                onChange={(e) => handleInputChange(e, 'email_id')}
                                className={`${styles.input} ${errors.email_id ? styles.errorInput : ''}`}
                                placeholder=" "
                            />
                            <label htmlFor="email_id" className={`${styles.placeholder} ${contact.email_id ? styles.filled : ''}`}>
                                Email Id
                            </label>
                        </div>
                        {errors.email_id && <span className={styles.error}>{errors.email_id}</span>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <input
                                type="text"
                                id="phone_number"
                                value={contact.phone_number}
                                onChange={(e) => handleInputChange(e, 'phone_number')}
                                className={`${styles.input} ${errors.phone_number ? styles.errorInput : ''}`}
                                placeholder=" "
                            />
                            <label htmlFor="phone_number" className={`${styles.placeholder} ${contact.phone_number ? styles.filled : ''}`}>
                                Phone Number
                            </label>
                        </div>
                        {errors.phone_number && <span className={styles.error}>{errors.phone_number}</span>}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className={styles.formGroup}>
                        <div className={styles.inputContainer}>
                            <input
                                type="text"
                                id="job_title"
                                value={contact.job_title}
                                onChange={(e) => handleInputChange(e, 'job_title')}
                                className={`${styles.input} ${errors.job_title ? styles.errorInput : ''}`}
                                placeholder=" "
                            />
                            <label htmlFor="job_title" className={`${styles.placeholder} ${contact.job_title ? styles.filled : ''}`}>
                                Job Title
                            </label>
                        </div>
                        {errors.job_title && <span className={styles.error}>{errors.job_title}</span>}
                    </div>
                </div>
                
            </div>
            <div className={styles.formGroup}>
                <button type="submit" className="btn btn-primary">Create Contact</button>
            </div>
        </form>
    );
}
