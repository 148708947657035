import moment from "moment";
import React, { useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";


import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
// import AdminNavbar from "../Navbar/AdminNavbar";
import AdminNavbar from "../Navbar/AdminNavbar";

import { CreateProperty, UploadDocument } from "../../../Api/Api";

const Createpropertyadmin = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  // useEffect(() => {
  // window.scroll(0, 0);

  //   getAmenityList();
  //   getCommunityList();
  // }, []);

  const [property, setProperty] = useState({});
  // const [amenities, setAmenities] = useState([]);
  // const [community, setCommunities] = useState([]);
  const [validation, setValidation] = useState({});
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const setData = (e, key) => {
    setProperty({ ...property, [key]: e });
    if (validation[key]) delete validation[key];
  };

  const setPropertyData = (e, key) => {
    setProperty({
      ...property,
      property_information: {
        ...property.property_information,
        [key]: e,
      },
    });
    if (validation[key]) delete validation[key];
  };

  const setFloorData = (e, key, subKey, index) => {
    let data = property[key] ?? [];
    data[index] = { ...data[index], [subKey]: e };
    setProperty({
      ...property,
      [key]: data,
    });
  };

  const setAddArrayData = (key) => {
    let data = property[key];
    data.push("");
    setProperty({
      ...property,
      [key]: data,
    });
  };

  const setRemoveArrayData = (key, index) => {
    let data = property[key];
    data.splice(index, 1);
    setProperty({
      ...property,
      [key]: data,
    });
  };

  const setArrayData = (e, key, index) => {
    let arr = [];
    arr = property[key] ?? [];

    if (index !== null) {
      arr.splice(index, 1);
    } else {
      arr.push(e);
    }

    setProperty({
      ...property,
      [key]: arr,
    });
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setData(html, "description");
    }
  };
  const number = /^[0-9]*$/;
  const CheckValidation = () => {
    let Status = true,
      validdateData = {};
    //console.log(validdateData);

    if (!property.property_id) {
      validdateData.property_id = "Property Id is Required";
      Status = false;
    }
    if (!property.property_type) {
      validdateData.property_type = "Property Type is Required";
      Status = false;
    }

    if (!property.reserve_price) {
      validdateData.reserve_price = "Reserve Price is Required";
      Status = false;
    }
    if (!property.emd_amount) {
      validdateData.emd_amount = "EMD Amount is Required";
      Status = false;
    }
    if (!property.auction_date) {
      validdateData.auction_date = "Auction Date is Required";
      Status = false;
    }
    if (!property.emd_submission_last_date) {
      validdateData.emd_amount = "EMD Submission Last Date is Required";
      Status = false;
    }


    if (!property.bank) {
      validdateData.bank = "Bank is Required";
      Status = false;
    }
    if (!property.bank_contact_details) {
      validdateData.bank_contact_details = "Bank Contact Details is Required";
      Status = false;
    }
    if (!property.location) {
      validdateData.location = "location is Required";
      Status = false;
    }
    if (!property.area) {
      validdateData.area = "area is Required";
      Status = false;
    }
    if (!property.city) {
      validdateData.city = "city is Required";
      Status = false;
    }
    if (!property.source_id) {
      validdateData.source_id = "Source is Required";
      Status = false;
    }
    if (!property.document) {
      property.document =[]
    }
    if (!property.sale_notice_url) {
      property.sale_notice_url = []
    }
    if (!property.description) {
      validdateData.description = "area is Required";
      Status = false;
    }


    // if (!property?.cover_image) {
    //   validdateData.cover_image = "Cover Image is Required";
    //   Status = false;
    // }
    // if (!property?.sponsered_status) {
    //   validdateData.sponsered_status = "Sponsered Status is Required";
    //   Status = false;
    // }
    if (!property?.description) {
      validdateData.description = "Property Description is Required";
      Status = false;
    }

    setValidation({ ...validation, ...validdateData });
    return Status;
  };

  // create property
  const createProperty = async (e) => {
    e.preventDefault();
    if (CheckValidation()) {
      const payload = {
        // ...{ role: state?.auth?.auth?.role, userId: state?.auth?.auth?._id },
        // ...{ userId: state?.auth?.auth?._id },
        ...property,
      };
      const token = state?.auth?.auth?.token;

      const response = await CreateProperty(token, payload);
      if (response && response.statusCode === 200) {

        successMsg(response.message);
        getTimeout();
      } else {
        errorMsg(response.message);
      }
    }
  };



  const UploadDocuments = async (e, key, subKey, ind) => {
    if (e.target.files.length <= 5) {
      for (let index = 0; index < e.target.files.length; index++) {
        const element = e.target.files[index];
        let formData = new FormData();
        let str = element.name;
        if (element.size > 1024 * 4096) {
          errorMsg("File Should Be Lesser than 5 MB");
          return false;
        }
        formData.append("file", element);
        formData.append(
          "fileName",
          moment().format("YYYYMMDDHHmmss") + "_" + str
        );
        formData.append("path", "Image/");
        const response = await UploadDocument(formData);
        if (response && response.statusCode === 200) {
          successMsg(response.message);
          if (subKey) {
            setFloorData(response.data, key, subKey, ind);
          } else {
            setArrayData(response.data, key, null);
          }
        } else {
          errorMsg(response.message);
        }
      }
    } else {
      errorMsg("You chosen more than 5 files");
    }
  };
  // const getAmenityList = async () => {
  //   const response = await AmenityList();
  //   if (response && response.statusCode === 200) {
  //     let amenty = response.data.map((label) => ({
  //       label: label.Name,
  //       value: label._id,
  //     }));
  //     setAmenities(amenty);
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };
  // const handleCreateAmenity = async (key, string) => {
  //   const Payload = {
  //     Name: string,
  //   };
  //   const response = await CreateAmenities(Payload);
  //   if (response && response.statusCode === 200) {
  //     getAmenityList();
  //     setArrayData(
  //       { label: response.data.Name, value: response.data._id },
  //       key,
  //       null
  //     );
  //     successMsg(response.message);
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };
  // const getCommunityList = async () => {
  //   const response = await CommunitiesList();
  //   if (response && response.statusCode === 200) {
  //     let community = response.data.map((label) => ({
  //       label: label.name,
  //       value: label._id,
  //     }));
  //     setCommunities(community);
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };
  // const handleCreatecommunity = async (key, string) => {
  //   const Payload = {
  //     name: string,
  //   };
  //   const response = await CreateCommunities(Payload);
  //   if (response && response.statusCode === 200) {
  //     getCommunityList();
  //     setArrayData(
  //       { label: response.data.name, value: response.data._id },
  //       key,
  //       null
  //     );
  //     successMsg(response.message);
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };
  const getTimeout = () => {
    setTimeout(() => {
      setTimeout(navigate("/Property"));
    }, 1000);
  };
  const successMsg = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const errorMsg = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <>
      <AdminNavbar />
      <div className="main">
        <div className="">
          <div className="">
            <button
              type="button"
              className="btn login-btn ms-2 "
              onClick={(e) => {
                navigate("/Property");
              }}
            >
              Back
            </button>
          </div>
          <div className=" my-4">
            <div className="bg-historytablehead rounded-3 py-3 px-3">
              <h6 className="mb-0">Create Property</h6>
            </div>
            <div className="container">
              <div className="row justify-content-center py-5">
                <div className="col-lg-11">
                  <div className="row">
                    <div className="col-lg-12">
                      <form>
                        <div className="admincard">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Property Id</label>
                                <input
                                  type="text"
                                  name="property_id"
                                  className="form-control"
                                  style={{ border: validation.property_name ? "1px solid red" : "" }}
                                  placeholder="Enter Property Id"
                                  autoComplete="off"
                                  defaultValue={property.property_id}
                                  onChange={(e) =>
                                    setData(e.target.value, "property_id")
                                  }
                                />
                                {validation.property_name && (
                                  <p className="text-danger">
                                    {validation.property_id}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Property Type</label>
                                <select
                                  className="form-control"
                                  style={{ border: validation.property_type ? "1px solid red" : "" }}
                                  value={property?.property_type}
                                  onChange={(e) =>
                                    setData(e.target.value, "property_type")
                                  }
                                >
                                  <option value="">Select Property Type</option>
                                  <option value="Apartment">Apartment</option>
                                  <option value="Villa">Villa</option>
                                  <option value="Individual House">
                                    Individual House
                                  </option>
                                  <option value="Plot">Plot</option>
                                </select>
                                {validation.property_type && (
                                  <p className="text-danger">
                                    {validation.property_type}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Possession Status</label>
                                <input
                                  type="text"
                                  name="possession_status"
                                  className="form-control"
                                  style={{ border: validation.possession_status ? "1px solid red" : "" }}
                                  placeholder="Enter Possession Status"
                                  autoComplete="off"
                                  defaultValue={property.possession_status}
                                  onChange={(e) =>
                                    setData(e.target.value, "possession_status")
                                  }
                                />
                                {validation.possession_status && (
                                  <p className="text-danger">
                                    {validation.possession_status}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Bank Name</label>
                                <input
                                  type="text"
                                  name="bank"
                                  className="form-control"
                                  style={{ border: validation.bank ? "1px solid red" : "" }}
                                  placeholder="Enter Bank Name"
                                  autoComplete="off"
                                  defaultValue={property.bank}
                                  onChange={(e) =>
                                    setData(e.target.value, "bank")
                                  }
                                />
                                {validation.bank && (
                                  <p className="text-danger">
                                    {validation.bank}
                                  </p>
                                )}
                              </div>
                            </div>

                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Reserve Price</label>
                                <input
                                  type="number"
                                  name="reserve_price"
                                  className="form-control"
                                  style={{ border: validation.reserve_price ? "1px solid red" : "" }}
                                  placeholder="Enter Reserve Price"
                                  autoComplete="off"
                                  defaultValue={property.reserve_price}
                                  onChange={(e) =>
                                    setData(e.target.value, "reserve_price")
                                  }
                                />
                                {validation.reserve_price && (
                                  <p className="text-danger">
                                    {validation.reserve_price}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">EMD Amount</label>
                                <input
                                  type="number"
                                  name="emd_amount"
                                  className="form-control"
                                  style={{ border: validation.emd_amount ? "1px solid red" : "" }}
                                  placeholder="Enter EMD Amount"
                                  autoComplete="off"
                                  defaultValue={property.emd_amount}
                                  onChange={(e) =>
                                    setData(e.target.value, "emd_amount")
                                  }
                                />
                                {validation.emd_amount && (
                                  <p className="text-danger">
                                    {validation.emd_amount}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">

                            <div className="col-lg-6">

                              <div className="mb-4">
                                <label className="form-label">Auction Date</label>
                                <input
                                  type="date"
                                  name="auction_date"
                                  className="form-control"
                                  style={{ border: validation.auction_date ? "1px solid red" : "" }}
                                  placeholder="Auction Date"
                                  autoComplete="off"
                                  defaultValue={property.auction_date}
                                  onChange={(e) =>
                                    setData(e.target.value, "auction_date")
                                  }
                                />
                                {validation.auction_date && (
                                  <p className="text-danger">
                                    {validation.auction_date}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">EMD Submission Last Date</label>
                                <input
                                  type="date"
                                  name="emd_submission_last_date"
                                  className="form-control"
                                  style={{ border: validation.emd_submission_last_date ? "1px solid red" : "" }}
                                  placeholder="EMD Submission Last Date"
                                  autoComplete="off"
                                  defaultValue={property.emd_submission_last_date}
                                  onChange={(e) =>
                                    setData(e.target.value, "emd_submission_last_date")
                                  }
                                />
                                {validation.emd_submission_last_date && (
                                  <p className="text-danger">
                                    {validation.emd_submission_last_date}
                                  </p>
                                )}
                              </div>
                            </div>




                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Borrower Name</label>
                                <input
                                  type="text"
                                  name="borrower_name"
                                  className="form-control"
                                  style={{ border: validation.borrower_name ? "1px solid red" : "" }}
                                  placeholder="Enter Borrower Name"
                                  autoComplete="off"
                                  defaultValue={property.borrower_name}
                                  onChange={(e) =>
                                    setData(e.target.value, "borrower_name")
                                  }
                                />
                                {validation.borrower_name && (
                                  <p className="text-danger">
                                    {validation.borrower_name}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Bank Contact Details</label>
                                <input
                                  type="text"
                                  name="bank_contact_details"
                                  className="form-control"
                                  style={{ border: validation.bank_contact_details ? "1px solid red" : "" }}
                                  placeholder="Enter Bank Contact Details"
                                  autoComplete="off"
                                  defaultValue={property.bank_contact_details}
                                  onChange={(e) =>
                                    setData(e.target.value, "bank_contact_details")
                                  }
                                />
                                {validation.bank_contact_details && (
                                  <p className="text-danger">
                                    {validation.bank_contact_details}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Location</label>
                                <input
                                  type="text"
                                  name="location"
                                  className="form-control"
                                  style={{ border: validation.location ? "1px solid red" : "" }}
                                  placeholder="Enter Location"
                                  autoComplete="off"
                                  defaultValue={property.location}
                                  onChange={(e) =>
                                    setData(e.target.value, "location")
                                  }
                                />
                                {validation.location && (
                                  <p className="text-danger">
                                    {validation.location}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Area</label>
                                <input
                                  type="text"
                                  name="area"
                                  className="form-control"
                                  style={{ border: validation.area ? "1px solid red" : "" }}
                                  placeholder="Enter Area"
                                  autoComplete="off"
                                  defaultValue={property.area}
                                  onChange={(e) =>
                                    setData(e.target.value, "area")
                                  }
                                />
                                {validation.area && (
                                  <p className="text-danger">
                                    {validation.area}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                            <div className="mb-4">
                                <label className="form-label">City</label>
                                <input
                                  type="text"
                                  name="city"
                                  className="form-control"
                                  style={{ border: validation.city ? "1px solid red" : "" }}
                                  placeholder="Enter City"
                                  autoComplete="off"
                                  defaultValue={property.city}
                                  onChange={(e) =>
                                    setData(e.target.value, "city")
                                  }
                                />
                                {validation.city && (
                                  <p className="text-danger">
                                    {validation.city}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                            <div className="mb-4">
                                <label className="form-label">Source</label>
                                <input
                                  type="text"
                                  name="source_id"
                                  className="form-control"
                                  style={{ border: validation.source_id ? "1px solid red" : "" }}
                                  placeholder="Enter Source"
                                  autoComplete="off"
                                  defaultValue={property.source_id}
                                  onChange={(e) =>
                                    setData(e.target.value, "source_id")
                                  }
                                />
                                {validation.source_id && (
                                  <p className="text-danger">
                                    {validation.source_id}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row my-4">
                            <div className="col-lg-12">
                              <label className="form-label">
                                Property Description
                              </label>
                              <div className="form-control">
                                <Editor
                                  wrapperClassName="wrapper-class"
                                  editorClassName="editor-class"
                                  toolbarClassName="toolbar-class"
                                  editorState={editorState}
                                  onEditorStateChange={(e) =>
                                    handleEditorChange(e)
                                  }
                                />
                                {validation.description && (
                                  <p className="text-danger">
                                    {validation.description}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">
                                  Document
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  style={{ border: validation.document ? "1px solid red" : "" }}
                                  id="myfile"
                                  accept="image/*"
                                  multiple
                                  onChange={(e) =>
                                    UploadDocuments(e, "document")
                                  }
                                />
                                {/* {validation.cover_image && (
                                <p className="text-danger">
                                  {validation.cover_image}
                                </p>
                              )} */}
                              </div>

                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">
                                  Sale Notice
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  style={{ border: validation.sale_notice_url ? "1px solid red" : "" }}
                                  id="myfile"
                                  accept="image/*"
                                  multiple
                                  onChange={(e) =>
                                    UploadDocuments(e, "sale_notice_url")
                                  }
                                />
                                {/* {validation.cover_image && (
                                <p className="text-danger">
                                  {validation.cover_image}
                                </p>
                              )} */}
                              </div>
                            </div>
                          </div>
                          <div className="textcenter mt-4">
                            <button
                              className="btn login-btn ms-2 "
                              onClick={(e) => createProperty(e)}
                            >
                              Create Property
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Createpropertyadmin;
