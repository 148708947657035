import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import moment from "moment";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  GoldSpecific, UpdateGold, UploadDocument
} from "../../../Api/Api";
import AdminNavbar from "../Navbar/AdminNavbar";
let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

const PropertygoldAuction = () => {
  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const day = (`0${d.getDate()}`).slice(-2);
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };


  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const {action, id } = useParams();
  let isDisabled = true;
  if(action == "Edit"){
    isDisabled = false;
  }
  useEffect(() => {
    window.scroll(0, 0);

    getAdminList();
    // getAmenityList();
    // getCommunityList();
  }, []);

  const [property, setProperty] = useState({
    floor_information: [{ floor_no: "", floor_image: "", floor_desc: "" }],
  });
  // const [amenities, setAmenities] = useState([]);
  // const [community, setCommunities] = useState([]);

  const getAdminList = async () => {
    // const token = state?.auth?.auth?.token;
    const response = await GoldSpecific(  state?.auth?.auth?.token,id);
    if (response && response.statusCode === 200) {
      let dummy = response.data;
      let cmsDescription = EditorState.createEmpty();
      if (dummy.property_desc) {
        let blocksFromHtml = htmlToDraft(dummy.property_desc);
        let { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        cmsDescription = EditorState.createWithContent(contentState);
      }
      dummy.property_desc = cmsDescription;
      // dummy.amenities = dummy.amenities.map((label) => ({
      //   label: label.Name,
      //   value: label._id,
      // }));
      // dummy.community = dummy.community.map((label) => ({
      //   label: label.name,
      //   value: label._id,
      // }));
      setProperty(dummy);
    } else {
      setProperty({});
      errorMsg(response.message);
    }
  };
  const updateGoldAuction = async (e) => {
    console.log(e)
    e.preventDefault();

    if (CheckValidation()) {

      const payload = {
        _id:property._id,
        id:property.id,
        status:property.status,
        application_submission_date:property.application_submission_date,
        auction_date:property.auction_date,
        bank_institude:property.bank_institude,
        borrower_name:property.borrower_name,
        branch:property.branch,
        contact_details:property.contact_details,
        description:property.description,
        document:property.document,
        sale_notice_url:property.sale_notice_url,
        source_id:property.source_id
      };
      const token = state?.auth?.auth?.token;

      const response = await UpdateGold(payload,token);
      if (response && response.statusCode === 200) {

        successMsg(response.message);
        getTimeout();
      } else {
        errorMsg(response.message);
      }
    }
  };



  const UploadDocuments = async (e, key, subKey, ind) => {
    if (e.target.files.length <= 5) {
      for (let index = 0; index < e.target.files.length; index++) {
        const element = e.target.files[index];
        let formData = new FormData();
        let str = element.name;
        if (element.size > 1024 * 4096) {
          errorMsg("File Should Be Lesser than 5 MB");
          return false;
        }
        formData.append("file", element);
        formData.append(
          "fileName",
          moment().format("YYYYMMDDHHmmss") + "_" + str
        );
        formData.append("path", "Image/");
        const response = await UploadDocument(formData);
        if (response && response.statusCode === 200) {
          successMsg(response.message);
          if (subKey) {
            setFloorData(response.data, key, subKey, ind);
          } else {
            setArrayData(response.data, key, null);
          }
        } else {
          errorMsg(response.message);
        }
      }
    } else {
      errorMsg("You chosen more than 5 files");
    }
  };
  const [validation, setValidation] = useState({});
  const setData = (e, key) => {
    setProperty({ ...property, [key]: e });
    if (validation[key]) delete validation[key];
  };

  const setFloorData = (e, key, subKey, index) => {
    let data = property[key] ?? [];
    data[index] = { ...data[index], [subKey]: e };
    setProperty({
      ...property,
      [key]: data,
    });
  };

  const setAddArrayData = (key) => {
    let data = property[key];

    data.push("");
    setProperty({
      ...property,
      [key]: data,
    });
    if (validation[key]) delete validation[key];
  };

  const setRemoveArrayData = (key, index) => {
    let data = property[key];
    data.splice(index, 1);
    setProperty({
      ...property,
      [key]: data,
    });
    if (validation[key]) delete validation[key];
  };

  const setPropertyData = (e, key) => {
    setProperty({
      ...property,
      property_information: {
        ...property.property_information,
        [key]: e,
      },
    });
    if (validation[key]) delete validation[key];
  };
  const setArrayData = (e, key, index) => {
    let arr = [];
    arr = property[key] ?? [];

    if (index !== null) {
      arr.splice(index, 1);
    } else {
      arr.push(e);
    }

    setProperty({
      ...property,
      [key]: arr,
    });
  };
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );


  const number = /^[0-9]*$/;
  const CheckValidation = () => {
    let Status = true,
      validdateData = {};
    //console.log(validdateData);

    if (!property.bank_institude) {
      validdateData.bank_institude = "bank_institude is Required";
      Status = false;
    }
    if (!property.auction_date) {
      validdateData.auction_date = "auction_date is Required";
      Status = false;
    }

    if (!property.application_submission_date) {
      validdateData.application_submission_date = "application_submission_date is Required";
      Status = false;
    }
    if (!property.document) {
      property.document =[]
    }
    if (!property.branch) {
      validdateData.branch = "branch is Required";
      Status = false;
    }
    if (!property.borrower_name) {
      validdateData.borrower_name ="borrower_name is Required";
      Status = false;
    }


    if (!property.contact_details) {
      validdateData.contact_details = "contact_details is Required";
      Status = false;
    }
    if (!property.source_id) {
      validdateData.source_id = "Source is Required";
      Status = false;
    }
    if (!property.sale_notice_url) {
      property.sale_notice_url = []
    }
    if (!property.description) {
      validdateData.description = "description is Required";
      Status = false;
    }
    if (!property.id) {
      validdateData.id = "id is Required";
      Status = false;
    }

    setValidation({ ...validation, ...validdateData });
    return Status;
  };

  // update property
  // const updatePropertydetail = async (e) => {
  //   e.preventDefault();
  //   if (property.property_type !== "Plot") {
  //     if (CheckValidation()) {
  //       let isNotEmpty =
  //         property.property_desc &&
  //         property.property_desc.getCurrentContent().hasText();
  //       if (isNotEmpty) {
  //         let html = draftToHtml(
  //           convertToRaw(property.property_desc.getCurrentContent())
  //         );
  //         const payload = {
  //           ...{ role: state?.auth?.auth?.role },
  //           ...property,
  //           property_desc: html,
  //           // amenities: property.amenities.map((list) => list.value),
  //           // community: property.community.map((list) => list.value),
  //         };

  //         const token = state?.auth?.auth?.token;
  //         const response = await UpdateProperty(payload, token);
  //         if (response && response.statusCode === 200) {
  //           successMsg(response.message);
  //           getTimeout();
  //         } else {
  //           errorMsg(response.message);
  //         }
  //       }
  //     } else {
  //       let isNotEmpty =
  //         property.property_desc &&
  //         property.property_desc.getCurrentContent().hasText();
  //       if (isNotEmpty) {
  //         let html = draftToHtml(
  //           convertToRaw(property.property_desc.getCurrentContent())
  //         );
  //         const payload = {
  //           ...{ role: state?.auth?.auth?.role },
  //           ...property,
  //           property_desc: html,
  //         };

  //         const token = state?.auth?.auth?.token;
  //         const response = await UpdateProperty(payload, token);
  //         if (response && response.statusCode === 200) {
  //           successMsg(response.message);
  //           getTimeout();
  //         } else {
  //           errorMsg(response.message);
  //         }
  //       }
  //     }
  //   }
  // };

  // const UploadDocuments = async (e, key, subKey, ind) => {
  //   if (e.target.files.length <= 5) {
  //     for (let index = 0; index < e.target.files.length; index++) {
  //       const element = e.target.files[index];

  //       let formData = new FormData();
  //       let str = element.name;
  //       if (element.size > 1024 * 4096) {
  //         errorMsg("File Should Be Lesser than 5 MB");
  //         return false;
  //       }
  //       formData.append("file", element);
  //       formData.append(
  //         "fileName",
  //         moment().format("YYYYMMDDHHmmss") + "_" + str
  //       );
  //       formData.append("path", "Image/");
  //       const response = await UploadDocument(formData);
  //       if (response && response.statusCode === 200) {
  //         successMsg(response.message);
  //         if (subKey) {
  //           setFloorData(response.data, key, subKey, ind);
  //         } else {
  //           setArrayData(response.data, key, null);
  //         }
  //       } else {
  //         errorMsg(response.message);
  //       }
  //     }
  //   } else {
  //     errorMsg("You chosen more than 5 files");
  //   }
  // };

  // const getAmenityList = async () => {
  //   const response = await AmenityList();
  //   if (response && response.statusCode === 200) {
  //     let amenty = response.data.map((label) => ({
  //       label: label.Name,
  //       value: label._id,
  //     }));
  //     setAmenities(amenty);
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };
  // const handleCreateLocation = async (key, string) => {
  //   const Payload = {
  //     Name: string,
  //   };
  //   const response = await CreateAmenities(Payload);
  //   if (response && response.statusCode === 200) {
  //     getAmenityList();
  //     setArrayData(
  //       { label: response.data.Name, value: response.data._id },
  //       key,
  //       null
  //     );

  //     successMsg(response.message);
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };
  // const getCommunityList = async () => {
  //   const response = await CommunitiesList();
  //   if (response && response.statusCode === 200) {
  //     let community = response.data.map((label) => ({
  //       label: label.name,
  //       value: label._id,
  //     }));
  //     setCommunities(community);
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };
  // const handleCreatecommunity = async (key, string) => {
  //   const Payload = {
  //     name: string,
  //   };
  //   const response = await CreateCommunities(Payload);
  //   if (response && response.statusCode === 200) {
  //     getCommunityList();
  //     setArrayData(
  //       { label: response.data.name, value: response.data._id },
  //       key,
  //       null
  //     );
  //     successMsg(response.message);
  //   } else {
  //     errorMsg(response.message);
  //   }
  // };
  const getTimeout = () => {
    setTimeout(() => {
      setTimeout(navigate("/GoldAuction"));
    }, 1000);
  };
  const successMsg = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const errorMsg = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <>
      <AdminNavbar />

      <div className="main">
        <div className="content">
          <div className="">
            <div className="">
              <button
                type="button"
                className="btn login-btn ms-2 "
                onClick={(e) => {
                  navigate("/GoldAuction");
                }}
              >
                Back
              </button>
            </div>
            <div className=" my-4">
              <div className="bg-historytablehead rounded-3 py-3 px-3">
                <h6 className="mb-0">Gold Auction Details</h6>
              </div>
              <div className="container">
              <div className="row justify-content-center py-5">
                <div className="col-lg-11">
                  <div className="row">
                    <div className="col-lg-12">
                      <form>
                        <div className="admincard">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Id</label>
                                <input 
                                  
                                  type="text"
                                  name="id"
                                  className="form-control"
                                  style={{ border: validation.id ? "1px solid red" : "" }}
                                  placeholder="Enter Id"
                                  autoComplete="off"
                                  defaultValue={property.id}
                                  onChange={(e) =>
                                    setData(e.target.value, "id")
                                  }
                                />
                                {validation.id && (
                                  <p className="text-danger">
                                    {validation.id}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Bank / Institude</label>
                                <input
                                disabled={isDisabled}
                                  type="text"
                                  name="bank_institude"
                                  className="form-control"
                                  style={{ border: validation.bank_institude ? "1px solid red" : "" }}
                                  placeholder="Enter Bank / Institude"
                                  autoComplete="off"
                                  defaultValue={property.bank_institude}
                                  onChange={(e) =>
                                    setData(e.target.value, "bank_institude")
                                  }
                                />
                                {validation.bank_institude && (
                                  <p className="text-danger">
                                    {validation.id}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Branch Name</label>
                                <input 
                                 disabled={isDisabled}
                                  type="text"
                                  name="bank"
                                  className="form-control"
                                  style={{ border: validation.branch ? "1px solid red" : "" }}
                                  placeholder="Enter Branch Name"
                                  autoComplete="off"
                                  defaultValue={property.branch}
                                  onChange={(e) =>
                                    setData(e.target.value, "branch")
                                  }
                                />
                                {validation.branch && (
                                  <p className="text-danger">
                                    {validation.branch}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Contact Details</label>
                                <input 
                                disabled={isDisabled}
                                  type="text"
                                  name="bank"
                                  className="form-control"
                                  style={{ border: validation.contact_details ? "1px solid red" : "" }}
                                  placeholder="Enter Contact Details"
                                  autoComplete="off"
                                  defaultValue={property.contact_details}
                                  onChange={(e) =>
                                    setData(e.target.value, "contact_details")
                                  }
                                />
                                {validation.contact_details && (
                                  <p className="text-danger">
                                    {validation.contact_details}
                                  </p>
                                )}
                              </div>
                            </div>

                          </div>
                          <div className="row">

<div className="col-lg-6">

  <div className="mb-4">
    <label className="form-label">Auction Date</label>
    <input
      disabled={isDisabled}
      type="date"
      name="auction_date"
      className="form-control"
      style={{ border: validation.auction_date ? "1px solid red" : "" }}
      placeholder="Auction Date"
      autoComplete="off"
      defaultValue={formatDate(property.auction_date)}
      onChange={(e) =>
        setData(e.target.value, "auction_date")
      }
    />
    {validation.auction_date && (
      <p className="text-danger">
        {validation.auction_date}
      </p>
    )}
  </div>
</div>
<div className="col-lg-6">
  <div className="mb-4">
    <label className="form-label">Application Submission Date</label>
    <input 
    disabled={isDisabled}

      type="date"
      name="application_submission_date"
      className="form-control"
      style={{ border: validation.application_submission_date ? "1px solid red" : "" }}
      placeholder="Application Submission Date"
      autoComplete="off"
      defaultValue={formatDate(property.application_submission_date)}
      onChange={(e) =>
        setData(e.target.value, "application_submission_date")
      }
    />
    {validation.application_submission_date && (
      <p className="text-danger">
        {validation.application_submission_date}
      </p>
    )}
  </div>
</div>




</div>
                          <div className="row">
                          <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Borrower Name</label>
                                <input 
                                disabled={isDisabled}
                                  type="text"
                                  name="borrower_name"
                                  className="form-control"
                                  style={{ border: validation.borrower_name ? "1px solid red" : "" }}
                                  placeholder="Enter Borrower Name"
                                  autoComplete="off"
                                  defaultValue={property.borrower_name}
                                  onChange={(e) =>
                                    setData(e.target.value, "borrower_name")
                                  }
                                />
                                {validation.borrower_name && (
                                  <p className="text-danger">
                                    {validation.borrower_name}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">Source</label>
                                <input 
                                disabled={isDisabled}
                                  type="text"
                                  name="source_id"
                                  className="form-control"
                                  style={{ border: validation.source_id ? "1px solid red" : "" }}
                                  placeholder="Enter Source"
                                  autoComplete="off"
                                  defaultValue={property.source_id}
                                  onChange={(e) =>
                                    setData(e.target.value, "source_id")
                                  }
                                />
                                {validation.source_id && (
                                  <p className="text-danger">
                                    {validation.source_id}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

              
                          <div className="row my-4">
                            <div className="col-lg-12">
                              <label className="form-label">
                                 Description
                              </label>
                              <div className="form-control">
                                <Editor
                                  wrapperClassName="wrapper-class"
                                  editorClassName="editor-class"
                                  toolbarClassName="toolbar-class"
                                  editorState={editorState}
                                 
                                />
                                {validation.description && (
                                  <p className="text-danger">
                                    {validation.description}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row my-4">
                              <div className="col-lg-6">
                                <label className="form-label">
                                  Sale Notice URL
                                </label>
                                {
                                  property?.sale_notice_url?.map((data) => {
                                    return (
                                      <img
                                        src={data}
                                        alt=""
                                        style={{ width: "200px", height: "200px" }}
                                        className="form-control"
                                      />
                                    )
                                  })
                                }

                              </div>
                              <div className="col-lg-6">
                                <label className="form-label">
                                  Documents
                                </label>
                                {
                                  property?.document?.map((data) => {
                                    return (
                                      <img
                                        src={data}
                                        alt=""
                                        style={{ width: "200px", height: "200px" }}
                                        className="form-control"
                                      />
                                    )
                                  })
                                }

                              </div>
                            </div>
                            <div style={{ visibility: isDisabled ? 'hidden' : 'visible'}}>
                            <div className="textcenter mt-4">
                            <button
                              className="btn login-btn ms-2 "
                              onClick={(e) => updateGoldAuction(e)}
                            >
                              Update Gold Auction
                            </button>
                          </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
 };

export default PropertygoldAuction;
