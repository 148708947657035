import React from 'react';
import coffee from '../../../Assets/coffee.gif';
import { useNavigate } from 'react-router-dom';

export default function ScheduleMeetBanner() {
    const navigate = useNavigate();
    return (
        <section class="position-relative space">
            <div class="th-bg-img" data-bg-src="https://dalmaf.com/img/bg/cta_bg_2.jpg"><img src="https://dalmaf.com/img/bg/bg_overlay_1.png"
                    alt="overlay"/></div>
            <div class="container z-index-common">
                <div class="row justify-content-center">
                    <div class="col-xl-6 col-lg-7 col-md-9 text-center">
                        <div class="title-area mb-35">
                            {/* <span class="sub-title">
                                <div class="icon-masking me-2">
                                    <span class="mask-icon" data-mask-src="img/theme-img/title_shape_2.svg"></span> 
                                    <img src="img/theme-img/title_shape_2.svg" alt="shape"/>
                                </div>
                                CONTACT US
                            </span> */}
                            <h2 class="sec-title text-white">
                                Still Confused About Your Business Needs? Consult With 
                                <span class="text-theme fw-normal"> Our Experts</span> Today To Know More!
                            </h2>
                        </div>
                        <a href=""  onClick={(e) => {e.preventDefault();navigate("/schedule-meet")}} class="th-btn style3">Schedule Meeting</a>
                    </div>
                </div>
            </div>
        </section>
        // <section className="pt-12 pb-12" style={{ backgroundColor: "#696d98" }}>
        //     <div className="container">
        //         <div className="row mt-5 align-items-center">
        //             <div className="col-md-6">
        //                 <div className="p-5 rounded bg-light shadow-lg" style={{ opacity: 0.9 }}>
        //                     <h3 className="mb-4 text-dark">Still Confused About Your Business Needs? Consult With Our Experts Today To Know More!</h3>
        //                     <button className="btn btn-primary rounded-pill text-white" style={{ border: '2px solid white' }} onClick={() => navigate("/schedule-meet")}>
        //                         Schedule Meeting
        //                     </button>
        //                 </div>
        //             </div>
        //             <div className="col-md-6 text-center">
        //                 <img src={coffee} className="img-fluid rounded " alt="Coffee Break" style={{ maxHeight: '400px' }} />
        //             </div>
        //         </div>
        //     </div>
        // </section>
    );
}
