import React, { useEffect, useRef, useState } from "react";
import RFA from "../../Assets/Images/audit1.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Ser1 from "../../Assets/Images/ser1.webp";
import Ser2 from "../../Assets/Images/ser2.webp";
import Ser3 from "../../Assets/Images/ser3.webp";
import Si1 from "../../Assets/Images/si-1.webp";
import Si2 from "../../Assets/Images/si-2.webp";
import Si3 from "../../Assets/Images/si-3.webp";
import OwlCarousel from "react-owl-carousel";
import { useLocation, useNavigate } from "react-router-dom";
import { sections } from "../../Datas";

import {
  ChangePassword,
  CreateOrder,
  CreatePaymentPackage,
  CreatePaymentService,
  ForgotOtp,
  ForgotPassword,
  GetContent,
  GetPackage,
  GetPricingWithName,
  GetUser,
  SignUp,
  UserLogin,
  UserServiceRequest,
  UseraboutContent,
  VerifyOtp,
} from "../../Api/Api";
import {
  EmailValidation,
  NonEmptyValidation,
  PasswordValidation,
  PhoneNumberValidation,
  RePasswordValidation,
  StringValidation,
} from "../../Store/validate";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess, registerDetails } from "../../Store/auth/action";
import CustomModal from "../CustomModal";
import OtpInput from "react-otp-input";
import ServicesAllpages from "../ServicesAllpages";

export default function LegalContent() {
 const [selectedTab, setSelectedTab] = React.useState("");
 const [BookNow, setBookNow] = useState(false);
  const locationSearch = useLocation();

 
  useEffect(() => {
    AOS.init();
    window.scroll(0, 0);
    const search = locationSearch.search;
    const tab = new URLSearchParams(search).get("tab");

    if (tab) {
      setSelectedTab(tab);
    }
    return () => {};
  }, [locationSearch]);

  const AllTabs = [
    "Whats Included",
    "Whats Not Included",
    "You May Also Want To Know",
  ];


  const state = useSelector((state) => state);

  const [legalcont, setLegalcont] = useState({});
 

const [legalcontValidation, setLegalcontValidation] = useState({});

const setLegalcontValue = (key, value) => {
  setLegalcont({ ...legalcont, [key]: value });
  if (legalcontValidation[key]) delete legalcontValidation[key];
};

const setLegalcontValidationValue = (key, value) => {
  setLegalcontValidation({ ...legalcontValidation, [key]: value });
};

//error
const showToastErrorMsg = (errMsg) => {
  toast.error(errMsg, {
    position: toast.POSITION.TOP_RIGHT,
    className: "toast-message",
    autoClose: 5000,
  });
};

//success
const showToastSuccessMsg = (successMsg) => {
  toast.success(successMsg, {
    position: toast.POSITION.TOP_RIGHT,
    className: "toast-message",
    autoClose: 3000,
  });
};

const [userProfile, setUserProfile] = useState([]);
useEffect(() => {
  if(state?.auth?.isLoggedIn===true){
    getUserProfile();
  }
  GetMyTsks()
}, []);
const getUserProfile = async () => {
  const id = state?.auth?.auth?._id 
  const token = state.auth.auth.token
  const response = await GetUser(token,id);
  if (response.statusCode === 200) {
    setUserProfile(response.data);
  } else {
    //console.log("error while getting user profile info")
  }
};



const handleSubmitForm = async (e) => {
  e.preventDefault();
  
  let validate = {};
  validate.name = StringValidation(legalcont?.name);
  validate.emailId = EmailValidation(legalcont?.emailId?.toLowerCase());
  validate.subject = NonEmptyValidation(legalcont?.subject);
  validate.question = NonEmptyValidation(legalcont?.question);
  validate.servicesType = NonEmptyValidation(legalcont?.servicesType);
  validate.mobileNumber = PhoneNumberValidation(legalcont?.mobileNumber);
  setLegalcontValidation(validate);
  if (Object.values(validate).every((v) => v.status === true)) {
    const Payload = {
      name: legalcont.name,
      emailId: legalcont.emailId?.toLowerCase(),
      subject: legalcont.subject,
      question: legalcont.question,
      mobileNumber: legalcont.mobileNumber,
      servicesType: legalcont.servicesType,
      // servicesCategory:cat[0].substring(cat[0].lastIndexOf("/")+1),
      // servicesSubcategory:localStorage?.getItem("subcategory"),
      // servicesTitle:localStorage?.getItem("title")
    };
    // const token = state?.auth?.auth?.token
    const response = await UseraboutContent(Payload);
    if (response && response.statusCode === 200) {
     
      showToastSuccessMsg(response.message);
      window.location.reload();
       setLegalcont({});
       legalcont({});
      localStorage?.removeItem("category");
      localStorage?.removeItem("subcategory");
      localStorage?.removeItem("title");
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  } else {
    //console.log("Error while submit a service request");
  }

  // }
};

const handleSubmitService = async (e) => {
  
    e.preventDefault();
    const Payload = {
      name: userProfile.name,
      email: userProfile.email,
      subject: " ",
      question: " ",
      mobileNumber: userProfile.phoneNumber,
      userId: state?.auth?.auth?._id,
      type:"service",
      servicesCategory: cat[0].substring(cat[0].lastIndexOf("/") + 1),
      servicesSubcategory: localStorage?.getItem("subcategory"),
      servicesTitle: localStorage?.getItem("title"),
    };
   
    const response = await UserServiceRequest(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setLegalcont({})
      setForm(false);
    
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
     
    }

};

const [loginModal, setloginModal] = useState(false);
const [createModal, setcreateModal] = useState(false);
const [fpwModal, setfpwModal] = useState(false);
const [otpModal, setotpModal] = useState(false);
const [otpFgtModal, setotpFgtModal] = useState(false);
const [changepswModal, setchangepswModal] = useState(false);

const [password, setPassword] = useState();
const viewPassword = () => {
  setPassword(!password);
};

const [passwordStrength, setPasswordStrength] = useState("");
const [registerDetail, setRegisterDetails] = useState({});

const [registerValidation, setRegisterValidation] = useState({});

const setRegisterDetailsValue = (key, value) => {
  setRegisterDetails({ ...registerDetail, [key]: value });
  if (registerValidation[key]) delete registerValidation[key];
};

const setRegisterValidationValue = (key, value) => {
  setRegisterValidation({ ...registerValidation, [key]: value });
};

const dispatch = useDispatch();
// const location = useLocation();
//error

//User Registration api call

const handleSubmit = async (e) => {
  e.preventDefault();
  let validate = {};
  validate.name = StringValidation(registerDetail?.name);
  validate.email = EmailValidation(registerDetail?.email?.toLowerCase());
  validate.password = PasswordValidation(registerDetail?.password);
  validate.phoneNumber = PhoneNumberValidation(registerDetail?.phoneNumber);
  setRegisterValidation(validate);
  if (Object.values(validate).every((v) => v.status === true)) {
    const Payload = {
      name: registerDetail.name,
      email: registerDetail.email?.toLowerCase(),
      password: registerDetail.password,
      phoneNumber: registerDetail.phoneNumber,
      role: "user",
    };

    const response = await SignUp(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setcreateModal(false);
      setRegisterDetails({});
      setRegisterValidationValue({});
      setotpModal(true);
      dispatch(registerDetails(registerDetail));
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  } else {
    //console.log("Error while register as user");
  }
};

const handleSubmitResend = async (e) => {
  e.preventDefault();
  const Payload = {
    name: state.auth.registerData.name,
    email: state.auth.registerData.email,
    password: state.auth.registerData.password,
    phoneNumber: state.auth.registerData.phoneNumber,
    role: "user",
  };

  const response = await SignUp(Payload);
  if (response && response.statusCode === 200) {
    showToastSuccessMsg(response.message);
    setMinutes(0);
    setSeconds(59);
  } else {
    showToastErrorMsg(
      response.message || response.error.response.data.message
    );
    //setfailureModal(true);
  }
};

const [code, setOtp] = useState("");
const [validation, setValidation] = useState({});
const [minutes, setMinutes] = useState(0);
const [seconds, setSeconds] = useState(0);
useEffect(() => {
  const interval = setInterval(() => {
    if (seconds > 0) {
      setSeconds(seconds - 1);
    }
    if (seconds === 0) {
      if (minutes === 0) {
        clearInterval(interval);
      } else {
        setSeconds(59);
        setMinutes(minutes - 1);
      }
    }
  }, 1000);

  return () => {
    clearInterval(interval);
  };
});
//Otp Submit
const handleChange = (code) => {
  setOtp(code);
  setValidation({});
};

// otp
const handleSubmitOtp = async (data, value) => {
  data.preventDefault();
  let validate = {};
  validate.code = NonEmptyValidation(code);
  setValidation(validate);
  if (Object.values(validate).every((v) => v.status === true)) {
    const Payload = {
      email: state.auth.registerData.email,
      otp: parseInt(code),
    };
    let response = await VerifyOtp(Payload);
    if (response && response.statusCode === 200) {
      setotpModal(false);
      setOtp("");
      setloginModal(true);
      showToastSuccessMsg(response.message);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      // setFailureModal(true);
    }
  } else {
    //console.log("Error while verify otp");
  }
};

const handleSubmitOtpFgt = async (data) => {
  data.preventDefault();
  let validate = {};
  validate.code = NonEmptyValidation(code);
  setValidation(validate);
  if (Object.values(validate).every((v) => v.status === true)) {
    const Payload = {
      email: state.auth.registerData.email,
      otp: parseInt(code),
    };
    let response = await ForgotOtp(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setotpFgtModal(false);
      setchangepswModal(true);
      setOtp("");
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      // setFailureModal(true);
    }
  }
};
const [registerDetail1, setRegisterDetails1] = useState({});

const [registerValidation1, setRegisterValidation1] = useState({});

const setRegisterDetailsValue1 = (key, value) => {
  setRegisterDetails1({ ...registerDetail1, [key]: value });
  if (registerValidation1[key]) delete registerValidation1[key];
};

const setRegisterValidationValue1 = (key, value) => {
  setRegisterValidation1({ ...registerValidation1, [key]: value });
};

//login api call
const navigate = useNavigate();

const handleSubmit1 = async (e) => {
  e.preventDefault();
  let validate = {};
  validate.email = EmailValidation(registerDetail1?.email?.toLowerCase());
  validate.password = PasswordValidation(registerDetail1?.password);
  setRegisterValidation1(validate);
  if (Object.values(validate).every((v) => v.status === true)) {
    const Payload = {
      email: registerDetail1.email?.toLowerCase(),
      password: registerDetail1.password,
    };

    const response = await UserLogin(Payload);
    if (response && response.statusCode === 200) {
      dispatch(loginSuccess(response.data, response?.data?.token));
      // setTimeout(() => {
      handleSubmitService("", response.data, "before");
      // }, 5000);
      setloginModal(false);

      setRegisterDetails1({});
      navigate("/");
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  } else {
    //console.log("Error while login as user");
  }
};

const [registerDetail2, setRegisterDetails2] = useState({});

const [registerValidation2, setRegisterValidation2] = useState({});

const setRegisterDetailsValue2 = (key, value) => {
  setRegisterDetails2({ ...registerDetail2, [key]: value });
  if (registerValidation2[key]) delete registerValidation2[key];
};

const setRegisterValidationValue2 = (key, value) => {
  setRegisterValidation2({ ...registerValidation2, [key]: value });
};

//forgot password api call

const handleSubmitForgotPassword = async (e) => {
  e.preventDefault();
  let validate = {};
  validate.email = EmailValidation(registerDetail2?.email?.toLowerCase());
  setRegisterValidation2(validate);
  if (Object.values(validate).every((v) => v.status === true)) {
    const Payload = {
      email: registerDetail2.email?.toLowerCase(),
    };

    const response = await ForgotPassword(Payload);
    if (response && response.statusCode === 200) {
      dispatch(registerDetails(registerDetail2));
      showToastSuccessMsg(response.message);
      setfpwModal(false);
      setotpFgtModal(true);
      setRegisterDetails2({});
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  } else {
    //console.log("Error while reset password");
  }
};

const [registerDetail3, setRegisterDetails3] = useState({});

const [registerValidation3, setRegisterValidation3] = useState({});

const setRegisterDetailsValue3 = (key, value) => {
  setRegisterDetails3({ ...registerDetail3, [key]: value });
  if (registerValidation3[key]) delete registerValidation3[key];
};

const setRegisterValidationValue3 = (key, value) => {
  setRegisterValidation3({ ...registerValidation3, [key]: value });
};

//forgot password api call

const handleSubmitChangePassword = async (e) => {
  e.preventDefault();
  let validate = {};
  validate.password = PasswordValidation(registerDetail3?.password);
  validate.re_password = RePasswordValidation(
    registerDetail3?.re_password,
    registerDetail3?.password
  );
  setRegisterValidation3(validate);
  if (Object.values(validate).every((v) => v.status === true)) {
    const Payload = {
      email: state.auth.registerData.email,
      password: registerDetail3?.password,
    };

    const response = await ChangePassword(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setchangepswModal(false);
      setloginModal(true);
      setRegisterDetails3({});
      getUserProfile();
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  } else {
    //console.log("Error while chenge password");
  }
};

const [loading, setLoading] = useState(true);
const [packageList, setPackageList] = useState([]);
const getPackageList = async () => {
  setLoading(true);
  const token = state.auth.auth.token;
  const response = await GetPackage(token, "active");
  if (response.statusCode === 200) {
    setPackageList(response.data);
    setLoading(false);
  } else {
    setUserProfile([]);
    setLoading(false);
    //console.log("error while getting user profile info");
  }
};
const handleOrder = async (e,amount,type,type2) => {
  e.preventDefault();

  const Payload = {
   
    price: amount*100,
  };
  // const token = state?.auth?.auth?.token;
  const response = await CreateOrder(Payload);
  if (response && response.statusCode === 200) {
    // showToastSuccessMsg(response.message);
    handleSubmitPayment(e,response?.data?.id,response?.data?.amount,type,type2)
  } else {
    showToastErrorMsg(
      response.message || response.error.response.data.message
    );
  }
};

const handleSubmitPayment = (e,orderId, amount, type,type2) => {
  e.preventDefault();
    var options = {
      key: "rzp_test_BPipIoC6fqmzK2",
      key_secret: "vEMlC3fZ6XTSh4i8kq03FYXj",
      order_id:orderId,
      amount: amount,
      currency: "INR",
      description: `Buy ${type}`,
      handler: function (response) {
        if(response){
          if(type==="package"){
            handlePaymentPackage(e,amount/100,response.razorpay_payment_id,type2,"Paid",response,type)
          }else{
            handlePaymentService(e,amount/100,response.razorpay_payment_id,"Paid",response,type)
          }
 
}
else{
//console.log(response)
  if(type==="package"){
    handlePaymentPackage(e,amount/100," ",type2,"Failed"," ",type)
  }else{
    handlePaymentService(e,amount/100," ","Failed"," ",type)
  }
 
}
      },
      prefill: {
        name: state?.auth?.auth?.name,
        email: state?.auth?.auth?.email,
        contact: userProfile?.phoneNumber,
      },
      notes: {
        address: "Razorpay Corporate office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    var pay = new window.Razorpay(options);
    pay.open();
 
};
let r = window.location.href;
let r1 = r?.split("/");
let cat = localStorage?.getItem("category")?.split("?");

const handlePaymentService = async (
  e,
  amount,
  paymentId,
  paymentStatus,
  paymentResponse,
  paymentType
) => {
  e.preventDefault();

  const Payload = {
    profileId: state.auth.auth._id,
    category: cat[0].substring(cat[0].lastIndexOf("/") + 1),
    subcategory: localStorage?.getItem("subcategory"),
    title: localStorage?.getItem("title"),
    price: amount,
    paymentId: paymentId,
    paymentStatus: paymentStatus,
    paymentResponse: {paymentResponse},
    paymentType: paymentType,
  };
  const token = state?.auth?.auth?.token;
  const response = await CreatePaymentService(Payload, token);
  if (response && response.statusCode === 200) {
    showToastSuccessMsg(response.message);
  } else {
    showToastErrorMsg(
      response.message || response.error.response.data.message
    );
  }
};

const handlePaymentPackage = async (
  e,
  amount,
  paymentId,
  packageId,
  paymentStatus,
  paymentResponse,
  paymentType
) => {
  e.preventDefault();

  const Payload = {
    profileId: state.auth.auth._id,
    packageId:packageId,
    price: amount,
    paymentId: paymentId,
    paymentStatus: paymentStatus,
    paymentResponse: {paymentResponse},
    paymentType: paymentType,
  };
  const token = state?.auth?.auth?.token;
  const response = await CreatePaymentPackage(Payload, token);
  if (response && response.statusCode === 200) {
    showToastSuccessMsg(response.message);
    setBookNow(false)
  } else {
    showToastErrorMsg(
      response.message || response.error.response.data.message
    );
  }
};

const [price, setPrice] = useState([]);
const getPriceList = async () => {
  let name = window.location.href;
  let pagename = name?.split("=");

  // const token = state.auth.auth.token
  const response = await GetPricingWithName(pagename[1]);
  if (response.statusCode === 200) {
    setPrice(response.data);
  } else {
    //console.log("error while getting user profile info");
  }
};

const [solo, setSolo] = useState("solo");

const [serviceReq1, setServiceReq1] = useState({});
  const [form, setForm] = useState(false);

const [serviceReqValidation1, setServiceReqValidation1] = useState({});

const setServiceReqValue1 = (key, value) => {
  setServiceReq1({ ...serviceReq1, [key]: value });
  if (serviceReqValidation1[key]) delete serviceReqValidation1[key];
};

const setServiceReqValidationValue1 = (key, value) => {
  setServiceReqValidation1({ ...serviceReqValidation1, [key]: value });
};


const handleSubmitServiceform = async (e) => {
  e.preventDefault()
    const Payload = {
      name: serviceReq1.name,
      email: serviceReq1.email,
      subject: serviceReq1.subject,
      question: serviceReq1.question,
      mobileNumber: serviceReq1.mobileNumber,
      type: "service",
      servicesCategory: cat[0].substring(cat[0].lastIndexOf("/") + 1),
        servicesSubcategory: localStorage?.getItem("subcategory"),
        servicesTitle: localStorage?.getItem("title"),
     
    };
    // const token = state?.auth?.auth?.token
    //console.log(Payload);
    const response = await UserServiceRequest(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setServiceReq1({})
      setForm(false)
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
};

const [auditContent,setAuditContent] = useState([])
  const GetMyTsks = async () => {
    let id = localStorage.getItem("level2Id")
    const token = state.auth.auth.token;
    let data = await GetContent("active",id);
    if (data && data.statusCode === 200) {
      setAuditContent(data.data);
    } else {
      setAuditContent([]);
      //console.log("Error while getting my task");
    }
  };
  //console.log(auditContent)
  return (
    <div className="overflow-pages">
      <div className="req-call-back-fixed">
      <button
                type="button"
                className="btn login-btn w-100"
                onClick={(e) => {
                  if (state?.auth?.isLoggedIn) {
                    handleSubmitService(e);
                  } else {
                    setForm(true);
                  }
                }}
              >
                Request Call Back
              </button>
      </div>
       
      <ToastContainer />
      <div>
        <div className="about-banner2 pt-5">
          <div className="container">
            {/* <div className="row ">
              <div className="col-lg-8 col-6"></div>
              <div className="col-lg-4 col-6 text-end">
              <button
                type="button"
                className="btn login-btn w-100"
                onClick={(e) => {
                  if (state?.auth?.isLoggedIn) {
                    handleSubmitService(e);
                  } else {
                    setForm(true);
                  }
                }}
              >
                Request Call Back
              </button>
              </div>
           
            </div> */}
            <div className="row">
              {/* <div className="col-lg-6 col-md-6  m-auto" data-aos="fade-left">
                <div className="about-img">
               
                </div>
              </div> */}
            
              <div className="col-lg-3 col-md-8  m-auto text-end" data-aos="fade-left">
              
                  {/* {price?.map((e) =>
                  e.price.map((p) => (
                    <div className="price-details">
                      <h6 className="fw-bold py-3 px-2 border-bottom">
                        Price Details
                      </h6>
                      <div className=" d-flex justify-content-between">
                        <p className="price-details-content">Market Price</p>
                        {solo === "solo" ? (
                          <p className="price-details-content">
                            ₹{p.solo.marketPrice}
                          </p>
                        ) : (
                          <p className="price-details-content">
                            ₹{p.company.marketPrice}
                          </p>
                        )}
                      </div>
                      <div className=" d-flex justify-content-between">
                        <p className="price-details-content">Dalmaf Price</p>
                        {solo === "solo" ? (
                          <p className="price-details-content text-secondary">
                            ₹{p.solo.dalmafPrice}
                          </p>
                        ) : (
                          <p className="price-details-content text-secondary">
                            ₹{p.company.dalmafPrice}
                          </p>
                        )}
                      </div>
                      <div className=" d-flex justify-content-between border-bottom">
                        <p className="price-details-content">GST </p>
                        {solo === "solo" ? (
                          <p className="price-details-content">₹{(p.solo.GST * 3) / 100}</p>
                        ) : (
                          <p className="price-details-content">
                           ₹{(p.company.GST * 3) / 100}
                          </p>
                        )}
                      </div>
                      <div className=" d-flex justify-content-between border-bottom">
                        <p className="price-details-content color-maroon">
                          Total Amount
                        </p>
                        {solo === "solo" ? (
                          <p className="price-details-content color-maroon">
                           ₹{p.solo.dalmafPrice + (p.solo.GST * 3) / 100}
                          </p>
                        ) : (
                          <p className="price-details-content color-maroon">
                           ₹{p.company.dalmafPrice + (p.company.GST * 3) / 100}
                          </p>
                        )}
                      </div>
                      <p className="py-3 px-2 small mb-0">
                        You will save{" "}
                        {solo === "solo" ? (
                        <span className="text-primary">
                          ₹{p.solo.marketPrice - p.solo.dalmafPrice}
                        </span>
                      ) : (
                        <span className="text-primary">
                          ₹{p.company.marketPrice + p.company.dalmafPrice}
                        </span>
                      )}{" "}on this
                        order
                      </p>
                      <div>
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                          style={{borderTopLeftRadius:"6px",
                            borderTopRightRadius: "6px"}}
                            type="button"
                            className="btn login-btn w-auto mb-2 
                            "
                            onClick={(val) => {
                              if (state?.auth?.isLoggedIn === true) {
                                if (solo === "solo") {
                                  handleOrder(
                                    val,
                                    p.solo.dalmafPrice + (p.solo.GST * 3) / 100,
                                    "solo",
                                    ""
                                  );
                                } else {
                                  handleOrder(
                                    val,
                                    p.company.dalmafPrice +
                                      (p.company.GST * 3) / 100,
                                    "company",
                                    ""
                                  );
                                }
                              } else {
                                setloginModal(true);
                                localStorage?.setItem("tab", r1[3]);
                              }
                            }}
                          >
                            Buy Now
                          </button>
                   
                        </div>

                        <button
                          type="button"
                          className="btn login-btn w-100"
                          onClick={(e) => {
                            if (state?.auth?.isLoggedIn) {
                              handleSubmitService(e, "", "after");
                            } else {
                              setloginModal(true);
                            }
                          }}
                        >
                          Request Service
                        </button>
                      </div>
                    </div>
                  ))
                )} */}
              </div>
            </div>
          </div>
        </div>
        <div className=" py-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 m-auto" data-aos="fade-left">
                {auditContent?.map((item, index) => {
                  return (
                    <div key={index} className="about-content my-5">
                      {/* {item.mainServiceId.title ? (
                        <h3 className="banner-heading after-line mb-3">
                          {item.mainServiceId.title}
                        </h3>
                      ) : null} */}
                      {item.subServiceL2Id.title && (
                        <p className="fw-bold">{item.subServiceL2Id.title}</p>
                      )}
                      {item?.content ? (
                        <p dangerouslySetInnerHTML={{
                          __html: item?.content,
                        }}></p>
                ) : null}
                      {/* {item?.points?.map((point, ind) => {
                        return <p key={ind}>{point}</p>;
                      })} */}
                      {/* {item?.footer && <p>{item.footer}</p>}
                      {item?.table && item.table} */}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="client-left-section py-5">
        <div className="container my-4">
          <h2 className="banner-heading after-line mb-5">FEEL FREE TO ASK</h2>
          <div className="row">
            <div className="col-lg-6 " data-aos="fade-left">
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.823571029211!2d80.23700327559305!3d13.046899587275508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267c25ec3b519%3A0xb10841584ccc73c4!2sDALMAF%20Private%20Limited!5e0!3m2!1sen!2sin!4v1688118977225!5m2!1sen!2sin"
                  width="100%"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-left">
              {/* {state?.auth?.isLoggedIn ? (<button type="button" className="btn login-btn w-100"
                  onClick={(e)=>{
                    handleSubmit(e)
                  }}
                  >
                  Request Callback
                </button>) : ( */}
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Name"
                      aria-invalid={Boolean(
                        legalcontValidation?.name?.status === false
                      )}
                      value={legalcont?.name || ""}
                      onChange={(e) => {
                        setLegalcontValue("name", e.target.value);
                      }}
                      onBlur={(e) => {
                        setLegalcontValidationValue(
                          "name",
                          StringValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {legalcontValidation?.name?.message
                        ? `Name ${legalcontValidation?.name?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Mobile No <span className="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      maxLength={10}
                      className="form-control"
                      value={legalcont?.mobileNumber || "" }
                      placeholder="Enter Your Mobile No"
                      aria-invalid={Boolean(
                        legalcontValidation?.mobileNumber?.status === false
                      )}
                      onChange={(e) => {
                        setLegalcontValue("mobileNumber", e.target.value);
                      }}
                      onBlur={(e) => {
                        setLegalcontValidationValue(
                          "mobileNumber",
                          PhoneNumberValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {legalcontValidation?.mobileNumber?.message
                        ? `Mobile no ${legalcontValidation?.mobileNumber?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      E- Mail ID <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your E- Mail ID"
                      aria-invalid={Boolean(
                        legalcontValidation?.emailId?.status === false
                      )}
                      value={legalcont?.emailId?.toLowerCase() || ""}
                      onChange={(e) => {
                        setLegalcontValue(
                          "emailId",
                          e.target.value?.toLowerCase()
                        );
                      }}
                      onBlur={(e) => {
                        setLegalcontValidationValue(
                          "emailId",
                          EmailValidation(e.target.value)?.toLowerCase()
                        );
                      }}
                    />
                    <small className="text-danger">
                      {legalcontValidation?.emailId?.message
                        ? `Email ${legalcontValidation?.emailId?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Questions <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Questions "
                      aria-invalid={Boolean(
                        legalcontValidation?.question?.status === false
                      )}
                      onChange={(e) => {
                        setLegalcontValue("question", e.target.value);
                      }}
                      value={legalcont?.question|| ""}

                      onBlur={(e) => {
                        setLegalcontValidationValue(
                          "question",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {legalcontValidation?.question?.message
                        ? `Question ${legalcontValidation?.question?.message}`
                        : ""}
                    </small>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Service Type 
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <select
                      className="form-control form-control-lg"
                      value={legalcont?.servicesType || "" }
                      onChange={(e) => {
                        setLegalcontValue("servicesType", e.target.value);
                      }}
                      onBlur={(e) => {
                        setLegalcontValidationValue(
                          "servicesType",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    >
                      <option value="">---Select Service Type---</option>
                      <option value="Legal">Legal</option>
                      <option value="Audit">Audit</option>
                      <option value="Manpower">Manpower</option>
                      <option value="Auction">Auction</option>
                      <option value="Buy/Sell">Buy/Sell</option>
                    </select>
                    {/* <small className="text-danger">
                      {legalcontValidation?.servicesType?.message
                        ? `Service Type ${legalcontValidation?.servicesType?.message}`
                        : ""}
                    </small> */}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Subject <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows="6"
                      className="form-control"
                      placeholder="Enter Subject"
                      aria-invalid={Boolean(
                        legalcontValidation?.subject?.status === false
                      )}
                      value={legalcont?.subject || "" }
                      
                      onChange={(e) => {
                        setLegalcontValue("subject", e.target.value);
                      }}
                      onBlur={(e) => {
                        setLegalcontValidationValue(
                          "subject",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    ></textarea>
                    <small className="text-danger">
                      {legalcontValidation?.subject?.message
                        ? `Subject ${legalcontValidation?.subject?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  
                  <p>
                    {/* <span className="material-symbols-rounded filled-icon tick-icon me-2 align-middle">
                      check_box
                    </span>
                    <span className="small">
                      I am interested in talking to a lawyer for my legal issue
                    </span> */}
                  </p>

                  <button
                    type="button"
                    className="btn login-btn w-100"
                    onClick={(e) => {
                      handleSubmitForm(e);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
      <div className="number-counter py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <span className="material-symbols-rounded align-middle about-call filled-icon">
                        call
                      </span>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">Toll Free</h5>
                    <h5 className="mb-0 fs-4">1800-309-8445</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <span className="material-symbols-rounded align-middle about-call filled-icon">
                        mail
                      </span>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">Email ID</h5>
                    <h5 className="mb-0 fs-4">info@dalmaf.com</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos="fade-in">
              <div className="counter-content text-start">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h1 className="mb-0">
                      <i className="fa-brands fa-whatsapp about-whatsapp-icon"></i>
                    </h1>
                  </div>
                  <div className="">
                    <h5 className="fs-6 mb-0">WhatsApp Us</h5>
                    <h5 className="mb-0 fs-4">+91 96000 38059</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div >
         <ServicesAllpages/>
      </div>
   
      <CustomModal
        open={loginModal}
        onClickOutside={() => {
          setloginModal(false);
          setRegisterValidation1({});
          setcreateModal(false);
          setfpwModal(false);
          setotpModal(false);
          setchangepswModal(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container ">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setloginModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-10">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-2">Login</h4>
                            </div>
                            <div className="mb-3">
                              <label for="" className="form-label">
                                Email
                              </label>

                              <input
                                type="email"
                                className="form-control"
                                placeholder="example@mail.com"
                                required
                                aria-invalid={Boolean(
                                  registerValidation1?.email?.status === false
                                )}
                                defaultValue={
                                  registerDetail1?.email?.toLowerCase() || ""
                                }
                                onChange={(e) => {
                                  setRegisterDetailsValue1(
                                    "email",
                                    e.target.value?.toLowerCase()
                                  );
                                }}
                                onBlur={(e) => {
                                  setRegisterValidationValue1(
                                    "email",
                                    EmailValidation(
                                      e.target.value?.toLowerCase()
                                    )
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {registerValidation1?.email?.message
                                  ? `Email ${registerValidation1?.email?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3 psw-icon-section">
                              <label for="" className="form-label">
                                Password
                              </label>
                              <input
                                type={password ? "text" : "password"}
                                className="form-control"
                                required
                                placeholder="******"
                                aria-invalid={Boolean(
                                  registerValidation1?.password?.status ===
                                    false
                                )}
                                defaultValue={registerDetail1?.password || ""}
                                onChange={(e) => {
                                  setRegisterDetailsValue1(
                                    "password",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setRegisterValidationValue1(
                                    "password",
                                    PasswordValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {registerValidation1?.password?.message
                                  ? `Password ${registerValidation1?.password?.message}`
                                  : ""}
                              </small>
                              <div className="psw-icon">
                                {password ? (
                                  <span
                                    className="material-symbols-rounded cursor align-middle"
                                    onClick={() => viewPassword()}
                                  >
                                    visibility
                                  </span>
                                ) : (
                                  <span
                                    className="material-symbols-rounded cursor align-middle"
                                    onClick={() => viewPassword()}
                                  >
                                    visibility_off
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => handleSubmit1(e)}
                              >
                                Login
                              </button>
                            </div>
                            <div className="mb-3 ">
                              <p className="text-center mb-1">
                                <span className="text-secondary">
                                  Don’t have account? ?{" "}
                                </span>
                                <span
                                  className="mx-2 cursor text-decoration-underline"
                                  onClick={() => {
                                    setloginModal(false);
                                    setcreateModal(true);
                                    setRegisterDetails1({});
                                    setRegisterValidation1({});
                                  }}
                                >
                                  Sign Up
                                </span>{" "}
                              </p>
                              <p className="text-center">
                                <span className="text-secondary">
                                  Forgot your password?
                                </span>
                                <span
                                  className="mx-2 cursor text-decoration-underline"
                                  onClick={() => {
                                    setloginModal(false);
                                    setfpwModal(true);
                                    setRegisterDetails1({});
                                    setRegisterValidation1({});
                                  }}
                                >
                                  Reset
                                </span>{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={createModal}
        onClickOutside={() => {
          setcreateModal(false);
          setRegisterValidation({});
          setfpwModal(false);
          setotpModal(false);
          setloginModal(false);
          setchangepswModal(false);
        }}
      >
        <div className="modal-content w-50 h-50  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setcreateModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          {/* <form> */}
                          <div className="col-lg-10">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-2">
                                Create an account
                              </h4>
                            </div>
                            <div className="mb-3">
                              <label for="" className="form-label">
                                Name
                              </label>
                              <input
                                // id="name"
                                // name="name"
                                type="text"
                                className="form-control"
                                placeholder="Enter your Name"
                                required
                                aria-invalid={Boolean(
                                  registerValidation?.name?.status === false
                                )}
                                defaultValue={registerDetail?.name || ""}
                                onChange={(e) => {
                                  setRegisterDetailsValue(
                                    "name",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setRegisterValidationValue(
                                    "name",
                                    StringValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {registerValidation?.name?.message
                                  ? `Name ${registerValidation?.name?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3">
                              <label for="" className="form-label">
                                Email
                              </label>
                              <input
                                // id="email"
                                type="email"
                                className="form-control"
                                placeholder="example@mail.com"
                                required
                                aria-invalid={Boolean(
                                  registerValidation?.email?.status === false
                                )}
                                defaultValue={
                                  registerDetail?.email?.toLowerCase() || ""
                                }
                                onChange={(e) => {
                                  setRegisterDetailsValue(
                                    "email",
                                    e.target.value?.toLowerCase()
                                  );
                                }}
                                onBlur={(e) => {
                                  setRegisterValidationValue(
                                    "email",
                                    EmailValidation(
                                      e.target.value?.toLowerCase()
                                    )
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {registerValidation?.email?.message
                                  ? `Email ${registerValidation?.email?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3">
                              <label for="" className="form-label">
                                Phone No
                              </label>
                              <input
                                // id="phoneNumber"
                                type="tel"
                                className="form-control"
                                maxLength={10}
                                placeholder="Phone Number"
                                required
                                aria-invalid={Boolean(
                                  registerValidation?.phoneNumber?.status ===
                                    false
                                )}
                                defaultValue={registerDetail?.phoneNumber || ""}
                                onChange={(e) => {
                                  setRegisterDetailsValue(
                                    "phoneNumber",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setRegisterValidationValue(
                                    "phoneNumber",
                                    PhoneNumberValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {registerValidation?.phoneNumber?.message
                                  ? `Phone no ${registerValidation?.phoneNumber?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3 psw-icon-section">
                              <label for="" className="form-label">
                                Password
                              </label>
                              <input
                                // id="password"
                                type={password ? "text" : "password"}
                                className="form-control"
                                required
                                placeholder="*********"
                                aria-invalid={Boolean(
                                  registerValidation?.password?.status === false
                                )}
                                defaultValue={registerDetail?.password || ""}
                                onChange={(e) => {
                                  setRegisterDetailsValue(
                                    "password",
                                    e.target.value
                                  );
                                  setPasswordStrength(e.target.value);
                                }}
                                onBlur={(e) => {
                                  setRegisterValidationValue(
                                    "password",
                                    PasswordValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {registerValidation?.password?.message
                                  ? `Password ${registerValidation?.password?.message}`
                                  : ""}
                              </small>
                              <div className="psw-icon">
                                {password ? (
                                  <span
                                    className="material-symbols-rounded cursor align-middle"
                                    onClick={() => viewPassword()}
                                  >
                                    visibility
                                  </span>
                                ) : (
                                  <span
                                    className="material-symbols-rounded cursor align-middle"
                                    onClick={() => viewPassword()}
                                  >
                                    visibility_off
                                  </span>
                                )}
                              </div>
                            </div>
                            {passwordStrength ? (
                              <div className="mt-3">
                                <b>Create a password with the following</b>
                                <br />
                                {
                                  <p
                                    className={`${
                                      passwordStrength.trim().length < 8
                                        ? "text-danger"
                                        : "text-success"
                                    } d-flex`}
                                  >
                                    {passwordStrength.trim().length < 8 ? (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        close
                                      </span>
                                    ) : (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        check
                                      </span>
                                    )}
                                    At least 8 Characters
                                  </p>
                                }
                                {
                                  <p
                                    className={`${
                                      !/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                        passwordStrength
                                      )
                                        ? "text-danger"
                                        : "text-success"
                                    } d-flex`}
                                  >
                                    {!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                      passwordStrength
                                    ) ? (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        close
                                      </span>
                                    ) : (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        check
                                      </span>
                                    )}
                                    An Uppercase &amp; Lowercase character
                                  </p>
                                }
                                {
                                  <p
                                    className={`${
                                      !/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                                        passwordStrength
                                      )
                                        ? "text-danger"
                                        : "text-success"
                                    } d-flex`}
                                  >
                                    {!/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                                      passwordStrength
                                    ) ? (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        close
                                      </span>
                                    ) : (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        check
                                      </span>
                                    )}
                                    A special character
                                  </p>
                                }
                                {
                                  <p
                                    className={`${
                                      !/[0-9]/.test(passwordStrength)
                                        ? "text-danger"
                                        : "text-success"
                                    } d-flex`}
                                  >
                                    {!/[0-9]/.test(passwordStrength) ? (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        close
                                      </span>
                                    ) : (
                                      <span className="material-symbols-rounded filled-icon close-icon">
                                        check
                                      </span>
                                    )}
                                    A number
                                  </p>
                                }
                              </div>
                            ) : null}
                            <div className="mb-3">
                              <button
                                // type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => handleSubmit(e)}
                              >
                                Create account
                              </button>
                            </div>
                            <div className="mb-3 ">
                              <p className="text-center mb-1">
                                <span className="text-secondary">
                                  Already have an account ?
                                </span>
                                <span
                                  className="mx-2 cursor text-decoration-underline"
                                  onClick={() => {
                                    setcreateModal(false);
                                    setloginModal(true);
                                    setRegisterDetails({});
                                    setRegisterValidation({});
                                  }}
                                >
                                  Log in
                                </span>{" "}
                              </p>
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={fpwModal}
        onClickOutside={() => {
          setfpwModal(false);
          setRegisterValidation2({});
          setcreateModal(false);
          setotpModal(false);
          setloginModal(false);
          setchangepswModal(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setfpwModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-10">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-2">Forgot Password</h4>
                            </div>
                            <div className="mb-3">
                              <label for="email" className="form-label">
                                Email
                              </label>
                              <input
                                id="email"
                                type="email"
                                className="form-control"
                                placeholder="example@mail.com"
                                required
                                aria-invalid={Boolean(
                                  registerValidation2?.email?.status === false
                                )}
                                defaultValue={
                                  registerDetail2?.email?.toLowerCase() || ""
                                }
                                onChange={(e) => {
                                  setRegisterDetailsValue2(
                                    "email",
                                    e.target.value?.toLowerCase()
                                  );
                                }}
                                onBlur={(e) => {
                                  setRegisterValidationValue2(
                                    "email",
                                    EmailValidation(
                                      e.target.value?.toLowerCase()
                                    )
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {registerValidation2?.email?.message
                                  ? `Email ${registerValidation2?.email?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => {
                                  handleSubmitForgotPassword(e);
                                }}
                              >
                                Submit
                              </button>
                            </div>
                            <div className="mb-3 ">
                              <p className="text-center mb-1">
                                <span className="text-secondary">
                                  Already have an account ?
                                </span>
                                <span
                                  className="mx-2 cursor text-decoration-underline"
                                  onClick={() => {
                                    setfpwModal(false);
                                    setloginModal(true);
                                    setRegisterDetails2({});
                                    setRegisterValidation2({});
                                  }}
                                >
                                  Log in
                                </span>{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={otpModal}
        onClickOutside={() => {
          setotpModal(false);
          setValidation({});
          setcreateModal(false);
          setfpwModal(false);
          setloginModal(false);
          setchangepswModal(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setotpModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-10">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-3">
                                Verify your email ID
                              </h4>
                              <small>Enter the code we just sent to</small>
                            </div>
                            <div className="mb-4 psw-icon-section">
                              <OtpInput
                                className="otpInput"
                                value={code}
                                onChange={handleChange}
                                numInputs={6}
                                separator={
                                  <span style={{ width: "8px" }}></span>
                                }
                                isInputNum={true}
                                shouldAutoFocus={true}
                                inputStyle={{
                                  border: "1px solid #CFD3DB",
                                  borderRadius: "8px",
                                  width: "45px",
                                  height: "45px",
                                  fontSize: "12px",
                                  color: "#000",
                                  fontWeight: "400",
                                  caretColor: "blue",
                                }}
                                focusStyle={{
                                  border: "1px solid black ",
                                  outline: "none",
                                }}
                              />
                            </div>
                            <small className="text-danger">
                              {validation?.code?.message
                                ? `OTP ${validation?.code?.message}`
                                : ""}
                            </small>
                            {seconds > 0 || minutes > 0 ? (
                              <div className="top-12">
                                Resend OTP in&nbsp;
                                <span className="text-green">
                                  {minutes < 10 ? `0${minutes}` : minutes}:
                                  {seconds < 10 ? `0${seconds}` : seconds}
                                </span>
                              </div>
                            ) : (
                              <div className="top-12">
                                Didn't receive code?&nbsp;
                                <span
                                  className="fgtpwd"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => handleSubmitResend(e)}
                                >
                                  Resend
                                </span>
                              </div>
                            )}
                            {/* <div className="mb-3 ">
                              <p
                                className="text-decoration-underline  text-pink"
                                style={{ cursor: "pointer" }}
                              >
                                Resend
                              </p>
                            </div> */}
                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => {
                                  if (
                                    localStorage.getItem(
                                      "accountcreation" === true
                                    )
                                  ) {
                                    handleSubmitOtp(e, "accountcreation");
                                  } else {
                                    handleSubmitOtp(e, "forgot");
                                  }

                                  // setchangepswModal(true);
                                }}
                              >
                                Verify
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={otpFgtModal}
        onClickOutside={() => {
          setotpFgtModal(false);
          setValidation({});
          setcreateModal(false);
          setfpwModal(false);
          setloginModal(false);
          setchangepswModal(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setotpFgtModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-10">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-3">
                                Verify your email ID
                              </h4>
                              <small>Enter the code we just sent to</small>
                            </div>
                            <div className="mb-4 psw-icon-section">
                              <OtpInput
                                className="otpInput"
                                value={code}
                                onChange={handleChange}
                                numInputs={6}
                                separator={
                                  <span style={{ width: "8px" }}></span>
                                }
                                isInputNum={true}
                                shouldAutoFocus={true}
                                inputStyle={{
                                  border: "1px solid #CFD3DB",
                                  borderRadius: "8px",
                                  width: "45px",
                                  height: "45px",
                                  fontSize: "12px",
                                  color: "#000",
                                  fontWeight: "400",
                                  caretColor: "blue",
                                }}
                                focusStyle={{
                                  border: "1px solid black ",
                                  outline: "none",
                                }}
                              />
                            </div>
                            <small className="text-danger">
                              {validation?.code?.message
                                ? `OTP ${validation?.code?.message}`
                                : ""}
                            </small>
                            {seconds > 0 || minutes > 0 ? (
                              <div className="top-12">
                                Resend OTP in&nbsp;
                                <span className="text-green">
                                  {minutes < 10 ? `0${minutes}` : minutes}:
                                  {seconds < 10 ? `0${seconds}` : seconds}
                                </span>
                              </div>
                            ) : (
                              <div className="top-12">
                                Didn't receive code?&nbsp;
                                <span
                                  className="fgtpwd"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => handleSubmitResend(e)}
                                >
                                  Resend
                                </span>
                              </div>
                            )}
                            {/* <div className="mb-3 ">
                              <p
                                className="text-decoration-underline  text-pink"
                                style={{ cursor: "pointer" }}
                              >
                                Resend
                              </p>
                            </div> */}
                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => {
                                  handleSubmitOtpFgt(e);
                                }}
                              >
                                Verify
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={changepswModal}
        onClickOutside={() => {
          setchangepswModal(false);
          setRegisterValidation3({});
          setcreateModal(false);
          setfpwModal(false);
          setotpModal(false);
          setloginModal(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setchangepswModal(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="col-lg-10">
                            <div className="mb-5">
                              <h4 className="fw-bold mt-3">Change Password</h4>
                            </div>
                            <div className="mb-3 ">
                              <label for="password" className="form-label">
                                New Password
                              </label>
                              <input
                                id="password"
                                type="password"
                                className="form-control"
                                placeholder="*********"
                                required
                                aria-invalid={Boolean(
                                  registerValidation3?.password?.status ===
                                    false
                                )}
                                onChange={(e) => {
                                  setRegisterDetailsValue3(
                                    "password",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setRegisterValidationValue3(
                                    "password",
                                    PasswordValidation(e.target.value)
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {registerValidation3?.password?.message
                                  ? `Password ${registerValidation3?.password?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3 ">
                              <label for="" className="form-label">
                                Re-Enter Password
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder="example@mail.com"
                                required
                                aria-invalid={Boolean(
                                  registerValidation3?.re_password?.status ===
                                    false
                                )}
                                onChange={(e) => {
                                  setRegisterDetailsValue3(
                                    "re_password",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setRegisterValidationValue3(
                                    "re_password",
                                    RePasswordValidation(
                                      e.target.value,
                                      registerDetail3?.password
                                    )
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {registerValidation3?.re_password?.message
                                  ? `Re-Password ${registerValidation3?.re_password?.message}`
                                  : ""}
                              </small>
                            </div>
                            <div className="mb-3">
                              <button
                                type="button"
                                className="btn  w-100 my-2  login-btn"
                                onClick={(e) => {
                                  handleSubmitChangePassword(e);
                                }}
                              >
                                Change
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={form}
        onClickOutside={() => {
          setForm(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container ">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setForm(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container px-4">
                  <div className="row justify-content-center reg-page-scroll">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                       <div className="mb-2">
                       <b>Please enter your information below, <br/>
                        Our staff will contact you as soon as possible.</b>
                       </div>
                       <hr/>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Name"
                      aria-invalid={Boolean(
                        serviceReqValidation1?.name?.status === false
                      )}
                      defaultValue={serviceReq1?.name}
                      onChange={(e) => {
                        setServiceReqValue1("name", e.target.value);
                      }}
                      onBlur={(e) => {
                        setServiceReqValidationValue1(
                          "name",
                          StringValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {serviceReqValidation1?.name?.message
                        ? `Name ${serviceReqValidation1?.name?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Mobile No <span className="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      maxLength={10}
                      className="form-control"
                      defaultValue={serviceReq1?.mobileNumber}
                      placeholder="Enter Your Mobile No"
                      aria-invalid={Boolean(
                        serviceReqValidation1?.mobileNumber?.status === false
                      )}
                      onChange={(e) => {
                        setServiceReqValue1("mobileNumber", e.target.value);
                      }}
                      onBlur={(e) => {
                        setServiceReqValidationValue1(
                          "mobileNumber",
                          PhoneNumberValidation(e.target.value)
                        );
                      }}
                    />
                    <small className="text-danger">
                      {serviceReqValidation1?.mobileNumber?.message
                        ? `Mobile no ${serviceReqValidation1?.mobileNumber?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      E- Mail ID <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your E- Mail ID"
                      aria-invalid={Boolean(
                        serviceReqValidation1?.email?.status === false
                      )}
                      defaultValue={serviceReq1?.email?.toLowerCase() || ""}
                      onChange={(e) => {
                        setServiceReqValue1(
                          "email",
                          e.target.value?.toLowerCase()
                        );
                      }}
                      onBlur={(e) => {
                        setServiceReqValidationValue1(
                          "emailId",
                          EmailValidation(e.target.value)?.toLowerCase()
                        );
                      }}
                    />
                    <small className="text-danger">
                      {serviceReqValidation1?.email?.message
                        ? `Email ${serviceReqValidation1?.email?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="mb-4">
                    <label className="form-label">
                      Questions 
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Questions "
                      aria-invalid={Boolean(
                        serviceReqValidation1?.question?.status === false
                      )}
                      onChange={(e) => {
                        setServiceReqValue1("question", e.target.value);
                      }}
                     
                    />
                   
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Service Type <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control form-control-lg"
                      value={serviceReq1?.servicesType}
                      onChange={(e) => {
                        setServiceReqValue1("servicesType", e.target.value);
                      }}
                      onBlur={(e) => {
                        setServiceReqValidationValue1(
                          "servicesType",
                          NonEmptyValidation(e.target.value)
                        );
                      }}
                    >
                      <option value="">---Select Service Type---</option>
                      <option value="Legal">Legal</option>
                      <option value="Audit">Audit</option>
                      <option value="Manpower">Manpower</option>
                      <option value="Auction">Auction</option>
                      <option value="Buy/Sell">Buy/Sell</option>
                    </select>
                    <small className="text-danger">
                      {serviceReqValidation1?.servicesType?.message
                        ? `Service Type ${serviceReqValidation1?.servicesType?.message}`
                        : ""}
                    </small>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-4">
                    <label className="form-label">
                      Subject 
                    </label>
                    <textarea
                      rows="6"
                      className="form-control"
                      placeholder="Enter Subject"
                      aria-invalid={Boolean(
                        serviceReqValidation1?.subject?.status === false
                      )}
                      onChange={(e) => {
                        setServiceReqValue1("subject", e.target.value);
                      }}
                     
                    ></textarea>
                    
                  </div>
                </div>
                <div className="col-lg-12">
                  

                  <button
                    type="button"
                    className="btn login-btn w-100"
                    onClick={(e) => {
                      handleSubmitServiceform(e);
                    }}
                  >
                    Submit
                  </button>
                </div>
              
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );

};