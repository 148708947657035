import React, { useState } from 'react';
import { CreateNewsLetter } from '../../../Api/Api';
import { showToastErrorMsg, showToastSuccessMsg } from '../../../Store/toastMsg';

export default function Newsletter() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name.trim() || !email.trim()) {
            setError('Name and Email are required.');
            return;
        }

        // Make API call here with the form data
        const Payload = {
            emailId: email.toLowerCase(),
            fullName: name,
        };
        const response = await CreateNewsLetter(Payload);
        if (response && response.statusCode === 200) {
            showToastSuccessMsg(response.message);
            setName('');
            setEmail('');
            setError('');
        } else {
            showToastErrorMsg(
                response.message || response.error.response.data.message
            );
        }
    };

    return (
        <section className="pt-12 pb-12 bg-white" style={{ backgroundImage: "linear-gradient(to right, rgba(255, 0, 0, 1), rgba(255, 100, 100, 1))", marginTop: "-50px" }}>
            <div className="container">
                <div className="row mt-5 align-items-center">
                    <div className="col-md-12">
                        <div className="p-5 rounded " style={{ opacity: 0.9 }}>
                            <h2 className="mb-3 text-white text-center">Subscribe to our Newsletter</h2>
                            <p className="lead text-center text-white">
                                Stay up to date with the latest legal updates enter your email and hit the subscribe button.
                            </p>
                            <form onSubmit={handleSubmit} className="text-center">
                                <div className="row justify-content-center">
                                    <div className="col-md-3 mb-3">
                                        <input
                                            type="text"
                                            className="form-control rounded-pill"
                                            placeholder="Your Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <input
                                            type="email"
                                            className="form-control rounded-pill"
                                            placeholder="Your Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                                {error && <div className="text-danger mb-3">{error}</div>}
                                <button
                                    type="submit"
                                    className="btn rounded-pill"
                                    style={{ border: '2px solid white'}}
                                >
                                    Subscribe
                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
