import React, { useEffect } from 'react';
import Footer from "../Components/Footer/Footer";
import Navigation from "../Components/Navbar/Navigation";
import WheelerBanner from "../Components/Property/WheelerBanner";
import FourWheelerContent from "../Components/Property/FourWheelerContent";
export default function FourWheeler() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="innerpages-nav">
       <div id="container">
      <Navigation />
      <WheelerBanner />
      <FourWheelerContent />
      <Footer />
      </div>
    </div>
  );
}
