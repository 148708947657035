import React, { useEffect, useRef, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GetUser,
  UserPasswordUpdate,
  UserProfileUpdate,
} from "../../../Api/Api";
import { useSelector } from "react-redux";
import {
  EmailValidation,
  NonEmptyValidation,
  PasswordValidation,
  PhoneNumberValidation,
  StringValidation,
  lastNameValidation,
} from "../../../Store/validate";
import { GetInitials } from "../../../Store/Constants";
import CropEasy from "../Crop/CropEasy";
import Swal from "sweetalert2";
export default function Profile() {
  const CeoRef = useRef();
  const [openCrop, setOpenCrop] = useState(false);
  const [profileDetail, setProfileDetails] = useState({});

  const [profileValidation, setProfileValidation] = useState({});

  const setProfileDetailsValue = (key, value) => {
    setProfileDetails({ ...profileDetail, [key]: value });
    if (profileValidation[key]) delete profileValidation[key];
  };

  const setProfileValidationValue = (key, value) => {
    setProfileValidation({ ...profileValidation, [key]: value });
  };

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };
  const state = useSelector((state) => state);

  async function fetchData() {
    const id = state?.auth?.auth?._id;
    const token = state?.auth?.auth?.token;
    const getBasicDetails = await GetUser(token, id);
    if (getBasicDetails && getBasicDetails?.statusCode === 200) {
      setProfileDetails(getBasicDetails.data);
    } else {
      //console.log("error while getting user profile info");
    }
  }

  useEffect(() => {
    window.scroll(0, 0);

    fetchData();
  }, []);

  const [photoURL, setPhotoURL] = useState(profileDetail?.profileImage);

  // const uploadCeoPic = async (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     let formData = new FormData();
  //   formData.append("file", e.target.files[0]);
  //   formData.append("fileName", e.target.files[0].name);
  //   formData.append("path", "Image/");

  //   let response = await UploadDocument(formData);
  //   if (response && response.statusCode === 200) {
  //     setProfileDetailsValue("profileImage",response.data)
  //     showToastSuccessMsg(response.message);
  //   } else {
  //     showToastErrorMsg(
  //       response.message || response.error.response.data.message
  //     );
  //   }
  // }
  // };

  //   // Profile update api call
  const [passwordStrength, setPasswordStrength] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.name = StringValidation(profileDetail?.name);
    validate.lastName = lastNameValidation(profileDetail?.lastName);
    validate.phoneNumber = PhoneNumberValidation(profileDetail?.phoneNumber);
    setProfileValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: profileDetail.email?.toLowerCase(),
        name: profileDetail.name,
        lastName: profileDetail.lastName,
        phoneNumber: Number(profileDetail.phoneNumber),
        _id: state?.auth?.auth?._id,
        role: state?.auth?.auth?.role,
      };
      const token = state.auth.auth.token;
      const response = await UserProfileUpdate(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    } else {
      //console.log("Error while update user details");
    }
  };
  const [fileName, setFileName] = useState("");
  const [field, setField] = useState("");
  const [file, setFile] = useState("");

  const uploadCeoPic = async (e, value) => {
    const file = e.target.files[0];
    if (file) {
      setField(value);
      setFile(file);
      setPhotoURL(URL.createObjectURL(file));

      setFileName(e.target.files[0].name);
      setOpenCrop(true);
    }
    // if (file) {
    //   let formData = new FormData();
    //   formData.append("file", e.target.files[0]);
    //   formData.append("fileName", e.target.files[0].name);
    //   formData.append("path", "Image/");

    //   let response = await UploadDocument(formData);
    //   if (response && response.statusCode === 200) {

    //     setPhotoURL(response.data);
    //     handleProfileImage(response.data);
    //   } else {
    //     showToastErrorMsg(
    //       response.message || response.error.response.data.message
    //     );
    //   }
    // }
  };

  const handleProfileImage = async (name) => {
    const token = state?.auth?.auth?.token;

    let Payload = {
      _id: profileDetail._id,
      profileImage: name,
      role: state?.auth?.auth?.role,
      email: profileDetail.email?.toLowerCase(),
      name: profileDetail.name,
      lastName: profileDetail.lastName,
      phoneNumber: profileDetail.phoneNumber,
    };

    const response = await UserProfileUpdate(Payload, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };
  const [password, setPassword] = useState();
  const viewPassword = () => {
    setPassword(!password);
  };
  const [password1, setPassword1] = useState();
  const viewPassword1 = () => {
    setPassword1(!password1);
  };
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordValidation, setOldPasswordValidation] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordValidation, setNewPasswordValidation] = useState("");

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    let validate = {}
    let validate1 = {}
    validate.oldPassword = PasswordValidation(oldPassword)
    validate1.newPassword = PasswordValidation(newPassword,oldPassword)
    setOldPasswordValidation(validate)
    setNewPasswordValidation(validate1)
    if(oldPasswordValidation?.oldPassword.status===true && newPasswordValidation?.newPassword.status===true){

      let Payload = {
        // _id: profileDetail._id,
        email: state?.auth?.auth?.email,
        password: oldPassword,
        updatePassword: newPassword,
      };
      const token = state?.auth?.auth?.token;
      const response = await UserPasswordUpdate(Payload, token);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setOldPassword("");
        setNewPassword("");
        setTimeout(() => {
          window.location.reload()
        }, 2000);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
      }
    }else{
      //console.log("Error while updating password")
    }
   
  };

  //  const handleImageClick = () => {

  //        const imageUrl =`${photoURL ? photoURL : profileDetail?.profileImage}`;
  // const userimage = `${profileDetail?.name}`;
  //        Swal.fire({
  //          title: userimage,

  //          imageUrl: imageUrl,
  //          imageWidth: 500,
  //          imageHeight: 600,
  //          imageAlt: "YOUR PROFILE",
  //        });

  //  };

  return (
    <div>
      <ToastContainer />
      <AdminNavbar />
      <div className="main">
        <div className="">
          <h5 className="mb-0 fw-bold color-maroon">Profile Management</h5>
        </div>
        {!openCrop ? (
          <div className="container py-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="pro-management">
                  <div className="profile-head">
                    <div className="profile-head1"></div>
                    <div className="d-flex profile-head2  align-items-center">
                      <div className="flex-shrink-0">
                        {photoURL || profileDetail.profileImage ? (
                          <img
                            style={{ width: "100px", height: "100px" }}
                            className="admin-pro-img"
                            src={
                              photoURL ? photoURL : profileDetail.profileImage
                            }
                            alt="..."
                          />
                        ) : (
                          <h1
                            style={{
                              width: "100px",
                              height: "100px",
                              textAlign: "center",
                            }}
                            className="admin-pro-img"
                          >
                            {" "}
                            {GetInitials(profileDetail?.name)}
                          </h1>
                        )}

                        <div className="admin-edit-icon">
                          <span
                            className="material-symbols-rounded filled-icon"
                            onClick={() => CeoRef.current.click()}
                          >
                            border_color
                            <input
                              autoComplete="off"
                              type="file"
                              name="myfile"
                              ref={CeoRef}
                              hidden
                              onChange={(e) => {
                                uploadCeoPic(e, "profileImage");
                              }}
                            />
                          </span>
                        </div>
                      </div>

                      <div className="flex-grow-1 ms-3">
                        <h6>
                          {profileDetail?.name} {profileDetail?.lastName}
                        </h6>
                        <p className="mb-0">
                          Please update your photo and personal details
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="pro-body">
                    <div className="container">
                      <div className="row justify-content-center py-5">
                        <div className="col-lg-10">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">
                                  First Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-lg"
                                  placeholder="Enter Your First Name"
                                  aria-invalid={Boolean(
                                    profileValidation?.name?.status === false
                                  )}
                                  value={profileDetail?.name}
                                  onChange={(e) => {
                                    setProfileDetailsValue(
                                      "name",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setProfileValidationValue(
                                      "name",
                                      StringValidation(e.target.value)
                                    );
                                  }}
                                  autoComplete="off"
                                />
                                <small className="text-danger">
                                  {profileValidation?.name?.message
                                    ? `Firts name ${profileValidation?.name?.message}`
                                    : ""}
                                </small>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">
                                  Last Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-lg"
                                  placeholder="Enter Your Last Name"
                                  aria-invalid={Boolean(
                                    profileValidation?.lastName?.status ===
                                      false
                                  )}
                                  value={profileDetail?.lastName}
                                  onChange={(e) => {
                                    setProfileDetailsValue(
                                      "lastName",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setProfileValidationValue(
                                      "lastName",
                                      lastNameValidation(e.target.value)
                                    );
                                  }}
                                  autoComplete="off"
                                />
                                <small className="text-danger">
                                  {profileValidation?.lastName?.message
                                    ? `Last name ${profileValidation?.lastName?.message}`
                                    : ""}
                                </small>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">
                                  E- Mail ID{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="email"
                                  className="form-control form-control-lg"
                                  placeholder="Enter Your E- Mail ID"
                                  aria-invalid={Boolean(
                                    profileValidation?.email?.status === false
                                  )}
                                  value={profileDetail?.email}
                                  
                                  disabled
                                  autoComplete="off"
                                />
                                <small className="text-danger">
                                  {profileValidation?.email?.message
                                    ? `Email ${profileValidation?.email?.message}`
                                    : ""}
                                </small>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label className="form-label">
                                  Phone Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  maxLength={10}
                                  className="form-control form-control-lg"
                                  placeholder="Enter Your Phone Number"
                                  aria-invalid={Boolean(
                                    profileValidation?.phoneNumber?.status ===
                                      false
                                  )}
                                  value={profileDetail?.phoneNumber}
                                  onChange={(e) => {
                                    setProfileDetailsValue(
                                      "phoneNumber",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={(e) => {
                                    setProfileValidationValue(
                                      "phoneNumber",
                                      PhoneNumberValidation(e.target.value)
                                    );
                                  }}
                                  autoComplete="off"
                                />
                                <small className="text-danger">
                                  {profileValidation?.phoneNumber?.message
                                    ? `Phone Number ${profileValidation?.phoneNumber?.message}`
                                    : ""}
                                </small>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              {/* <button
                                type="button"
                                className="btn white-btn me-2 "
                                onClick={() => {
                                  window.location.reload();
                                }}
                              >
                                Cancel
                              </button> */}
                              <button
                                type="button"
                                className="btn login-btn ms-2 "
                                onClick={(e) => {
                                  handleSubmit(e);
                                }}
                              >
                                Save
                              </button>
                            </div>
                            <div className="col-lg-10 mt-5">
                              <h6>
                                <b>Password Update</b>
                              </h6>
                            </div>
                            <div className="col-lg-10 mt-5">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <div style={{ position: "relative" }}>
                                      <label className="form-label ">
                                        Old Password{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type={password ? "text" : "password"}
                                        className="form-control form-control-lg"
                                        placeholder="*********"
                                        aria-invalid={Boolean(
                                          oldPasswordValidation?.status ===
                                            false
                                        )}
                                        defaultValue={oldPassword}
                                        onChange={(e) => {
                                          setOldPassword(e.target.value);
                                          setOldPasswordValidation("oldPassword","")
                                        }}
                                        onBlur={(e) => {
                                          setOldPasswordValidation(
                                            PasswordValidation(e.target.value)
                                          );
                                        }}
                                        autoComplete="off"
                                      />
                                      <div className="psw-icon">
                                        {password ? (
                                          <span
                                            className="material-symbols-rounded cursor align-middle"
                                            onClick={() => viewPassword()}
                                          >
                                            visibility
                                          </span>
                                        ) : (
                                          <span
                                            className="material-symbols-rounded cursor align-middle"
                                            onClick={() => viewPassword()}
                                          >
                                            visibility_off
                                          </span>
                                        )}
                                      </div>
                                    </div>

                                    <small className="text-danger">
                                      {oldPasswordValidation?.message
                                        ? `Password ${oldPasswordValidation?.message}`
                                        : ""}
                                    </small>
                                    <small className="text-danger">
                                      {oldPasswordValidation?.oldPassword?.message
                                        ? `Password ${oldPasswordValidation?.oldPassword?.message}`
                                        : ""}
                                    </small>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-4">
                                    <div style={{ position: "relative" }}>
                                      <label className="form-label ">
                                        New Password{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type={password1 ? "text" : "password"}
                                        className="form-control form-control-lg"
                                        placeholder="********"
                                        aria-invalid={Boolean(
                                          newPasswordValidation?.status ===
                                            false
                                        )}
                                        defaultValue={newPassword}
                                        onChange={(e) => {
                                          setNewPassword(e.target.value);
                                          setPasswordStrength(e.target.value);
                                          setNewPasswordValidation("")
                                          setNewPasswordValidation("newPassword","")
                                        }}
                                        onBlur={(e) => {
                                          setNewPasswordValidation(
                                            PasswordValidation(
                                              e.target.value,
                                              oldPassword
                                            )
                                          );
                                        }}
                                        autoComplete="off"
                                      />
                                      <div className="psw-icon">
                                        {password1 ? (
                                          <span
                                            className="material-symbols-rounded cursor align-middle"
                                            onClick={() => viewPassword1()}
                                          >
                                            visibility
                                          </span>
                                        ) : (
                                          <span
                                            className="material-symbols-rounded cursor align-middle"
                                            onClick={() => viewPassword1()}
                                          >
                                            visibility_off
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <small className="text-danger">
                                      {newPasswordValidation?.message
                                        ? `New Password ${newPasswordValidation?.message}`
                                        : ""}
                                    </small>
                                    <small className="text-danger">
                                      {newPasswordValidation?.newPassword?.message
                                        ? `New Password ${newPasswordValidation?.newPassword?.message}`
                                        : ""}
                                    </small>
                                  </div>
                                  {passwordStrength ? (
                                    <div className="mt-3">
                                      <b>
                                        Create a password with the following
                                      </b>
                                      <br />
                                      {
                                        <p
                                          className={`${
                                            passwordStrength.trim().length < 8
                                              ? "text-danger"
                                              : "text-success"
                                          } d-flex`}
                                        >
                                          {passwordStrength.trim().length <
                                          8 ? (
                                            <span className="material-symbols-rounded filled-icon close-icon">
                                              close
                                            </span>
                                          ) : (
                                            <span className="material-symbols-rounded filled-icon close-icon">
                                              check
                                            </span>
                                          )}
                                          At least 8 Characters
                                        </p>
                                      }
                                      {
                                        <p
                                          className={`${
                                            !/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                              passwordStrength
                                            )
                                              ? "text-danger"
                                              : "text-success"
                                          } d-flex`}
                                        >
                                          {!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                            passwordStrength
                                          ) ? (
                                            <span className="material-symbols-rounded filled-icon close-icon">
                                              close
                                            </span>
                                          ) : (
                                            <span className="material-symbols-rounded filled-icon close-icon">
                                              check
                                            </span>
                                          )}
                                          An Uppercase &amp; Lowercase character
                                        </p>
                                      }
                                      {
                                        <p
                                          className={`${
                                            !/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                                              passwordStrength
                                            )
                                              ? "text-danger"
                                              : "text-success"
                                          } d-flex`}
                                        >
                                          {!/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                                            passwordStrength
                                          ) ? (
                                            <span className="material-symbols-rounded filled-icon close-icon">
                                              close
                                            </span>
                                          ) : (
                                            <span className="material-symbols-rounded filled-icon close-icon">
                                              check
                                            </span>
                                          )}
                                          A special character
                                        </p>
                                      }
                                      {
                                        <p
                                          className={`${
                                            !/[0-9]/.test(passwordStrength)
                                              ? "text-danger"
                                              : "text-success"
                                          } d-flex`}
                                        >
                                          {!/[0-9]/.test(passwordStrength) ? (
                                            <span className="material-symbols-rounded filled-icon close-icon">
                                              close
                                            </span>
                                          ) : (
                                            <span className="material-symbols-rounded filled-icon close-icon">
                                              check
                                            </span>
                                          )}
                                          A number
                                        </p>
                                      }
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-lg-12">
                                  {/* <button
                                    type="submit"
                                    className="btn white-btn me-2 "
                                    onClick={(e) => {
                                      window.location.reload();
                                      setOldPassword("");
                                      setNewPassword("");
                                    }}
                                  >
                                    Cancel
                                  </button> */}
                                  <button
                                    type="button"
                                    className="btn login-btn ms-2 "
                                    onClick={(e) => {
                                      handlePasswordChange(e);
                                    }}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <CropEasy
            {...{
              photoURL,
              handleProfileImage,
              setOpenCrop,
              setPhotoURL,
              setFile,
              fileName,
              field,
            }}
          />
        )}
      </div>
    </div>
  );
}
