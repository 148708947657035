import { useEffect, useState } from "react";
import ViewLeadComponent from "../../../../Components/v1/FreelancerPartner/Leads/ViewLeadComponent";
import FreelancerPartnerNav from "../../../../Components/v1/NavBar/FreelancerPartnerNav";


export default function ViewLead({userTypeProps, lableProps,isClientProps,routeProps}) {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const [show, setShow] = useState(true);
  const handleToggle = () => {setShow(!show);};

  return (
    <>
      <FreelancerPartnerNav showFullNav={show} onToggle={handleToggle} userTypeProps={userTypeProps} routeProps={routeProps} />
      <ViewLeadComponent showFullNav={show} lableProps={lableProps} userTypeProps={userTypeProps} isClientProps={isClientProps} routeProps={routeProps}/>
    </>
  );
}
