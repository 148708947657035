import React from 'react';

const faqs = [
    {
        question: 'What services does DALMAF provide?',
        answer: `DALMAF's detailed and customer-centric solutions are tailored to leverage business objectives, ensuring seamless operations that significantly adhere to regulatory norms and compliance. We cover a range of services that include financial, legal, and compliance services. Visit the 'our service' section of our website to know more.`
    },
    {
        question: 'How can DALMAF help my business stay compliant?',
        answer: 'The experts at DALMAF are well-versed in the fact that it is important for a business to stay legal to do business of any kind. Our experts help businesses remain compliant and that they follow specific norms and regulations. We make sure that all your legal documentation is compliant to avoid any unnecessary and avoidable penalties.'
    },
    {
        question: 'What industries does DALMAF serve?',
        answer: `DALMAF caters to industries from different domains and spectrums. Our services are tailored as per the needs and requirements of a business of any domain. Contact us today to discuss the specific requirements your business needs.`
    },
    {
        question: 'Can DALMAF assist with company incorporation?',
        answer: `Yes, DALMAF offers comprehensive business incorporation services for more than 30+ countries. Be it a start-up or a branch office, we offer guidance and support throughout the process.`
    },
    {
        question: 'How does DALMAF ensure data security and Privacy?',
        answer: 'DALMAF considers data security and privacy to be the most serious discipline. Our system and processes are designed to ensure the security and confidentiality of your sensitive information. Visit our website to learn more about our security measures.'
    },
    // Add more FAQs here
];

export default function FAQ() {
    return (
        <>
        <div class="bg-smoke overflow-hidden space" id="faq-sec">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-xl-6 col-lg-9">
                        <div class="title-area text-center text-xl-start"><span class="sub-title">
                            <div class="icon-masking me-2"><span class="mask-icon"
                                data-mask-src="https://dalmaf.com/img/theme-img/title_shape_2.svg"></span> <img
                                    src="https://dalmaf.com/img/theme-img/title_shape_2.svg" alt="shape" /></div>Frequently Ask
                            Question
                        </span>
                            <h2 class="sec-title">Talk To About Any <span class="text-theme fw-normal">Question?</span></h2>
                        </div>
                        <div className="accordion" id="faqAccordion">
                            {faqs.map((faq, index) => (
                                <div className="accordion-item" key={index}>
                                    <h2 className="accordion-header" id={`faqHeading${index}`}>
                                        <button className="accordion-button collapsed text-dark" type="button" data-bs-toggle="collapse" data-bs-target={`#faqCollapse${index}`} aria-expanded="false" aria-controls={`faqCollapse${index}`}>
                                            {faq.question}
                                        </button>
                                    </h2>
                                    <div id={`faqCollapse${index}`} className="accordion-collapse collapse" aria-labelledby={`faqHeading${index}`} data-bs-parent="#faqAccordion">
                                        <div className="accordion-body">
                                            {faq.answer}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* <div class="accordion-area accordion" id="faqAccordion">
                            {faqs.map((faq, index) => (
                                <div class="accordion-card" key={index}>
                                    <div class="accordion-header" id={`faqHeading${index}`}>
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#faqCollapse${index}`} aria-expanded="false" aria-controls="collapse-1">
                                            {faq.question}
                                        </button>
                                    </div>
                                    <div id={`faqCollapse${index}`} class="accordion-collapse collapse" aria-labelledby="collapse-item-1" data-bs-parent="#faqAccordion">
                                        <div class="accordion-body active">
                                            <p class="faq-text">
                                                {faq.answer}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div> */}
                    

                    </div>
                    <div class="col-xl-6 mt-35 mt-xl-0">
                        <div class="faq-img tilt-active">
                            <div class="img-shape icon-masking"><span class="mask-icon"
                                data-mask-src="https://dalmaf.com/img/normal/about_3_1-shape.png"></span> <img
                                    src="https://dalmaf.com/img/normal/about_3_1-shape.png" alt="img" /></div><img
                                src="https://dalmaf.com/img/normal/faq_1_1.png" alt="Faq" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="shape-mockup jump" data-bottom="0%" data-left="0%"><img src="https://dalmaf.com/img/shape/tech_shape_5.png"
                alt="shape" /></div>
        </div>
        {/* <section className="pt-12 pb-12 bg-white" style={{ backgroundImage: `url('https://img.freepik.com/premium-photo/question-mark-cream-wall-background-faq-concept-image_157125-10037.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center', paddingTop: '50px', paddingBottom: '50px', height: '650px' }}>
            <div className="container">
                <div className="row mt-5 align-items-center">
                    <div className="col-md-8">
                        <div className="p-5 rounded " style={{ opacity: 0.9 }}>
                            <h2 className="mb-4 text-dark">Frequently Asked Questions</h2>
                            <div className="accordion" id="faqAccordion">
                                {faqs.map((faq, index) => (
                                    <div className="accordion-item" key={index}>
                                        <h2 className="accordion-header" id={`faqHeading${index}`}>
                                            <button className="accordion-button collapsed text-dark" type="button" data-bs-toggle="collapse" data-bs-target={`#faqCollapse${index}`} aria-expanded="false" aria-controls={`faqCollapse${index}`}>
                                                {faq.question}
                                            </button>
                                        </h2>
                                        <div id={`faqCollapse${index}`} className="accordion-collapse collapse" aria-labelledby={`faqHeading${index}`} data-bs-parent="#faqAccordion">
                                            <div className="accordion-body">
                                                {faq.answer}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        </>
    );
}
