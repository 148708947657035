import { useEffect, useState } from "react";
import FreelancerPartnerNav from "../../../../Components/v1/NavBar/FreelancerPartnerNav";
import UpdateProfileComponent from "../../../../Components/v1/FreelancerPartner/Profile/UpdateProfile";


export default function UpdateProfile({routeProps,lableProps,userTypeProps,isClient}) {
  const [show, setShow] = useState(true);
  const handleToggle = () => {setShow(!show);};

  useEffect(() => {
    window.scroll(0, 0)
  }, [])


  return (
    <>
      <FreelancerPartnerNav showFullNav={show} onToggle={handleToggle} userTypeProps={userTypeProps} routeProps={routeProps} />
      <UpdateProfileComponent showFullNav={show}/>
    </>
  );
}
