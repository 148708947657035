import React, { useEffect, useState } from "react";
import AdminNavbar from "../Navbar/AdminNavbar";

import JoditEditor from "jodit-react";
import { useSelector } from "react-redux";
import {
 
  GetSpecificJob,
  
  UpdateJob,
} from "../../../Api/Api";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate, useParams } from "react-router-dom";
import { NonEmptyValidation, StringValidation } from "../../../Store/validate";

export default function JobEdit() {
    const [loading,setLoading] = useState(true)
    const navigate = useNavigate()
    const state = useSelector((state)=>state)
    const {id} = useParams()
    useEffect(() => {
        GetjobSpecificTsks()
    },  [id])
    
    const [jobTask, setJobTask] = useState({});
    const [jobTaskValidation, setJobValidation] = useState({});
  
    const setJobValue = (key, value) => {
      setJobTask({ ...jobTask, [key]: value });
      if (jobTaskValidation[key]) delete jobTaskValidation[key];
    };
  
    const setJobValidationValue = (key, value) => {
      setJobValidation({ ...jobTaskValidation, [key]: value });
    };
    const GetjobSpecificTsks = async () => {
      setLoading(true);
      // const token = state.auth.auth.token;
      let data = await GetSpecificJob(id);
      if (data && data.statusCode === 200) {
        setJobTask(data.data);
        setLoading(false);
      } else {
        setJobTask([]);
        setLoading(false);
        //console.log("Error while getting my specific task");
      }
    };

      //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: false,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let validate = {};
        validate.jobTitle = NonEmptyValidation(jobTask?.jobTitle);
        validate.location = StringValidation(jobTask?.location);
        validate.description = NonEmptyValidation(jobTask?.description);
        
        validate.requirement = NonEmptyValidation(jobTask?.requirement);
    
        setJobValidation(validate);
        if (Object.values(validate).every((v) => v.status === true)) {
          const Payload = {
            _id:jobTask._id,
            jobTitle: jobTask.jobTitle,
            location: jobTask.location,
            description: jobTask.description,
            requirement: jobTask.requirement,
            status:jobTask.status
          };
          const token = state?.auth?.auth?.token;
          const response = await UpdateJob(Payload, token);
          if (response && response.statusCode === 200) {
            showToastSuccessMsg(response.message);
            window.scrollTo(0, 0)
            GetjobSpecificTsks()
          } else {
            showToastErrorMsg(
              response.message || response.error.response.data.message
            );
          }
        } else {
          //console.log("Error while update user details");
        }
      };
    

    


  return (
    <div>
      <AdminNavbar />
      <ToastContainer/>
      <div className="main">
      <div>
        <div className="row">
        <div className="col-6">
                <button
                              type="button"
                              className="btn login-btn ms-2 "
                              onClick={(e) => {
                               navigate('/JobManagement')
                              }}
                            >
                              Back
                            </button>
                </div>
                <div className="col-6 text-end">
                <button
                              type="button"
                              className="btn login-btn ms-2 "
                              onClick={() => {
                               navigate(`/JobApplications/${id}`)
                              }}
                            >
                              Applied Candidates
                            </button>
                </div>
        </div>
                
                <div className=" my-4">
                  <div className="bg-historytablehead rounded-3 py-3 px-3">
                    <h6 className="mb-0">Update Job</h6>
                  </div>
                  <div className="container">
                    <div className="row justify-content-center py-5">
                      <div className="col-lg-11">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Job Title</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder="Enter The JOB Title"
                                aria-invalid={Boolean(
                                  jobTaskValidation?.jobTitle?.status === false
                                )}
                                value={jobTask?.jobTitle}
                                onChange={(e) => {
                                  setJobValue("jobTitle", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setJobValidationValue(
                                    "jobTitle",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {jobTaskValidation?.jobTitle?.message
                                  ? `Job Title ${jobTaskValidation?.jobTitle?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Location</label>
                              <input
                                type="text"
                                className="form-control form-control-lg"
                                placeholder=" Enter The Location"
                                aria-invalid={Boolean(
                                  jobTaskValidation?.location?.status === false
                                )}
                                value={jobTask?.location}
                                onChange={(e) => {
                                  setJobValue("location", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setJobValidationValue(
                                    "location",
                                    StringValidation(e.target.value)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small className="text-danger">
                                {jobTaskValidation?.location?.message
                                  ? `Location ${jobTaskValidation?.location?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Job Description</label>
                              <div
                                className="form-control"
                                style={{ overflowY: "scroll", height: "400px" }}
                              >
                              <JoditEditor
                                
                                className="form-control form-control-lg"
                                aria-invalid={Boolean(
                                  jobTaskValidation?.description?.status ===
                                    false
                                )}
                                value={jobTask?.description}
                                onChange={(e) => {
                                  setJobValue(
                                    "description",
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  setJobValidationValue(
                                    "description",
                                    NonEmptyValidation(e)
                                  );
                                }}
                                autoComplete="off"
                              />
                              </div>
                              <small classtaskDescription="text-danger">
                                {jobTaskValidation?.description?.message
                                  ? `Job description ${jobTaskValidation?.description?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Job Requirement</label>
                              <JoditEditor
                                
                                className="form-control form-control-lg"
                                aria-invalid={Boolean(
                                  jobTaskValidation?.requirement?.status ===
                                    false
                                )}
                                value={jobTask?.requirement}
                                onChange={(e) => {
                                  setJobValue(
                                    "requirement",
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  setJobValidationValue(
                                    "requirement",
                                    NonEmptyValidation(e)
                                  );
                                }}
                                autoComplete="off"
                              />
                              <small classtaskDescription="text-danger">
                                {jobTaskValidation?.requirement?.message
                                  ? `Job requirement ${jobTaskValidation?.requirement?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label ">
                                Status
                              </label>

                              <select
                                className="form-control form-control-lg"
                                value={jobTask?.status}
                                onChange={(e) => {
                                  setJobValue("status", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setJobValidationValue(
                                    "status",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="active">
                                 Active
                                </option>
                               
                                  <option value="inactive">Inactive</option>

                              </select>
                             
                            </div>
                          </div>
                          <div className="col-lg-12">
                            
                            <button
                              type="button"
                              className="btn login-btn ms-2 "
                              onClick={(e) => {
                                handleSubmit(e);
                              }}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      </div>
    </div>
  );
}
