import { useEffect, useState } from "react";
import FreelancerPartnerNav from "../../../../Components/v1/NavBar/FreelancerPartnerNav";
import UpdatePasswordComponent from "../../../../Components/v1/FreelancerPartner/Profile/UpdatePassword";

export default function UpdatePassword({routeProps,lableProps,userTypeProps,isClient}) {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const [show, setShow] = useState(true);
  const handleToggle = () => {setShow(!show);};


  return (
    <>
      <FreelancerPartnerNav showFullNav={show} onToggle={handleToggle} userTypeProps={userTypeProps} routeProps={routeProps} />
      <UpdatePasswordComponent/>
    </>
  );
}
