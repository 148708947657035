import React from 'react'

export default function CareerBanner() {
  return (
    <div className=''>
      <div className="careerpage-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="head-banner-posi">
                <h1 className="banner-heading after-line ">Careers</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
