import React, { useEffect, useState } from "react";
import { GetPropertyUser } from "../../Api/Api";
import Spinner from "../../Spinner/Spinner";
import { useSelector } from "react-redux";
import CustomModal from "../CustomModal";
import { useNavigate } from "react-router-dom";
import FilterComponent from "./FilterComponent";
import PropertyListComponent from "./PropertyListComponent";
import styles from './PropertyAuctionList.module.css';

export default function PropertyAuctionList() {
  const [loading, setLoading] = useState(true);
  const [allProperties, setAllProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [loginModal, setLoginModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [filterValues, setFilterValues] = useState({ cities: [], propertyTypes: [], maxEmd: 0, maxReservePrice: 0 });

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const response = await GetPropertyUser();
      if (response && response.statusCode === 200) {
        setAllProperties(response.data);
        setFilteredProperties(response.data);
        updateFilterValues(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateFilterValues = (data) => {
    const cities = [...new Set(data.map(item => item.city))];
    const propertyTypes = [...new Set(data.map(item => item.property_type))];
    const maxEmd = Math.max(...data.map(item => item.emd_amount));
    const maxReservePrice = Math.max(...data.map(item => item.reserve_price));
    setFilterValues({ cities, propertyTypes, maxEmd, maxReservePrice });
  };

  const applyFilters = (updatedFilters) => {
    let filtered = allProperties;
    if (updatedFilters.city) {
      filtered = filtered.filter(item => item.city === updatedFilters.city);
    }
    if (updatedFilters.property_type) {
      filtered = filtered.filter(item => item.property_type === updatedFilters.property_type);
    }
    if (updatedFilters.emd_amount) {
      filtered = filtered.filter(item => item.emd_amount <= updatedFilters.emd_amount);
    }
    if (updatedFilters.reserve_price) {
      filtered = filtered.filter(item => item.reserve_price <= updatedFilters.reserve_price);
    }
    setFilteredProperties(filtered);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters, [name]: parseInt(value) || value };
      applyFilters(updatedFilters);
      return updatedFilters;
    });
  };

  const navigate = useNavigate();
  const state = useSelector((state) => state);

  const viewMore = (id) => {
    const token = state?.auth?.auth?.token;
    if (token) {
      navigate(`/PropertyAuctionDetail/${id}`);
    } else {
      setLoginModal(true);
    }
  };

  return (
    <div className={styles.auctionContainer}>
      {loading ? (
        <Spinner />
      ) : (
        <div className="row">
          <div className="col-md-3 col-lg-3">
            <FilterComponent 
              filters={filters} 
              onChange={handleFilterChange} 
              filterValues={filterValues}
            />
          </div>
          <div className="col-md-9 col-lg-9">
            <PropertyListComponent properties={filteredProperties} viewMore={viewMore} />
          </div>
        </div>
      )}
      <div
        className={`modal fade ${loginModal ? 'show d-block' : ''}`}
        tabIndex="-1"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header text-white" style={{backgroundColor:'#dc3545'}}>
              <h5 className="modal-title">Validate Login</h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                onClick={() => setLoginModal(!loginModal)}
              ></button>
            </div>
            <div className="modal-body text-center">
              <h5 className="mb-4">Login to View More</h5>
            </div>
          </div>
        </div>
      </div>
      {/* <CustomModal
        open={loginModal}
        onClickOutside={() => setLoginModal(false)}
      >
        <div className={`modal-content ${styles.modalContent}`}>
          <div className="modal-body text-center py-4">
            <p className="text-center">
              You must be logged in to view property details.
            </p>
          </div>
        </div>
      </CustomModal> */}
    </div>
  );
}
