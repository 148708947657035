import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import styles from './css/ListLeadComponent.module.css';
import 'react-toastify/dist/ReactToastify.css';
import styles from './css/EditContactComponent.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fetchWithQueryAPI, updateAPI } from '../../../../Api/v1/Util/Api';
import { contacts_fetch_by_find_particular, contacts_update } from '../../../../Api/APIUrl';
import { showToastErrorMsg, showToastSuccessMsg } from '../../../../Store/toastMsg';

export default function EditContactComponent({ showFullNav, userTypeProps,isClient,lableProps,routeProps }) {

    const state = useSelector((state) => state);
    const navigate = useNavigate();
    const { id, contactId } = useParams();

    const [contact, setContact] = useState(null);
    const [formValues, setFormValues] = useState({
        _id: contactId,
        first_name: '',
        last_name: '',
        email_id: '',
        phone_number: '',
        job_title: '',
        gender: ''
    });

    const fetchContact = async () => {
        try {
            const response = await fetchWithQueryAPI(state.auth.auth.token, contacts_fetch_by_find_particular + contactId);
            if (response && response.statusCode === 200) {
                setContact(response);
                setFormValues({
                    _id: contactId,
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                    email_id: response.data.email_id,
                    phone_number: response.data.phone_number,
                    job_title: response.data.job_title,
                    gender: response.data.gender
                });
            } else {
                toast.error("Failed to fetch contact details");
            }
        } catch (error) {
            toast.error("An error occurred while fetching contact details");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await updateAPI(state.auth.auth.token, contacts_update, formValues);
            if (response && response.statusCode === 200) {
                showToastSuccessMsg("Contact updated successfully");
                navigate(`/${userTypeProps}/${routeProps}/view-${routeProps}/${id}`);
            } else {
                showToastErrorMsg("Failed to update contact");
            }
        } catch (error) {
            toast.error("An error occurred while updating contact");
        }
    };

    useEffect(() => {
        fetchContact();
    }, [id]);


    return (
        <>
            <ToastContainer />
            <div className={showFullNav ? styles.leadsContainer : styles.leadsContainerSmallNav}>
                <div className="row">
                    <div className="col-md-10"><h2>Update Contact Info</h2></div>
                    <div className="col-md-2">
                        <button onClick={() => { navigate(`/${userTypeProps}/${routeProps}/view-${routeProps}/${id}`); }} className='btn btn-primary' style={{ float: 'right' }}>
                            <ArrowBackIcon />
                        </button>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className='row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                            <TextField
                                name="first_name"
                                label="First Name"
                                variant="outlined"
                                value={formValues.first_name}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                            <TextField
                                name="last_name"
                                label="Last Name"
                                variant="outlined"
                                value={formValues.last_name}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                            <TextField
                                name="email_id"
                                label="Email Id"
                                variant="outlined"
                                value={formValues.email_id}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                            <TextField
                                name="phone_number"
                                label="Phone Number"
                                variant="outlined"
                                value={formValues.phone_number}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                            <TextField
                                name="job_title"
                                label="Job Title"
                                variant="outlined"
                                value={formValues.job_title}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Gender</InputLabel>
                                <Select
                                    name="gender"
                                    value={formValues.gender}
                                    onChange={handleChange}
                                    label="Gender"
                                >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                    <MenuItem value="Other">Other</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 mt-2' style={{ float: 'right' }}>
                            <Button type="submit" className='btn btn-primary'>
                                Update
                            </Button>
                            <Button onClick={() => { navigate(`/${userTypeProps}/${routeProps}/view-${routeProps}/${id}`); }} className="btn btn-secondary">
                                Cancel
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
