import React, { useState, useRef, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import AdminNavbar from "../Navbar/AdminNavbar";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-toastify/dist/ReactToastify.css";
import CreatableSelect from "react-select/creatable";

import Swal from "sweetalert2";
import CustomModal from "../../../Components/CustomModal";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import moment from "moment";
import { useSelector } from "react-redux";
import {
  getAllPackagesAdmin,
  getSpecificPackagee,
  adminservicepakage,
  GetPackageService,
  CreatePackageService,
  GetPackageType,
  UpdateServicePackage,
  DeletePackage,
  GetAllSubAdminAndAdmin,
  GetAllSubAdmin,
  getAllPackagesUser,
} from "../../../Api/Api";
import {
  StringValidation,
  NonEmptyValidation,
  NumberValidation,
  NonEmptyObjectValidation,
  EmailValidation,
  PhoneNumberValidation,
  NonEmptyValidationDes,
} from "../../../Store/validate";
import Spinner from "../../../Spinner/Spinner";

import MUIDataTable from "mui-datatables";

//
export const dataLineChart = [
  ["Task", "Hours per Day"],
  ["1", 11],
  ["2 ", 20],
  ["3", 12],
  ["4", 5],
  ["5", 18],
  ["6", 8],
  ["7", 16],
  ["8", 8],
  ["9", 35],
  ["10", 6],
];
let htmlToDraft = null;
if (typeof window === "object") {
  htmlToDraft = require("html-to-draftjs").default;
}

export const optionsLineChart = {
  title: "Services Packages ",
  curveType: "function",
  legend: { position: "bottom" },
};

export default function ServicePackage() {
  const [serviceDetails, setServiceDetails] = useState(true);
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState("");
  const [content1, setContent1] = useState("");

  const [btnDisable,setBtnDisable] = useState(false);

  const [selectedTab, setSelectedTab] = React.useState("All");
  const AllTabs = ["All","Dalmaf Package", "Custom"];

  const [createServicePackageModal, setCreateServicePackageModal] =
    useState(false);
  const [createServicePackageModal1, setCreateServicePackageModal1] =
    useState(false);
  const [updateServicePackageModal, setUpdateServicePackageModal] =
    useState(false);
  const [updateServicePackageModal1, setUpdateServicePackageModal1] =
    useState(false);

  const fileInput = useRef(null);

  useEffect((e) => {
    window.addEventListener("keyup", clickFileInput);
    return () => window.removeEventListener("keyup", clickFileInput);
  });

  function clickFileInput(e) {
    if (fileInput.current.nextSibling.contains(document.activeElement)) {
      // Bind space to trigger clicking of the button when focused
      if (e.keyCode === 32) {
        fileInput.current.click();
      }
    }
  }

  const state = useSelector((state) => state);

  const [allServicePackage, setAllServicePackage] = useState([]);
  async function fetchDataPackage(value) {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    const data = await getAllPackagesAdmin(token);
    if (data && data?.statusCode === 200) {
      let data1 = data?.data.filter((e) => e.customPackage === value);
      let temp = [];
      data1.reverse().map((data, i) => {
        let dummy = {
          _id: data?._id,
          title: data?.title,
          price: data?.price,
          name: data?.name,
          email: data?.email,
          phoneNumber: data?.phoneNumber,
          package_create_path: data?.package_create_path,
          createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
          status: data?.status,
          referredBy: data?.referredBy?.name,
          customPackage: data?.customPackage,
        };
        temp.push(dummy);
        return null;
      });
      setAllServicePackage(temp);

      setLoading(false);
    } else {
      setAllServicePackage([]);
      setLoading(false);
      //console.log("error while getting package");
    }
  }
  async function fetchDataPackageAll() {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    const data = await getAllPackagesAdmin(token);
    if (data && data?.statusCode === 200) {
     
      let temp = [];
      data?.data?.reverse().map((data, i) => {
        let dummy = {
          _id: data?._id,
          title: data?.title,
          price: data?.price,
          name: data?.name,
          email: data?.email,
          phoneNumber: data?.phoneNumber,
          package_create_path: data?.package_create_path,
          createdAt: moment(data?.createdAt).format("DD/MM/YYYY"),
          status: data?.status,
          referredBy: data?.referredBy?.name,
          customPackage: data?.customPackage,
        };
        temp.push(dummy);
        return null;
      });
      setAllServicePackage(temp);

      setLoading(false);
    } else {
      setAllServicePackage([]);
      setLoading(false);
      //console.log("error while getting package");
    }
  }

  async function fetchServicePackageUser() {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    // const id = state?.auth?.auth?._id;
    const getServicePackageDetails = await getAllPackagesUser(token);
    if (
      getServicePackageDetails &&
      getServicePackageDetails?.statusCode === 200
    ) {
      setAllServicePackage(getServicePackageDetails?.data);
      // setAllServicePackage(
      //   getServicePackageDetails?.data.filter((e) => e.customPackage === value)
      // );

      setLoading(false);
    } else {
      setAllServicePackage([]);
      setLoading(false);
      //console.log("error while getting package");
    }
  }

  useEffect(() => {
    // fetchDataPackage("Auto");
    fetchDataPackageAll()
    getSpecificPackage();
    getServicePackagelist();
    getServiceTypelist();
    GetAllSubadmins();
    getSpecificUser();
  }, []);

  //

  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 3000,
    });
  };
  const [msg, setMsg] = useState("");
  useEffect(() => {}, []);

  const [inputFields, setInputFields] = useState([
    {
      image: "",
      title: "",
      logo: "",
      description: "",
    },
  ]);

  const [inputFieldValidation, setInputFieldValidation] = useState([
    {
      image: "",
      title: "",
      logo: "",
      description: "",
    },
  ]);

  const handleFormChange = (index, event, name) => {
    let data = [...inputFields];
    if (name === "image" || name === "logo") {
      data[index][name] = event;
    } else {
      data[index][name] = event.target.value;
    }

    setInputFields(data);

    if (inputFieldValidation[data[index][name]])
      delete inputFieldValidation[data[index][name]];
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorStateCustom, setEditorStateCustom] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setservicePackDetailValue("description", html);
    }
  };
  const [editorState1, setEditorState1] = useState(() =>
    EditorState.createEmpty()
  );

  const handleEditorChange1 = (state) => {
    setEditorState1(state);
    let isNotEmpty = state && state.getCurrentContent().hasText();
    if (isNotEmpty) {
      let html = draftToHtml(convertToRaw(state.getCurrentContent()));
      setServicePackDetailValue1("description", html);
    }
  };

  const handleFormChangeValidation = (index, name, value) => {
    let data = [...inputFieldValidation];
    data[index][name] = value;
    setInputFieldValidation(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.servicesName = NonEmptyObjectValidation(
      servicePackDetail?.servicesName
    );
    validate.toWhom = NonEmptyValidation(servicePackDetail?.toWhom);
    validate.title = NonEmptyValidation(servicePackDetail?.title);
    validate.description = NonEmptyValidation(content);
    validate.price = NumberValidation(servicePackDetail?.price);
    // validate.packageType = NonEmptyObjectValidation(servicePackDetail?.packageType);
    // validate.gst = NonEmptyValidation(servicePackDetail?.gst);
    validate.gstPercentage = NumberValidation(servicePackDetail?.gstPercentage);

    setServicePackDetailValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let Payload = {
        services:
          servicePackDetail?.servicesName?._id ||
          servicePackDetail?.servicesName?.map((e) => e._id.toString()),
        toWhom: servicePackDetail.toWhom,
        title: servicePackDetail.title,
        description: content,
        price: Number(servicePackDetail.price),
        package_create_path: servicePackDetail.price,
        gst: true,

        gstPercentage: Number(servicePackDetail.gstPercentage),
        package_periods: Number(servicePackDetail.package_periods),
        status: "active",
        customPackage: "Auto",
      };

      try {
        const token = state?.auth?.auth?.token;
        const response = await adminservicepakage(token, Payload);
        if (response.statusCode === 200) {
          const editorStateReset = EditorState.push(
            editorState,
            ContentState.createFromText("")
          );
          setEditorState(editorStateReset);

          setServicePackDetail({});
          setCreateServicePackageModal(false);
          fetchDataPackage("Auto");
          showToastSuccessMsg(response.message);
        } else {
          showToastErrorMsg(
            response.message || response.error?.response?.data?.message
          );
        }
      } catch (error) {
        showToastErrorMsg(error.message);
      }
    }
  };

  const handleSubmitCustom = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.price = NumberValidation(servicePackDetail?.price);
    // validate.gstPercentage = NonEmptyValidation(servicePackDetail?.gstPercentage);
    validate.title = NonEmptyValidation(servicePackDetail?.title);
    // validate.package_periods = NumberValidation(servicePackDetail?.package_periods);
    validate.phoneNumber = PhoneNumberValidation(
      servicePackDetail?.phoneNumber
    );
    validate.referredBy = NonEmptyObjectValidation(
      servicePackDetail?.referredBy
    );
    validate.description = NonEmptyValidation(content1);
    validate.email = EmailValidation(servicePackDetail?.email);
    validate.name = StringValidation(servicePackDetail?.name);

    setServicePackDetailValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      setBtnDisable(true);
      let Payload = {
        price: Number(servicePackDetail.price),
        // gstPercentage: Number(servicePackDetail.gstPercentage),
        // package_periods: Number(servicePackDetail.package_periods),
        referredBy: servicePackDetail.referredBy,
        title: servicePackDetail.title,
        package_create_path: "Walk-In",
        description: content1,
        phoneNumber: servicePackDetail.phoneNumber,
        name: servicePackDetail.name,
        email: servicePackDetail.email,
        customPackage: "Custom",
      };

      try {
        const token = state?.auth?.auth?.token;
        const response = await adminservicepakage(token, Payload);
        if (response.statusCode === 200) {
          const editorStateReset1 = EditorState.push(
            editorStateCustom,
            ContentState.createFromText("")
          );
          setEditorStateCustom(editorStateReset1);
          showToastSuccessMsg(response.message);
          setServicePackDetail({});
          setCreateServicePackageModal1(false);
          fetchDataPackage("Custom");
        } else {
          showToastErrorMsg(
            response.message || response.error?.response?.data?.message
          );
        }
      } catch (error) {
        showToastErrorMsg(error.message);
      }
    }
  };

  const [servicePackDetail, setServicePackDetail] = useState({});
  const [servicePackDetailValidation, setServicePackDetailValidation] =
    useState({});

  const setservicePackDetailValue = (key, value) => {
    setServicePackDetail({ ...servicePackDetail, [key]: value });
    if (servicePackDetailValidation[key])
      delete servicePackDetailValidation[key];
  };

  const setservicePackDetailValidationValue = (key, value) => {
    setServicePackDetailValidation({
      ...servicePackDetailValidation,
      [key]: value,
    });
  };

  const [servicePackDetailDetail1, setservicePackDetailDetails1] = useState({});
  const [servicePackDetailValidation1, setservicePackDetailValidation1] =
    useState({});

  const setServicePackDetailValue1 = (key, value) => {
    setservicePackDetailDetails1({ ...servicePackDetailDetail1, [key]: value });
    if (servicePackDetailValidation1[key])
      delete servicePackDetailValidation1[key];
  };

  const setServicePackDetailValidationValue1 = (key, value) => {
    setservicePackDetailValidation1({
      ...servicePackDetailValidation1,
      [key]: value,
    });
  };

  const fetchUserInfo = async (number) => {
    let response = await GetAllSubAdmin(
      state?.auth?.auth?.token,
      "user",
      "active"
    );
    let filteredOutput = response.data.filter(
      (input) => input?.phoneNumber?.toString() === number.toString()
    );

    if (filteredOutput.length) {
      setServicePackDetail((prevState) => ({
        ...prevState,
        ...{ name: filteredOutput[0].name, email: filteredOutput[0].email },
      }));
      if (servicePackDetailValidation["name"])
        delete servicePackDetailValidation["name"];
      if (servicePackDetailValidation["email"])
        delete servicePackDetailValidation["email"];
    }
    //console.log(servicePackDetail);
  };

  // //console.log(servicePackDetail)

  const [packageService, setPackageService] = useState([]);
  async function getServicePackagelist() {
    const token = state?.auth?.auth?.token;
    const getEmployeeSkills = await GetPackageService(token);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.servicesName,
          label: e.servicesName,
          _id: e._id,
        };
      });
      setPackageService(mappingData);
    }
  }

  const [specificPackage, setSpecificPackage] = useState([]);

  const getSpecificPackage = async (id) => {
    setLoading(true);
    const _id = state?.auth?.auth?._id;
    const data = await getSpecificPackagee(id, _id);
    if (data && data.statusCode === 200) {
      // let dummy = data.data;
      // let cmsDescription = EditorState.createEmpty();
      // if (dummy.description) {
      //   let blocksFromHtml = htmlToDraft(dummy.description);
      //   let { contentBlocks, entityMap } = blocksFromHtml;
      //   let contentState = ContentState.createFromBlockArray(
      //     contentBlocks,
      //     entityMap
      //   );
      //   cmsDescription = EditorState.createWithContent(contentState);
      // }
      // dummy.description = cmsDescription;

      setservicePackDetailDetails1(data?.data);
      setSpecificPackage(data.data);
      setContent(data.data.description);
      setLoading(false);
    } else {
      setservicePackDetailDetails1([]);
      setSpecificPackage([]);
      setLoading(false);
      //console.log("Error while getting specific packages");
    }
  };

  const handleCreateServiceName = async (string) => {
    const valid = NonEmptyValidation(string);
    setservicePackDetailValidationValue("servicesName", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.token;
      const Payload = {
        servicesName: string,
      };
      const response = await CreatePackageService(Payload, token);
      if (response && response.statusCode === 200) {
        getServicePackagelist();

        const data = {
          value: response.data.servicesName,
          label: response.data.servicesName,
          _id: response.data._id,
        };
        if (servicePackDetail?.servicesName?.length) {
          let scheduleData = servicePackDetail?.servicesName;

          scheduleData.push(data);
          setservicePackDetailValue("servicesName", scheduleData);
        } else {
          setservicePackDetailValue("servicesName", [data]);
        }
      }
    }
  };
  const handleCreateServiceName1 = async (string) => {
    const valid = NonEmptyValidation(string);
    setServicePackDetailValidationValue1("services", valid);
    if (valid.status) {
      const token = state?.auth?.auth?.token;
      const Payload = {
        servicesName: string,
      };
      const response = await CreatePackageService(Payload, token);
      if (response && response.statusCode === 200) {
        getServicePackagelist();

        const data = {
          value: response.data.servicesName,
          label: response.data.servicesName,
          _id: response.data._id,
        };
        if (servicePackDetailDetail1?.services?.length) {
          let scheduleData = servicePackDetailDetail1?.services;

          scheduleData.push(data);
          setServicePackDetailValue1("services", scheduleData);
        } else {
          setServicePackDetailValue1("services", [data]);
        }
      }
    }
  };

  const [packageType, setPackageType] = useState([]);
  async function getServiceTypelist() {
    const token = state?.auth?.auth?.token;
    const getEmployeeSkills = await GetPackageType(token);
    if (getEmployeeSkills && getEmployeeSkills.statusCode === 200) {
      let data1 = getEmployeeSkills.data;
      let mappingData = data1.map((e) => {
        return {
          value: e.packageName,
          label: e.packageName,
          _id: e._id,
        };
      });
      setPackageType(mappingData);
    }
  }

  // const [photoURL, setPhotoURL] = useState(servicePackDetail?.profileImage);

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.services = NonEmptyObjectValidation(
      servicePackDetailDetail1?.services
    );
    validate.toWhom = NonEmptyValidation(servicePackDetailDetail1?.toWhom);

    setservicePackDetailValidation1(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let Payload = {
        _id: servicePackDetailDetail1?._id,
        services:
          servicePackDetailDetail1?.services?._id ||
          servicePackDetailDetail1?.services?.map((e) => e._id.toString()),
        toWhom: servicePackDetailDetail1.toWhom,
        price: Number(servicePackDetailDetail1.price),
        gst: true,
        gstPercentage: Number(servicePackDetailDetail1.gstPercentage),
        package_periods: Number(servicePackDetailDetail1.package_periods),
        status: servicePackDetailDetail1.status,
        description: content,
        customPackage: "Auto",
      };

      try {
        const token = state?.auth?.auth?.token;
        const response = await UpdateServicePackage(Payload, token);
        if (response.statusCode === 200) {
          showToastSuccessMsg(response.message);
          window.location.reload();
          setContent("")
          setUpdateServicePackageModal(false);
          setservicePackDetailDetails1({});

          fetchDataPackage("Auto");
        } else {
          showToastErrorMsg(
            response.message || response.error?.response?.data?.message
          );
        }
      } catch (error) {
        showToastErrorMsg(error.message);
      }
    }
  };

  const handleSubmitUpdateCustom = async (e) => {
   
    e.preventDefault();
    let validate = {};
  
    // console.log(validate,"validate")
    // validate.price = NumberValidation(servicePackDetailDetail1?.price);
    // validate.referredBy = NonEmptyValidation(
    //   servicePackDetailDetail1?.referredBy
    // );
    // validate.package_periods = NonEmptyValidation(
    //   Number(servicePackDetailDetail1?.package_periods)
    // );

    // validate.description = NonEmptyValidationDes(
    //   content
    // );
    // validate.phoneNumber = NonEmptyValidation(servicePackDetailDetail1?.phoneNumber);

    setservicePackDetailValidation1(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      let Payload;
console.log(Payload,"Payload")
      // let html = draftToHtml(
      //   convertToRaw(servicePackDetailDetail1.description.getCurrentContent())
      // );

      Payload = {
        _id: servicePackDetailDetail1?._id,
        price: Number(servicePackDetailDetail1.price),
        referredBy: servicePackDetailDetail1.referredBy,
        // package_periods: Number(servicePackDetailDetail1.package_periods),
        description: content,
        package_create_path: "Walk-In",
        phoneNumber: servicePackDetailDetail1.phoneNumber,
        title: servicePackDetailDetail1.title,
        status: servicePackDetailDetail1.status,
        name: servicePackDetailDetail1.name,
        email: servicePackDetailDetail1.email,
        customPackage: "Custom",
      };

      try {
        const token = state?.auth?.auth?.token;
        const response = await UpdateServicePackage(Payload, token);
        if (response.statusCode === 200) {
          showToastSuccessMsg(response.message);
          // window.location.reload();
          setContent("")
          setUpdateServicePackageModal1(false);
          setservicePackDetailDetails1({});

          fetchDataPackage("Custom");
        } else {
          showToastErrorMsg(
            response.message || response.error?.response?.data?.message
          );
        }
      } catch (error) {
        showToastErrorMsg(error.message);
      }
    }
  };
  //console.log(servicePackDetailDetail1);
  const [specificUser, setSpecificUser] = useState([]);

  const getSpecificUser = async () => {
    const token = state?.auth?.auth?.token;
    // const id = state?.auth?.auth?._id;
    const data = await GetAllSubAdmin(token, "user", "active");
    if (data && data.statusCode === 200) {
      setSpecificUser(data?.data);
    } else {
      setSpecificUser([]);
      //console.log("Error while getting user details");
    }
  };
  const handleSubmitDelete = async (e, id, value) => {
    e.preventDefault();

    const token = state?.auth?.auth?.token;
    const response = await DeletePackage(id, token);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);

      fetchDataPackage(value);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const [allSubadmin, setAllSubadmin] = useState([]);
  const GetAllSubadmins = async () => {
    setLoading(true);
    const token = state?.auth?.auth?.token;
    let data = await GetAllSubAdminAndAdmin(token);
    if (data && data.statusCode === 200) {
      setAllSubadmin(data.data);
      setLoading(false);
    } else {
      setAllSubadmin([]);
      setLoading(false);
      //console.log("Error while getting all subadmins");
    }
  };

  const columns = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "title",
      label: "Title ",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={(val) => {
                  getSpecificPackage(tableMeta.rowData[0]);
                  setUpdateServicePackageModal(true);
                }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={(val) => {
                  getSpecificPackage(tableMeta.rowData[0]);
                  setUpdateServicePackageModal(true);
                }}
              >
                ₹{value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "package_create_path",
      label: "Type",
      options: {
        customBodyRender: (value, tableMeta) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "createdAt",
      label: "Created At ",
      options: {
        customBodyRender: (value, tableMeta) => {
          return <div>{value}</div>;
        },
      },
    },

    {
      name: "createdAt",
      label: "Created At ",
      options: {
        customBodyRender: (value, tableMeta) => {
          return <div>{value}</div>;
        },
      },
    },

    {
      name: "customPackage",
      label: "Custom Package",
      options: {
        display: false,
      },
    },

    {
      name: "Action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div className="text-center">
              <i
                onClick={(e) => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You want to delete !",
                    // icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#540326",
                    cancelButtonColor: "#e09737",
                    confirmButtonText: "Yes, delete",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleSubmitDelete(
                        e,
                        tableMeta.rowData[0],
                        tableMeta.rowData[6]
                      );
                    }
                  });
                }}
                // onClick={(e) => {
                //   handleSubmitDelete(e,tableMeta.rowData[0],value)
                // }}
                className="fa fa-trash-can"
                style={{
                  color: "#E34242",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              ></i>
            </div>
          );
        },
      },
    },
  ];

  const columns1 = [
    {
      name: "_id",
      label: "S.No",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          return meta.rowIndex + 1;
        },
      },
    },
    {
      name: "title",
      label: "Title ",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={(val) => {
                  getSpecificPackage(tableMeta.rowData[0]);
                  setUpdateServicePackageModal1(true);
                }}
              >
                {value}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              onClick={(val) => {
                getSpecificPackage(tableMeta.rowData[0]);
                setUpdateServicePackageModal1(true);
              }}
            >
              {value}
            </div>
          );
        },
      },
    },
   
    {
      name: "referredBy",
      label: "Referred By",
    },
    {
      name: "email",
      label: "Email",
      options: {
        customBodyRender: (value, tableMeta) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "phoneNumber",
      label: "Mobile Number",
      options: {
        customBodyRender: (value, tableMeta) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              {tableMeta.rowData[5] === "Auto" ? (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(val) => {
                    getSpecificPackage(tableMeta.rowData[0]);
                    setUpdateServicePackageModal(true);
                  }}
                >
                  ₹{value}
                </div>
              ) : (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(val) => {
                    getSpecificPackage(tableMeta.rowData[0]);
                    setUpdateServicePackageModal1(true);
                  }}
                >
                  ₹{value}
                </div>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "package_create_path",
      label: "Type",
      options: {
        customBodyRender: (value, tableMeta) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "createdAt",
      label: "Created At ",
      options: {
        customBodyRender: (value, tableMeta) => {
          return <div>{value}</div>;
        },
      },
    },

    {
      name: "customPackage",
      label: "Custom Package",
      options: {
        display: false,
      },
    },

    // {
    //   name: "Action",
    //   label: "Action",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <div className="text-center">
    //           <i
    //             onClick={(e) => {
    //               Swal.fire({
    //                 title: "Are you sure?",
    //                 text: "You want to delete !",
    //                 // icon: "warning",
    //                 showCancelButton: true,
    //                 confirmButtonColor: "#540326",
    //                 cancelButtonColor: "#e09737",
    //                 confirmButtonText: "Yes, delete",
    //               }).then((result) => {
    //                 if (result.isConfirmed) {
    //                   handleSubmitDelete(
    //                     e,
    //                     tableMeta.rowData[0],
    //                     tableMeta.rowData[6]
    //                   );
    //                 }
    //               });
    //             }}
    //             // onClick={(e) => {
    //             //   handleSubmitDelete(e,tableMeta.rowData[0],value)
    //             // }}
    //             className="fa fa-trash-can"
    //             style={{
    //               color: "#E34242",
    //               fontSize: "16px",
    //               cursor: "pointer",
    //             }}
    //           ></i>
    //         </div>
    //       );
    //     },
    //   },
    // },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "standard",
    setRowProps: (row) => {
      if (row[5] === false) {
        return {
          style: { background: "#DBDFEA", cursor: "pointer" },
        };
      } else {
        return {
          style: { background: "white", cursor: "pointer" },
        };
      }
    },
  };

  return (
    <div className="service-package">
      <AdminNavbar />
      <ToastContainer />
      <div className="main">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {serviceDetails ? (
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-0 fw-bold my-2 color-maroon">
                    Subscription Package
                  </h5>
                  <div>
                    <button
                      type="button"
                      className="btn py-1 my-1 login-btn me-1"
                      onClick={() => setCreateServicePackageModal(true)}
                    >
                      Create Package
                    </button>
                    <button
                      type="button"
                      className="btn py-1 my-1 login-btn me-1"
                      onClick={() => setCreateServicePackageModal1(true)}
                    >
                      Create Custom Package
                    </button>
                  </div>
                </div>
                <div className="my-4">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-7 px-0">
                        <div className="tab-section">
                          <ul className="ps-0">
                            {AllTabs.map((tab, index) => {
                              return (
                                <div
                                  key={index}
                                  className={
                                    selectedTab === tab
                                      ? "serviceTab active"
                                      : "serviceTab inactive"
                                  }
                                  onClick={() => {
                                    if (state.auth.auth.role === "user") {
                                      if (tab === "Dalmaf Package") {
                                        fetchDataPackage("Auto");
                                      } else if (tab === "Custom") {
                                        fetchServicePackageUser("Custom");
                                      }else{
                                        fetchDataPackageAll();
                                      }
                                    } else {
                                      if (tab === "Dalmaf Package") {
                                        fetchDataPackage("Auto");
                                      } else if (tab === "Custom") {
                                        fetchDataPackage("Custom");
                                      }else{
                                        fetchDataPackageAll();
                                      }
                                    }

                                    setSelectedTab(tab);
                                  }}
                                >
                                  <li>
                                    <span className="align-middle">{tab}</span>
                                  </li>
                                </div>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {selectedTab === "Dalmaf Package" ? (
                    <MUIDataTable
                      title={"Dalmaf -  Subscription Package "}
                      data={allServicePackage}
                      columns={columns}
                      options={options}
                    />
                  ) : (
                    <MUIDataTable
                      title={"Dalmaf -  Subscription Package"}
                      data={allServicePackage}
                      columns={columns1}
                      options={options}
                    />
                  )}
                  {/* <table className="table">
                    <thead className="bg-historytablehead">
                      <tr>
                        <th scope="col" style={{ borderTopLeftRadius: "0" }}>
                          S. No
                        </th>
                        <th scope="col">client name</th>
                        <th scope="col">service request</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>

                    {getMyTask?.map((e, index) => (
                      <tbody className="historytablebody">
                        <tr
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(`/LeadEdit/${e._id}`);
                          }}
                        >
                         
                          <td>
                            <div className="">
                              <h6 className="fw-bold">{index + 1}</h6>
                            </div>
                          </td>
                          <td scope="row">
                            <div className="d-flex align-items-center ">
                              <div className=" ms-3">
                                <h6 className="fw-bold">{e?.clientName}</h6>
                              </div>
                            </div>
                          </td>

                          <td>
                            <div className=" ms-3">
                              <h6 className="fw-bold">{e?.serviceRequest}</h6>
                            </div>
                          </td>

                          <td>
                            <h6 className="fw-bold">
                              {moment(e.createdAt).format("DD/MM/YYYY")}
                            </h6>
                          </td>

                          <td>
                            <h6 className="fw-bold color-red">{e.status}</h6>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table> */}
                </div>
              </div>
            ) : (
              <div>
                <div className="">
                  <p className="mb-0">
                    {" "}
                    <span
                      style={{ cursor: "pointer" }}
                      className="mb-4 fs-5 fw-bold color-maroon mr-2"
                      onClick={() => {
                        setServiceDetails(true);
                      }}
                    >
                      Services Packages{" "}
                    </span>{" "}
                    <span className="mb-0 fs-6 color-maroon">
                      {" "}
                      \Service Details
                    </span>{" "}
                  </p>
                </div>
                <div className="container py-4 mt-5">
                  <div className="row">
                    <>
                      <div className="col-lg-8 mb-3 ps-rel">
                        <div className="ser-section">
                          <div className="d-flex mb-2 align-items-center">
                            <b>Serive Name:</b>
                            {specificPackage?.services?.map((e, i) => (
                              <h6 className="banner-heading mb-0" key={i}>
                                {e?.servicesName}
                                <li
                                  style={{
                                    padding: "3px",
                                    listStyleType: "none",
                                  }}
                                >
                                  {e?.servicesName},
                                </li>
                              </h6>
                            ))}
                          </div>
                          <p className="mb-0 ">
                            <b>
                              To Whome:{" "}
                              <span className="banner-heading">
                                {specificPackage?.toWhom}
                              </span>
                            </b>
                          </p>
                          <p className="mb-0 ">
                            <b>
                              Price:{" "}
                              <span className="banner-heading">
                                {specificPackage?.price}
                              </span>
                            </b>
                          </p>
                          <p className="mb-0 ">
                            <b>
                              GST:{" "}
                              <span className="banner-heading">
                                {specificPackage?.gstPercentage}
                              </span>
                            </b>
                          </p>
                          <p className="mb-0 ">
                            <b>
                              Created At:{" "}
                              <span className="banner-heading">
                                {moment(specificPackage?.createdAt).format(
                                  "DD/MM-YYYY"
                                )}
                              </span>
                            </b>
                          </p>
                          <p className="mb-0 ">
                            <b>
                              Status:{" "}
                              <span className="banner-heading">
                                {specificPackage?.status}
                              </span>
                            </b>
                          </p>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <CustomModal
          open={createServicePackageModal}
          onClickOutside={() => {
            setCreateServicePackageModal(false);
            // window.location.reload();
            setServicePackDetail({});
          }}
        >
          <div className="modal-content bg-white  m-auto">
            <div className="modalHeader text-center py-2">
              <h5 className="font-bold mt-2">Create Package</h5>
            </div>
            <div className="modal-body p-0 ">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="my-4 px-3 create-package-scroll">
                          <div className="mb-3">
                            <label for="services" className="form-label">
                              Title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Title"
                              aria-invalid={Boolean(
                                servicePackDetailValidation?.title?.status ===
                                  false
                              )}
                              defaultValue={servicePackDetail?.title}
                              onChange={(e) => {
                                setservicePackDetailValue(
                                  "title",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setservicePackDetailValidationValue(
                                  "title",
                                  NonEmptyValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation?.title?.message
                                ? `Title ${servicePackDetailValidation?.title?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="mb-3">
                            <label for="" className="form-label">
                              Service
                            </label>
                            <CreatableSelect
                              options={packageService}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: "6px",
                                zIndex: 3,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#DFF1F1", // selected option background color
                                  neutral20:
                                    servicePackDetailValidation?.servicesName
                                      ?.status === false
                                      ? "red"
                                      : " #540326", // border color
                                  primary:
                                    servicePackDetailValidation?.servicesName
                                      ?.status === false
                                      ? "red"
                                      : " #540326", // border color when focus
                                },
                              })}
                              styles={{
                                menu: (provided, state) => ({
                                  ...provided,
                                  zIndex: "5 !important",
                                }),
                              }}
                              placeholder="Choose Service Name"
                              isClearable
                              isMulti
                              value={
                                servicePackDetail?.servicesName
                                  ? servicePackDetail?.servicesName?.map(
                                      (v) => {
                                        return {
                                          value: v.value ? v.value : v.value,
                                          label: v.label ? v.label : v.label,
                                          _id: v._id ? v._id : v._id,
                                        };
                                      }
                                    )
                                  : []
                              }
                              onInputChange={() => {
                                if (servicePackDetailValidation["servicesName"])
                                  delete servicePackDetailValidation[
                                    "servicesName"
                                  ];
                              }}
                              onChange={(newValue) => {
                                setservicePackDetailValue(
                                  "servicesName",
                                  newValue
                                );
                              }}
                              onCreateOption={handleCreateServiceName}
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation?.servicesName
                                ?.message
                                ? `Service name ${servicePackDetailValidation?.servicesName?.message}`
                                : ""}
                            </small>
                          </div>

                          <div className="mb-3">
                            <label for="services" className="form-label">
                              To Whom
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setservicePackDetailValue(
                                  "toWhom",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setservicePackDetailValidationValue(
                                  "toWhom",
                                  NonEmptyValidation(e.target.value)
                                );
                              }}
                            >
                              <option value="">---Select---</option>
                              <option value="individual">Individual</option>
                              <option value="company institutions">
                                Company And Institutions
                              </option>
                              <option value="company retainership">
                                Company Retainership
                              </option>
                            </select>
                            <small className="text-danger">
                              {servicePackDetailValidation?.toWhom?.message
                                ? `To whom ${servicePackDetailValidation?.toWhom?.message}`
                                : ""}
                            </small>
                          </div>
                          {/* <div className="mb-3">
                            <label for="" className="form-label">
                              Package Type
                            </label>
                            <CreatableSelect
                              options={gstPercentage}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: "6px",
                                zIndex: 3,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#DFF1F1", // selected option background color
                                  neutral20:
                                    servicePackDetailValidation?.packageType?.status ===
                                    false
                                      ? "red"
                                      : " #540326", // border color
                                  primary:
                                    servicePackDetailValidation?.packageType?.status ===
                                    false
                                      ? "red"
                                      : " #540326", // border color when focus
                                },
                              })}
                              styles={{
                                menu: (provided, state) => ({
                                  ...provided,
                                  zIndex: "5 !important",
                                }),
                              }}
                              placeholder="Choose Department Name"
                              isClearable
                              value={
                                servicePackDetail?.packageType
                                  ? {
                                      value: servicePackDetail?.packageType?.value,
                                      label: servicePackDetail?.packageType?.label,
                                      _id: servicePackDetail?.packageType?._id,
                                    }
                                  : {}
                              }
                              onInputChange={() => {
                                if (servicePackDetailValidation["packageType"])
                                  delete servicePackDetailValidation["packageType"];
                              }}
                              onChange={(newValue) => {
                                setservicePackDetailValue("packageType", newValue);
                              }}
                              onCreateOption={handleCreateServiceType}
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation?.packageType?.message
                                ? `Package Type ${servicePackDetailValidation?.title?.message}`
                                : ""}
                            </small>
                          </div> */}

                          <div className="mb-3">
                            <label for="" className="form-label">
                              Price
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Price"
                              aria-invalid={Boolean(
                                servicePackDetailValidation?.price?.status ===
                                  false
                              )}
                              defaultValue={servicePackDetail?.price}
                              onChange={(e) => {
                                setservicePackDetailValue(
                                  "price",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setservicePackDetailValidationValue(
                                  "price",
                                  NumberValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation?.price?.message
                                ? `Price ${servicePackDetailValidation?.price?.message}`
                                : ""}
                            </small>
                          </div>
                          {/* <div className="mb-3">
                            <input
                              className=" me-3"
                              type="checkbox"
                              value={servicePackDetail?.gst}
                              onChange={(e) => {
                                setservicePackDetailValue("gst", e.target.checked);
                              }}
                              autoComplete="off"
                            />{" "}
                            <label for="" className="form-label">
                              GST
                            </label>
                            <small className="text-danger">
                              {servicePackDetailValidation?.gst?.message
                                ? `GST ${servicePackDetailValidation?.gst?.message}`
                                : ""}
                            </small>
                          </div> */}
                          <div className="mb-3">
                            <label for="" className="form-label">
                              GST Amount
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter gst amount"
                              aria-invalid={Boolean(
                                servicePackDetailValidation?.gstPercentage
                                  ?.status === false
                              )}
                              defaultValue={servicePackDetail?.gstPercentage}
                              onChange={(e) => {
                                setservicePackDetailValue(
                                  "gstPercentage",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setservicePackDetailValidationValue(
                                  "gstPercentage",
                                  NumberValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation?.gstPercentage
                                ?.message
                                ? `GST Percentage ${servicePackDetailValidation?.gstPercentage?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="mb-3">
                            <label for="" className="form-label">
                              Package Periods in Days
                            </label>
                            <input
                              type="type"
                              placeholder="Enter in days"
                              className="form-control"
                              aria-invalid={Boolean(
                                servicePackDetailValidation?.package_periods
                                  ?.status === false
                              )}
                              defaultValue={servicePackDetail?.package_periods}
                              onChange={(e) => {
                                setservicePackDetailValue(
                                  "package_periods",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setservicePackDetailValidationValue(
                                  "package_periods",
                                  NumberValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation?.package_periods
                                ?.message
                                ? `Package period ${servicePackDetailValidation?.package_periods?.message}`
                                : ""}
                            </small>
                          </div>

                          <div className="mb-3">
                            <label for="" className="form-label">
                              Description
                            </label>
                            <div
                              className="form-control"
                              // style={{ overflowY: "scroll", height: "400px" }}
                            >
                               <SunEditor
                                  setOptions={{
                                    height: 400,
                                    buttonList: [
                                      ["undo", "redo"],
                                      ["font", "fontSize", "formatBlock"],
                                      [
                                        "bold",
                                        "underline",
                                        "italic",
                                        "strike",
                                        "subscript",
                                        "superscript",
                                      ],
                                      ["fontColor", "hiliteColor", "textStyle"],
                                      ["removeFormat"],
                                      "/", // Line break
                                      [
                                        "align",
                                        "horizontalRule",
                                        "list",
                                        "lineHeight",
                                      ],
                                      ["table", "link", "image", "video"],
                                      ["fullScreen", "showBlocks", "codeView"],
                                      ["preview", "print"],
                                      ["save", "template"],
                                    ],
                                  }}
                                  setContents={content}
                                  onChange={(content) => {
                                    setContent(content);
                                  }}
                                />
                              {/* <Editor
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                editorState={editorState}
                                onEditorStateChange={(e) =>
                                  setservicePackDetailValue("description", e)
                                }
                              /> */}
                            </div>
                            <small className="text-danger">
                              {servicePackDetailValidation?.description?.message
                                ? `Description ${servicePackDetailValidation?.description?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-center border-0">
            <div>
              <button
                type="button"
                className="btn mx-2 white-btn"
                onClick={() => {
                  const editorStateReset = EditorState.push(
                    editorState,
                    ContentState.createFromText("")
                  );
                  setEditorState(editorStateReset);
                  setCreateServicePackageModal(false);
                  // window.location.reload();
                  setServicePackDetail({});
                  setServicePackDetailValidation({});
                  setMsg("");
                  setInputFieldValidation({});
                }}
              >
                {" "}
                Cancel
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn mx-2  login-btn"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                {" "}
                Save
              </button>
            </div>
          </div>
        </CustomModal>
        <CustomModal
          open={createServicePackageModal1}
          onClickOutside={() => {
            setCreateServicePackageModal1(false);
            // window.location.reload();

            setServicePackDetail({});
          }}
        >
          <div className="modal-content bg-white  m-auto">
            <div className="modalHeader text-center py-2">
              <h5 className="font-bold mt-2">Create Custom Package</h5>
            </div>
            <div className="modal-body p-0 ">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="my-4 px-3 create-package-scroll">
                          <div className="mb-3">
                            <label for="" className="form-label">
                              Mobile Number
                            </label>
                            <input
                              type="text"
                              placeholder="Enter mobile number"
                              className="form-control"
                              aria-invalid={Boolean(
                                servicePackDetailValidation?.phoneNumber
                                  ?.status === false
                              )}
                              maxLength={10}
                              defaultValue={servicePackDetail?.phoneNumber}
                              onChange={(e) => {
                                setservicePackDetailValue(
                                  "phoneNumber",
                                  e.target.value
                                );
                                fetchUserInfo(e.target.value);
                              }}
                              onBlur={(e) => {
                                setservicePackDetailValidationValue(
                                  "phoneNumber",
                                  PhoneNumberValidation(e.target.value)
                                );
                                fetchUserInfo(e.target.value);
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation?.phoneNumber?.message
                                ? `Mobile Number ${servicePackDetailValidation?.phoneNumber?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="mb-3">
                            <label for="services" className="form-label">
                              Title
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Title"
                              aria-invalid={Boolean(
                                servicePackDetailValidation?.title?.status ===
                                  false
                              )}
                              defaultValue={servicePackDetail?.title}
                              onChange={(e) => {
                                setservicePackDetailValue(
                                  "title",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setservicePackDetailValidationValue(
                                  "title",
                                  NonEmptyValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />

                            <small className="text-danger">
                              {servicePackDetailValidation?.title?.message
                                ? `Title ${servicePackDetailValidation?.title?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="mb-3">
                            <label for="" className="form-label">
                              Name
                            </label>
                            <input
                              type="text"
                              placeholder="Enter your name"
                              className="form-control"
                              aria-invalid={Boolean(
                                servicePackDetailValidation?.name?.status ===
                                  false
                              )}
                              defaultValue={servicePackDetail?.name}
                              onChange={(e) => {
                                setservicePackDetailValue(
                                  "name",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setservicePackDetailValidationValue(
                                  "name",
                                  StringValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />

                            <small className="text-danger">
                              {servicePackDetailValidation?.name?.message
                                ? `Name ${servicePackDetailValidation?.name?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="mb-3">
                            <label for="" className="form-label">
                              Email
                            </label>
                            <input
                              type="text"
                              placeholder="Enter your email"
                              className="form-control"
                              aria-invalid={Boolean(
                                servicePackDetailValidation?.email?.status ===
                                  false
                              )}
                              defaultValue={servicePackDetail?.email}
                              onChange={(e) => {
                                setservicePackDetailValue(
                                  "email",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setservicePackDetailValidationValue(
                                  "email",
                                  EmailValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />

                            <small className="text-danger">
                              {servicePackDetailValidation?.email?.message
                                ? `Email ${servicePackDetailValidation?.email?.message}`
                                : ""}
                            </small>
                          </div>

                          <div className="mb-3">
                            <label for="" className="form-label">
                              Price
                            </label>
                            <input
                              type="type"
                              className="form-control"
                              placeholder="Enter Price"
                              aria-invalid={Boolean(
                                servicePackDetailValidation?.price?.status ===
                                  false
                              )}
                              defaultValue={servicePackDetail?.price}
                              onChange={(e) => {
                                setservicePackDetailValue(
                                  "price",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setservicePackDetailValidationValue(
                                  "price",
                                  NumberValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation?.price?.message
                                ? `Price ${servicePackDetailValidation?.price?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="mb-3">
                            <label for="services" className="form-label">
                              Referred By
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setservicePackDetailValue(
                                  "referredBy",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setservicePackDetailValidationValue(
                                  "referredBy",
                                  NonEmptyValidation(e.target.value)
                                );
                              }}
                            >
                              <option value="">---Select---</option>
                              {allSubadmin?.map((e) => (
                                <option value={e._id}>{e.name}</option>
                              ))}
                            </select>
                            <small className="text-danger">
                              {servicePackDetailValidation?.referredBy?.message
                                ? `Referred By ${servicePackDetailValidation?.referredBy?.message}`
                                : ""}
                            </small>
                          </div>

                          {/* <div className="mb-3">
                            <label for="" className="form-label">
                              GST Percentage
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter gst percentage"
                              aria-invalid={Boolean(
                                servicePackDetailValidation?.gstPercentage?.status ===
                                  false
                              )}
                              defaultValue={servicePackDetail?.gstPercentage}
                              onChange={(e) => {
                                setservicePackDetailValue(
                                  "gstPercentage",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setservicePackDetailValidationValue(
                                  "gstPercentage",
                                  NumberValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation?.gstPercentage?.message
                                ? `GST Percentage ${servicePackDetailValidation?.gstPercentage?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="mb-3">
                            <label for="" className="form-label">
                              Package Periods in days
                            </label>
                            <input
                              type="number"
                              placeholder="Enter in days"
                              className="form-control"
                              aria-invalid={Boolean(
                                servicePackDetailValidation?.package_periods?.status ===
                                  false
                              )}
                              defaultValue={servicePackDetail?.package_periods}
                              onChange={(e) => {
                                setservicePackDetailValue(
                                  "package_periods",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setservicePackDetailValidationValue(
                                  "package_periods",
                                  NumberValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation?.package_periods?.message
                                ? `Package period ${servicePackDetailValidation?.package_periods?.message}`
                                : ""}
                            </small>
                          </div> */}

                          <div className="mb-3">
                            <label for="" className="form-label">
                              Description
                            </label>
                            <div
                              className="form-control"
                              // style={{ overflowY: "scroll", height: "400px" }}
                            >
                               <SunEditor
                                  setOptions={{
                                    height: 400,
                                    buttonList: [
                                      ["undo", "redo"],
                                      ["font", "fontSize", "formatBlock"],
                                      [
                                        "bold",
                                        "underline",
                                        "italic",
                                        "strike",
                                        "subscript",
                                        "superscript",
                                      ],
                                      ["fontColor", "hiliteColor", "textStyle"],
                                      ["removeFormat"],
                                      "/", // Line break
                                      [
                                        "align",
                                        "horizontalRule",
                                        "list",
                                        "lineHeight",
                                      ],
                                      ["table", "link", "image", "video"],
                                      ["fullScreen", "showBlocks", "codeView"],
                                      ["preview", "print"],
                                      ["save", "template"],
                                    ],
                                  }}
                                  setContents={content1}
                                  onChange={(content) => {
                                    setContent1(content);
                                  }}
                                />
                              {/* <Editor
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                editorState={editorState}
                                onEditorStateChange={(e) =>
                                  handleEditorChange(e)
                                }
                              /> */}
                            </div>
                            <small className="text-danger">
                              {servicePackDetailValidation?.description?.message
                                ? `Description ${servicePackDetailValidation?.description?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-center border-0">
            <div>
              <button
                type="button"
                className="btn mx-2 white-btn"
                onClick={() => {
                  setCreateServicePackageModal1(false);
                  // window.location.reload();
                  setServicePackDetail({});
                  setServicePackDetailValidation({});
                  setMsg("");
                  // setInputFieldValidation({})
                }}
              >
                {" "}
                Cancel
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn mx-2  login-btn"
                onClick={(e) => {
                  
                  handleSubmitCustom(e);
                }}
                disabled={btnDisable}
              >
                {" "}
                Save
              </button>
            </div>
          </div>
        </CustomModal>
        <CustomModal
          open={updateServicePackageModal}
          onClickOutside={() => {
            setUpdateServicePackageModal(false);
            // window.location.reload();
          }}
        >
          <div className="modal-content bg-white  m-auto">
            <div className="modalHeader text-center py-2">
              <h5 className="font-bold mt-2">Package</h5>
            </div>
            <div className="modal-body p-0 ">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="my-4 px-3 create-package-scroll">
                          <div className="mb-3">
                            <label for="services" className="form-label">
                              Title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Title"
                              aria-invalid={Boolean(
                                servicePackDetailValidation1?.title?.status ===
                                  false
                              )}
                              defaultValue={servicePackDetailDetail1?.title}
                              onChange={(e) => {
                                setServicePackDetailValue1(
                                  "title",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setServicePackDetailValidationValue1(
                                  "title",
                                  StringValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation1?.title?.message
                                ? `Title ${servicePackDetailValidation1?.title?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="mb-3">
                            <label for="" className="form-label">
                              Service
                            </label>
                            <CreatableSelect
                              options={packageService}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: "6px",
                                zIndex: 3,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#DFF1F1", // selected option background color
                                  neutral20:
                                    servicePackDetailValidation1?.services
                                      ?.status === false
                                      ? "red"
                                      : " #540326", // border color
                                  primary:
                                    servicePackDetailValidation1?.services
                                      ?.status === false
                                      ? "red"
                                      : " #540326", // border color when focus
                                },
                              })}
                              styles={{
                                menu: (provided, state) => ({
                                  ...provided,
                                  zIndex: "5 !important",
                                }),
                              }}
                              placeholder="Choose Service Name"
                              isClearable
                              isMulti
                              value={
                                servicePackDetailDetail1?.services
                                  ? servicePackDetailDetail1?.services?.map(
                                      (v) => {
                                        return {
                                          value: v.servicesName
                                            ? v.servicesName
                                            : v.value,
                                          label: v.servicesName
                                            ? v.servicesName
                                            : v.label,
                                          _id: v._id ? v._id : v._id,
                                        };
                                      }
                                    )
                                  : []
                              }
                              onInputChange={() => {
                                if (servicePackDetailValidation1["services"])
                                  delete servicePackDetailValidation1[
                                    "services"
                                  ];
                              }}
                              onChange={(newValue) => {
                                setServicePackDetailValue1(
                                  "services",
                                  newValue
                                );
                              }}
                              onCreateOption={handleCreateServiceName1}
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation1?.services?.message
                                ? `Service name ${servicePackDetailValidation1?.services?.message}`
                                : ""}
                            </small>
                          </div>

                          <div className="mb-3">
                            <label for="services" className="form-label">
                              To Whom
                            </label>
                            <select
                              className="form-control"
                              value={servicePackDetailDetail1?.toWhom}
                              onChange={(e) => {
                                setServicePackDetailValue1(
                                  "toWhom",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setServicePackDetailValidationValue1(
                                  "toWhom",
                                  NonEmptyValidation(e.target.value)
                                );
                              }}
                            >
                              <option value="">---Select---</option>
                              <option value="individual">Individual</option>
                              <option value="company institutions">
                                Company And Institutions
                              </option>
                              <option value="company retainership">
                                Company Retainership
                              </option>
                            </select>
                            <small className="text-danger">
                              {servicePackDetailValidation1?.toWhom?.message
                                ? `To whom ${servicePackDetailValidation1?.toWhom?.message}`
                                : ""}
                            </small>
                          </div>
                          {/* <div className="mb-3">
                            <label for="" className="form-label">
                              Package Type
                            </label>
                            <CreatableSelect
                              options={packageType}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: "6px",
                                zIndex: 3,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#DFF1F1", // selected option background color
                                  neutral20:
                                    servicePackDetailValidation1?.packageType?.status ===
                                    false
                                      ? "red"
                                      : " #540326", // border color
                                  primary:
                                    servicePackDetailValidation1?.packageType?.status ===
                                    false
                                      ? "red"
                                      : " #540326", // border color when focus
                                },
                              })}
                              styles={{
                                menu: (provided, state) => ({
                                  ...provided,
                                  zIndex: "5 !important",
                                }),
                              }}
                              placeholder="Choose Department Name"
                              isClearable
                              value={
                                servicePackDetailDetail1?.packageType
                                  ? {
                                      value:
                                        servicePackDetailDetail1?.packageType
                                          ?.packageName ||
                                        servicePackDetailDetail1?.packageType?.value,
                                      label:
                                        servicePackDetailDetail1?.packageType
                                          ?.packageName ||
                                        servicePackDetailDetail1?.packageType?.label,
                                      _id:
                                        servicePackDetailDetail1?.packageType?._id ||
                                        servicePackDetailDetail1?.packageType?._id,
                                    }
                                  : {}
                              }
                              onInputChange={() => {
                                if (servicePackDetailValidation1["packageType"])
                                  delete servicePackDetailValidation1["packageType"];
                              }}
                              onChange={(newValue) => {
                                setServicePackDetailValue1(
                                  "packageType",
                                  newValue
                                );
                              }}
                              onCreateOption={handleCreateServiceType1}
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation1?.packageType?.message
                                ? `Package Type ${servicePackDetailValidation1?.packageType?.message}`
                                : ""}
                            </small>
                          </div> */}

                          <div className="mb-3">
                            <label for="" className="form-label">
                              Price
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              aria-invalid={Boolean(
                                servicePackDetailValidation1?.price?.status ===
                                  false
                              )}
                              value={servicePackDetailDetail1?.price}
                              onChange={(e) => {
                                setServicePackDetailValue1(
                                  "price",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setServicePackDetailValidationValue1(
                                  "price",
                                  NumberValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation1?.price?.message
                                ? `Price ${servicePackDetailValidation1?.price?.message}`
                                : ""}
                            </small>
                          </div>
                          {/* <div className="mb-3">
                            <input
                              className=" me-3"
                              type="checkbox"
                              defaultValue={servicePackDetailDetail1?.gst}
                              onChange={(e) => {
                                setServicePackDetailValue1(
                                  "gst",
                                  e.target.checked
                                );
                              }}
                              checked={servicePackDetailDetail1?.gst === true}
                              autoComplete="off"
                            />{" "}
                            <label for="" className="form-label">
                              GST
                            </label>
                            <small className="text-danger">
                              {servicePackDetailValidation1?.gst?.message
                                ? `GST ${servicePackDetailValidation1?.gst?.message}`
                                : ""}
                            </small>
                          </div> */}
                          <div className="mb-3">
                            <label for="" className="form-label">
                              GST Percentage
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              aria-invalid={Boolean(
                                servicePackDetailValidation1?.gstPercentage
                                  ?.status === false
                              )}
                              value={servicePackDetailDetail1?.gstPercentage}
                              onChange={(e) => {
                                setServicePackDetailValue1(
                                  "gstPercentage",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setServicePackDetailValidationValue1(
                                  "gstPercentage",
                                  NumberValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation1?.gstPercentage
                                ?.message
                                ? `GST Percentage ${servicePackDetailValidation1?.gstPercentage?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="mb-3">
                            <label for="" className="form-label">
                              Package Periods in days
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              aria-invalid={Boolean(
                                servicePackDetailValidation1?.package_periods
                                  ?.status === false
                              )}
                              value={servicePackDetailDetail1?.package_periods}
                              onChange={(e) => {
                                setServicePackDetailValue1(
                                  "package_periods",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setServicePackDetailValidationValue1(
                                  "package_periods",
                                  NumberValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation1?.package_periods
                                ?.message
                                ? `Package Periods ${servicePackDetailValidation1?.package_periods?.message}`
                                : ""}
                            </small>
                          </div>

                          <div className="mb-3">
                            <label for="" className="form-label">
                              Description
                            </label>
                            <div
                              className="form-control"
                              // style={{ overflowY: "scroll", height: "400px" }}
                            >
                              <SunEditor
                                  setOptions={{
                                    height: 400,
                                    buttonList: [
                                      ["undo", "redo"],
                                      ["font", "fontSize", "formatBlock"],
                                      [
                                        "bold",
                                        "underline",
                                        "italic",
                                        "strike",
                                        "subscript",
                                        "superscript",
                                      ],
                                      ["fontColor", "hiliteColor", "textStyle"],
                                      ["removeFormat"],
                                      "/", // Line break
                                      [
                                        "align",
                                        "horizontalRule",
                                        "list",
                                        "lineHeight",
                                      ],
                                      ["table", "link", "image", "video"],
                                      ["fullScreen", "showBlocks", "codeView"],
                                      ["preview", "print"],
                                      ["save", "template"],
                                    ],
                                  }}
                                  setContents={content}
                                  onChange={(content) => {
                                    setContent(content);
                                  }}
                                />
                              {/* <Editor
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                editorState={
                                  servicePackDetailDetail1?.description
                                }
                                onEditorStateChange={(e) =>
                                  setServicePackDetailValue1("description", e)
                                }
                              /> */}
                            </div>
                            <small className="text-danger">
                              {servicePackDetailValidation?.description?.message
                                ? `Description ${servicePackDetailValidation?.description?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="mb-3">
                            <label for="services" className="form-label">
                              Status
                            </label>
                            <select
                              className="form-control"
                              value={servicePackDetailDetail1?.status}
                              onChange={(e) => {
                                setServicePackDetailValue1(
                                  "status",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setServicePackDetailValidationValue1(
                                  "status",
                                  NonEmptyValidation(e.target.value)
                                );
                              }}
                            >
                              <option value="active">Active</option>
                              <option value="inactive">In Active</option>
                            </select>
                            <small className="text-danger">
                              {servicePackDetailValidation1?.status?.message
                                ? `To whom ${servicePackDetailValidation1?.status?.message}`
                                : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-center border-0">
            <div>
              <button
                type="button"
                className="btn mx-2 white-btn"
                onClick={() => {
                  setUpdateServicePackageModal(false);
                  // window.location.reload();
                  setservicePackDetailDetails1({});
                  setservicePackDetailValidation1({});
                  setMsg("");
                  setContent("")
                  // setInputFieldValidation({})
                }}
              >
                {" "}
                Cancel
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn mx-2  login-btn"
                onClick={(e) => {
                  handleSubmitUpdate(e);
                }}
              >
                {" "}
                Save
              </button>
            </div>
          </div>
        </CustomModal>
        <CustomModal
          open={updateServicePackageModal1}
          onClickOutside={() => {
            setUpdateServicePackageModal1(false);
            // window.location.reload();
          }}
        >
          <div className="modal-content bg-white  m-auto">
            <div className="modalHeader text-center py-2">
              <h5 className="font-bold mt-2">Update Package</h5>
            </div>
            <div className="modal-body p-0 ">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center  mt-0">
                  <div className="container ">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="my-4 px-3 create-package-scroll">
                          <div className="mb-3">
                            <label for="" className="form-label">
                              Mobile Number
                            </label>
                            <input
                              type="text"
                              placeholder="Enter mobile number"
                              className="form-control"
                              maxLength={10}
                              aria-invalid={Boolean(
                                servicePackDetailValidation1?.phoneNumber
                                  ?.status === false
                              )}
                              defaultValue={
                                servicePackDetailDetail1?.phoneNumber


                              }

                              
                              onChange={(e) => {
                                setServicePackDetailValue1(
                                  "phoneNumber",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setServicePackDetailValidationValue1(
                                  "phoneNumber",
                                  PhoneNumberValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation1?.phoneNumber
                                ?.message
                                ? `Mobile Number ${servicePackDetailValidation1?.phoneNumber?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="mb-3">
                            <label for="services" className="form-label">
                              Title
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Title"
                              aria-invalid={Boolean(
                                servicePackDetailValidation1?.title?.status ===
                                  false
                              )}
                              defaultValue={servicePackDetailDetail1?.title}
                              onChange={(e) => {
                                setServicePackDetailValue1(
                                  "title",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setServicePackDetailValidationValue1(
                                  "title",
                                  StringValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />

                            <small className="text-danger">
                              {servicePackDetailValidation1?.title?.message
                                ? `Title ${servicePackDetailValidation1?.title?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="mb-3">
                            <label for="" className="form-label">
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              aria-invalid={Boolean(
                                servicePackDetailValidation1?.name?.status ===
                                  false
                              )}
                              value={servicePackDetailDetail1?.name}
                              onChange={(e) => {
                                setServicePackDetailValue1(
                                  "name",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setServicePackDetailValidationValue1(
                                  "name",
                                  NumberValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation1?.name?.message
                                ? `Name ${servicePackDetailValidation1?.name?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="mb-3">
                            <label for="" className="form-label">
                              Email
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              aria-invalid={Boolean(
                                servicePackDetailValidation1?.email?.status ===
                                  false
                              )}
                              value={servicePackDetailDetail1?.email}
                              onChange={(e) => {
                                setServicePackDetailValue1(
                                  "email",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setServicePackDetailValidationValue1(
                                  "email",
                                  EmailValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation1?.email?.message
                                ? `Email ${servicePackDetailValidation1?.email?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="mb-3">
                            <label for="" className="form-label">
                              Price
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              aria-invalid={Boolean(
                                servicePackDetailValidation1?.price?.status ===
                                  false
                              )}
                              value={servicePackDetailDetail1?.price}
                              onChange={(e) => {
                                setServicePackDetailValue1(
                                  "price",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setServicePackDetailValidationValue1(
                                  "price",
                                  NumberValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation1?.price?.message
                                ? `Price ${servicePackDetailValidation1?.price?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="mb-3">
                            <label for="services" className="form-label">
                              Referred By
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setServicePackDetailValue1(
                                  "referredBy",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setServicePackDetailValidationValue1(
                                  "referredBy",
                                  NonEmptyValidation(e.target.value)
                                );
                              }}
                            >
                              <option value={servicePackDetailDetail1?.referredBy?._id}>{servicePackDetailDetail1?.referredBy?.name}</option>
                              {/* {console.log("allSubadmin",allSubadmin)} */}
                              {allSubadmin?.filter(data => data._id != servicePackDetailDetail1?.referredBy?._id ).map((e) => (
                                <option value={e._id}>{e.name}</option>
                              ))}
                            </select>
                            <small className="text-danger">
                              {servicePackDetailValidation?.referredBy?.message
                                ? `Referred By ${servicePackDetailValidation?.referredBy?.message}`
                                : ""}
                            </small>
                          </div>
                          {/* <div className="mb-3">
                            <label for="" className="form-label">
                              GST Percentage
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              aria-invalid={Boolean(
                                servicePackDetailValidation1?.gstPercentage?.status ===
                                  false
                              )}
                              value={servicePackDetailDetail1?.gstPercentage}
                              onChange={(e) => {
                                setServicePackDetailValue1(
                                  "gstPercentage",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setServicePackDetailValidationValue1(
                                  "gstPercentage",
                                  NumberValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation1?.gstPercentage?.message
                                ? `GST Percentage ${servicePackDetailValidation1?.gstPercentage?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="mb-3">
                            <label for="" className="form-label">
                              Package Periods in days
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              aria-invalid={Boolean(
                                servicePackDetailValidation1?.package_periods?.status ===
                                  false
                              )}
                              value={servicePackDetailDetail1?.package_periods}
                              onChange={(e) => {
                                setServicePackDetailValue1(
                                  "package_periods",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setServicePackDetailValidationValue1(
                                  "package_periods",
                                  NumberValidation(e.target.value)
                                );
                              }}
                              autoComplete="off"
                            />
                            <small className="text-danger">
                              {servicePackDetailValidation1?.package_periods?.message
                                ? `Package Periods ${servicePackDetailValidation1?.package_periods?.message}`
                                : ""}
                            </small>
                          </div> */}

                          <div className="mb-3">
                            <label for="" className="form-label">
                              Description
                            </label>
                            <div
                              className="form-control"
                              style={{ overflowY: "scroll", height: "400px" }}
                            >
                               <SunEditor
                                  setOptions={{
                                    height: 400,
                                    buttonList: [
                                      ["undo", "redo"],
                                      ["font", "fontSize", "formatBlock"],
                                      [
                                        "bold",
                                        "underline",
                                        "italic",
                                        "strike",
                                        "subscript",
                                        "superscript",
                                      ],
                                      ["fontColor", "hiliteColor", "textStyle"],
                                      ["removeFormat"],
                                      "/", // Line break
                                      [
                                        "align",
                                        "horizontalRule",
                                        "list",
                                        "lineHeight",
                                      ],
                                      ["table", "link", "image", "video"],
                                      ["fullScreen", "showBlocks", "codeView"],
                                      ["preview", "print"],
                                      ["save", "template"],
                                    ],
                                  }}
                                  setContents={content}
                                  onChange={(content) => {
                                    setContent(content);
                                  }}
                                />
                              {/* <Editor
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                editorState={
                                  servicePackDetailDetail1?.description
                                }
                                onEditorStateChange={(e) =>
                                  setServicePackDetailValue1("description", e)
                                }
                              /> */}
                            </div>
                            <small className="text-danger">
                              {servicePackDetailValidation?.description?.message
                                ? `Description ${servicePackDetailValidation?.description?.message}`
                                : ""}
                            </small>
                          </div>
                          <div className="mb-3">
                            <label for="services" className="form-label">
                              Status
                            </label>
                            <select
                              className="form-control"
                              value={servicePackDetailDetail1?.status}
                              onChange={(e) => {
                                setServicePackDetailValue1(
                                  "status",
                                  e.target.value
                                );
                              }}
                              onBlur={(e) => {
                                setServicePackDetailValidationValue1(
                                  "status",
                                  NonEmptyValidation(e.target.value)
                                );
                              }}
                            >
                              <option value="active">Active</option>
                              <option value="inactive">In Active</option>
                            </select>
                            <small className="text-danger">
                              {servicePackDetailValidation1?.status?.message
                                ? `Status ${servicePackDetailValidation1?.status?.message}`
                                : ""}
                            </small>
                          </div>
                          {/* <div className="mb-3">
                            <label for="" className="form-label">
                              Description
                            </label>
                            <div
                                className="form-control"
                                style={{ overflowY: "scroll", height: "400px" }}
                              >
                                 <Editor
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            editorState={servicePackDetailDetail1?.description}
                            onEditorStateChange={(e) =>
                              setServicePackDetailValue1("description", e)
                            }
                          />
                                </div>
                            <small className="text-danger">
                              {servicePackDetailValidation?.description?.message
                                ? `Description ${servicePackDetailValidation?.description?.message}`
                                : ""}
                            </small>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-center border-0">
            <div>
              <button
                type="button"
                className="btn mx-2 white-btn"
                onClick={() => {
                  setUpdateServicePackageModal1(false);
                  // window.location.reload();
                  setservicePackDetailDetails1({});
                  setservicePackDetailValidation1({});
                  setMsg("");
                  setContent("")
                  // setInputFieldValidation({})
                }}
              >
                {" "}
                Cancel
              </button>
            </div>
            <div>
            <div className="modal-footer justify-content-center border-0">
            <div>
              
            <button
                type="button"
                className="btn mx-2  login-btn"
                onClick={(e) => {
                  handleSubmitUpdateCustom(e);
                  // console.log("test")
                }}
              >
                {" "}
              Update
              </button></div></div>
             
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
}
