import React, { useEffect, useState } from 'react';
import './BestServices.css'; // Import CSS file for styling
import { useNavigate } from 'react-router-dom';
import { fetchAllService } from '../../../Api/v1/service/Api';

export default function ListAllServices() {
    useEffect(() => {
        window.scrollTo(0, 0);
        getServices();
    }, []);

    const navigate = useNavigate();
    const [services, setServices] = useState([]);
    const [mainServiceFilter, setMainServiceFilter] = useState('');
    const [subServiceFilter, setSubServiceFilter] = useState('');
    const [loading, setLoading] = useState(true);

    const getUrlParameter = (name) => {
        const params = new URLSearchParams(window.location.search);
        let value = params.get(name);
        if (value) {
            value = value.replace(/%27/g, ''); // Remove %27 from the value
        }
        return value;
    };
    

    const getServices = async () => {
        let data = await fetchAllService();
        if (data && data.statusCode === 200) {
            setServices(data?.data);
            let filterBy = getUrlParameter('filterBy')
            if(filterBy){
                handleMainServiceClick(filterBy)
            }
        } else {
            setServices([]);
        }
        setLoading(false);
    };

    const handleMainServiceClick = (title) => {
        setMainServiceFilter(title);
        setSubServiceFilter('');
    };

    const handleSubServiceClick = (title) => {
        setSubServiceFilter(title);
    };

    const filteredServices = services.service_details?.filter((service) => {
        return (
            (mainServiceFilter === '' || service.mainServiceId.title === mainServiceFilter) &&
            (subServiceFilter === '' || service.subServiceL1Id.title === subServiceFilter)
        );
    });

    const uniqueMainServices = [...new Set(services.service_details?.map(service => service.mainServiceId.title))];
    const uniqueSubServices = [...new Set(
        services.service_details
            ?.filter(service => mainServiceFilter === '' || service.mainServiceId.title === mainServiceFilter)
            .map(service => service.subServiceL1Id.title)
    )];

    return (
        <div className="container py-5">
            <div className="row">
                <div className="col-md-3 fixed-height">
                    <h5>Main Services</h5>
                    <ul className="list-group mb-4">
                        {
                            loading ? <SkeletonFilterLoader /> :
                                <>{uniqueMainServices.map((title, index) => (
                                    <li
                                        key={index}
                                        className={`list-group-item ${mainServiceFilter === title ? 'active-filter' : ''}`}
                                        onClick={() => handleMainServiceClick(title)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {title}
                                    </li>
                                ))}</>
                        }

                    </ul>
                    <h5>Related Services</h5>
                    <ul className="list-group" style={{ height: '35vh', overflowY: 'auto' }}>
                        {loading ? <SkeletonFilterLoader /> :
                            <>
                                {uniqueSubServices.map((title, index) => (
                                    <li
                                        key={index}
                                        className={`list-group-item ${subServiceFilter === title ? 'active-filter' : ''}`}
                                        onClick={() => handleSubServiceClick(title)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {title}
                                    </li>
                                ))}
                            </>
                        }
                    </ul>
                </div>
                <div className="col-md-9 fixed-height">
                    <h2 className="mb-4 position-relative">
                        Our Services
                        <span className="underline" style={{ left: '10%' }}></span>
                    </h2>
                    {loading ? (
                        <SkeletonLoader /> // Show skeleton loader while loading
                    ) : (
                        <div className="row row-cols-1 row-cols-md-3 g-4">
                            {filteredServices?.map((data, index) => (
                                <div className="col" key={index} style={{ cursor: 'pointer' }}>
                                    <div className="card h-100 shadow service-card">
                                        <div className="card-body d-flex flex-column justify-content-between">
                                            <div>
                                                <h5 className="card-title">{data.title}</h5>
                                                <h6 className="card-subtitle mb-2 text-muted">
                                                    {data.mainServiceId.title} - {data.subServiceL1Id.title}
                                                </h6>
                                            </div>
                                            <button
                                                onClick={() => {
                                                    navigate({
                                                        pathname: '/Service',
                                                        search: `tab=${data.title}&subtab=${data.subServiceL1Id.title} | ${data.mainServiceId.title}&id=${data._id}`,
                                                    });
                                                }}
                                                className="btn btn-primary mt-auto"
                                            >
                                                Know more
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function SkeletonLoader() {
    return (
        <div className="skeleton-container">
            <div className="skeleton-card">
                <div className="skeleton-header"></div>
                <div className="skeleton-title"></div>
                <div className="skeleton-subtitle"></div>
                <div className="skeleton-button"></div>
            </div>
        </div>
    );
}

function SkeletonFilterLoader() {
    return (
        <div className="skeleton-container">
            <div className="skeleton-card">
                <div className="skeleton-header"></div>
                {/* <div className="skeleton-title"></div>
                <div className="skeleton-subtitle"></div>
                <div className="skeleton-button"></div> */}
            </div>
        </div>
    );
}
