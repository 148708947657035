import { useEffect, useState } from "react";
import FreelancerPartnerNav from "../../../../Components/v1/NavBar/FreelancerPartnerNav";
import CreateTaskComponent from "../../../../Components/v1/FreelancerPartner/Leads/CreateTaskComponent";


export default function CreateTask({userTypeProps, lableProps,isClient,routeProps}) {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const [show, setShow] = useState(true);
  const handleToggle = () => {setShow(!show);};


  return (
    <>
      <FreelancerPartnerNav showFullNav={show} onToggle={handleToggle} userTypeProps={userTypeProps} routeProps={routeProps}/>
      <CreateTaskComponent showFullNav={show} lableProps={lableProps} userTypeProps={userTypeProps} isClient={isClient} routeProps={routeProps}/>
    </>
  );
}
